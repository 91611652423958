import Vue from 'vue';
import SPR from '@/models/SPR';

const actions = {
  // Delete the given SPR
  async deleteSPRAsync({ commit }, data) {
    if (!data || !data.spr || !data.spr.delete) throw new Error('expected spr');
    await commit('setLastError', '');
    await commit('setSaving', true);
    try {
      // remove the SPR from the authorization provider
      await data.spr.delete();
    } catch (error) {
      await commit('setLastError', error.message || error);
      await commit('setSaving', false);
      throw error;
    }
    // remove the SPR from the store
    await commit('deleteSPR', data.spr);
    await commit('setSaving', false);
  },
  // Load SPR configs from BE
  async loadSPRsAsync({ commit, dispatch, rootGetters }) {
    const companyId = rootGetters['companies/getSelectedCompanyId'];
    if (!companyId) {
      await commit('setLoading', false);
      throw new Error('companyId is required by loadSPRsAsync');
    }
    await dispatch('resetState');
    // get SPRs
    let sprs;
    try {
      sprs = await SPR.getSPRs(companyId);
    } catch (error) {
      // reset the loading flag only on failure
      await commit('setLoading', false);
      throw error;
    }
    await commit('setSPRs', sprs);
  },
  // Reset the state to initial values
  resetState({ commit }) {
    commit('setLoading', true);
    commit('setSaving', false);
    commit('setSPRs', {});
  },
  // Save the given SPR
  async saveSPRAsync({ commit }, data) {
    if (!data || !data.spr || !data.spr.post) throw new Error('expected spr');
    await commit('setLastError', '');
    await commit('setSaving', true);
    let spr;
    try {
      if (data.spr.isNewSPR) spr = await data.spr.post();
      else spr = await data.spr.put();
    } catch (error) {
      await commit('setLastError', error.message || error);
      await commit('setSaving', false);
      throw error;
    }
    await commit('saveSPR', spr);
    await commit('setSaving', false);
  },
  // set the loading flag to the given value
  setLoading({ commit }, value) {
    commit('setLoading', value);
  },
  setSPRs({ commit }, value) {
    commit('setSPRs', value);
  },
  // set the saving flag to the given value
  setSaving({ commit }, value) {
    commit('setSaving', value);
  },
  // set the searchString to the given value
  setSearchString({ commit }, value) {
    commit('setSearchString', value);
  },
};

const getters = {
  // returns the last error received by the api
  getLastError: state => {
    return state.lastError;
  },
  // returns true if data is being loaded or false otherwise
  getLoading: state => {
    return state.loading;
  },
  getSPRs: state => {
    return state.sprs;
  },
  getSPRsById: state => id => {
    if (!id || !state.sprs) throw new Error('invalid params or state empty');
    let result;
    const keys = Object.keys(state.sprs);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const siteSprList = state.sprs[key] || [];
      result = siteSprList.find(s => s.id === id);
      if (result) {
        break;
      }
    }
    return result;
  },
  // returns true if data is being saved or false otherwise
  getSaving: state => {
    return state.saving;
  },
  getSearchString: state => {
    return state.searchString;
  },
};

const mutations = {
  // delete specified SPR from state.sprs
  deleteSPR(state, spr) {
    if (spr && spr.siteId) {
      // remove the SPR from the store
      Vue.set(
        state.sprs,
        spr.siteId,
        (state.sprs[spr.siteId] || []).filter(i => i.id !== spr.id),
      );
    }
  },
  // add new SPR or update specified SPR in state.sprs
  saveSPR(state, spr) {
    if (spr && spr.siteId) {
      Vue.set(state.sprs, spr.siteId, state.sprs[spr.siteId] || []);
      const index = state.sprs[spr.siteId].findIndex(i => i.id === spr.id);
      if (index >= 0) {
        // update the spr in the store
        Vue.set(state.sprs[spr.siteId], index, spr);
      } else {
        // add the spr to the store
        state.sprs[spr.siteId].push(spr);
      }
    }
  },
  // set the last error message from the api
  setLastError(state, value) {
    state.lastError = value;
  },
  // set the loading flag to the given value
  setLoading(state, value) {
    state.loading = value;
  },
  // set the loading flag to the given value
  setSPRs(state, value) {
    state.sprs = value;
  },
  // set the saving flag to the given value
  setSaving(state, value) {
    state.saving = value;
  },
  // set the search string to the given value
  setSearchString(state, value) {
    state.searchString = value;
  },
};

const state = () => ({
  lastError: '',
  loading: true,
  saving: false,
  searchString: '',
  sprs: {},
});

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
