import isString from 'lodash-es/isString';

// returns true if the given time is in the expected format or false otherwise
const isValidTime = time => {
  // verify that the time is a string
  if (!isString(time)) {
    return false;
  }

  // ensure the time matches 24 hour time format (HH:MM:SS)
  const regex1 = /^(?:[01]\d|2[0-3])(?::[0-5]\d){2}$/;
  // ensure the time matches 24 hour time format (HH:MM)
  const regex2 = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

  return regex1.test(time) || regex2.test(time);
};

export default isValidTime;
