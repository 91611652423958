import flatten from 'lodash-es/flatten';
import has from 'lodash-es/has';
import Vue from 'vue';
import cerberusAPI from '@/api/cerberusAPI';
import constants from '@/config/constants';
import Schedule from '@/models/Schedule';
import handleCerberusData from '@/util/handleCerberusData';

const actions = {
  // add a new empty schedule for the selected site to the store
  addNewSchedule({ commit, getters, rootGetters }) {
    const siteId = rootGetters['sites/getSelectedSiteId'];

    if (!getters.getCurrentBatchId || !siteId) {
      throw new Error('currentBatchId and siteId are required by addNewSchedule');
    }

    commit('addSchedules', {
      batchId: getters.getCurrentBatchId,
      scheduleList: [
        {
          schedule: new Schedule('', [], '', '', []),
          siteId,
        },
      ],
    });
  },

  // add a new empty shift for the selected site to the store
  addNewShift({ commit, getters, rootGetters }, data) {
    const siteId = rootGetters['sites/getSelectedSiteId'];

    if (!getters.getCurrentBatchId || !siteId || !has(data, 'scheduleIndex')) {
      throw new Error('currentBatchId, siteId, and scheduleIndex are required by addNewShift');
    }

    commit('addShift', {
      scheduleIndex: data.scheduleIndex,
      shift: { end_time: '', name: '', start_time: '' },
      siteId,
    });
  },

  // delete the given schedule from the store
  deleteSchedule({ commit, getters, rootGetters }, data) {
    const siteId = rootGetters['sites/getSelectedSiteId'];

    if (!getters.getCurrentBatchId || !siteId || !has(data, 'scheduleIndex')) {
      throw new Error('currentBatchId, siteId, and scheduleIndex are required by deleteSchedule');
    }
    commit('deleteSchedule', {
      scheduleIndex: data.scheduleIndex,
      siteId,
    });
  },

  // delete the given shift from the store
  deleteShift({ commit, getters, rootGetters }, data) {
    const siteId = rootGetters['sites/getSelectedSiteId'];

    if (
      !getters.getCurrentBatchId ||
      !siteId ||
      !has(data, 'scheduleIndex') ||
      !has(data, 'shiftIndex')
    ) {
      throw new Error(
        'currentBatchId, siteId, scheduleIndex, and shiftIndex are required by deleteShift',
      );
    }

    commit('deleteShift', {
      scheduleIndex: data.scheduleIndex,
      shiftIndex: data.shiftIndex,
      siteId,
    });
  },

  /**
   * get the pending schedules for the given company, batch, and optional site
   * from the api and store them by batch id and site id
   */
  async getPendingSchedules({ commit }, data) {
    if (!has(data, 'companyId')) {
      throw new Error('companyId are required by getPendingSchedules');
    }
    let scheduleList;
    // fetch schedules
    if (data.siteId) {
      const result = await cerberusAPI.getPendingScheduleBySite({
        companyId: data.companyId,
        siteId: data.siteId,
      });
      scheduleList = handleCerberusData.processScheduleBySiteResp(result);
    } else {
      const result = await cerberusAPI.getPendingSchedule({
        companyId: data.companyId,
      });
      scheduleList = handleCerberusData.processScheduleByCompanyResp(result);
    }
    commit('addSchedules', {
      batchId: constants.BATCH_PENDING,
      scheduleList,
    });
  },

  /**
   * get the published schedules for the given company, batch, and optional site
   * from the api and store them by batch id and site id
   */
  async getPublishedSchedules({ commit }, data) {
    if (!has(data, 'companyId')) {
      throw new Error('companyId are required by getPublishedSchedules');
    }
    let scheduleList;
    // fetch schedules
    if (data.siteId) {
      const result = await cerberusAPI.getScheduleBySite({
        companyId: data.companyId,
        siteId: data.siteId,
      });
      scheduleList = handleCerberusData.processScheduleBySiteResp(result);
    } else {
      const result = await cerberusAPI.getSchedule({
        companyId: data.companyId,
      });
      scheduleList = handleCerberusData.processScheduleByCompanyResp(result);
    }
    commit('addSchedules', {
      batchId: constants.BATCH_LATEST,
      scheduleList,
    });
  },

  // load the Schedules definitions
  async loadSchedules({ commit, dispatch, rootGetters }, siteId) {
    const companyId = rootGetters['companies/getSelectedCompanyId'];

    if (!companyId) {
      commit('setLoading', false);

      throw new Error('companyId is required by loadSchedules');
    }

    dispatch('resetState');

    // get published Schedule definitions
    try {
      await dispatch('getPublishedSchedules', {
        companyId,
        siteId,
      });
    } catch (error) {
      // reset the loading flag only on failure
      commit('setLoading', false);

      throw error;
    }

    // get pending Schedule definitions
    try {
      await dispatch('getPendingSchedules', {
        companyId,
        siteId,
      });
    } catch (error) {
      // reset the loading flag only on failure
      commit('setLoading', false);

      throw error;
    }

    await dispatch('setCurrentBatchId');
  },

  // publish the saved schedules for the current company
  async publishSchedules({ commit, rootGetters }) {
    const companyId = rootGetters['companies/getSelectedCompanyId'];

    if (!companyId) {
      throw new Error('companyId is required by publishSchedules');
    }

    commit('setSaving', true);

    try {
      await cerberusAPI.publishPendingSchedule({ companyId });
    } finally {
      commit('setSaving', false);
    }
  },

  // reset the state to initial values
  resetState({ commit }) {
    commit('setCurrentBatchId', null);
    commit('setLoading', true);
    commit('setShiftsOverlap', false);
    commit('setSchedulesOverlap', false);
    commit('setSaving', false);
    commit('setSchedules', {});
    commit('setValid', true);
  },

  // save the schedules
  async saveSchedules({ commit, getters, rootGetters }) {
    const companyId = rootGetters['companies/getSelectedCompanyId'];
    const siteId = rootGetters['sites/getSelectedSiteId'];

    if (!getters.getCurrentBatchId || !companyId || !siteId) {
      throw new Error('companyId, currentBatchId, and siteId are required by saveSchedules');
    }

    const scheduleJSON = {
      company_id: companyId,
      schedules: getters.getCurrentSchedules.map(schedule => schedule.toJSON()),
      site_id: siteId,
    };

    commit('setSaving', true);

    try {
      await cerberusAPI.createPendingSchedule(
        {
          companyId,
          siteId,
        },
        scheduleJSON,
      );
    } finally {
      commit('setSaving', false);
    }
  },

  // determine the current batch and store it
  async setCurrentBatchId({ commit, getters, rootGetters }) {
    const siteId = rootGetters['sites/getSelectedSiteId'];

    const latest = getters.getSchedulesBatch(constants.BATCH_LATEST, siteId);
    const pending = getters.getSchedulesBatch(constants.BATCH_PENDING, siteId);
    // do not display pending schedules for users without EDITOR access
    const hasScheduleEditFeatureControl = rootGetters['user/hasFeatureControl'](
      constants.FEATURES.SCHEDULE,
      constants.CONTROL_LEVELS.EDITOR,
    );

    if (!hasScheduleEditFeatureControl || (latest.length > 0 && pending.length === 0)) {
      commit('setCurrentBatchId', constants.BATCH_LATEST);
    } else {
      commit('setCurrentBatchId', constants.BATCH_PENDING);
    }
  },

  // set the loading flag to the given value
  async setLoading({ commit }, value) {
    commit('setLoading', value);
  },

  /**
   * update the schedule overlap flag for the given schedule and then update the
   * overall schedule overlap state
   */
  async setScheduleOverlap({ commit, dispatch, getters }, data) {
    if (!getters.getCurrentBatchId || !has(data, 'scheduleIndex') || !has(data, 'siteId')) {
      throw new Error('currentBatchId and index and siteId are required by setScheduleOverlap');
    }

    commit('setScheduleObjectOverlapFlag', data);

    await dispatch('validateOverlap');
  },

  /**
   * update the given schedule with the given data and then update the overall
   * validation state
   */
  async setScheduleValue({ commit, dispatch, getters }, data) {
    if (
      !getters.getCurrentBatchId ||
      !has(data, 'field') ||
      !has(data, 'scheduleIndex') ||
      !has(data, 'siteId')
    ) {
      throw new Error(
        'currentBatchId, field, scheduleIndex, and siteId are required by setScheduleValue',
      );
    }

    commit('setScheduleValue', data);

    // if necessary, trigger overall validation
    if (data.validate) {
      await dispatch('validateSchedules');
    }
  },

  /**
   * update the shift overlap flag for the given shift and then update the
   * overall overlap state
   */
  async setShiftOverlap({ commit, getters, dispatch, rootGetters }, data) {
    if (
      !getters.getCurrentBatchId ||
      !has(data, 'shiftIndex') ||
      !has(data, 'scheduleIndex') ||
      !rootGetters['sites/getSelectedSiteId']
    ) {
      throw new Error(
        'currentBatchId and shiftIndex and scheduleIndex and rootGetters[sites/getSelectedSiteId] are required by setShiftOverlap',
      );
    }

    commit('setShiftObjectOverlapFlag', {
      siteId: rootGetters['sites/getSelectedSiteId'],
      ...data,
    });

    await dispatch('validateOverlap');
  },

  /**
   * update the given shift with the given data and then update the overall
   * validation state
   */
  async setShiftTimeOutsideSchedule({ commit, getters }, data) {
    if (
      !getters.getCurrentBatchId ||
      !has(data, 'scheduleIndex') ||
      !has(data, 'shiftIndex') ||
      !has(data, 'siteId')
    ) {
      throw new Error(
        'currentBatchId, field, scheduleIndex, shiftIndex, and siteId are required by setShiftTimeOutsideSchedule',
      );
    }

    commit('setShiftTimeOutsideScheduleValue', data);
  },

  /**
   * update the given shift with the given data and then update the overall
   * validation state
   */
  async setShiftValue({ commit, dispatch, getters }, data) {
    if (
      !getters.getCurrentBatchId ||
      !has(data, 'field') ||
      !has(data, 'scheduleIndex') ||
      !has(data, 'shiftIndex') ||
      !has(data, 'siteId')
    ) {
      throw new Error(
        'currentBatchId, field, scheduleIndex, shiftIndex, and siteId are required by setShiftValue',
      );
    }

    commit('setShiftValue', data);

    // if necessary, trigger overall validation
    if (data.validate) {
      await dispatch('validateSchedules');
    }
  },

  // verify that the schedules are valid
  async validate({ dispatch }) {
    await dispatch('validateOverlap');
    await dispatch('validateSchedules');
  },

  // verify that the shifts do not overlap
  async validateOverlap({ commit, getters, rootGetters }) {
    if (!getters.getCurrentBatchId || !rootGetters['sites/getSelectedSiteId']) {
      throw new Error('currentBatchId and siteId are required by validateOverlap');
    }

    // find overlapping shifts
    const overlappingShifts = getters.getCurrentSchedules.filter(schedule => {
      const overlapped = schedule.shifts.filter(shift => shift.overlap === true);
      return overlapped.length > 0;
    });

    commit('setShiftsOverlap', overlappingShifts.length > 0);

    // find overlapping schedules
    const overlappingSchedules = getters.getCurrentSchedules.filter(
      schedule => schedule.overlap === true,
    );
    // TODO: write logic to identify overlapping Schedules

    commit('setSchedulesOverlap', overlappingSchedules.length > 0);
  },

  // verify that the schedules do not have any field related errors
  async validateSchedules({ commit, getters, rootGetters }) {
    if (!getters.getCurrentBatchId || !rootGetters['sites/getSelectedSiteId']) {
      throw new Error('currentBatchId and siteId are required by validateSchedules');
    }

    // find invalid schedules
    const invalidSchedules = getters.getCurrentSchedules.filter(
      schedule => schedule.isValid() === false,
    );

    commit('setValid', invalidSchedules.length === 0);
  },
};

const getters = {
  /**
   * returns true if schedules for the given site are considered to be
   * complete or false otherwise
   */
  areSchedulesComplete: (state, schedulesGetters) => siteId => {
    return !schedulesGetters.hasPending(siteId) && schedulesGetters.hasLatest(siteId);
  },

  // returns true if all schedules are valid or false otherwise
  areSchedulesValid: state => {
    return state.valid;
  },

  // returns a deep copy of schedule.shifts from all current schedules
  getAllShifts: (state, schedulesGetters) => {
    const schedules = schedulesGetters.getCurrentSchedules || [];
    return flatten(
      schedules.map(schedule => {
        return schedule.getShifts();
      }),
    );
  },

  // Returns Shift objects extrapolated by days in Schedule definition
  getAllShiftsByDay: (state, schedulesGetters) => {
    const schedules = schedulesGetters.getCurrentSchedules || [];
    return flatten(
      schedules
        .filter(schedule => schedule.isComplete())
        .map(schedule => {
          return schedule.getShiftsByDay();
        }),
    );
  },

  // returns the current batch id
  getCurrentBatchId: state => {
    return state.currentBatchId;
  },

  // returns an array of schedules for the current batch and site
  getCurrentSchedules: (state, schedulesGetters, rootState, rootGetters) => {
    const siteId = rootGetters['sites/getSelectedSiteId'];

    return schedulesGetters.getSchedulesBatch(state.currentBatchId, siteId);
  },

  // returns true if data is being loaded or false otherwise
  getLoading: state => {
    return state.loading;
  },

  // returns true if data is being saved or false otherwise
  getSaving: state => {
    return state.saving;
  },

  // returns the current schedule as per the index supplied
  getScheduleByIndex: (state, schedulesGetters) => index => {
    const schedules = schedulesGetters.getCurrentSchedules || [];
    return index >= 0 && schedules && schedules[index] ? schedules[index] : null;
  },

  // returns the schedules object
  getSchedules: state => {
    return state.schedules;
  },

  // returns the schedules for the given batch and site
  getSchedulesBatch: state => (batchId, siteId) => {
    if (has(state.schedules, `${batchId}.${siteId}`)) {
      return state.schedules[batchId][siteId];
    }

    return [];
  },

  // returns the number of schedules in the given batch
  getSchedulesBatchCount: state => (batchId, siteId) => {
    // if the given batch or optional site do not exist return 0
    if (!has(state.schedules, batchId) || (siteId && !has(state.schedules[batchId], siteId))) {
      return 0;
    }

    /**
     * if the site id was given return the number of schedules defined
     * for the site
     */
    if (siteId) {
      return state.schedules[batchId][siteId].length;
    }

    // otherwise return the total number of schedules in the batch
    let total = 0;
    Object.values(state.schedules[batchId]).forEach(schedule => {
      total += schedule.length;
    });

    return total;
  },

  // returns only Unique Shift names which Vue component uses for color coding
  getUniqueShiftNames: (state, schedulesGetters) => {
    const allShifts = schedulesGetters.getAllShifts || [];
    return Array.from(new Set(allShifts.map(shift => shift.name)));
  },

  /**
   * returns true if schedules are defined for the current company
   * or false otherwise
   *
   * if the optional siteId is provided the result is specific to the given site
   */
  hasLatest: (state, schedulesGetters) => siteId => {
    return schedulesGetters.getSchedulesBatchCount(constants.BATCH_LATEST, siteId) > 0;
  },

  /**
   * returns true if there are pending schedules for the current company
   * or false otherwise
   *
   * if the optional siteId is provided the result is specific to the given site
   */
  hasPending: (state, schedulesGetters) => siteId => {
    return schedulesGetters.getSchedulesBatchCount(constants.BATCH_PENDING, siteId) > 0;
  },

  // returns true if two or more Schedules overlap or false otherwise
  isSchedulesOverlap: state => {
    return state.schedulesOverlap;
  },

  // returns true if two or more shift overlap or false otherwise
  isShiftOverlap: state => {
    return state.shiftsOverlap;
  },
};

const mutations = {
  // add the given schedules to the store
  addSchedules(state, data) {
    let batchId;
    if (has(data, 'batchId')) {
      batchId = data.batchId;
    } else {
      batchId = state.currentBatchId;
    }

    if (!has(state.schedules, batchId)) {
      Vue.set(state.schedules, batchId, {});
    }

    if (data.scheduleList && Array.isArray(data.scheduleList)) {
      data.scheduleList.forEach(scheduleData => {
        if (!has(state.schedules[batchId], scheduleData.siteId)) {
          Vue.set(state.schedules[batchId], scheduleData.siteId, []);
        }
        state.schedules[batchId][scheduleData.siteId].push(scheduleData.schedule);
      });
    }
  },

  // add the given shift to the store
  addShift(state, data) {
    let batchId;
    if (has(data, 'batchId')) {
      batchId = data.batchId;
    } else {
      batchId = state.currentBatchId;
    }

    if (!has(state.schedules, batchId)) {
      Vue.set(state.schedules, batchId, {});
    }

    if (!has(state.schedules[batchId], data.siteId)) {
      Vue.set(state.schedules[batchId], data.siteId, []);
    }

    if (!has(state.schedules[batchId][data.siteId][data.scheduleIndex], 'shifts')) {
      Vue.set(state.schedules[batchId][data.siteId][data.scheduleIndex], 'shifts', []);
    }

    state.schedules[batchId][data.siteId][data.scheduleIndex].shifts.push(data.shift);
  },

  // delete the given schedule from the store
  deleteSchedule(state, data) {
    Vue.delete(state.schedules[state.currentBatchId][data.siteId], data.scheduleIndex);
  },

  // delete the given shift from the store
  deleteShift(state, data) {
    Vue.delete(
      state.schedules[state.currentBatchId][data.siteId][data.scheduleIndex].shifts,
      data.shiftIndex,
    );
  },

  // set currentBatchId to the given value
  setCurrentBatchId(state, value) {
    state.currentBatchId = value;
  },

  // set the loading flag to the given value
  setLoading(state, value) {
    state.loading = value;
  },

  // set the saving flag to the given value
  setSaving(state, value) {
    state.saving = value;
  },

  // set the overlap flag for the given shift to the given value
  setScheduleObjectOverlapFlag(state, data) {
    Vue.set(
      state.schedules[state.currentBatchId][data.siteId][data.scheduleIndex],
      'overlap',
      data.value,
    );
  },

  // set the value for the given schedule field to the given value
  setScheduleValue(state, data) {
    Vue.set(
      state.schedules[state.currentBatchId][data.siteId][data.scheduleIndex],
      data.field,
      data.value,
    );
  },

  // set schedules to the given value
  setSchedules(state, value) {
    state.schedules = value;
  },

  // set the overlap flag to the given value
  setSchedulesOverlap(state, value) {
    state.schedulesOverlap = value;
  },

  // set the overlap flag for the given shift to the given value
  setShiftObjectOverlapFlag(state, data) {
    Vue.set(
      state.schedules[state.currentBatchId][data.siteId][data.scheduleIndex].shifts[
        data.shiftIndex
      ],
      'overlap',
      data.value,
    );
  },

  // set the timeOutsideSchedule flag for given shift
  setShiftTimeOutsideScheduleValue(state, data) {
    // Not using Vue.set below, as this doesn't have any effect on the UI.
    // On Submit Validations are run which uses this flag in combination with other things.
    state.schedules[state.currentBatchId][data.siteId][data.scheduleIndex].shifts[
      data.shiftIndex
    ].timeOutsideSchedule = data.value;
  },

  // set the value for the given shift field to the given value
  setShiftValue(state, data) {
    Vue.set(
      state.schedules[state.currentBatchId][data.siteId][data.scheduleIndex].shifts[
        data.shiftIndex
      ],
      data.field,
      data.value,
    );
  },

  // set the overlap flag to the given value
  setShiftsOverlap(state, value) {
    state.shiftsOverlap = value;
  },

  // set the valid flag to the given value
  setValid(state, value) {
    state.valid = value;
  },
};

const state = () => ({
  currentBatchId: null,
  loading: true,
  saving: false,
  schedules: {},
  schedulesOverlap: false,
  shiftsOverlap: false,
  valid: true,
});

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
