<template>
  <BaseButton
    ref="menuButton"
    class="c-btn--toggle-menu"
    button-type="ghost"
    icon="fg-icon-v-menu"
    icon-height="24px"
    icon-width="24px"
    :icon-sprite="iconSprite"
    viewBox="0 0 24 24"
    just-icon
    @click="onToggleMenu"
  />
</template>

<script>
import { BaseButton } from '@seegrid/components';
import Vue from 'vue';
import IconSprite from '@/assets/images/icon-sprite.svg';

export default Vue.extend({
  name: 'UsersMgmtTableActions',
  components: { BaseButton },
  props: {},
  computed: {
    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },
  },
  methods: {
    invokeParentMethod(action, event) {
      event.stopPropagation();
      this.params.context.componentParent.performRowAction(event, this.params, action);
    },
    onToggleMenu(e) {
      this.params.context.contextMenu(this.params, true, e.pageX, e.pageY);
      e.cancelBubble = true;
      return false;
    },
  },
});
</script>

<style lang="scss">
.ag-row.ag-row-selected .c-btn.c-btn--toggle-menu {
  display: flex;
}
</style>
