<template>
  <header class="bg-primary-dark">
    <TheNavigation
      ref="theNavigation"
      :company-change-guard="companyChangeGuard"
      :company-redirect-route="companyRedirectRoute"
      :hide-company-select="hideCompanySelect"
      @company-change-initiated="$emit('company-change-initiated')"
    />
  </header>
</template>

<script>
import TheNavigation from '@/components/TheNavigation.vue';

export default {
  name: 'TheHeader',

  components: {
    TheNavigation,
  },

  props: {
    // :company-change-guard="true" means on Company drop down change only notification will emit and the hosting view needs to confirm the company change
    // Otherwise TheNavigation component will complete the company change
    // This is used to prevent company change with unsaved changes
    companyChangeGuard: {
      default: false,
      type: Boolean,
    },
    companyRedirectRoute: {
      default: 'sites',
      type: String,
    },
    hideCompanySelect: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    cancelCompanyChange() {
      this.$refs.theNavigation.cancelChangeCompany();
    },
    async confirmCompanyChange() {
      await this.$refs.theNavigation.changeCompany();
    },
  },
};
</script>
