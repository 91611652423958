import { cerberusClient } from './cerberusClient';
import Routes from './cerberusRoutes';
import constants from '@/config/constants';

// add the given global role(s) to the given user
const addUserGlobalRoles = (userId, data) => {
  const route = Routes.auth.USERS_USER_GLOBAL_ROLES.replace(':user_id', userId);

  return cerberusClient().post(route, data);
};

// create a user with the given data
const createUser = data => {
  const route = Routes.auth.USERS;

  return cerberusClient().post(route, data);
};

// create bulk users with the given data
const createBulkUsers = data => {
  const route = Routes.auth.USERS_BULK;

  return cerberusClient().post(route, data);
};

// delete the given user
const deleteUser = userId => {
  const route = Routes.auth.USERS_USER.replace(':user_id', userId);

  return cerberusClient().delete(route);
};

// returns a mock roles api response
const getRoles = () => {
  const route = Routes.auth.ROLES;

  return cerberusClient().get(route);
};

// get the user for the given id
const getUser = userId => {
  const route = Routes.auth.USERS_USER.replace(':user_id', userId);

  return cerberusClient().get(route);
};

// get the global roles for the given user
const getUserGlobalRoles = userId => {
  const route = Routes.auth.USERS_USER_GLOBAL_ROLES.replace(':user_id', userId);

  return cerberusClient().get(route);
};

/**
 * Get the user's organizations
 * global_access=true: return all organizations the target user has access to
 * global_access=false: return only the current organization info
 * @param {*} userId - id of the user
 * @param {*} globalAccess - boolean
 */
const getUserOrganizations = (userId, globalAccess = false) => {
  const route = Routes.auth.USERS_USER_ORGANIZATIONS.replace(':user_id', userId);
  const clientAccess = globalAccess
    ? constants.CERBERUS_CLIENT_TYPES.GLOBAL
    : constants.CERBERUS_CLIENT_TYPES.ORG_BASED;

  return cerberusClient(clientAccess).get(route);
};

/**
 * Get the user's organization group mappings
 * global_access=true: return group mappings in all companies that the target user have access to
 * global_access=false: return only the group mappings of the current organization
 * @param {*} userId - id of the user
 * @param {*} globalAccess - boolean
 */
const getUserOrganizationsGroups = (userId, globalAccess = false) => {
  const route = Routes.auth.USERS_USER_ORGANIZATIONS_GROUPS.replace(':user_id', userId);
  const clientAccess = globalAccess
    ? constants.CERBERUS_CLIENT_TYPES.GLOBAL
    : constants.CERBERUS_CLIENT_TYPES.ORG_BASED;

  return cerberusClient(clientAccess).get(route);
};

/**
 * Get the user's organization role mappings
 * global_access=true: return role mappings in all companies that the target user have access to
 * global_access=false: return only the role mappings of the current organization
 * @param {*} userId - id of the user
 * @param {*} globalAccess - boolean
 */
const getUserOrganizationsRoles = (userId, globalAccess = false) => {
  const route = Routes.auth.USERS_USER_ORGANIZATIONS_ROLES.replace(':user_id', userId);
  const clientAccess = globalAccess
    ? constants.CERBERUS_CLIENT_TYPES.GLOBAL
    : constants.CERBERUS_CLIENT_TYPES.ORG_BASED;

  return cerberusClient(clientAccess).get(route);
};

// remove the given global role(s) from the given user
const removeUserGlobalRoles = (userId, data) => {
  const route = Routes.auth.USERS_USER_GLOBAL_ROLES.replace(':user_id', userId);

  return cerberusClient().delete(route, { data });
};

// send the welcome email to the given user
const sendResetPasswordEmail = userId => {
  const route = Routes.auth.USERS_USER_SEND_RESET_PASSWORD_EMAIL.replace(':user_id', userId);

  return cerberusClient().post(route);
};

// send the welcome email to the given user
const sendVerificationEmail = userId => {
  const route = Routes.auth.USERS_USER_SEND_VERIFICATION_EMAIL.replace(':user_id', userId);

  return cerberusClient().post(route);
};

// send the welcome email to the given user
const sendWelcomeEmail = userId => {
  const route = Routes.auth.USERS_USER_SEND_WELCOME_EMAIL.replace(':user_id', userId);

  return cerberusClient().post(route);
};

// update the given user with the given data
const updateUser = (userId, data) => {
  const route = Routes.auth.USERS_USER.replace(':user_id', userId);

  return cerberusClient().put(route, data);
};

export default {
  addUserGlobalRoles,
  createBulkUsers,
  createUser,
  deleteUser,
  getRoles,
  getUser,
  getUserGlobalRoles,
  getUserOrganizations,
  getUserOrganizationsGroups,
  getUserOrganizationsRoles,
  removeUserGlobalRoles,
  sendResetPasswordEmail,
  sendVerificationEmail,
  sendWelcomeEmail,
  updateUser,
};
