<template>
  <div class="admin-page-container">
    <TheHeader
      ref="theHeader"
      :company-change-guard="true"
      @company-change-initiated="handleCompanyChangeInit"
    />

    <Spinner v-if="savingShifts" />

    <section class="flex flex-col flex-grow h-0 max-w-6xl mt-16 mx-auto w-11/12">
      <!-- Hiding this per ux / design recommendation
      <p class="font-medium mt-5 text-gray-6 text-sm">
        {{ $t('navigation.breadcrumbs.accountManagement') }} / {{ $t('navigation.schedules') }}
      </p> -->
      <div class="flex items-center mb-4">
        <BaseButton
          button-type="ghost"
          icon="arrow-left"
          icon-height="24px"
          icon-width="24px"
          viewBox="0 0 16 16"
          just-icon
          :icon-sprite="iconSprite"
          @click="onCancel()"
        />
        <div v-if="site">
          <span class="font-medium inline-block ml-2 mr-4 text-2xl text-primary-dark">{{
            site.name
          }}</span>
          <span class="font-medium text-gray-6 text-xs">{{ getCanonicalTimezone }}</span>
        </div>
        <div class="flex items-center ml-auto">
          <BaseButton
            v-if="hasScheduleEditFeatureControl"
            size="small"
            :disabled="isSaveButtonDisabled"
            @click="onSubmit()"
            >{{ $t('schedules.saveSchedule') }}</BaseButton
          >
        </div>
      </div>

      <div class="flex flex-grow">
        <div class="flex flex-col mr-3 w-1/4" style="min-width: 30rem;">
          <BaseGlobalMessage
            v-if="messageDisplay"
            style="border: none; border-radius:0.25rem; margin:0 0 0.75rem; min-width: 30rem;"
            :text="messageText"
            :type="messageType"
          />
          <div class="bg-white flex-grow h-0 pb-2 rounded" style="min-width: 30rem;">
            <ScheduleList ref="scheduleList" :loading-schedules="loadingSchedules" />
          </div>
        </div>
        <div ref="calendarContainer" v-resize.initial="onResize" class="flex-grow overflow-y-auto">
          <Calendar
            class="absolute"
            :days="days"
            :height="height"
            :margins="margins"
            :width="width"
          />
        </div>
      </div>
      <ConfirmationModal
        ref="exitModal"
        align="left"
        :confirm-title="$t('formShift.leaveModalTitle')"
        :confirm-message="$t('formShift.leaveModalText')"
        :cancel-button="$t('formShift.leaveModalCancel')"
        :confirm-button="$t('formShift.leaveModalConfirm')"
        :danger-confirm="false"
        @on-confirm-action="onConfirmNavigate"
        @on-cancel-action="onCancelNavigate"
      />
    </section>

    <TheFooter />
  </div>
</template>

<script>
import { BaseButton, BaseGlobalMessage } from '@seegrid/components';
import { DateTime } from 'luxon';
import resize from 'vue-resize-directive';
import IconSprite from '@/assets/images/icon-sprite.svg';
import Calendar from '@/components/Calendar.vue';
import ScheduleList from '@/components/ScheduleList.vue';
import Spinner from '@/components/Spinner.vue';
import TheFooter from '@/components/TheFooter.vue';
import TheHeader from '@/components/TheHeader.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import constants from '@/config/constants';
import i18n from '@/i18n';
import authorization from '@/mixins/authorization';
import leaveConfirmModalHandlers from '@/mixins/leaveConfirmModalHandlers';
import message from '@/mixins/message';

const CALENDAR_MIN_HEIGHT = 240;
const CALENDAR_MIN_WIDTH = 360;

export default {
  name: 'SchedulesView',

  components: {
    BaseButton,
    BaseGlobalMessage,
    Calendar,
    ConfirmationModal,
    ScheduleList,
    Spinner,
    TheFooter,
    TheHeader,
  },

  directives: {
    resize,
  },

  mixins: [authorization, message, leaveConfirmModalHandlers],

  beforeRouteLeave(to, from, next) {
    if (this.areSchedulesChanged && !this.leaveConfirmed) {
      this.leaveTo = to;
      this.$refs.exitModal.open();

      return next(false);
    }

    return next();
  },

  data() {
    return {
      areSchedulesChanged: false,
      height: 600,
      leaveConfirmed: false,
      leaveTo: {},
      margins: {
        bottom: 1,
        left: 50,
        right: 1,
        top: 20,
      },
      width: 1000,
    };
  },

  computed: {
    // returns true if two or more shift definitions overlap or false otherwise
    areScheduleOverlapping() {
      return this.$store.getters['schedules/isSchedulesOverlap'];
    },

    // returns true if all schedules have valid data or false otherwise
    areSchedulesValid() {
      return this.$store.getters['schedules/areSchedulesValid'];
    },

    // returns true if two or more shift definitions overlap or false otherwise
    areShiftsOverlapping() {
      return this.$store.getters['schedules/isShiftOverlap'];
    },

    // returns an array of days used by the calendar
    days() {
      return [
        i18n.t(`days.${constants.DAYS.MONDAY}`),
        i18n.t(`days.${constants.DAYS.TUESDAY}`),
        i18n.t(`days.${constants.DAYS.WEDNESDAY}`),
        i18n.t(`days.${constants.DAYS.THURSDAY}`),
        i18n.t(`days.${constants.DAYS.FRIDAY}`),
        i18n.t(`days.${constants.DAYS.SATURDAY}`),
        i18n.t(`days.${constants.DAYS.SUNDAY}`),
      ];
    },

    getCanonicalTimezone() {
      return DateTime.fromObject({ zone: this.site.timezone }).toFormat("ZZZZZ '(GMT'Z')'");
    },

    // returns true if the site has pending schedules or false otherwise
    hasPending() {
      return this.$store.getters['schedules/hasPending'](this.site.id);
    },

    /**
     * returns true if the user has permissions to alter shift definitions
     * or false otherwise
     */
    hasScheduleEditFeatureControl() {
      return this.hasFeatureControl(this.FEATURES.SCHEDULE, this.CONTROL_LEVELS.EDITOR);
    },

    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },

    /**
     * returns true if the save button should be disabled or false otherwise
     *
     * the save button should be disabled if:
     * - there are not any shifts
     * - shifts have not been changed
     * - shifts are being saved
     */
    isSaveButtonDisabled() {
      return (
        !this.areSchedulesChanged ||
        this.savingShifts ||
        (!this.loadingSchedules && this.schedules.length === 0)
      );
    },

    // returns true if schedule data is loading or false otherwise
    loadingSchedules() {
      return this.$store.getters['schedules/getLoading'];
    },

    // returns true if shift data is being saved or false otherwise
    savingShifts() {
      return this.$store.getters['schedules/getSaving'];
    },

    // returns the schedules for the current site
    schedules() {
      return this.$store.getters['schedules/getCurrentSchedules'];
    },

    // returns the current site
    site() {
      return this.$store.getters['sites/getSelectedSite'];
    },
  },

  watch: {
    loadingSchedules(value) {
      this.$store.dispatch('companies/setLocked', value);
    },

    schedules: {
      deep: true,
      handler() {
        if (!this.loadingSchedules) {
          this.areSchedulesChanged = true;
        }
      },
    },

    // reloads the schedules when the selected site changes
    async site() {
      // if navigating away from the page do not load schedules
      if (this.leaveConfirmed) {
        return;
      }

      await this.$store.dispatch('schedules/loadSchedules', this.site.id);
      this.areSchedulesChanged = false;
    },
  },

  beforeMount() {
    this.$store.dispatch('schedules/resetState');
  },

  // load the required data
  async mounted() {
    // ensure the user has selected a site
    if (!this.site) {
      await this.$router.push({ name: 'sites' });
      return;
    }

    await this.$store.dispatch('schedules/setLoading', true);

    await this.$store.dispatch('schedules/loadSchedules', this.site.id);

    await this.$store.dispatch('schedules/setLoading', false);

    this.areSchedulesChanged = false;

    if (this.hasPending) {
      this.displayPendingScheduleWarningMessage();
    }
  },

  methods: {
    // display a warning message saying pending schedules may not be accurate
    displayPendingScheduleWarningMessage() {
      if (this.hasScheduleEditFeatureControl) {
        this.$store.dispatch('message/display', {
          text: this.$t('schedules.warnings.pendingScheduleEditor'),
          type: 'warning',
        });
      } else {
        this.$store.dispatch('message/display', {
          text: this.$t('schedules.warnings.pendingScheduleViewer'),
          type: 'warning',
        });
      }
    },

    async handleCompanyChangeInit() {
      if (this.areSchedulesChanged) {
        this.$refs.exitModal.open();
      } else {
        this.leaveConfirmed = true;
        await this.$refs.theHeader.confirmCompanyChange();
      }
    },
    // event handler for clicking the back link
    async onCancel() {
      try {
        await this.$router.push({ name: 'sites' });
      } catch (error) {
        // do nothing if intercepted by beforeRouteLeave
      }
    },

    onResize() {
      this.width = Math.max(this.$refs.calendarContainer.clientWidth, CALENDAR_MIN_WIDTH);
      this.height = Math.max(this.$refs.calendarContainer.clientHeight, CALENDAR_MIN_HEIGHT);
    },

    // event handler for clicking the done button
    async onSubmit() {
      // hack to trigger vuelidate form validation on the child components
      this.$refs.scheduleList.validate = true;
      await this.$nextTick();
      this.$refs.scheduleList.validate = false;

      // validate the current state of all shift definitions
      await this.$store.dispatch('schedules/validate');

      if (this.areSchedulesValid && !this.areShiftsOverlapping && !this.areScheduleOverlapping) {
        await this.$store.dispatch('schedules/saveSchedules');

        this.leaveConfirmed = true;

        await this.$router.push({ name: 'sites' });
      }
    },
  },
};
</script>
