import config from '@/config/config';

export function getSupportedLocales() {
  const annotatedLocales = [];

  Object.keys(config.SUPPORTED_LOCALES).forEach(code => {
    annotatedLocales.push({
      code,
      name: config.SUPPORTED_LOCALES[code],
    });
  });

  return annotatedLocales;
}

export function supportedLocalesInclude(locale) {
  return Object.keys(config.SUPPORTED_LOCALES).includes(locale);
}

export function getBrowserLocale(options = {}) {
  const defaultOptions = { countryCodeOnly: false };
  const opt = { ...defaultOptions, ...options };
  const navigatorLocale =
    navigator.languages !== undefined ? navigator.languages[0] : navigator.language;

  if (!navigatorLocale) {
    return undefined;
  }

  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim();

  return trimmedLocale;
}
export function setDocumentDirectionPerLocale(locale) {
  document.dir = locale === 'ar' ? 'rtl' : 'ltr';
}

export function setDocumentLang(lang) {
  document.documentElement.lang = lang;
}

export function setDocumentTitle(newTitle) {
  document.title = newTitle;
}
