<template>
  <DataGridV2
    :context="context"
    :default-sort-model="defaultSortModel"
    :enable-row-multiselect="true"
    :height="height"
    :table-columns="columnDefs"
    :table-rows="users"
    @grid-ready="onGridReady"
    @selection-changed="onSelectionChanged"
  />
</template>

<script>
import cloneDeep from 'lodash-es/cloneDeep';
import DataGridV2 from '@/components/controls/DataGridV2.vue';
import SprUserTableActions from '@/components/sprconfig/SprUserTableActions.vue';
import i18n from '@/i18n';

export default {
  name: 'SprUserTable',
  components: { DataGridV2 },
  props: {
    enableAditionalColumns: {
      default: false,
      type: Boolean,
    },
    enableRowAction: {
      default: true,
      type: Boolean,
    },
    height: {
      default: '20rem',
      type: String,
    },
    showInternalUsers: {
      required: true,
      type: Boolean,
    },
    users: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      additionalColumns: [
        {
          cellStyle: { textAlign: 'left' },
          field: 'status',
          headerName: i18n.t('sprConfig.userTableColumns.status'),
        },
        {
          cellStyle: { textAlign: 'left' },
          field: 'modifiedDateTime',
          headerName: i18n.t('sprConfig.userTableColumns.dateModified'),
        },
      ],
      columnDefs: null,
      defaultSortModel: [{ colId: 'name', sort: 'asc' }],
      gridApi: null,
      rowActionColumn: {
        cellRendererFramework: SprUserTableActions,
        cellStyle: { display: 'flex', justifyContent: 'flex-end' },
        headerName: '',
        sortable: false,
        width: 60,
      },
    };
  },
  computed: {},
  beforeMount() {
    this.columnDefs = [
      {
        cellStyle: { textAlign: 'left' },
        checkboxSelection: true,
        field: 'name',
        headerCheckboxSelection: true,
        headerName: i18n.t('sprConfig.userTableColumns.name'),
      },
      {
        cellStyle: { textAlign: 'left' },
        field: 'email',
        headerName: i18n.t('sprConfig.userTableColumns.email'),
      },
    ];
    if (this.enableAditionalColumns) {
      this.columnDefs.push(...this.additionalColumns);
    }
    if (this.enableRowAction) {
      this.columnDefs.push(this.rowActionColumn);
    }
    this.context = { componentParent: this };
  },
  methods: {
    clearSelections() {
      if (this.gridApi) {
        this.gridApi.deselectAll();
        this.gridApi.deselectAllFiltered();
      }
    },
    deleteUser(userData) {
      this.$emit('deleteUser', userData);
    },
    editUser(userData) {
      this.$emit('editUser', cloneDeep(userData));
    },
    inviteUser(userData) {
      this.$emit('inviteUser', userData);
    },
    onGridReady(api) {
      this.gridApi = api;
    },
    onSelectionChanged() {
      const selectedRows = this.gridApi.getSelectedRows();
      this.$emit('selection-changed', selectedRows);
    },
    performUserRowAction(rowData, action) {
      this.$emit(action, rowData);
    },
  },
};
</script>
