<template>
  <div :id="schedulesContainerId" class="c-schedulelist-container max-h-full">
    <Spinner v-if="loadingSchedules" :is-local="true" />

    <template v-else>
      <div class="border-b border-opacity-10 border-primary-dark c-schedulelist-header px-4">
        <span class="c-schedulelist-label font-medium inline-block mr-4 text-primary-dark text-xl">
          {{ $t('schedules.title') }}
        </span>
        <Tooltip :content="$t('schedules.widgetTooltip')" direction="right" width="412px">
          <BaseIcon
            class="c-schedulelist-info text-primary-dark"
            height="24px"
            name="fg-icon-help"
            :icon-sprite="iconSprite"
            width="24px"
            viewBox="0 0 16 16"
          />
        </Tooltip>
        <div class="push" />
        <BaseButton
          v-if="!isMissingScheduleDefinitions && hasScheduleEditFeatureControl"
          button-type="secondary"
          class="c-schedulelist-add"
          icon="plus"
          size="small"
          @click="addSchedule"
        >
          {{ $t('schedules.addScheduleBtn') }}
        </BaseButton>
      </div>
      <div ref="schedulelist" class="c-schedulelist">
        <div v-if="isMissingScheduleDefinitions" class="flex items-center mt-10 px-4">
          <span class="mr-8 text-primary-dark text-sm">
            {{ $t('sites.sections.schedules.noSchedules') }}
          </span>
          <BaseButton
            v-if="hasScheduleEditFeatureControl"
            button-type="secondary"
            class="c-schedulelist-add"
            icon="plus"
            size="small"
            @click="addSchedule"
          >
            {{ $t('schedules.addScheduleBtn') }}
          </BaseButton>
        </div>
        <ScheduleForm
          v-for="(schedule, index) in schedules"
          v-else-if="hasScheduleEditFeatureControl"
          ref="scheduleForms"
          :key="`schedule-${index}`"
          :index="index"
          :schedule="schedule"
          :validate="validate"
          @trigger-validate="onValidate"
        />
        <ScheduleDetailsText
          v-for="(schedule, index) in schedules"
          v-else
          ref="scheduleDetailsText"
          :key="`schedule-details-${index}`"
          :schedule="schedule"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { BaseButton, BaseIcon } from '@seegrid/components';
import IconSprite from '@/assets/images/icon-sprite.svg';
import ScheduleDetailsText from '@/components/ScheduleDetailsText.vue';
import ScheduleForm from '@/components/ScheduleForm.vue';
import Spinner from '@/components/Spinner.vue';
import Tooltip from '@/components/Tooltip.vue';
import constants from '@/config/constants';
import authorization from '@/mixins/authorization';

export default {
  name: 'ScheduleList',
  components: {
    BaseButton,
    BaseIcon,
    ScheduleDetailsText,
    ScheduleForm,
    Spinner,
    Tooltip,
  },
  mixins: [authorization],
  data() {
    return {
      schedulesContainerId: constants.SCHEDULE_LIST_ELEMENT_ID,
      tooltipVisible: false,
      validate: false,
    };
  },
  computed: {
    hasScheduleEditFeatureControl() {
      return this.hasFeatureControl(this.FEATURES.SCHEDULE, this.CONTROL_LEVELS.EDITOR);
    },
    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },
    isMissingScheduleDefinitions() {
      return !this.loadingSchedules && this.schedules.length === 0;
    },
    // returns true if schedule data is loading or false otherwise
    loadingSchedules() {
      return this.$store.getters['schedules/getLoading'];
    },

    // returns the schedules for the current site
    schedules() {
      return this.$store.getters['schedules/getCurrentSchedules'];
    },
  },
  methods: {
    async addSchedule() {
      this.$store.dispatch('schedules/addNewSchedule');
      await this.$nextTick();
      this.expandNewlyAddedSchedule();
    },
    async expandNewlyAddedSchedule() {
      if (this.$refs.scheduleForms.length > 0) {
        this.$refs.scheduleForms[this.$refs.scheduleForms.length - 1].toggleShifts();
        await this.$nextTick();
        this.scrollToBottom();
      }
    },
    async onValidate() {
      // hide previous errors before full validation
      this.$store.dispatch('message/hide');
      // hack to trigger vuelidate form validation on the child components
      this.validate = true;
      await this.$nextTick();
      this.validate = false;
    },
    scrollToBottom() {
      if (this.$refs.schedulelist) {
        this.$refs.schedulelist.scrollTop = this.$refs.schedulelist.scrollHeight;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@seegrid/components/assets/scss/variables' as var;
@use '@seegrid/components/assets/scss/tools' as t;

.c-schedulelist-container {
  display: flex;
  flex-flow: column;
  .c-schedulelist-header {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 5rem;
    .c-schedulelist-label {
      order: 0;
    }
  }
  .c-schedulelist {
    width: 100%;
    max-height: calc(100% - 6rem);
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.c-schedulelist-info {
  order: 1;
}
.c-schedulelist-add {
  order: 3;
}
.push {
  margin-left: auto;
  order: 2;
}
</style>
