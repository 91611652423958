<template>
  <div class="admin-page-container">
    <div class="flex flex-grow justify-center pt-8 px-8">
      <CompanySelector />
    </div>

    <TheFooter />
  </div>
</template>

<script>
import CompanySelector from '@/components/CompanySelector.vue';
import TheFooter from '@/components/TheFooter.vue';

export default {
  name: 'CompanySelectorView',

  components: {
    CompanySelector,
    TheFooter,
  },

  async mounted() {
    await this.$store.dispatch('companies/loadCompanies');
  },
};
</script>
