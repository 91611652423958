import constants from '@/config/constants';

const days = [
  constants.DAYS.MONDAY,
  constants.DAYS.TUESDAY,
  constants.DAYS.WEDNESDAY,
  constants.DAYS.THURSDAY,
  constants.DAYS.FRIDAY,
  constants.DAYS.SATURDAY,
  constants.DAYS.SUNDAY,
];
export const getNextWeekday = day => {
  if (!day || days.indexOf(day) === -1) {
    return day;
  }
  const nextDayIndex = days.indexOf(day) < 6 ? days.indexOf(day) + 1 : 0;
  return days[nextDayIndex];
};
export const getPreviousWeekday = day => {
  if (!day || days.indexOf(day) === -1) {
    return day;
  }
  const prevDayIndex = days.indexOf(day) === 0 ? 6 : days.indexOf(day) - 1;
  return days[prevDayIndex];
};
