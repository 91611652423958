import config from '@/config/config';

// class for representing and managing a route network import
/* eslint class-methods-use-this: ["error", { "exceptMethods":
    ["deleteRouteNetworkImport", "getRouteNetworkImports", "getSiteRouteNetworkImport",
    "getPollInterval", "importRouteNetwork", "hasRouteNetworkImportState"]
  }]
*/
export default class RouteNetworkImport {
  constructor(companyId, siteId) {
    this.companyId = companyId;
    this.errors = [];
    this.id = null;
    this.siteId = siteId;
    this.status = null;
    this.timestamp = null;
  }

  // add the given error to the errors array
  addError(error) {
    this.errors.push(error);
  }

  // delete the route network import
  async deleteRouteNetworkImport() {
    return null;
  }

  // return the errors array
  getErrors() {
    return this.errors;
  }

  // returns the status poll interval in seconds
  getPollInterval() {
    return config.IMPORT_POLL_INTERVAL_SECONDS;
  }

  // get all route network imports
  async getRouteNetworkImports() {
    return null;
  }

  // get the route network import for the site
  async getSiteRouteNetworkImport() {
    return null;
  }

  // returns true if the route network is in the given state or false otherwise
  hasRouteNetworkImportState() {
    return null;
  }

  // import route network data
  async importRouteNetwork() {
    return null;
  }
}
