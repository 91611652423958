<template>
  <div>
    <div class="auth-error-container">
      <div ref="content">
        <div id="wrapper" class="wrapper-content">
          <div id="mb-16 page-content-wrapper">
            <div class="flex justify-center">
              <div class="">
                <div class="cell error-content">
                  <h2 class="font-medium page-header text-center text-primary-a11y-dark">
                    {{ $t('pages.error.generalErrorHeader') }}
                  </h2>
                  <p class="font-medium sub-header text-4xl text-primary-dark">
                    {{ $t(`pages.error.authError.${error_code}.errorSubheader`) }}
                  </p>
                  <h4
                    v-show="displayReasonsHeader"
                    id="ReasonsHeader"
                    class="mb-4 text-large text-primary-dark"
                  >
                    {{ $t('pages.error.errorReasons') }}
                  </h4>
                  <p class="mb-8 text">
                    {{ errorReasonsText1 }}
                    <br />
                    {{ $t(`pages.error.authError.${error_code}.errorReasonsText2`) }}
                  </p>
                  <section class="content-center flex place-content-center py-5">
                    <button
                      id="reauthBtn"
                      class="button hollow leading-none px-5 py-2.5 round text-primary"
                      @click="onLogout"
                    >
                      {{ $t('navigation.reauthenticate') }}
                    </button>
                    <p class="my-auto">or</p>
                    <a
                      id="contactBtn"
                      class="button contactBtn hollow leading-none px-5 py-2.5 round"
                      href="mailto:support@seegrid.com"
                      >{{ $t('companies.contactSupport') }}</a
                    >
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TheFooter />
  </div>
</template>

<script>
import TheFooter from '@/components/TheFooter.vue';

const supportedErrors = ['NOT_VERIFIED', 'FORBIDDEN'];

export default {
  name: 'AuthErrorView',
  components: {
    TheFooter,
  },
  props: {
    // This is the javascript error object from Auth0 client
    authErrorDesc: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      error_code: 'GENERIC_ERROR',
      error_user: null,
    };
  },
  computed: {
    displayReasonsHeader() {
      return this.error_code && this.authErrorDesc && this.error_code !== 'FORBIDDEN';
    },
    errorReasonsText1() {
      if (
        this.$t(`pages.error.authError.${this.error_code}.errorReasonsText1`).includes('{email}')
      ) {
        return this.$t(`pages.error.authError.${this.error_code}.errorReasonsText1`, {
          email: this.error_user,
        });
      }
      return (
        this.$t(`pages.error.authError.${this.error_code}.errorReasonsText1`) || this.authErrorDesc
      );
    },
  },
  mounted() {
    let errorDesc = this.authErrorDesc;
    let receivedCode = '';
    let userEmail;
    try {
      if (
        errorDesc &&
        errorDesc.length > 2 &&
        typeof errorDesc === 'string' &&
        errorDesc.indexOf(']') > -1
      ) {
        /* 
            extract error code & user email from the authErrorDesc
            expected format: "[errorCode] - [userEmail] - description"
          */

        // extract errorCode from errorDesc string
        const errorCode = errorDesc.slice(1, errorDesc.indexOf(']'));
        // remove error code from errorDesc string
        errorDesc = errorDesc.substring(errorDesc.indexOf(']') + 1);
        if (errorDesc.indexOf(']') > -1) {
          // extract userEmail from errorDesc string
          userEmail = errorDesc.slice(errorDesc.indexOf('[') + 1, errorDesc.indexOf(']'));
        }

        receivedCode = supportedErrors.indexOf(errorCode) > -1 ? errorCode : '';
      }
      this.error_code = receivedCode || 'GENERIC_ERROR';
      this.error_user = userEmail || null;
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    // method for logging the user out
    async onLogout() {
      await this.$store.dispatch('user/logout');
    },
  },
};
</script>

<style scoped lang="scss">
.error-content {
  max-width: 47rem;
}

.auth-error-container {
  min-height: 90vh;
}

.button {
  font-size: 0.9rem;
}

.text {
  text-align: left;
  line-height: 1.5rem;
  color: #002642;
}

.page-header {
  padding-top: 10rem;
  padding-bottom: 2rem;
  font-size: 5rem;
  line-height: 5.85rem;
}

.header {
  font-size: 5rem;
  line-height: 5rem;
  font-weight: 800;
}

.sub-header {
  line-height: 2.5rem;
  text-align: left;
  margin: 1rem 0 2rem 0;
}

.text-large {
  font-size: 2rem;
  line-height: 3rem;
  text-align: left;
}

#reauthBtn {
  background-color: white;
  border-radius: 25px;
  border: 2px solid #1779ba;
  margin: 0 10px 0 10px;
  text-decoration: none;
}

#contactBtn {
  background-color: white;
  border-radius: 25px;
  border: none;
  color: #002642;
  margin: 0 10px 0 10px;
  text-decoration: none;
}

@media only screen and (max-width: 40rem) {
  .page-header {
    padding-top: 5rem;
    padding-bottom: 0.1rem;
  }
  .header {
    font-size: 2.5rem;
  }
  .sub-header {
    font-size: 1.4rem;
    margin: 1rem 0 1rem 0;
  }
  .text-large {
    font-size: 1.2rem;
    font-weight: 800;
    margin: 0 0 0.5rem;
  }
}
</style>
