<template>
  <div class="admin-page-container">
    <TheHeader
      ref="theHeader"
      :company-change-guard="true"
      @company-change-initiated="handleCompanyChangeInit"
    />
    <BaseGlobalMessage v-if="!loading && displayError" :text="errorMessage" :type="'negative'" />

    <section
      class="flex flex-col flex-grow items-center justify-center max-w-6xl mt-16 mx-auto w-10/12"
    >
      <Spinner v-if="loading" :is-local="true" />
      <RouteNetwork
        v-else
        @api-error="onApiError($event)"
        @navigate="handleNavigate"
        @unsaved-changes="setUnsavedChanges"
      />
    </section>

    <TheFooter />
    <ConfirmationModal
      ref="exitModal"
      align="left"
      :confirm-title="$t('routeNetworkView.exitModal.heading')"
      :confirm-message="$t('routeNetworkView.exitModal.subHeading')"
      :cancel-button="$t('routeNetworkView.exitModal.actionOne')"
      :confirm-button="$t('routeNetworkView.exitModal.actionTwo')"
      :danger-confirm="false"
      @on-confirm-action="onConfirmNavigate"
      @on-cancel-action="onCancelNavigate"
    />
  </div>
</template>

<script>
import { BaseGlobalMessage } from '@seegrid/components';
import RouteNetwork from '@/components/RouteNetwork.vue';
import Spinner from '@/components/Spinner.vue';
import TheFooter from '@/components/TheFooter.vue';
import TheHeader from '@/components/TheHeader.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import constants from '@/config/constants';
import leaveConfirmModalHandlers from '@/mixins/leaveConfirmModalHandlers';
import Site from '@/models/Site';
import SupervisorBackupImport from '@/models/SupervisorBackupImport';

export default {
  name: 'RouteNetworkView',

  components: {
    BaseGlobalMessage,
    ConfirmationModal,
    RouteNetwork,
    Spinner,
    TheFooter,
    TheHeader,
  },

  mixins: [leaveConfirmModalHandlers],

  beforeRouteLeave(to, from, next) {
    // Block navigation and show confirm if unsaved changes and pending confirmation
    if (this.unsavedChanges && !this.leaveConfirmed) {
      this.leaveTo = to;
      this.openModal();
      return next(false);
    }
    return next();
  },

  data: () => {
    return {
      displayError: false,
      errorMessage: null,
      leaveConfirmed: false,
      leaveTo: null,
      unsavedChanges: false,
    };
  },

  computed: {
    // check if Route Network Import has an error
    hasImportError() {
      return this.$store.getters['routeNetworkImports/hasImportError'];
    },

    // returns true if the route network is completely processed or false otherwise
    isProcessed() {
      if (!(this.siteRouteNetworkImport instanceof SupervisorBackupImport)) {
        return false;
      }

      return this.siteRouteNetworkImport.isExtractRouteNetworkComplete();
    },

    // returns true if data is loading or false otherwise
    loading() {
      return (
        this.$store.getters['routeNetworks/getLoading'] ||
        this.$store.getters['routeNetworkImports/getLoading']
      );
    },

    // returns the current site
    site() {
      return this.$store.getters['sites/getSelectedSite'];
    },

    // returns the latest route network import for the current site
    siteRouteNetworkImport() {
      if (!(this.site instanceof Site)) {
        return null;
      }
      return this.$store.getters['routeNetworkImports/getSiteRouteNetworkImport'](this.site.id);
    },
  },
  watch: {
    loading(value) {
      this.$store.dispatch('companies/setLocked', value);
    },
  },

  beforeMount() {
    this.displayError = false;

    this.$store.dispatch('routeNetworkImports/resetState');
    this.$store.dispatch('routeNetworks/resetState');
  },

  async mounted() {
    // ensure the user has selected a site
    if (!(this.site instanceof Site)) {
      await this.$router.push({ name: 'sites' });
      return;
    }

    await this.loadData();
  },

  methods: {
    // display the delete error message of the given type
    displayErrorMessage(type) {
      this.errorMessage = this.$t(`map.error.${type}`);
      this.displayError = true;
    },

    /**
     * returns the appropriate parameters for getting the route network based
     * on the current import state
     */
    getRouteNetworkParameters() {
      // if the route network import is in progress return the backup id
      if (!this.isProcessed) {
        return {
          backupId: this.siteRouteNetworkImport.id,
        };
      }

      // by default return the company id and site id
      return {
        companyId: this.$store.getters['companies/getSelectedCompanyId'],
        siteId: this.site.id,
      };
    },

    // handle Company change in header
    async handleCompanyChangeInit() {
      if (this.unsavedChanges) {
        this.openModal();
      } else {
        this.leaveConfirmed = true;
        await this.$refs.theHeader.confirmCompanyChange();
      }
    },

    // handle Navigation requested from child component
    async handleNavigate(route) {
      if (!route) {
        throw new Error('route parameter is required');
      }
      if (route === 'routeNetworkUpload') {
        this.leaveConfirmed = true;
      } else {
        this.leaveConfirmed = false;
      }
      try {
        await this.$router.push({ name: route });
      } catch (error) {
        // do nothing if intercepted by beforeRouteLeave
      }
    },

    // load the route network data
    async loadData() {
      try {
        await this.loadRouteNetworkImport();
        await this.loadRouteNetwork();
      } catch (error) {
        this.displayErrorMessage(constants.API_ERROR_TYPES.LOAD);
      }

      if (
        !(this.siteRouteNetworkImport instanceof SupervisorBackupImport) ||
        this.siteRouteNetworkImport.status === constants.IMPORT_STATUS.ERROR
      ) {
        this.displayErrorMessage(constants.API_ERROR_TYPES.PROCESS);
      }
    },

    /**
     * load the correct route network for the current site based on the
     * current import state
     */
    async loadRouteNetwork() {
      const parameters = this.getRouteNetworkParameters();

      await this.$store.dispatch('routeNetworks/loadRouteNetwork', parameters);

      await this.$store.dispatch('routeNetworks/setLoading', false);
    },

    // load the latest route network import for the current site
    async loadRouteNetworkImport() {
      const companyId = this.$store.getters['companies/getSelectedCompanyId'];

      const routeNetworkImport = new SupervisorBackupImport(companyId, this.site.id);

      await this.$store.dispatch('routeNetworkImports/loadRouteNetworkImports', routeNetworkImport);

      await this.$store.dispatch('routeNetworkImports/setLoading', false);
    },

    // event handler for displaying the given api error type
    onApiError(event) {
      if (Object.values(constants.API_ERROR_TYPES).includes(event)) {
        this.displayErrorMessage(event);
      }
    },

    openModal() {
      this.$refs.exitModal.open();
    },

    // Unsaved changes as set by child component used for Leave Confirm modal
    setUnsavedChanges(val) {
      this.unsavedChanges = val;
    },
  },
};
</script>
