/**
 * Auth0 route guard for enforcing authentication and authorization
 *
 * the original version is here:
 * https://github.com/auth0-samples/auth0-vue-samples/blob/master/01-Login/src/auth/authGuard.js
 */

import has from 'lodash-es/has';
import { getInstance } from '@/plugins/Auth0Plugin';
import store from '@/store';

// authenticate the user
function authenticate(to, from, next) {
  const authService = getInstance();
  if (authService.error) {
    // Error occurred during authentication
    console.error(`An error occurred during authentication ${authService.error}`);
    return next({
      name: 'AuthErrorView',
      params: { authErrorDesc: authService.error.error_description },
    });
  }
  // verify that the user is authenticated, if not redirect to the login
  if (!authService.isAuthenticated) {
    return authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  }

  // verify that the user is authorized, if not redirect appropriately
  if (!store.getters['user/isAuthorized'](to)) {
    // by default redirect to the dashboard
    let redirectURL = process.env.BASE_URL_ADMIN;

    if (has(to, 'meta.redirectURL')) {
      redirectURL = to.meta.redirectURL;
    }

    return window.location.replace(redirectURL);
  }

  return next();
}

// route guard to ensure user is authenticated and authorized
function guard(to, from, next) {
  const authService = getInstance();

  if (!authService.loading) {
    return authenticate(to, from, next);
  }

  authService.$watch('loading', loading => {
    if (loading === false) {
      return authenticate(to, from, next);
    }

    return null;
  });

  return null;
}

export default guard;
