import IdleVue from 'idle-vue';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import App from '@/App.vue';
import auth0Options from '@/config/auth0';
import i18n from '@/i18n';
import { Auth0Plugin } from '@/plugins/Auth0Plugin';
import router from '@/router';
import store from '@/store';

Vue.config.productionTip = false;

Vue.use(Vuelidate);

Vue.use(Auth0Plugin, { store, ...auth0Options });

Vue.use(IdleVue, {
  events: ['mousedown', 'touchstart'], // ['mousemove', 'keydown', 'mousedown', 'touchstart']
  idleTime: 1.75 * 60 * 60 * 1000, // idle time in ms --> set to 1 hour 45 minutes
  startAtIdle: false,
  store,
});

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app');
