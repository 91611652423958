<template>
  <BaseSelect
    :disabled="disabled"
    :label="label"
    :options="computedOptions"
    :hidden="hidden"
    :error="error"
    @select-updated="$emit('input', $event)"
  />
</template>

<script>
import { BaseSelect } from '@seegrid/components';

export default {
  name: 'InputSelect',
  components: { BaseSelect },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    // This is needed to preselet the Option that matches "value" in Select
    computedOptions() {
      let newOptions;
      if (this.value && this.options) {
        newOptions = this.options.map(o => {
          return { ...o, selected: o.value === this.value };
        });
      }
      return newOptions || this.options;
    },
  },
};
</script>
