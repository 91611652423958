<template>
  <BaseModalCustom
    ref="userModal"
    class="c-modal__add-user-modal"
    :action-one="$t('userManagement.addUsers.cancel')"
    :action-two="$t('userManagement.addUsers.add')"
    :close-on-esc="false"
    :heading="heading"
    @modal-button-clicked="onModalButton"
  >
    <AddUserForm
      ref="userForm"
      :user="userData"
      :new-user="newUser"
      :validate="validate"
      :company="company"
      :send-user-invite="sendUserInvite"
      :current-users="currentUsers"
      :internal-users="internalUsers"
      :internal-user-mode="internalUserMode"
      @update-form="updateUserData"
      @validate-form-result="validateUserDataResult"
      @resolve-email-address="resolveEmailAddress"
    />
  </BaseModalCustom>
</template>
<script>
import cloneDeep from 'lodash-es/cloneDeep';
import has from 'lodash-es/has';
import BaseModalCustom from '@/components/BaseModalCustom.vue';
import AddUserForm from '@/components/users/AddUserForm.vue';
import constants from '@/config/constants';
import Company from '@/models/Company';
import allowWelcomeEmail from '@/util/allowWelcomeEmail';

export default {
  name: 'AddUserModal',

  components: {
    AddUserForm,
    BaseModalCustom,
  },
  props: {
    company: {
      default: null,
      type: Company,
    },
    currentUsers: {
      type: Array,
      required: true,
    },
    internalUserMode: {
      type: Boolean,
      required: true,
    },
    internalUsers: {
      type: Array,
      required: true,
    },
    newUser: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sendUserInvite: true,
      userData: cloneDeep(this.user),
      validForm: true,
      validate: false,
    };
  },
  computed: {
    heading() {
      // if (!this.userData.isNewUser) {
      //   return this.$t('userManagement.addUsers.title.edit');
      // }
      if (this.internalUserMode) {
        return this.$t('userManagement.addUsers.title.internal');
      }
      if (this.company) {
        return this.$t('userManagement.addUsers.title.company', {
          company_name: this.company.name,
        });
      }
      return this.$t('userManagement.addUsers.title.default');
    },
  },
  watch: {
    user() {
      this.userData = cloneDeep(this.user);
    },
  },
  methods: {
    cloneUser(user) {
      if (!user) {
        throw new Error('Valid user is required for cloneUser');
      }
      const newUser = cloneDeep(user);
      if (this.internalUserMode) {
        newUser.sites = [constants.AUTH0.ALL_SITES];
      }
      return newUser;
    },
    close() {
      this.$refs.userModal.close();
    },
    // display the error message for the given type
    displayErrorMessage(type) {
      this.$store.dispatch('message/display', {
        text: this.$t(`userManagement.error.${type}`),
        type: 'negative',
      });
    },
    // display the user exists error message
    displayUserExistsErrorMessage(email) {
      this.$store.dispatch('message/display', {
        text: this.$t('userManagement.error.exists', { email }),
        type: 'negative',
      });
    },
    // called when the user clicks the modal button
    onModalButton(event) {
      if (event.action === constants.MODAL_CONFIRM) {
        this.validate = true;
      } else if (event.action === constants.MODAL_CANCEL) {
        this.promptOnCancel();
      }
    },
    // show the form
    open() {
      this.$refs.userModal.open();
    },
    // handles when the user clicks cancel when adding a new user
    promptOnCancel() {
      if (!this.userData.isNewUser) return;
      if (!this.userData.email) return;
      if (!this.userData.firstName && !this.userData.lastName) return;
      this.$emit('prompt-on-cancel');
    },
    // reset the form between opens
    reset() {
      this.userData = cloneDeep(this.user);
      this.$refs.userForm.$v.$reset();
    },
    // called when an email address is entered to lookup the user
    resolveEmailAddress(email) {
      // find an existing user match
      const checkEmail = (email || '').toLowerCase();
      for (let i = 0; i < this.currentUsers.length; i += 1) {
        const user = this.currentUsers[i];
        if ((user.email || '').toLowerCase() === checkEmail) {
          this.userData = this.cloneUser(user);
          return;
        }
      }
      // find an internal user match (not in the current list)
      if (this.internalUserMode) {
        for (let i = 0; i < this.internalUsers.length; i += 1) {
          const user = this.internalUsers[i];
          if ((user.email || '').toLowerCase() === checkEmail) {
            this.userData = this.cloneUser(user);
            // reset the company
            this.userData.companyId = this.company.id;
            return;
          }
        }
      }
      // set a new user (or update the existing new user)
      if (this.userData.id) {
        this.userData = this.cloneUser(this.newUser);
      }
      this.userData.email = email;
    },
    // called to save the current user
    async saveUser() {
      if (!(this.company instanceof Company)) {
        throw new TypeError('company is required to save user');
      }

      await this.$store.dispatch('users/setLoading', true);

      const welcomeEmailEligible = allowWelcomeEmail.containsConfirmedSite(this.userData.sites);

      try {
        // Create a new user or update their site list?
        if (this.userData.isNewUser) {
          await this.$store.dispatch('users/saveUser', {
            company: this.company,
            invite: this.sendUserInvite && welcomeEmailEligible,
            user: this.userData,
          });
        } else {
          await this.$store.dispatch('users/saveUserSites', {
            company: this.company,
            user: this.userData,
          });
        }
      } catch (error) {
        console.error(error || 'Unknown Error in saveUser!');
        let userExists = false;

        // check for user exists error
        if (has(error, 'response.data.errors') && Array.isArray(error.response.data.errors)) {
          const { errors } = error.response.data;

          if (
            errors.find(
              e => e.error_code === 'user_already_exists' || e.error_code === 'resource_conflict',
            )
          ) {
            userExists = true;
          }
        }

        // display the appropriate error message
        if (userExists) {
          this.displayUserExistsErrorMessage(this.userData.email);
        } else {
          this.displayErrorMessage(constants.API_ERROR_TYPES.SAVE);
        }
      }

      await this.$store.dispatch('users/setLoading', false);
    },
    // called as the user entered new form data
    updateUserData(event) {
      switch (event.field) {
        case 'firstName': {
          this.userData.firstName = event.value;
          this.userData.name = `${this.userData.firstName} ${this.userData.lastName}`;
          break;
        }
        case 'lastName': {
          this.userData.lastName = event.value;
          this.userData.name = `${this.userData.firstName} ${this.userData.lastName}`;
          break;
        }
        case 'selectedSites': {
          this.userData.sites = event.value;
          break;
        }
        case 'sendUserInvite':
          this.sendUserInvite = event.value;
          break;
        default: {
          this.userData[event.field] = event.value;
          break;
        }
      }
    },
    // called after the user data has been validated
    async validateUserDataResult(result) {
      this.validate = false;
      this.validForm = result;
      if (this.validForm) {
        await this.saveUser();
      } else {
        // Form is invalid, reopen form after save button click
        this.$refs.userModal.open();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.c-modal__add-user-modal {
  ::v-deep .c-modal__heading,
  ::v-deep .c-modal__subheading,
  ::v-deep .c-modal__body-container {
    text-align: left;
  }
  ::v-deep .c-modal__body-container {
    padding-bottom: 1rem;
  }
  ::v-deep .c-modal-mask {
    display: block;
    .c-modal {
      margin-top: 1rem;
    }
  }
  @media (min-height: 600px) {
    ::v-deep .c-modal-mask {
      display: block;
      .c-modal {
        margin-top: calc(15vh);
      }
    }
  }
  @media (min-height: 900px) {
    ::v-deep .c-modal-mask {
      display: block;
      .c-modal {
        margin-top: calc(33vh);
      }
    }
  }
}
</style>
