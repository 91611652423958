/**
 * Auth0 plugin for providing global authentication state through this.$auth
 *
 * the original version is here:
 * https://github.com/auth0-samples/auth0-vue-samples/blob/master/01-Login/src/auth/authWrapper.js
 */

import createAuth0Client from '@auth0/auth0-spa-js';
import jwtDecode from 'jwt-decode';
import Vue from 'vue';

/** List of errors we plan to handle which are generated from Auth0 actions */
const auth0ErrorParams = {
  UNAUTHORIZED: 'error=access_denied',
};

let instance;

export const getInstance = () => instance;

export const useAuth0 = ({
  onRedirectCallback,
  redirectUri = window.location.origin + process.env.VUE_APP_BASE_URL_ADMIN,
  ...options
}) => {
  if (instance) {
    return instance;
  }

  instance = new Vue({
    data() {
      return {
        auth0Client: null,
        currentOrgId: null,
        error: null,
        isAuthenticated: false,
        loading: true,
        organizationMap: null,
        popupOpen: false,
        user: {},
      };
    },

    async created() {
      try {
        this.auth0Client = await createAuth0Client({
          audience: options.audience,
          client_id: options.clientId,
          domain: options.domain,
          issuer: options.issuer,
          redirect_uri: redirectUri,
          scope: options.scope,
        });

        if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
          const { appState } = await this.auth0Client.handleRedirectCallback();
          this.error = null;
          if (onRedirectCallback) {
            onRedirectCallback(appState);
          } else {
            window.history.replaceState({}, document.title, window.location.pathname);
          }
        } else if (
          window.location.search.includes('state=') &&
          window.location.search.includes(auth0ErrorParams.UNAUTHORIZED)
        ) {
          // If Auth0 spa client is not able to recognize and throw error, we are creating a mock error
          // The function from Auth0 lib that fails is Auth0Client.prototype.handleRedirectCallback
          // object to be handled by the application
          this.error = this.processErrorFromURL();
        }
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.user = await this.auth0Client.getUser();
        if (this.user) {
          await this.processIdTokenClaims();
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },

    methods: {
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },

      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },

      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o).catch(reason => {
          console.error('Could not get token with popup, perhaps your popups are blocked', reason);
          throw reason;
        });
      },

      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          await this.processIdTokenClaims();
          this.isAuthenticated = true;
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },

      async loginWithPopup(o) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(o);
          this.user = await this.auth0Client.getUser();
          await this.processIdTokenClaims();
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.popupOpen = false;
        }
      },

      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },

      logout(o) {
        return this.auth0Client.logout({
          returnTo: window.location.origin + process.env.VUE_APP_BASE_URL_ADMIN,
          ...o,
        });
      },
      /** Process Error from redirect */
      processErrorFromURL() {
        const errorObj = {};
        try {
          const params = new URLSearchParams(window.location.search);
          const error = params.get('error');
          const errorDesc = params.get('error_description');
          errorObj.error = error || 'GENERIC_ERROR';
          errorObj.error_description = errorDesc || '';
        } catch (e) {
          console.error('error occurred in processErrorFromURL: ', e);
        }
        return errorObj;
      },

      async processIdTokenClaims() {
        let orgInfo;
        try {
          const claims = await this.auth0Client.getIdTokenClaims();
          /* eslint-disable-next-line no-underscore-dangle */
          const idToken = claims.__raw;
          const decodedIdToken = jwtDecode(idToken);
          orgInfo = decodedIdToken[`${process.env.VUE_APP_AUTH0_NAMESPACE}org_info`];
        } catch (e) {
          console.error('Error occurred in processIdTokenClaims finding orgInfo', e);
          this.error = e;
        } finally {
          if (orgInfo) {
            this.organizationMap = { ...orgInfo };
            options.store.dispatch('companies/setAuthProviderCompanies', { ...orgInfo });
          }
        }
      },
      setCurrentOrgId(orgId) {
        if (!this.organizationMap || !orgId) {
          throw new Error(
            'Error in setCurrentOrgId, organizationMap is empty or orgId is not valid',
          );
        }
        if (this.organizationMap && this.organizationMap[orgId]) {
          this.currentOrgId = orgId;
        }
      },
      setCurrentOrgIdByName(orgName) {
        if (!this.organizationMap || !orgName) {
          throw new Error(
            'Error in setCurrentOrgIdByName, organizationMap is empty or orgName is not valid',
          );
        }
        let companyId;
        Object.keys(this.organizationMap).forEach(k => {
          const o = this.organizationMap[k];
          if (o && o.toLowerCase().trim() === orgName.toLowerCase().trim()) {
            companyId = k;
          }
        });
        if (companyId) {
          this.setCurrentOrgId(companyId);
        }
      },
    },
  });

  return instance;
};

export const Auth0Plugin = {
  install(app, options) {
    /* eslint-disable-next-line no-param-reassign */
    app.prototype.$auth = useAuth0(options);
  },
};
