<template>
  <div class="bg-gray-1 mt-5 p-6 rounded-lg">
    <div class="cursor-pointer flex items-center justify-between p-2" @click="onClickSite()">
      <div class="mr-auto text-primary-dark">
        <span class="font-medium text-primary-dark text-xl">{{ site.name }}</span>
      </div>

      <Spinner v-if="loadingSiteData" :is-local="true" />
      <div v-else>
        <span class="font-medium text-primary-dark text-xl">{{ completionStatus }}</span>
        <BaseIcon
          v-if="!site.display"
          :name="'chevron-down'"
          class="inline-block ml-3 text-primary-dark text-xl"
        />
        <BaseIcon
          v-else
          :icon-sprite="iconSprite"
          :name="'chevron-up'"
          class="inline-block ml-3 text-primary-dark text-xl"
        />
      </div>
    </div>

    <SiteUnconfirmedMessage v-if="site.display && unconfirmedVisibility" :site-id="site.id" />
    <div v-if="site.display" class="grid mb-20 mt-16" :class="`grid-cols-${gridColumns}`">
      <SiteSettingsSchedules
        v-if="hasPermissionShiftDefinitions"
        :site-id="site.id"
        :class="{ 'border-r-2': hasPermissionMap || hasPermissionUsers }"
      />
      <SiteSettingsMap
        v-if="hasPermissionMap"
        :site-id="site.id"
        :class="{ 'border-r-2': hasPermissionUsers }"
      />
      <SiteSettingsUsers v-if="hasPermissionUsers" :site-id="site.id" />
    </div>
  </div>
</template>

<script>
import { BaseIcon } from '@seegrid/components';
import IconSprite from '@/assets/images/icon-sprite.svg';
import SiteSettingsMap from '@/components/SiteSettingsMap.vue';
import SiteSettingsSchedules from '@/components/SiteSettingsSchedules.vue';
import SiteSettingsUsers from '@/components/SiteSettingsUsers.vue';
import SiteUnconfirmedMessage from '@/components/SiteUnconfirmedMessage.vue';
import Spinner from '@/components/Spinner.vue';
import constants from '@/config/constants';
import authorization from '@/mixins/authorization';

export default {
  name: 'Site',

  components: {
    BaseIcon,
    SiteSettingsMap,
    SiteSettingsSchedules,
    SiteSettingsUsers,
    SiteUnconfirmedMessage,
    Spinner,
  },

  mixins: [authorization],

  props: {
    site: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      unconfirmedVisibility: false,
    };
  },

  computed: {
    // returns the overall completion status progress
    completionStatus() {
      let completedTasks = 0;

      if (
        this.$store.getters['routeNetworkImports/hasRouteNetworkImportState'](
          this.site.id,
          constants.SUPERVISOR_BACKUP_EXTRACTION_TYPES.EXTRACT_ROUTE_NETWORK,
          constants.IMPORT_STATUS.COMPLETE,
        )
      ) {
        completedTasks += 1;
      }

      // TODO figure out if this is an actual issue or a false positive
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (this.$store.getters['schedules/areSchedulesComplete'](this.site.id)) {
        completedTasks += 1;
      }

      if (this.$store.getters['users/areUsersComplete'](this.site.id)) {
        completedTasks += 1;
      }

      return this.$t('sites.completedMilestone', {
        complete: completedTasks,
        total: this.gridColumns,
      });
    },

    // returns the number of columns that should be displayed based on user permissions
    gridColumns() {
      let columns = 0;

      if (this.hasPermissionMap) {
        columns += 1;
      }

      if (this.hasPermissionShiftDefinitions) {
        columns += 1;
      }

      if (this.hasPermissionUsers) {
        columns += 1;
      }

      return columns;
    },

    // returns true if the user has access to the route network page or false otherwise
    hasPermissionMap() {
      return this.hasFeatureControl(this.FEATURES.MAP, this.CONTROL_LEVELS.VIEWER);
    },

    // returns true if the user has access to the shift definition page or false otherwise
    hasPermissionShiftDefinitions() {
      return this.hasFeatureControl(this.FEATURES.SCHEDULE, this.CONTROL_LEVELS.VIEWER);
    },

    // returns true if the user has access to the user page or false otherwise
    hasPermissionUsers() {
      return this.hasFeatureControl(this.FEATURES.USER, this.CONTROL_LEVELS.VIEWER);
    },

    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },

    // returns true if site data is loading or false otherwise
    loadingSiteData() {
      return (
        this.$store.getters['routeNetworkImports/getLoading'] ||
        this.$store.getters['schedules/getLoading']
      );
    },
  },

  async mounted() {
    await this.displayPreviousSite();
    this.setUnconfirmedVisibility();
  },

  methods: {
    // check for a previously selected site and if necessary display it
    async displayPreviousSite() {
      const previousSite = this.$store.getters['sites/getPreviousSelectedSiteId'];

      if (previousSite && previousSite === this.site.id) {
        await this.$store.dispatch('sites/setSiteDisplay', {
          siteId: this.site.id,
          value: true,
        });
      }
    },

    // event handler for toggling the site container state
    async onClickSite() {
      // prevent toggle while data is loading
      if (this.loadingSiteData) {
        return;
      }

      await this.$store.dispatch('sites/setSiteDisplay', {
        siteId: this.site.id,
        value: !this.site.display,
      });
    },

    // if site is unconfirmed during render, display SiteUnconfirmedMessage
    setUnconfirmedVisibility() {
      this.unconfirmedVisibility = !this.site.setupConfirmed;
    },
  },
};
</script>
