import constants from '@/config/constants';

// get the corresponding control level value of given control level
export const getControlLevelValue = levelName => {
  if (typeof levelName !== 'string') {
    throw new Error('levelName has to be a valid String in getControlLevelValue');
  }
  const controlLevel = Object.keys(constants.CONTROL_LEVELS).find(level => {
    return constants.CONTROL_LEVELS[level].name === levelName;
  });
  if (constants.CONTROL_LEVELS[controlLevel]) {
    return constants.CONTROL_LEVELS[controlLevel].value;
  }
  return null;
};

// check if the feature name is a valid key in the featureControl object
// i.e. 'map', 'schedule', 'user'
export const validateFeatureName = featureName => {
  return Object.keys(constants.FEATURES).some(feature => {
    return constants.FEATURES[feature] === featureName;
  });
};

// check if the feature control name is a valid name in the featureControl object
// i.e. 'DISALLOWED', 'VIEWER', 'EDITOR'
export const validateControlLevelName = controlLevelName => {
  return Object.keys(constants.CONTROL_LEVELS).some(level => {
    return constants.CONTROL_LEVELS[level].name === controlLevelName;
  });
};

// check if the control level object is a valid value in the featureControl object
// i.e. { name: 'DISALLOWED', value: 0 }, { name: 'VIEWER', value: 1 }
export const validateControlLevelObject = controlLevelObject => {
  return Object.keys(constants.CONTROL_LEVELS).some(level => {
    return (
      constants.CONTROL_LEVELS[level].name === controlLevelObject.name &&
      constants.CONTROL_LEVELS[level].value === controlLevelObject.value
    );
  });
};

// validate the featureControl object
export const validateFeatureControlObject = featureControl => {
  if (typeof featureControl !== 'object') return false;
  let valid = true;
  // check if any of the feature names or control levels are invalid
  Object.keys(featureControl).forEach(key => {
    if (!validateFeatureName(key) || !validateControlLevelName(featureControl[key])) {
      valid = false;
    }
  });
  return valid;
};
