import { cerberusClient } from './cerberusClient';
import Routes from './cerberusRoutes';

/**
 * Add one or more users to a given company site
 * @param {*} companyId - id of the company
 * @param {*} siteId  - id of the company site
 * @param {*} users - array of one or more user ids
 */
const addUsersToCompanySite = (companyId, siteId, users) => {
  if (!companyId || !siteId || !users || !users.length)
    throw new Error('missing one or more parameters');
  const route = Routes.auth.GROUP_SITE_MEMBERS.replace(':company_id', companyId).replace(
    ':site_id',
    siteId,
  );
  return cerberusClient().post(route, users);
};

/**
 * Returns the company site details
 * @param {*} companyId - id of the company
 */
const getCompanySites = companyId => {
  if (!companyId) throw new Error('missing one or more parameters');
  const route = Routes.auth.GROUP_SITES.replace(':company_id', companyId);
  return cerberusClient().get(route);
};

/**
 * Removes one or more users from a given company site
 * @param {*} companyId - id of the company
 * @param {*} siteId  - id of the company site
 * @param {*} users - array of one or more user ids
 */
const removeUsersFromCompanySite = (companyId, siteId, users) => {
  if (!companyId || !siteId || !users || !users.length)
    throw new Error('missing one or more parameters');
  const route = Routes.auth.GROUP_SITE_MEMBERS.replace(':company_id', companyId).replace(
    ':site_id',
    siteId,
  );
  return cerberusClient().delete(route, { data: users });
};

export default {
  addUsersToCompanySite,
  getCompanySites,
  removeUsersFromCompanySite,
};
