<template>
  <div
    class="bg-gray-400 h-2 overflow-hidden relative w-full"
    :class="[{ 'rounded-full': rounded }, { indeterminate: indeterminate }]"
  >
    <div
      class="h-full progressbar"
      :class="['bg-primary', { 'absolute top-0': indeterminate }, { 'rounded-full': rounded }]"
      :style="{ width: `${percentage}%` }"
      :aria-valuenow="percentage"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <span class="flex h-full items-center">
        <slot></slot>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProgressBar',
  props: {
    indeterminate: {
      type: Boolean,
      default: false,
    },
    percentage: {
      type: Number,
      default: 0,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="css" scoped>
@keyframes progress-indeterminate {
  0% {
    width: 30%;
    left: -40%;
  }
  60% {
    left: 100%;
    width: 100%;
  }
  to {
    left: 100%;
    width: 0;
  }
}
.progressbar {
  transition: width 0.25s ease;
}
.indeterminate .progressbar {
  animation: progress-indeterminate 1.4s ease infinite;
}
</style>
