<template>
  <div class="c-input__with-search" :class="{ 'c-input__with-search--show-results': showResults }">
    <div class="c-input__with-search__input-wrapper">
      <BaseInput
        ref="inputTextBox"
        class="c-input__with-search__input"
        :label="label"
        :placeholder="placeholder"
        :error="showError"
        :hidden="hidden"
        :value="value"
        @input-updated="onNewValue"
      />
      <BaseButton
        v-show="showClear"
        ref="clearButton"
        class="c-input__with-search__close-icon"
        :button-type="'ghost'"
        icon="fg-icon-close"
        icon-height="16px"
        icon-width="16px"
        :icon-sprite="iconSprite"
        viewBox="0 0 24 24"
        :just-icon="true"
        @click.stop="onInputClear"
      />
    </div>
    <ul v-show="showResults" ref="matchList" class="c-input__with-search__search">
      <li
        v-for="(option, index) in matchingOptions"
        :key="index"
        ref="options"
        tabindex="0"
        @click="onResultClick(option, index, $event)"
        @keyup="onResultKeyUp(option, index, $event)"
        @mouseenter="onResultHover(option, index, $event)"
      >
        {{ option }}
      </li>
    </ul>
  </div>
</template>

<script>
import { BaseButton, BaseInput } from '@seegrid/components';
import IconSprite from '@/assets/images/icon-sprite.svg';

export default {
  name: 'InputWithSearch',
  components: { BaseButton, BaseInput },
  props: {
    error: {
      type: String,
      default: '',
    },
    formatAddNew: {
      type: Function,
      default: null,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    maxResults: {
      type: Number,
      default: 4,
    },
    placeholder: {
      type: String,
      default: '',
    },
    searchOptions: {
      type: Array,
      default: () => [],
    },
    showAddNew: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hasFocus: false,
      searchFor: this.value,
      selected: this.value,
    };
  },
  computed: {
    hasSelection() {
      if (!this.selected) return false;
      return this.selected === this.searchFor;
    },
    iconSprite() {
      return IconSprite;
    },
    matchingOptions() {
      if (!this.searchFor) return [];
      if (!this.searchOptions || !this.searchOptions.length) return [];
      const matches = [];
      const match = this.searchFor.toLowerCase().trim();
      const max = this.maxResults || 5;
      let exists = false;
      for (let i = 0; i < this.searchOptions.length; i += 1) {
        const check = (this.searchOptions[i] || '').toLowerCase().trim();
        if (check.startsWith(match) && matches.length < max) {
          matches.push(check);
        }
        if (check === match) {
          exists = true;
        }
      }
      if (this.showAddNew && !exists) {
        // add a 'new entry' to the list
        let addNewEntry = '';
        if (this.formatAddNew) {
          addNewEntry = this.formatAddNew(this.searchFor);
        }
        if (!addNewEntry) {
          addNewEntry = this.$t('addNewEntry', { option: this.searchFor });
        }
        matches.unshift(addNewEntry);
        if (matches.length >= max) {
          matches.pop();
        }
      }
      return matches;
    },
    showClear() {
      if (this.hidden) return false;
      if (this.showError) return false;
      return !!this.searchFor;
    },
    showError() {
      // hide errors when showing options
      if (!this.error) return this.error;
      return this.showResults ? '' : this.error;
    },
    showResults() {
      if (this.hidden) return false; // dont show when hidden
      if (!this.hasFocus) return false; // dont show when out of focus
      if (this.hasSelection) return false; // dont show after a selection is made
      if (this.matchingOptions.length < 1) return false; // dont show on no options
      if (this.matchingOptions.length > 1) return true; // always show mutiple options
      if (this.matchingOptions[0] !== this.searchFor) return true; // is the selection the same?
      return this.selected !== this.searchFor; // show matching options if selection differs
    },
  },
  watch: {
    hasFocus(val) {
      if (!val) {
        // make a selection of blur
        this.onNewValue(this.searchFor, true);
      }
    },
  },
  mounted() {
    const input = this.findInputElement();
    if (input) {
      // Listen to key presses
      input.addEventListener('keyup', this.onInputKeyup);
    }
  },
  created() {
    document.addEventListener('focusin', this.onFocusChange);
  },
  beforeDestroy() {
    document.removeEventListener('focusin', this.onFocusChange);
  },
  methods: {
    clearSelection() {
      this.selected = '';
    },
    findInputElement() {
      // NOTE: Without changing the component library, access
      //       to the input element in the BaseInput component
      //       is extremely limited. This provides raw access
      //       to the element for wiring up and listening
      //       to events. Be careful what you do with it.
      if (!this.$refs.inputTextBox) return false;
      const inputElement = this.$refs.inputTextBox.$el.querySelector('input');
      return !inputElement ? false : inputElement;
    },
    hasFocusButton() {
      const { activeElement } = document;
      if (!activeElement) return false;
      if (!this.$refs.clearButton) return false;
      return activeElement === this.$refs.clearButton.$el;
    },
    hasFocusInput() {
      const { activeElement } = document;
      if (!activeElement) return false;
      return activeElement === this.findInputElement();
    },
    hasFocusSearch() {
      const { activeElement } = document;
      if (!activeElement) return false;
      if (!this.$refs.options) return false;
      for (let i = 0; i < this.$refs.options.length; i += 1) {
        if (activeElement === this.$refs.options[i]) return true;
      }
      return false;
    },
    onFocusChange() {
      this.hasFocus = this.hasFocusInput() || this.hasFocusButton() || this.hasFocusSearch();
    },
    onInputClear() {
      this.onNewValue('', true);
      this.$nextTick(() => {
        this.setInputFocus();
      });
    },
    onInputKeyup(e) {
      if (!e || !e.target) return;
      if (e.keyCode === 13) {
        // enter pressed
        this.onNewValue(this.searchFor, true);
      }
      if (e.keyCode === 40) {
        // key down
        if (this.matchingOptions.length) {
          // select the first option
          this.setOptionFocus(0);
        }
      }
    },
    async onNewValue(value, force) {
      if (force) {
        // NOTE: This is NOT the proper way to do this
        //       but until the BaseInput is changed
        //       this will have to do.
        const input = this.findInputElement();
        if (input) input.value = value;
      }
      this.searchFor = value;
      this.$emit('input-updated', value);
      if (force) {
        // set the current selection
        this.selected = value;
        this.$emit('input-selected', value);
      }
      await this.$nextTick();
      this.scrollToOptions();
    },
    onResultClick(option, index /* , e */) {
      if (!option) return;
      if (index === 0 && this.showAddNew) {
        this.onNewValue((this.searchFor || '').trim(), true);
      } else {
        this.onNewValue(option, true);
      }
    },
    onResultHover(option, index, e) {
      if (!e || !e.target) return;
      e.target.focus();
    },
    onResultKeyUp(option, index, e) {
      if (!e || !e.keyCode) return;
      if (e.keyCode === 13) {
        // enter pressed
        if (index === 0 && this.showAddNew) {
          this.onNewValue((this.searchFor || '').trim(), true);
        } else {
          this.onNewValue(option, true);
        }
      }
      if (e.keyCode === 38) {
        // key up
        const prev = index - 1;
        if (prev < 0) {
          this.setInputFocus();
        } else {
          this.setOptionFocus(prev);
        }
      }
      if (e.keyCode === 40) {
        // key down
        const next = index + 1;
        if (next >= this.matchingOptions.length) {
          this.setInputFocus();
        } else {
          this.setOptionFocus(next);
        }
      }
    },
    // fires when new User form row added, scroll
    scrollToOptions() {
      try {
        if (this.showResults && this.matchingOptions.length > 0) {
          this.$refs.matchList.scrollIntoView(false);
        }
      } catch (err) {
        console.warn('Error in InputWithSearch scrollToLastRow', err);
      }
    },
    setInputFocus() {
      // NOTE: Witout modifying BaseInput this is
      //       the only way to set focus
      const input = this.findInputElement();
      if (input) input.focus();
    },
    setOptionFocus(index) {
      if (index < 0) return;
      if (!this.$refs.options) return;
      if (!this.$refs.options[index]) return;
      this.$refs.options[index].focus();
    },
  },
};
</script>

<style lang="scss">
@use "sass:math";
@import '@/assets/css/variables.scss';
.c-input__with-search {
  .c-input__with-search__input-wrapper {
    position: relative;
    .c-input__with-search__close-icon {
      position: absolute;
      transform: translateY(60%); // includes label...adjustments
      bottom: 40%; // may be needed in the future
      right: 0;
      padding: 0 0.5rem;
      margin: 0;
      height: 2.25rem;
      border-radius: 0.25rem;
    }
  }
  .c-input__with-search__search {
    margin-top: 1rem;
    line-height: 2.5rem;
    font-size: 1rem;
    font-weight: 400;
    li {
      padding: 0 0.75rem;
      border-bottom: 1px solid rgba(0, 38, 66, 0.06);
      &:focus {
        background-color: $c-primary-light-blue;
        color: white;
        outline: 0;
      }
    }
  }
  &--show-results {
    .c-input__with-search__input {
      .c-input__input-wrapper {
        .c-input__input {
          &:hover:not(:disabled),
          &:focus:not(:disabled) {
            box-shadow: inset 0 0.0625rem 0 0.0625rem $c-primary-dark,
              inset 0.0625rem 0.0625rem 0 0.0625rem $c-primary-dark,
              inset -0.0625rem 0.0625rem 0 0.0625rem $c-primary-dark !important; // Base styles use important... why?
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
          // &:focus-visible {
          //   outline: none;
          // }
        }
      }
    }
  }
}
</style>
