<template>
  <VueMultiselect
    ref="select"
    class="c-single-select"
    :v-model="currentValue"
    :placeholder="placeholder"
    :disabled="disabled"
    :options="options"
    :searchable="false"
    :multiple="false"
    :show-labels="false"
    track-by="value"
    label="text"
    :open-direction="openDirection"
    @select="onSelect"
  >
    <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
    <div slot="caret" name="alert" class="c-single-select__icon">
      <BaseIcon
        class="closed icon"
        name="chevron-down"
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
      />
      <BaseIcon
        class="icon open"
        name="chevron-up"
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
      />
    </div>
  </VueMultiselect>
</template>

<script>
import { BaseIcon } from '@seegrid/components';
import VueMultiselect from 'vue-multiselect';

//
// See: https://vue-multiselect.js.org/
export default {
  name: 'BaseSingleSelect',
  components: {
    BaseIcon,
    VueMultiselect,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    openDirection: {
      default: 'auto',
      type: String,
      validator: value => {
        // The value must match one of these strings
        return ['above', 'auto', 'below', 'bottom', 'top'].indexOf(value) !== -1;
      },
    },
    options: {
      // Expected Format:
      // [{ text: 'Label' value: 'VAL' }]
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentValue: null,
    };
  },
  computed: {},
  methods: {
    onSelect(value) {
      let current = !value ? '' : value;
      current = !current.value ? '' : current.value;
      this.$emit('select-updated', current);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>;

<style lang="scss">
@use "sass:math";
@import '@/assets/css/variables.scss';
@import '@/assets/css/calc.scss';

.c-single-select.multiselect {
  min-height: rem(36px);
  font-size: rem(14px);
  .c-single-select__icon {
    position: absolute;
    top: 50%;
    right: 0.75rem;
    width: 1.25rem;
    height: 1.25rem;
    pointer-events: none;
    transform: translateY(-50%);
    .icon.closed {
      display: block;
    }
    .icon.open {
      display: none;
    }
  }
  .multiselect__tags {
    min-height: rem(36px);
    padding-top: 4px;
    background-color: $c-gray-1;
    .multiselect__placeholder {
      font-size: rem(14px);
      white-space: nowrap;
      color: $c-ctrl-color-dark;
      margin-bottom: 4px;
    }
  }
  &.multiselect--active {
    z-index: 400;
    .icon.closed {
      display: none;
    }
    .icon.open {
      display: block;
    }
    .multiselect__tags {
      border-top-color: black;
      border-left-color: black;
      border-right-color: black;
      .multiselect__placeholder {
        display: block;
      }
    }
    .multiselect__content-wrapper {
      border-left-color: black;
      border-right-color: black;
      border-bottom-color: black;
    }
  }
  .multiselect__tag,
  .multiselect__tag-icon:focus,
  .multiselect__tag-icon:hover,
  .multiselect__option--highlight,
  .multiselect__option--highlight::after {
    background: $c-primary;
  }
}
</style>
