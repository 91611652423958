<template>
  <BaseModalCustom
    ref="userModal"
    class="c-modal__add-user-modal flex flex-col mx-6"
    size="xlarge"
    :close-on-esc="false"
    :heading="$t('userManagement.addUsers.title.bulk')"
  >
    <section v-show="!internalUserMode" class="my-10 px-6">
      <BaseMultiselect
        class="w-1/2"
        :error="getError($v.selectedSites)"
        :group-options="false"
        :label="$t('userManagement.addUsers.sites')"
        :multiple="true"
        :options="sitesOptions"
        :value="selectedSites"
        :validation="$v.selectedSites"
        @input="updateInputSites($event)"
      />
    </section>
    <section ref="userFormContainer" class="flex flex-col max-h-64 overflow-auto px-6">
      <BulkAddUserForm
        v-for="(user, index) in usersData"
        :id="`bulkUserForm${index}`"
        ref="bulkAddUserForms"
        :key="index"
        :index="index"
        :user="user"
        :validate="validate"
        :company="company"
        :current-users="currentUsers"
        :current-email-entries="emailsEntered"
        :internal-users="internalUsers"
        :internal-user-mode="internalUserMode"
        @delete-row="deleteRow"
        @update-form="updateUserData"
        @resolve-email-address="resolveEmailAddress"
      />
    </section>
    <section class="border-opacity-10 bottom-border flex mx-6 pb-8">
      <BaseButton
        :size="'small'"
        :button-type="'secondary'"
        class="text-primary-dark text-sm"
        icon="plus"
        @click.stop="addUser"
      >
        {{ $t('userManagement.buttons.addUser') }}
      </BaseButton>
    </section>
    <section class="flex mb-8 mt-4 px-6">
      <BaseCheckbox
        v-show="!internalUserMode"
        id="bulkUserSendInviteCheck"
        class="c-modal__add-user-modal__form__control mt-4"
        :disabled="!containsConfirmedSite"
        :value="sendUserInvite && containsConfirmedSite"
        :label="$t('userManagement.addUsers.sendUserInvite')"
        label-position="right"
        @input="updateInputUserInvite"
      />
    </section>
    <section v-if="containsUnconfirmedSite" class="flex mb-8 mt-4 px-6">
      <BaseLocalMessage id="unconfirmedSitesMessage" class="border border-primary-lightBlue w-full">
        <div class="ml-3.5 text-primary-lightBlue text-xs">
          {{ $t('userManagement.addUsers.bulk.mixedConfirmMessage') }}
        </div>
      </BaseLocalMessage>
    </section>
    <section class="flex flex-row-reverse px-6">
      <BaseButton button-type="primary" class="ml-3" @click="startSaveUsers">
        {{ $t('userManagement.addUsers.add') }}
      </BaseButton>
      <BaseButton button-type="secondary" @click="onClose">
        {{ $t('userManagement.addUsers.cancel') }}
      </BaseButton>
    </section>
  </BaseModalCustom>
</template>
<script>
import { BaseButton, BaseCheckbox } from '@seegrid/components';
import cloneDeep from 'lodash-es/cloneDeep';
import { required } from 'vuelidate/lib/validators';
import BaseLocalMessage from '@/components/BaseLocalMessage.vue';
import BaseModalCustom from '@/components/BaseModalCustom.vue';
import BaseMultiselect from '@/components/BaseMultiselect.vue';
import BulkAddUserForm from '@/components/users/BulkAddUserForm.vue';
import constants from '@/config/constants';
import Company from '@/models/Company';
import OrganizationMember from '@/models/OrganizationMember';
import allowWelcomeEmail from '@/util/allowWelcomeEmail';
import getErrorMessage from '@/util/validation';

export default {
  name: 'BulkAddUsersModal',

  components: {
    BaseButton,
    BaseCheckbox,
    BaseLocalMessage,
    BaseModalCustom,
    BaseMultiselect,
    BulkAddUserForm,
  },
  props: {
    company: {
      default: null,
      type: Company,
    },
    currentUsers: {
      type: Array,
      required: true,
    },
    internalUserMode: {
      type: Boolean,
      required: true,
    },
    internalUsers: {
      type: Array,
      required: true,
    },
    userViewSites: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sendUserInvite: false,
      userSelectedSites: null,
      usersData: [],
      validForm: 0,
      validate: false,
    };
  },
  computed: {
    allSitesOption() {
      return {
        name: this.$t('sites.allSites'),
        value: constants.AUTH0.ALL_SITES,
      };
    },

    containsConfirmedSite() {
      const selectedSites = this.selectedSites.map(site => site.value);
      return allowWelcomeEmail.containsConfirmedSite(selectedSites);
    },

    containsUnconfirmedSite() {
      const selectedSites = this.selectedSites.map(site => site.value);
      return allowWelcomeEmail.containsUnconfirmedSite(selectedSites);
    },

    // Array of newly entered emails in bulk modal
    emailsEntered() {
      return (this.usersData || []).map(u => u.email);
    },
    selectedSites() {
      // Internal users added to All Sites
      if (this.internalUserMode) {
        return [this.sitesOptions.find(o => o.value === constants.AUTH0.ALL_SITES)];
      }
      if (!Array.isArray(this.userSelectedSites)) {
        return this.userViewSites.map(site => this.sitesOptions.find(o => o.value === site.id));
      }
      return this.userSelectedSites.map(site => this.sitesOptions.find(o => o.value === site));
    },
    sites() {
      return this.$store.getters['sites/getSites'];
    },
    // the list of available site options
    sitesOptions() {
      const options = this.sites.map(site => {
        return {
          name: site.name,
          value: site.id,
        };
      });
      options.unshift(this.allSitesOption);
      return options;
    },
  },
  validations: {
    selectedSites: {
      required,
    },
  },
  methods: {
    // create new user for use by the users modal
    async addUser() {
      const user = this.createUser();
      this.usersData.push(user);
      await this.$nextTick();
      this.scrollToLastRow();
    },
    cloneUser(user) {
      if (!user) {
        throw new Error('Valid user is required for cloneUser');
      }
      const newUser = cloneDeep(user);
      if (this.internalUserMode) {
        newUser.sites = [constants.AUTH0.ALL_SITES];
      }
      return newUser;
    },
    close() {
      this.$refs.userModal.close();
    },
    // create new user instance
    createUser() {
      const user = new OrganizationMember();
      user.sites = this.selectedSites.map(site => site.value);
      return user;
    },
    // deletes User Row
    deleteRow(rowIndex) {
      if (this.usersData && this.usersData[rowIndex]) {
        this.usersData.splice(rowIndex, 1);
      }
    },
    // display the error message for the given type
    displayErrorMessage(type) {
      this.$store.dispatch('message/display', {
        text: this.$t(`userManagement.error.${type}`),
        type: 'negative',
      });
    },
    getError(validation) {
      return getErrorMessage(validation);
    },
    onClose() {
      this.$refs.userModal.close();
      this.promptOnCancel();
    },
    // show the form
    open() {
      if (!this.usersData || this.usersData.length === 0) {
        this.addUser();
      }
      this.$refs.userModal.open();
    },
    // handles when the user clicks cancel when adding a new user
    promptOnCancel() {
      if (!this.usersData) return;
      if (!this.usersData.length) return;
      if (!this.usersData[0] || !this.usersData[0].email) return;
      this.$emit('prompt-on-cancel');
    },
    // reset the form between opens
    reset() {
      this.sendUserInvite = false;
      this.usersData = [];
      this.validForm = 0;
      this.validate = false;
      this.$v.$reset();
      // Reset the validation error state for individual form
      if (this.$refs.bulkAddUserForms) {
        this.$refs.bulkAddUserForms.forEach(f => {
          if (f && f.$v) {
            f.$v.$reset();
          }
        });
      }
    },
    // called when an email address is entered to lookup the user
    resolveEmailAddress({ email, index }) {
      // find an existing user match
      const checkEmail = (email || '').toLowerCase();
      for (let i = 0; i < this.currentUsers.length; i += 1) {
        const user = this.currentUsers[i];
        if ((user.email || '').toLowerCase() === checkEmail) {
          this.$set(this.usersData, index, this.cloneUser(user));
          return;
        }
      }
      // find an internal user match (not in the current list)
      if (this.internalUserMode) {
        for (let i = 0; i < this.internalUsers.length; i += 1) {
          const user = this.internalUsers[i];
          if ((user.email || '').toLowerCase() === checkEmail) {
            this.$set(this.usersData, index, this.cloneUser(user));
            return;
          }
        }
      }
      // User does not exist - create a new user object to clear any previously updated attributes
      const user = this.createUser();
      user.email = email;
      this.$set(this.usersData, index, user);
    },
    // called to save the current user
    async saveUsers() {
      if (!(this.company instanceof Company)) {
        throw new TypeError('company is required to save user');
      }
      await this.$store.dispatch('users/setLoading', true);
      this.$refs.userModal.close();
      try {
        // Create a new user from the list
        const newUsers = this.usersData.filter(u => u.isNewUser);
        const existingUsers = this.usersData.filter(u => !u.isNewUser);
        if (newUsers && newUsers.length > 0) {
          await this.$store.dispatch('users/saveBulkUsers', {
            company: this.company,
            invite: this.sendUserInvite,
            users: newUsers,
          });
        }
        if (existingUsers && existingUsers.length > 0) {
          await this.$store.dispatch('users/saveUserSitesBulk', {
            company: this.company,
            isInternal: this.internalUserMode,
            sites: this.selectedSites.map(site => site.value),
            users: existingUsers,
          });
        }
      } catch (error) {
        if (error.message === constants.API_ERROR_TYPES.BULK_USER_PARTIAL_FAILED) {
          this.displayErrorMessage(constants.API_ERROR_TYPES.BULK_USER_PARTIAL_FAILED);
        } else {
          console.error(JSON.stringify(error));
          this.displayErrorMessage(constants.API_ERROR_TYPES.SAVE);
        }
      }
      this.close();
      await this.$store.dispatch('users/setLoading', false);
    },
    // fires when new User form row added, scroll
    scrollToLastRow() {
      try {
        const lastIndex = this.$refs.bulkAddUserForms.length - 1;
        if (this.$refs.bulkAddUserForms[lastIndex] && this.$refs.bulkAddUserForms[lastIndex].$el) {
          this.$refs.bulkAddUserForms[lastIndex].$el.scrollIntoView(false);
        }
      } catch (err) {
        console.warn('Failed scrolling to New User row', err);
      }
    },
    // startSaveUsers triggers the save process by starting validations
    async startSaveUsers() {
      this.validate = true;
      await this.$nextTick();
      const invalidForm = this.$refs.bulkAddUserForms.find(
        formComponent => formComponent.$v.$invalid === true,
      );
      // All Forms are valid
      if (!this.$v.$invalid && !invalidForm) {
        await this.saveUsers();
      } else {
        // At least one form is invalid, reopen form after save button click
        this.$refs.userModal.open();
      }
    },
    // called as the user updates for entries
    updateInputSites(inputData) {
      let selectedOptions = inputData;
      // did the user JUST select the all sites option?
      const allSitedJustAdded =
        selectedOptions.includes(this.allSitesOption) &&
        !this.selectedSites.includes(this.allSitesOption);
      if (allSitedJustAdded) {
        // make it the only selection
        selectedOptions = [this.allSitesOption.value];
      } else {
        // filter all sites from the list
        selectedOptions = selectedOptions.filter(o => o !== this.allSitesOption).map(o => o.value);
      }
      this.usersData = this.usersData.map(u => {
        // eslint-disable-next-line no-param-reassign
        u.sites = selectedOptions;
        return u;
      });
      this.userSelectedSites = selectedOptions;
      this.$v.selectedSites.$touch();
    },
    updateInputUserInvite(inputData) {
      this.sendUserInvite = inputData;
      this.usersData.forEach(el => {
        // eslint-disable-next-line no-param-reassign
        el.sendUserInvite = inputData;
      });
    },
    // called as the user entered new form data
    updateUserData(event) {
      if (typeof event.index !== 'number' || !this.usersData[event.index]) {
        return;
      }
      const user = this.usersData[event.index];
      switch (event.field) {
        case 'firstName': {
          user.firstName = event.value;
          user.name = `${user.firstName} ${user.lastName}`;
          break;
        }
        case 'lastName': {
          user.lastName = event.value;
          user.name = `${user.firstName} ${user.lastName}`;
          break;
        }
        default: {
          user[event.field] = event.value;
          break;
        }
      }
      // Force update local state of usersData
      this.usersData.splice(event.index, 1, user);
    },
  },
};
</script>

<style lang="scss" scoped>
#unconfirmedSitesMessage {
  @apply bg-white;
}

.c-modal__add-user-modal {
  ::v-deep .c-modal__heading,
  ::v-deep .c-modal__subheading,
  ::v-deep .c-modal__body-container {
    text-align: left;
  }
  ::v-deep .c-modal__body-container {
    padding-bottom: 1rem;
  }
  ::v-deep .c-modal-mask {
    display: block;
    .c-modal {
      margin-top: 1rem;
      max-height: 80vh;
    }
  }
  @media (min-height: 600px) {
    ::v-deep .c-modal-mask {
      display: block;
      .c-modal {
        margin-top: calc(15vh);
      }
    }
  }
  @media (min-height: 900px) {
    ::v-deep .c-modal-mask {
      display: block;
      .c-modal {
        margin-top: calc(10vh);
      }
    }
  }
}
</style>
