<template>
  <ConfirmationModal
    ref="userSendEmailModal"
    align="left"
    :confirm-title="heading"
    :confirm-message="subHeading"
    :cancel-button="$t('cancel')"
    :confirm-button="$t('userManagement.confirmSendEmail.confirm')"
    :danger-confirm="false"
    @on-confirm-action="onConfirmSend"
  />
</template>

<script>
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import constants from '@/config/constants';
import i18n from '@/i18n';
import allowWelcomeEmail from '@/util/allowWelcomeEmail';

export default {
  name: 'UserSendEmailModal',

  components: {
    ConfirmationModal,
  },

  props: {
    eventType: {
      type: String,
      required: true,
      validator: value => {
        // The value must match one of these strings
        return (
          [
            constants.USER_EMAIL_TYPES.WELCOME,
            constants.USER_EMAIL_TYPES.RESET_PASSWORD,
            constants.USER_EMAIL_TYPES.VERIFICATION,
            '',
          ].indexOf(value) !== -1
        );
      },
    },
    users: {
      type: Array,
      required: true,
    },
  },

  computed: {
    // returns the modal heading based on the event
    heading() {
      switch (this.eventType) {
        case constants.USER_EMAIL_TYPES.WELCOME:
          return i18n.t('userManagement.confirmSendEmail.welcomeEmail.title');
        case constants.USER_EMAIL_TYPES.VERIFICATION:
          return i18n.t('userManagement.confirmSendEmail.verificationEmail.title');
        case constants.USER_EMAIL_TYPES.RESET_PASSWORD:
          return i18n.t('userManagement.confirmSendEmail.resetPassword.title');
        default:
          return '';
      }
    },

    invalidUsers() {
      return this.users.filter(user => {
        return !this.validUsers.some(validUser => validUser.id === user.id);
      });
    },

    // returns the modal text based on the number of users to invite
    subHeading() {
      if (this.users.length > 1) {
        const usersText = this.validUsers.map(user => user.name).join(', ');
        switch (this.eventType) {
          case constants.USER_EMAIL_TYPES.WELCOME:
            if (this.invalidUsers.length > 0) {
              return i18n.t('userManagement.confirmSendEmail.welcomeEmail.messageManyUnconfirmed', {
                users: usersText,
              });
            }
            return i18n.t('userManagement.confirmSendEmail.welcomeEmail.messageMany', {
              users: usersText,
            });
          case constants.USER_EMAIL_TYPES.VERIFICATION:
            return i18n.t('userManagement.confirmSendEmail.verificationEmail.messageMany', {
              users: usersText,
            });
          case constants.USER_EMAIL_TYPES.RESET_PASSWORD:
            return i18n.t('userManagement.confirmSendEmail.resetPassword.messageMany', {
              users: usersText,
            });
          default:
            break;
        }
      }

      if (this.validUsers.length === 1) {
        switch (this.eventType) {
          case constants.USER_EMAIL_TYPES.WELCOME:
            if (this.invalidUsers.length > 0) {
              return i18n.t('userManagement.confirmSendEmail.welcomeEmail.messageOneUnconfirmed', {
                email: this.validUsers[0].email,
                name: this.validUsers[0].name,
              });
            }
            return i18n.t('userManagement.confirmSendEmail.welcomeEmail.messageOne', {
              email: this.validUsers[0].email,
              name: this.validUsers[0].name,
            });
          case constants.USER_EMAIL_TYPES.VERIFICATION:
            return i18n.t('userManagement.confirmSendEmail.verificationEmail.messageOne', {
              email: this.validUsers[0].email,
              name: this.validUsers[0].name,
            });
          case constants.USER_EMAIL_TYPES.RESET_PASSWORD:
            return i18n.t('userManagement.confirmSendEmail.resetPassword.messageOne', {
              email: this.validUsers[0].email,
              name: this.validUsers[0].name,
            });
          default:
            break;
        }
      }

      return '';
    },

    validUsers() {
      if (this.eventType === constants.USER_EMAIL_TYPES.WELCOME) {
        return this.users.filter(user => {
          const allSitesGroup = user.sites.some(site => site === 'all_sites');
          const userSites = allSitesGroup
            ? this.$store.getters['sites/getSites'].map(site => site.id)
            : user.sites;

          return allowWelcomeEmail.containsConfirmedSite(userSites);
        });
      }
      return this.users;
    },
  },

  methods: {
    // display the error message for the given type
    displayErrorMessage(type) {
      this.$store.dispatch('message/display', {
        text: this.$t(`userManagement.error.${type}`),
        type: 'negative',
      });
    },

    // event handler for sending invite emails to the given user(s)
    async onConfirmSend() {
      const userIdList = this.validUsers.map(u => u.id);
      try {
        await this.$store.dispatch('users/sendEmail', {
          emailType: this.eventType,
          userIdList,
        });
      } catch (error) {
        console.error(error);
        let errorType;
        switch (this.eventType) {
          case constants.USER_EMAIL_TYPES.WELCOME:
            errorType = 'sendWelcomeEmails';
            break;
          case constants.USER_EMAIL_TYPES.VERIFICATION:
            errorType = 'sendVerificationEmails';
            break;
          case constants.USER_EMAIL_TYPES.RESET_PASSWORD:
            errorType = 'sendResetPasswordEmails';
            break;
          default:
            break;
        }
        if (errorType) {
          this.displayErrorMessage(errorType);
        }
      }
    },

    open() {
      this.$refs.userSendEmailModal.open();
    },
  },
};
</script>
