import differenceBy from 'lodash-es/differenceBy';
import has from 'lodash-es/has';
import intersectionBy from 'lodash-es/intersectionBy';
import cerberusGroupsAPI from '@/api/cerberusGroupsAPI';
import cerberusOrganizationAPI from '@/api/cerberusOrganizationAPI';
import constants from '@/config/constants';
import Company from '@/models/Company';
import OrganizationMember from '@/models/OrganizationMember';

const actions = {
  // delete the given user
  async deleteUser({ commit }, user) {
    if (!(user instanceof OrganizationMember)) {
      throw new TypeError('user is required');
    }

    commit('setSaving', true);

    try {
      // remove the user from the authorization provider
      await user.delete();
    } catch (error) {
      commit('setSaving', false);

      throw error;
    }

    // remove the user from the store
    commit('deleteUser', user);

    commit('setSaving', false);
  },

  // delete a list of users
  async deleteUsers({ commit }, users) {
    if (!Array.isArray(users)) {
      throw new TypeError('array of users is required');
    }

    commit('setSaving', true);

    try {
      // remove the users from the authorization provider
      await Promise.all(users.map(u => u.delete()));
    } catch (error) {
      console.error(error);

      commit('setSaving', false);

      throw error;
    }

    // remove the users from the store
    commit('deleteUsers', users);

    commit('setSaving', false);
  },

  /**
   * this list of all organization users
   */
  async loadOrgMembers({ commit }, data = {}) {
    // expects a company id
    if (!data || !data.parameters || !data.parameters.companyId)
      throw new Error('users/common/loadOrgMembers expected companyId');

    // request roles separately for performance reasons
    const parameters = {
      ...data.parameters,
      includeRoles: data.parameters.includeRoles || false,
    };

    // get all users in the org
    let members;
    try {
      members = await OrganizationMember.getOrganizationMembers(parameters);
    } catch (error) {
      commit('setLoading', false);
      throw error;
    }

    commit('setOrgMembers', members, data.parameters.companyId);
  },

  /**
   * this list of all seegrid users
   */
  async loadSeegridMembers({ commit, getters, rootGetters }, data = {}) {
    let members;
    let resetClient = false;
    try {
      // First check, has the list been loaded already?
      members = getters.getSeegridMembers;
      if (members && members.length && data.force !== true) return;

      // Next find the seegrid org id and if necessary, switch the auth client
      const seegridOrgId = rootGetters['companies/getSeegridOrgId'];
      const selectedId = rootGetters['companies/getSelectedCompanyId'];
      if (seegridOrgId !== selectedId) {
        resetClient = true;
        const seegrid = rootGetters['companies/getCompanyById'](seegridOrgId);
        await this.dispatch('companies/changeAuthClientOrgId', seegrid);
      }

      // Finally, load the members....
      // to filter seegrid members we really
      // only need the id of the user
      const parameters = {
        companyId: seegridOrgId,
        includeDetails: true, // needed when adding to an org
        includeRoles: false,
        includeSites: false,
      };
      members = ((await OrganizationMember.getOrganizationMembers(parameters)) || []).map(u =>
        u.markAsInternal(),
      );
    } catch (error) {
      console.error(error);
      commit('setLoading', false);
      throw error;
    } finally {
      if (resetClient) {
        // set the client back to the selected company
        await this.dispatch('companies/changeAuthClientOrgId');
      }
    }

    commit('setSeegridMembers', members);
  },

  // remove the given user from organization
  async removeUserOrganization({ commit }, user) {
    if (!(user instanceof OrganizationMember)) {
      throw new TypeError('user is required');
    }

    commit('setSaving', true);

    try {
      // remove the user from the given organization
      await user.removeOrganization();
    } catch (error) {
      commit('setSaving', false);

      throw error;
    }

    // remove the user from the store
    commit('deleteUser', user);

    commit('setSaving', false);
  },

  async removeUserSitesBulk({ commit }, data) {
    if (!has(data, 'users') || !Array.isArray(data.users)) {
      throw new TypeError('users Array is required by removeUserSitesBulk');
    }

    if (!Array.isArray(data.sites)) {
      throw new TypeError('array of sites is required');
    }

    if (!has(data, 'company')) {
      throw new TypeError('company required by removeUserSitesBulk');
    }

    commit('setSaving', true);

    const userIds = data.users.map(u => u.id);

    try {
      const allPromises = data.sites.map(async siteId => {
        cerberusGroupsAPI.removeUsersFromCompanySite(data.company.id, siteId, userIds);
      });
      await Promise.all(allPromises);
    } catch (error) {
      commit('setSaving', false);

      throw error;
    }

    commit('removeBulkUsersSite', { sites: data.sites, users: data.users });

    commit('setSaving', false);
  },

  // remove a list of users from organization
  async removeUsersOrganization({ commit }, users) {
    if (!Array.isArray(users)) {
      throw new TypeError('array of users is required');
    }

    commit('setSaving', true);

    try {
      // remove the users from the given organization
      await Promise.all(users.map(u => u.removeOrganization()));
    } catch (error) {
      console.error(error);

      commit('setSaving', false);

      throw error;
    }

    // remove the users from the store
    commit('deleteUsers', users);

    commit('setSaving', false);
  },

  // reset the state to initial values
  resetState({ commit }) {
    commit('setLoading', true);
    commit('setOrgMembers', [], '');
    // commit('setSeegridMembers', []); // Keep and reuse
  },

  // save the given user
  async saveBulkUsers({ commit }, data) {
    if (!has(data, 'users') || !Array.isArray(data.users)) {
      throw new TypeError('users Array is required by saveBulkUsers');
    }
    if (!has(data, 'company') || !(data.company instanceof Company)) {
      throw new TypeError('company object is required by saveBulkUsers');
    }

    commit('setSaving', true);

    try {
      const { addedUsers, failedUsers } = await OrganizationMember.saveBulk(
        data.company,
        data.users,
        data.invite,
      );
      commit('setBulkUsers', addedUsers);
      if (failedUsers && failedUsers.length > 0) {
        console.error('Failed creating users:', failedUsers);
        throw new Error(constants.API_ERROR_TYPES.BULK_USER_PARTIAL_FAILED);
      }
    } catch (error) {
      commit('setSaving', false);

      throw error;
    }

    commit('setSaving', false);
  },

  // save the given user
  async saveUser({ commit }, data) {
    if (!has(data, 'user') || !(data.user instanceof OrganizationMember)) {
      throw new TypeError('user object is required by saveUser');
    }
    if (!has(data, 'company') || !(data.company instanceof Company)) {
      throw new TypeError('company object is required by saveUser');
    }

    commit('setSaving', true);

    try {
      await data.user.save(data.company, data.invite);
    } catch (error) {
      commit('setSaving', false);

      throw error;
    }

    commit('setUser', data.user);

    commit('setSaving', false);
  },

  // save the users site list (only)
  async saveUserSites({ commit }, data) {
    if (!has(data, 'user') || !(data.user instanceof OrganizationMember)) {
      throw new TypeError('user object is required by saveUserSites');
    }

    commit('setSaving', true);

    try {
      await data.user.updateSites();
    } catch (error) {
      commit('setSaving', false);

      throw error;
    }

    commit('setUser', data.user);

    commit('setSaving', false);
  },

  async saveUserSitesBulk({ commit }, data) {
    if (!has(data, 'users') || !Array.isArray(data.users)) {
      throw new TypeError('users Array is required by saveUserSitesBulk');
    }

    if (!Array.isArray(data.sites)) {
      throw new TypeError('array of sites is required');
    }

    if (!has(data, 'company')) {
      throw new TypeError('company required by saveUserSitesBulk');
    }

    commit('setSaving', true);

    const userIds = data.users.map(u => u.id);
    if (data.isInternal) {
      try {
        // Add internal users to organization
        await cerberusOrganizationAPI.addOrganizationMembers(data.company.id, userIds);
      } catch (error) {
        commit('setSaving', false);
        throw error;
      }
    }

    try {
      const allPromises = data.sites.map(async siteId => {
        cerberusGroupsAPI.addUsersToCompanySite(data.company.id, siteId, userIds);
      });
      await Promise.all(allPromises);
    } catch (error) {
      commit('setSaving', false);

      throw error;
    }

    commit('setBulkUsersSites', { sites: data.sites, users: data.users });

    commit('setSaving', false);
  },

  // save the given user
  async sendEmail({ commit, getters }, data) {
    if (!has(data, 'userIdList') || !has(data, 'emailType') || !Array.isArray(data.userIdList)) {
      throw new Error('userIdList and emailType are required by sendEmail');
    }
    let errors = 0;
    commit('setSaving', true);

    // First check, if member list been loaded
    const members = getters.getOrgMembers;
    const orgId = getters.getOrgMemberId;
    if (!members || members.length === 0) return;

    const membersToEmail = data.userIdList.map(id => {
      const m = members.find(u => u.id === id);
      return m;
    });
    for (let i = 0; i < membersToEmail.length; i += 1) {
      const member = membersToEmail[i];
      try {
        if (member) {
          // eslint-disable-next-line no-await-in-loop
          const resp = await member.sendEmail(data.emailType);
          if (resp && resp.data && resp.data.account_status) {
            member.accountStatus = resp.data.account_status;
          }
        }
      } catch (e) {
        console.error(`Failed sending email for userId: ${member.id}`, e);
        errors += 1;
      }
    }

    commit('setOrgMembers', [...members], orgId);

    commit('setSaving', false);

    if (errors > 0) {
      throw new Error(`Error sending emails for users count: ${errors}`);
    }
  },

  // set the loading flag to the given value
  async setLoading({ commit }, value) {
    commit('setLoading', value);
  },
};

const getters = {
  /**
   * returns true if users for the given site are considered to be complete
   * or false otherwise
   */
  areUsersComplete: (state, usersGetters) => siteId => {
    const orgMembers = usersGetters.getOrgMembers.filter(u => u.hasSiteAccess(siteId));
    const externalMembers = usersGetters.filterSeegridMembers(orgMembers);
    return orgMembers.length > 0 && externalMembers.length > 0;
  },

  // returns the provided list of seegrid users
  filterSeegridMembers: state => list => {
    return differenceBy(list || [], state.seegridMembers, 'id');
  },

  // returns the list of seegrid users also in the provided list
  findSeegridMembers: state => list => {
    return intersectionBy(list || [], state.seegridMembers, 'id');
  },

  // returns true if data is being loaded or false otherwise
  getLoading: state => {
    return state.loading;
  },

  // returns the id of the organization used to load the list of users (see loadOrgMembers)
  getOrgMemberId: state => {
    return state.orgMemberId;
  },

  // returns the list of users in organization (see loadOrgMembers)
  getOrgMembers: state => {
    return state.orgMembers;
  },

  // returns the list of users in organization with the given role (by name)
  getOrgMembersWithRole: (state, usersGetters) => roleName => {
    const role = usersGetters.getRoleByName(roleName);
    return !role ? [] : state.orgMembers.filter(user => user.hasOrganizationRole(role.id));
  },

  // returns true if data is being saved or false otherwise
  getSaving: state => {
    return state.saving;
  },

  // returns the seegrid users array
  getSeegridMembers: state => {
    return state.seegridMembers;
  },
};

const mutations = {
  // delete specified user from the list of loaded members
  deleteUser(state, user) {
    // remove the user from the store
    const index = state.orgMembers.indexOf(user);
    if (index >= 0) {
      state.orgMembers.splice(index, 1);
    }
  },

  // delete specified users from the list of loaded members
  deleteUsers(state, users) {
    if (users && users.length) {
      for (let i = 0; i < users.length; i += 1) {
        // remove the user from the store
        const index = state.orgMembers.indexOf(users[i]);
        if (index >= 0) {
          state.orgMembers.splice(index, 1);
        }
      }
    }
  },

  // remove sites from Users that were bulk removed via the API
  removeBulkUsersSite(state, values) {
    const { sites, users } = values;
    if (!Array.isArray(sites) && sites.length < 1) {
      throw new Error('sites array is required by removeBulkUsersSite');
    }
    if (!Array.isArray(users) && users.length < 1) {
      throw new Error('users array is required by removeBulkUsersSite');
    }

    const toRemoveSet = new Set(sites);
    users.forEach(user => {
      const userState = state.orgMembers.find(userData => userData.id === user.id);

      if (userState) {
        userState.sites = userState.sites.filter(u => !toRemoveSet.has(u));
      }
    });
  },

  // add Users that were bulk added via the API
  setBulkUsers(state, users) {
    if (Array.isArray(users) && users.length > 0) {
      users.forEach(user => {
        const index = state.orgMembers.findIndex(userData => userData.id === user.id);

        if (index >= 0) {
          // update the user in the store
          state.orgMembers.splice(index, 1, user);
        } else {
          // add the user to the store
          state.orgMembers.push(user);
        }
      });
    }
  },

  // add sites to Users that were bulk added via the API
  setBulkUsersSites(state, data) {
    const { sites, users } = data;
    if (Array.isArray(users) && users.length > 0) {
      users.forEach(user => {
        const stateUser = state.orgMembers.find(userData => userData.id === user.id);
        if (stateUser) {
          sites.forEach(site => {
            if (!stateUser.sites.includes(site)) {
              stateUser.sites.push(site);
            }
          });
        }
      });
    }
  },

  // set the loading flag to the given value
  setLoading(state, value) {
    state.loading = value;
  },

  // set users with access to the company
  setOrgMembers(state, orgMembers, orgId) {
    state.orgMemberId = orgId;
    state.orgMembers = orgMembers;
  },

  // set the saving flag to the given value
  setSaving(state, value) {
    state.saving = value;
  },

  // set seegrid users to the given value
  setSeegridMembers(state, seegridMembers) {
    state.seegridMembers = seegridMembers;
  },

  // add new user or update specified user in the list of loaded members
  setUser(state, user) {
    const index = state.orgMembers.findIndex(userData => userData.id === user.id);

    if (index >= 0) {
      // update the user in the store
      state.orgMembers.splice(index, 1, user);
    } else {
      // add the user to the store
      state.orgMembers.push(user);
    }
  },
};

const state = () => ({
  loading: true,
  orgMemberId: '', // the id used to populate the orgMembers
  orgMembers: [], // users with access to the company
  saving: false,
  seegridMembers: [], // seegrid users with access to the company
});

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
