<template>
  <div class="admin-page-container">
    <TheHeader />
    <section class="admin-content-container">
      <div class="border-opacity-10 bottom-border flex items-center justify-between my-4 pb-2">
        <div class="flex items-center mr-auto">
          <BaseButton
            button-type="ghost"
            icon="arrow-left"
            icon-height="24px"
            icon-width="24px"
            viewBox="0 0 16 16"
            just-icon
            :icon-sprite="iconSprite"
            @click="onNavigate({ name: 'sprConfigList' })"
          />
          <h2 class="font-medium ml-2 text-2xl text-primary-dark">
            {{ $t('sprConfig.scheduleReport') }}
          </h2>
        </div>
      </div>
      <div v-if="loading" class="flex justify-items-center mt-10">
        <Spinner :is-local="true" class="mt-4 mx-auto" />
      </div>
      <template v-else>
        <div class="bg-white flex items-center justify-between pb-4 rounded">
          <ScheduleReportForm
            class="p-8"
            :customer-schedules="schedules"
            :report-schedule="blankSPR"
            @navigate="onNavigate"
            @form-updated="onFormUpdated"
          />
        </div>
      </template>
    </section>
    <BaseModal
      ref="leaveConfirmModal"
      :action-one="$t('reportConfigForm.leaveModalCancel')"
      :action-two="$t('reportConfigForm.leaveModalConfirm')"
      :action-two-negative="true"
      :sub-heading="$t('reportConfigForm.leaveModalText')"
      :heading="$t('reportConfigForm.leaveModalTitle')"
      @modal-button-clicked="onLeave"
    />
    <TheFooter />
  </div>
</template>

<script>
import { BaseButton, BaseModal } from '@seegrid/components';
import IconSprite from '@/assets/images/icon-sprite.svg';
import Spinner from '@/components/Spinner.vue';
import TheFooter from '@/components/TheFooter.vue';
import TheHeader from '@/components/TheHeader.vue';
import ScheduleReportForm from '@/components/sprconfig/ScheduleReportForm.vue';
import constants from '@/config/constants';
import authorization from '@/mixins/authorization';
import Company from '@/models/Company';
import SPR from '@/models/SPR';

export default {
  name: 'SprAddView',

  components: {
    BaseButton,
    BaseModal,
    ScheduleReportForm,
    Spinner,
    TheFooter,
    TheHeader,
  },

  mixins: [authorization],

  beforeRouteLeave(to, from, next) {
    if (this.isSavePending && !this.leaveConfirmed) {
      this.leaveTo = to;
      this.$refs.leaveConfirmModal.open();
      return next(false);
    }
    return next();
  },

  data: () => {
    return {
      blankSPR: SPR.getNewEmptySPRSchedule(),
      isSavePending: false,
      leaveConfirmed: false,
      leaveTo: '',
    };
  },

  computed: {
    // returns the currently selected company
    company() {
      return this.$store.getters['companies/getSelectedCompany'];
    },

    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },

    // Loading data
    loading() {
      return this.$store.getters['users/getLoading'] || this.$store.getters['schedules/getLoading'];
    },

    // returns the schedules for the current company
    schedules() {
      return this.$store.getters['schedules/getSchedules'];
    },

    // returns all sites for the current company
    sites() {
      return this.$store.getters['sites/getSites'];
    },

    // returns all sites for the current company
    users() {
      return this.$store.getters['users/getOrgMembers'];
    },
  },
  async mounted() {
    if (!this.sites || this.sites.length === 0) {
      await this.$store.dispatch('companies/loadCompanies');
      await this.$store.dispatch('companies/loadSelectedCompany');
    }
    // ensure the user has access to the selected company
    if (!(this.company instanceof Company)) {
      await this.$router.push({ name: 'home' });
    }
    // Load Users if not loaded
    if (!this.users || this.users.length === 0) {
      await this.loadUsers();
    }
    // Load published schedules
    if (!this.schedules || Object.keys(this.schedules).length === 0) {
      await this.loadPublishedSchedules();
    }
  },
  methods: {
    // load the schedules
    async loadPublishedSchedules() {
      /**
       * if the user does not have the required read permissions ensure the
       * loading flag is reset
       */
      if (
        !this.hasFeatureControl(this.FEATURES.SCHEDULE, this.CONTROL_LEVELS.VIEWER) ||
        !this.company
      ) {
        await this.$store.dispatch('schedules/setLoading', false);
        return;
      }
      await this.$store.dispatch('schedules/setLoading', true);
      await this.$store.dispatch('schedules/getPublishedSchedules', {
        companyId: this.company.id,
      });
      await this.$store.dispatch('schedules/setLoading', false);
    },
    // load the users
    async loadUsers() {
      /**
       * if the user does not have the required read permissions ensure the
       * loading flag is reset
       */
      if (
        !this.hasFeatureControl(this.FEATURES.USER, this.CONTROL_LEVELS.VIEWER, this.company.id) ||
        !this.hasFeatureControl(
          this.FEATURES.USER,
          this.CONTROL_LEVELS.VIEWER,
          process.env.VUE_APP_SEEGRID_ORG_ID,
        )
      ) {
        await this.$store.dispatch('users/setLoading', false);
        console.error(
          'This user does not have the correct permissions to view members of Seegrid organization.',
        );
        return;
      }

      await this.$store.dispatch('users/setLoading', true);

      try {
        await this.$store.dispatch('roles/loadRoles', { force: true });
        await this.$store.dispatch('users/loadOrgMembers', {
          parameters: { companyId: this.company.id, includeSites: true },
        });
        await this.$store.dispatch('users/loadSeegridMembers');
      } catch (error) {
        console.error('Error in SPRConfigListView: loadUsers', error);
      }

      await this.$store.dispatch('users/setLoading', false);
    },
    onFormUpdated(state) {
      this.isSavePending = state;
    },
    async onLeave(event) {
      if (event.action === constants.MODAL_CONFIRM) {
        this.leaveConfirmed = true;
        // wait a tick to allow the modal to execute it's close routine
        await this.$nextTick();
        // complete the navigation
        await this.$router.push(this.leaveTo);
      } else {
        this.$refs.leaveConfirmModal.close();
      }
    },
    async onNavigate(route) {
      try {
        if (route) {
          await this.$router.push(route);
        }
      } catch (error) {
        // do nothing if intercepted by beforeRouteLeave
      }
    },
  },
};
</script>
