import cloneDeep from 'lodash-es/cloneDeep';
import has from 'lodash-es/has';
import sortBy from 'lodash-es/sortBy';
import cerberusAPI from '@/api/cerberusAPI';
import { refreshCerberusToken } from '@/api/cerberusClient';
import config from '@/config/config';
import constants from '@/config/constants';
import Company from '@/models/Company';
import Site from '@/models/Site';
import { getInstance } from '@/plugins/Auth0Plugin';
import { getControlLevelValue } from '@/util/featureControlUtils';

const actions = {
  /**
   * Force the auth client to change organization ids.
   * CAUTION: If you change the ID make sure you set it back to
   *          the selected organization or calls afterwards to
   *          retrieve data will not match.
   * @param {*} company - the company / organization
   * @param {*} authClient - the auth client (or null)
   */
  async changeAuthClientOrgId({ getters }, company, authClient) {
    let newCompany = company;
    if (!newCompany) {
      const newCompanyId = getters.getSelectedCompanyId;
      newCompany = !newCompanyId ? undefined : getters.getCompanyById(newCompanyId);
    }
    if (!newCompany || !newCompany.orgId) {
      console.warn('expected company param or selected company');
      return;
    }
    const client = authClient || getInstance();
    if (!client) throw new Error('unable to get an instance of auth0 client');
    client.setCurrentOrgId(newCompany.orgId);
    await refreshCerberusToken(constants.CERBERUS_CLIENT_TYPES.ORG_BASED);
  },

  // load the companies
  async loadCompanies({ commit, dispatch }) {
    commit('setLoading', true);

    let result = {};
    try {
      result = await cerberusAPI.getLoginMetadata();
    } catch (error) {
      commit('setLoading', false);

      throw error;
    }
    const { metadata = [] } = result.data;

    // Filter list of companies only which user has Admin permissions to
    const companiesWithAdmin = metadata.filter(company => {
      if (company && has(company, 'feature_control') && has(company, 'company_id')) {
        const featureName = constants.FEATURES.ADMIN_COMPANY_SELECTION;
        const controlLevel = constants.CONTROL_LEVELS.VIEWER;
        // compare the feature control values to determine if the company should be listed
        const userControlLevel = getControlLevelValue(company.feature_control[featureName]);
        const compareControlLevel = getControlLevelValue(controlLevel.name);
        return userControlLevel >= compareControlLevel;
      }
      return false;
    });
    await dispatch('setCompanies', sortBy(companiesWithAdmin.map(Company.fromJSON), 'name'));

    commit('setLoading', false);
  },

  /**
   * load the currently selected company id from the state or session, deferring
   * to the state
   *
   * if the selected company is invalid is it reset to null
   */
  async loadSelectedCompany({ dispatch, state }) {
    const sessionCompanyId = sessionStorage.getItem(config.SESSION_KEY_COMPANY_ID);
    const stateCompanyId = state.selectedCompanyId;

    let companyId = null;
    if (stateCompanyId) {
      companyId = stateCompanyId;
    } else if (sessionCompanyId) {
      companyId = sessionCompanyId;
    }

    // ensure the user has access to to selected company
    if (!(state.companies.find(company => company.id === companyId) instanceof Company)) {
      console.warn('company id not found');
      companyId = null;
    }

    await dispatch('setSelectedCompany', companyId);
  },

  // set the Companies  to the given value
  async setAuthProviderCompanies({ commit }, companies) {
    commit('setAuthProviderCompanies', companies);
  },

  // set the companies to the given value
  async setCompanies({ commit, dispatch }, companies) {
    commit('setCompanies', companies);
    await dispatch('updateSelectedCompany');
  },

  // set the current company id used by the company select
  async setCompanySelect({ commit }, companySelectId) {
    commit('setCompanySelect', companySelectId);
  },

  // set the current company id used by the company select
  setLocked({ commit }, value) {
    commit('setLocked', value);
  },

  // set the selected company to the given company id
  async setSelectedCompany({ commit, dispatch, getters, rootGetters }, selectedCompanyId) {
    dispatch('routeNetworkImports/stopAllNetworkImportPolling', null, { root: true });
    commit('setSwitchingCompanies', true);
    let error;
    try {
      const company = getters.getCompanyById(selectedCompanyId);
      await dispatch('changeAuthClientOrgId', company);

      commit('setSelectedCompany', selectedCompanyId);

      // get the sites for the selected company
      const sites = getters.getCompanySites(selectedCompanyId);
      /**
       * update the current sites with a cloned version of the company sites in
       * order to preserve the initial state of the company sites
       */
      await dispatch('sites/setSites', cloneDeep(sites), { root: true });

      /**
       * if the selected site is not valid for the selected company
       * clear the selected site
       */
      if (!(rootGetters['sites/getSelectedSite'] instanceof Site)) {
        await dispatch('sites/setSelectedSite', null, { root: true });
      }
    } catch (err) {
      console.error('Error while setting company: ', err);
      error = err;
    } finally {
      // Need to do this always as it blocks masks entire application
      commit('setSwitchingCompanies', false);
    }
    if (error) {
      throw error;
    }
  },

  async updateSelectedCompany({ dispatch, getters }) {
    const selectedCompanyId = getters.getSelectedCompanyId;
    const company = getters.getCompanyById(selectedCompanyId);
    if (company) {
      await dispatch('setSelectedCompany', company.id);
    }
  },
};

const getters = {
  // returns an array of companies
  getAuthProviderCompanies: state => {
    return state.authProviderCompanies;
  },

  // returns an array of companies
  getCompanies: state => {
    return state.companies;
  },

  // returns the company with the given company id
  getCompanyById: state => companyId => {
    let company;
    if (state.authProviderCompanies && state.authProviderCompanies[companyId]) {
      company = state.companies.find(
        searchCompany =>
          searchCompany.name.toLowerCase().trim() ===
          state.authProviderCompanies[companyId].toLowerCase().trim(),
      );
    } else {
      company = state.companies.find(searchCompany => searchCompany.id === companyId);
    }

    return company instanceof Company ? company : null;
  },

  // returns the feature control object with the given company id
  getCompanyFeatureControl: state => companyId => {
    const company = state.companies.find(searchCompany => searchCompany.id === companyId);

    if (company instanceof Company) {
      if (!company.featureControl) {
        throw new Error('featureControl has not been set for this company');
      }
      return company.featureControl;
    }
    return null;
  },

  // returns the current company id used by the company select
  getCompanySelectId: state => {
    return state.companySelectId;
  },

  // returns the sites for the given company, if any
  getCompanySites: state => companyId => {
    const company = state.companies.find(searchCompany => searchCompany.id === companyId);

    if (company instanceof Company) {
      return company.sites;
    }

    return null;
  },

  // returns true if data is being loaded or processed or false otherwise
  getLoading: state => {
    return state.loading;
  },

  // returns true is if switching company is locked
  getLocked: state => {
    return state.locked;
  },

  // returns the id seegrid organization / company
  getSeegridOrgId: () => {
    const seegridId = process.env.VUE_APP_SEEGRID_ORG_ID;
    if (!seegridId) {
      console.warn('missing .env VUE_APP_SEEGRID_ORG_ID setting');
      return constants.SEEGRID_DEF_ORG_ID;
    }
    return seegridId;
  },

  // returns the selected company object or null if none selected
  getSelectedCompany: state => {
    const { selectedCompanyId, companies } = state;

    if (!selectedCompanyId) {
      return null;
    }

    const company = companies.find(searchCompany => searchCompany.id === selectedCompanyId);

    return company instanceof Company ? company : null;
  },

  // returns the selected company id
  getSelectedCompanyId: state => {
    return state.selectedCompanyId;
  },

  // returns switchingCompanies
  getSwitchingCompanies: state => {
    return state.switchingCompanies;
  },
};

const mutations = {
  // store the Auth Provider companies
  setAuthProviderCompanies(state, companies) {
    state.authProviderCompanies = companies;
  },

  // store the companies
  setCompanies(state, companies) {
    state.companies = companies;
  },

  // store the companySelectId used by the company select
  setCompanySelect(state, companySelectId) {
    state.companySelectId = companySelectId;
  },

  // store the given value for the loading also update locked flag
  setLoading(state, value) {
    state.loading = value;
    state.locked = value;
  },

  // store the given value for the locked flag
  setLocked(state, value) {
    state.locked = value;
  },

  // store the selectedCompanyId in the state and session
  setSelectedCompany(state, selectedCompanyId) {
    // update state
    state.companySelectId = selectedCompanyId;
    state.selectedCompanyId = selectedCompanyId;

    // update session, the key should match the one used by the dashboard
    sessionStorage.setItem(config.SESSION_KEY_COMPANY_ID, selectedCompanyId);
  },

  // update switchingCompanies flag in the state
  setSwitchingCompanies(state, value) {
    if (typeof value !== 'boolean') {
      throw new Error('setSwitchingCompanies accepts only true or false');
    }
    state.switchingCompanies = value;
  },
};

const state = () => ({
  authProviderCompanies: null,
  companies: [],
  companySelectId: null,
  loading: false,
  locked: false,
  selectedCompanyId: null,
  switchingCompanies: false,
});

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
