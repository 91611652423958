<template>
  <section class="font-normal m-4 max-w-sm pt-4">
    <p class="mb-4 text-base">
      {{ $t('scheduleDetails.scheduleName') }}:
      {{ schedule.name }}
    </p>
    <p class="mb-4 text-xs">
      {{ $t('scheduleDetails.daysActive') }}:
      {{ formatDaysOfWeek(schedule.daysOfWeek) }}
    </p>
    <p class="mb-4 text-xs">
      {{ $t('scheduleDetails.timeSpan') }}:
      {{
        $t('scheduleDetails.scheduleStartEndTime', {
          start: formatTime(schedule.startTimeOfDay),
          end: formatTime(schedule.endTimeOfDay),
        })
      }}
    </p>
    <section
      v-for="(shift, shiftIndex) in schedule.shifts"
      :key="`shift-${shiftIndex}`"
      ref="shiftDetailsText"
    >
      <p class="mb-4 text-sm">
        {{ $t('scheduleDetails.shiftName') }}:
        {{ shift.name }}
      </p>
      <p class="mb-4 text-xs">
        {{ $t('scheduleDetails.startEndTime') }}: {{ formatTime(shift.start_time) }} to
        {{ formatTime(shift.end_time) }}
      </p>
    </section>
  </section>
</template>

<script>
import constants from '@/config/constants';

export default {
  name: 'ScheduleDetailsText',
  props: {
    schedule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sortedDaysOfWeek: [
        constants.DAYS.MONDAY,
        constants.DAYS.TUESDAY,
        constants.DAYS.WEDNESDAY,
        constants.DAYS.THURSDAY,
        constants.DAYS.FRIDAY,
        constants.DAYS.SATURDAY,
        constants.DAYS.SUNDAY,
      ],
    };
  },
  methods: {
    formatDaysOfWeek(daysOfWeek) {
      if (daysOfWeek && Array.isArray(daysOfWeek)) {
        const filteredDays = this.sortedDaysOfWeek.filter(day => daysOfWeek.indexOf(day) >= 0);
        const formattedDays = filteredDays.map(day => this.$t(`days.${day}`));
        return formattedDays.length > 0
          ? formattedDays.join(', ')
          : this.$t('scheduleDetails.noDaysActive');
      }
      return this.$t('scheduleDetails.noDaysActive');
    },
    formatTime(timeString) {
      // timeString given in HH:MM:SS
      const datetime = new Date(`1970-01-01 ${timeString}`);
      // return empty string for invalid inputs
      if (Number.isNaN(datetime.getDate())) return '';
      // Add 0 in front of hour and minute values less than 10
      const hours = datetime.getHours() < 10 ? `0${datetime.getHours()}` : datetime.getHours();
      const minutes =
        datetime.getMinutes() < 10 ? `0${datetime.getMinutes()}` : datetime.getMinutes();
      // return formatted time in HH:MM
      return `${hours}:${minutes}`;
    },
  },
};
</script>
