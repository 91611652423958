import has from 'lodash-es/has';
import { Duration } from 'luxon';
import constants from '@/config/constants';
import Schedule from '@/models/Schedule';
import { getPreviousWeekday } from '@/util/dayUtils';

const getSprTimeBySchedule = ({ reportType, reportDay, schedules, siteId }) => {
  let result = constants.SPR_DEFAULT_SEND_TIME;
  if (schedules && has(schedules, `latest.${siteId}`) && schedules.latest[siteId].length > 0) {
    const siteSchedules = schedules.latest[siteId];
    if (reportType === constants.SPR_DELIVERIES.WEEKLY) {
      // Weekly SPR report
      const reportPrevDay = getPreviousWeekday(reportDay);
      // Find schedules that ends on reportDay
      for (let i = 0; i < siteSchedules.length; i += 1) {
        const schedule = siteSchedules[i];
        const scheduleEndTimeBasedOnShifts = schedule.getEndTimeObjBasedOnShifts();
        // Check if Schedule starts on day before report day but end on report day
        // Or check if it starts and ends on the same dat as report day
        if (
          (scheduleEndTimeBasedOnShifts.as('days') >= 1 &&
            schedule.daysOfWeek.indexOf(reportPrevDay) > -1) ||
          (scheduleEndTimeBasedOnShifts.as('days') < 1 &&
            schedule.daysOfWeek.indexOf(reportDay) > -1)
        ) {
          // Set Report send time to 30mins after the schedule end time.
          const scheduleEndTime = Duration.fromObject(Schedule.parseTime(schedule.endTimeOfDay));
          const reportTimeObj = scheduleEndTime.plus(Duration.fromObject({ minutes: 30 }));
          result = reportTimeObj.toFormat('hh:mm');
          console.debug(
            `Found Customer schedule named "${schedule.name}" ending on ${reportDay} at ${schedule.endTimeOfDay}. Setting report send time ${result}`,
          );
          break;
        }
      }
    } else if (reportType === constants.SPR_DELIVERIES.DAILY) {
      // Daily SPR report
      // Find schedules with most number of days
      const schedule = siteSchedules.reduce((a = {}, b = {}) =>
        b.daysOfWeek.length > a.daysOfWeek.length ? b : a,
      );
      if (schedule) {
        const scheduleEndTime = Duration.fromObject(Schedule.parseTime(schedule.endTimeOfDay));
        const reportTimeObj = scheduleEndTime.plus(Duration.fromObject({ minutes: 30 }));
        // Set Report send time to 30mins after the schedule end time.
        result = reportTimeObj.toFormat('hh:mm');
        console.debug(
          `Found Customer schedule named "${schedule.name}" with ${schedule.daysOfWeek.length} days, ending at ${schedule.endTimeOfDay}. Setting report send time ${result}`,
        );
      }
    } else {
      throw new Error(`No Handler for Report Type: ${reportType} in getSprTimeBySchedule.`);
    }
  }
  return result;
};

export default getSprTimeBySchedule;
