<template>
  <div class="admin-page-container">
    <TheHeader />
    <section class="admin-content-container">
      <BaseGlobalMessage
        v-if="displayMessage"
        :can-delete="true"
        :text="messageText"
        :type="messageType"
        class="c-admin-global-message mb-6"
        @delete-error="onHideMessage"
      />

      <div v-if="loading" />
      <template v-else>
        <div
          v-if="siteReports.length || sprSearchString.length"
          class="flex flex-wrap items-center justify-between mt-4 pb-2"
        >
          <div class="mr-auto">
            <h2 class="font-medium text-2.5xl text-primary-dark">
              {{ $t('sprConfig.spr') }}
            </h2>
          </div>
          <div class="flex flex-grow items-center justify-end ml-4" flex-grow="4">
            <BaseSearch
              class="c-search--heading flex flex-grow"
              :min-characters-to-search="1"
              :placeholder="$t('sprConfig.search')"
              @search-updated="onSearch"
            />
            <HelpTooltip
              v-if="showHelp"
              :content="$t('sprConfig.toolTips.searching')"
              class="ml-2"
              direction="top"
              width="295px"
            />
            <BaseButton
              v-if="hasSprEditFeatureControl"
              :size="'small'"
              class="c-btn--heading ml-8 py-3"
              icon="plus"
              @click="onAddSPR"
            >
              {{ $t('sprConfig.makeNewReport') }}
            </BaseButton>
          </div>
        </div>
      </template>

      <BaseLocalMessage v-if="sprSearchString.length" class="mb-3.5">
        <span class="ml-3.5 text-primary-dark2 text-sm">
          {{
            $tc('sprConfig.searchReportHelpText', searchedRecipients.length, {
              count: searchedRecipients.length,
            })
          }}
        </span>
      </BaseLocalMessage>

      <div v-if="sites.length" class="mx-auto">
        <div v-if="loading" class="flex justify-items-center mt-10">
          <Spinner :is-local="true" class="mt-4 mx-auto" />
        </div>
        <template v-else>
          <div v-if="siteReports.length || sprSearchString.length">
            <div v-if="siteReports.length" class="border-opacity-10 top-border">
              <SprSite
                v-for="item in siteReports"
                :key="item.site.id"
                ref="SprSites"
                :site="item.site"
                :reports="item.reports"
                @edit-report="onEditSPR"
              />
            </div>
          </div>
          <div
            v-else-if="hasSprEditFeatureControl"
            class="flex flex-col items-center mx-auto my-36"
          >
            <h2 class="font-medium text-2xl text-center text-primary-dark">
              {{ $t('sprConfig.noSprHelpHeadingEditor') }}
            </h2>
            <p class="mt-4 text-base text-center text-gray-6">
              {{ $t('sprConfig.noSprHelpSubHeadingEditor') }}
            </p>
            <BaseButton
              v-if="hasSprEditFeatureControl"
              :size="'medium'"
              :button-type="'secondary'"
              class="mt-12"
              @click="onAddSPR"
            >
              {{ $t('sprConfig.scheduleReport') }}
            </BaseButton>
          </div>
          <div v-else class="flex flex-col items-center mx-auto my-36">
            <h2 class="font-medium text-2xl text-center text-primary-dark">
              {{ $t('sprConfig.noSprHelpHeadingViewer') }}
            </h2>
          </div>
        </template>
      </div>
    </section>
    <EditSprModal
      v-if="editingSprId"
      ref="editSprModal"
      :customer-schedules="schedules"
      :spr-id="editingSprId"
      @closed="onEditSPRModalClosed"
      @report-updated="onSPRUpdateSuccess"
    />
    <SaveSprStatusModal
      :auto-close="true"
      :allow-retry="false"
      :modal-text="'sprConfig.statusModal'"
    />
    <TheFooter />
  </div>
</template>

<script>
import { BaseButton, BaseGlobalMessage, BaseSearch } from '@seegrid/components';
import BaseLocalMessage from '@/components/BaseLocalMessage.vue';
import Spinner from '@/components/Spinner.vue';
import TheFooter from '@/components/TheFooter.vue';
import TheHeader from '@/components/TheHeader.vue';

import HelpTooltip from '@/components/controls/HelpTooltip.vue';
import EditSprModal from '@/components/sprconfig/EditSprModal.vue';
import SaveSprStatusModal from '@/components/sprconfig/SaveSprStatusModal.vue';
import SprSite from '@/components/sprconfig/SprSite.vue';
import authorization from '@/mixins/authorization';
import Company from '@/models/Company';

export default {
  name: 'SPRConfigListView',

  components: {
    BaseButton,
    BaseGlobalMessage,
    BaseLocalMessage,
    BaseSearch,
    EditSprModal,
    HelpTooltip,
    SaveSprStatusModal,
    Spinner,
    SprSite,
    TheFooter,
    TheHeader,
  },

  mixins: [authorization],

  data: () => {
    return {
      displayMessage: false,
      editingSprId: null,
      messageText: null,
      messageType: null,
      modalHandler: null,
      modalText: '',
      modalTitle: '',
      searchedRecipients: [],
    };
  },

  computed: {
    // returns the currently selected company
    company() {
      return this.$store.getters['companies/getSelectedCompany'];
    },

    // returns true if user can view sprs
    hasSprEditFeatureControl() {
      return this.hasFeatureControl(this.FEATURES.SPR, this.CONTROL_LEVELS.EDITOR);
    },

    // returns true if data is loading or false otherwise
    loading() {
      return this.$store.getters['sprs/getLoading'];
    },

    // returns the schedules for the current company
    schedules() {
      return this.$store.getters['schedules/getSchedules'];
    },

    // hide or show the help icon
    showHelp() {
      // TODO: what logic drives this?
      return true;
    },

    // returns a list of reports by site
    siteReports() {
      const sites = this.$store.getters['sites/getSites'];
      if (!sites || !sites.length) {
        return [];
      }
      const spReports = this.$store.getters['sprs/getSPRs'] || [];
      const siteReports = [];
      for (let i = 0; i < sites.length; i += 1) {
        const site = sites[i];
        const reports = this.filterReportsByUser(spReports[site.id]);
        if (reports && reports.length) {
          // only show sites with reports
          siteReports.push({ reports, site });
        }
      }
      return siteReports;
    },

    // returns all sites for the current company
    sites() {
      return this.$store.getters['sites/getSites'];
    },

    sprSearchString() {
      return this.$store.getters['sprs/getSearchString'];
    },

    // returns the schedules for the current company
    sprs() {
      return this.$store.getters['sprs/getSPRs'];
    },

    // returns all sites for the current company
    users() {
      return this.$store.getters['users/getOrgMembers'];
    },
  },

  watch: {
    // reload the site data when the selected sites change
    async sites() {
      if (!this.loading) {
        await this.loadData();
      }
    },
  },

  // load the required data
  async mounted() {
    // Load companies if not already loaded
    if (!(this.company instanceof Company)) {
      await this.$store.dispatch('companies/loadCompanies');
      await this.$store.dispatch('companies/loadSelectedCompany');
    }
    // ensure the user has access to the selected company
    if (!(this.company instanceof Company)) {
      await this.$router.push({ name: 'home' });
    }
    // Load Users if not loaded
    if (!this.users || this.users.length === 0) {
      await this.loadUsers();
    }
    // Load Sprs if not loaded
    if (Object.keys(this.sprs).length === 0) {
      await this.loadData();
    }
    // Load published schedules
    if (!this.schedules || Object.keys(this.schedules).length === 0) {
      await this.loadPublishedSchedules();
    }
  },

  methods: {
    // returns an array of reports that have users matching their name or email with the search criteria
    filterReportsByUser(reports) {
      return this.sprSearchString
        ? reports.filter(report => {
            return this.filterUsersByNameEmail(report.recipients).length > 0;
          })
        : reports;
    },
    // returns an array of users that match the search criteria, if any
    filterUsersByNameEmail(users) {
      const recipientIds = this.searchedRecipients.map(recipient => recipient.id);
      return this.sprSearchString
        ? users.filter(user => {
            const matchCriteria =
              user.given_name.toLowerCase().includes(this.sprSearchString.toLowerCase()) ||
              user.family_name.toLowerCase().includes(this.sprSearchString.toLowerCase()) ||
              user.email.toLowerCase().includes(this.sprSearchString.toLowerCase());
            if (matchCriteria && !recipientIds.includes(user.id)) {
              this.searchedRecipients.push(user);
            }
            return matchCriteria;
          })
        : users;
    },
    // load the site data
    async loadData() {
      await this.$store.dispatch('sprs/setLoading', true);

      await this.$store.dispatch('sprs/loadSPRsAsync');

      await this.$store.dispatch('sprs/setLoading', false);
    },
    // load the schedules
    async loadPublishedSchedules() {
      /**
       * if the user does not have the required read permissions ensure the
       * loading flag is reset
       */
      if (
        !this.hasFeatureControl(this.FEATURES.SCHEDULE, this.CONTROL_LEVELS.VIEWER) ||
        !this.company
      ) {
        await this.$store.dispatch('schedules/setLoading', false);
        return;
      }
      await this.$store.dispatch('schedules/setLoading', true);
      await this.$store.dispatch('schedules/getPublishedSchedules', {
        companyId: this.company.id,
      });
      await this.$store.dispatch('schedules/setLoading', false);
    },
    // load the users
    async loadUsers() {
      /**
       * if the user does not have the required read permissions ensure the
       * loading flag is reset
       */
      if (
        !this.hasFeatureControl(this.FEATURES.USER, this.CONTROL_LEVELS.VIEWER, this.company.id) ||
        !this.hasFeatureControl(
          this.FEATURES.USER,
          this.CONTROL_LEVELS.VIEWER,
          process.env.VUE_APP_SEEGRID_ORG_ID,
        )
      ) {
        await this.$store.dispatch('users/setLoading', false);
        console.error('This user does not have the correct permissions to view anything.');
        return;
      }

      await this.$store.dispatch('users/setLoading', true);

      try {
        await this.$store.dispatch('roles/loadRoles', { force: true });
        await this.$store.dispatch('users/loadOrgMembers', {
          parameters: { companyId: this.company.id, includeSites: true },
        });
        await this.$store.dispatch('users/loadSeegridMembers');
      } catch (error) {
        console.error('Error in SPRConfigListView: loadUsers', error);
      }

      await this.$store.dispatch('users/setLoading', false);
    },
    onAddSPR() {
      this.$router.push({ name: 'sprConfigAdd' });
      return false;
    },
    // handle editing the report
    async onEditSPR(id) {
      this.editingSprId = id;
      await this.$nextTick();
      this.$refs.editSprModal.open();
    },
    onEditSPRModalClosed() {
      this.editingSprId = null;
    },
    onSPRUpdateSuccess() {
      console.log('Updated SPR schedule with id: ', this.editingSprId);
      this.editingSprId = null;
    },
    async onSearch(event) {
      this.searchedRecipients = [];
      this.$store.dispatch('sprs/setSearchString', event.searchString ? event.searchString : '');
      await this.$nextTick();
      // Toggle SprReport components to display search results
      this.$refs.SprSites.forEach(component => {
        component.showSPRs(event.searchString !== null);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.c-search--heading {
  max-width: 24rem;
}
.c-btn--heading.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
</style>
