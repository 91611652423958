<template>
  <div :style="{ height }">
    <ag-grid-vue
      class="ag-theme-alpine h-full"
      :column-defs="tableColumns"
      :context="context"
      :default-col-def="tableDefaultColumnOptions"
      :grid-options="tableOptions"
      :row-data="tableRows"
      :row-selection="'multiple'"
      :row-multi-select-with-click="enableRowMultiselect"
      :overlay-no-rows-template="noRowsTemplate"
      @grid-ready="onGridReady"
      @selection-changed="onSelectionChanged"
    />
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import i18n from '@/i18n';

export default {
  name: 'DataGridV2',
  components: {
    AgGridVue,
  },
  props: {
    context: {
      required: true,
      type: Object,
    },
    defaultSortModel: {
      default: null,
      type: Array,
    },
    enableRowMultiselect: {
      default: true,
      type: Boolean,
    },
    height: {
      default: '20rem',
      type: String,
    },
    noRowsMessage: {
      default: null,
      type: String,
    },
    tableColumns: {
      required: true,
      type: Array,
    },
    tableRows: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      gridApi: null,
      tableDefaultColumnOptions: {},
      tableOptions: {},
    };
  },
  computed: {
    noRowsTemplate() {
      return this.noRowsMessage ? this.noRowsMessage : i18n.t('noRowsMessage');
    },
  },
  beforeMount() {
    this.tableDefaultColumnOptions = {
      sortable: true,
      sortingOrder: ['asc', 'desc'],
      suppressMovable: true,
    };
    this.tableOptions.suppressCellSelection = true;
    this.tableOptions.suppressHorizontalScroll = true;
    this.tableOptions.onGridSizeChanged = () => {
      this.tableOptions.api.sizeColumnsToFit();
    };
  },
  mounted() {
    if (Array.isArray(this.defaultSortModel)) {
      this.tableOptions.columnApi.applyColumnState({
        state: this.defaultSortModel,
      });
    }
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.$emit('grid-ready', params.api);
    },
    onSelectionChanged() {
      this.$emit('selection-changed');
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/css/variables' as var;
@import '../../../node_modules/ag-grid-community/dist/styles/ag-grid.css';
@import '../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css';

$row-highlight-color: rgba(116, 204, 255, 0.1);
$border-color: rgba(0, 38, 66, 0.12);

::v-deep .ag-column-moving {
  .ag-cell,
  .ag-header-cell {
    transition: left 0s;
  }
}

::v-deep .ag-header-cell {
  min-width: 100%;
}
::v-deep .ag-center-cols-viewport {
  overflow-x: hidden;
}
::v-deep .ag-overlay {
  position: static;
  .ag-overlay-wrapper {
    line-height: 3rem;
    font-size: 0.875rem;
  }
}
::v-deep .ag-theme-alpine {
  & .ag-header-row {
    background-color: var.$c-gray-1;
  }
  & .ag-root-wrapper {
    border: none;
  }
  & .ag-header {
    background-color: var.$c-gray-1;
    border-bottom: none;
    padding-left: 1.5rem;
  }
  & .ag-row {
    background-color: #fff;
    border-right: none;
    border-left: none;
    border-top: 1px solid $border-color;
    border-bottom: none;
    & > .ag-cell {
      padding-left: 2.5rem;
    }
    & .c-btn {
      display: none;
    }
    &.ag-row-selected {
      background-color: $row-highlight-color;
    }
    &.ag-row-hover {
      background-color: $row-highlight-color;
      & .c-btn {
        display: flex;
      }
    }
  }
  & .ag-row-odd {
    background-color: #fff;
    &.ag-row-selected {
      background-color: $row-highlight-color;
    }
  }
  & .ag-checkbox-input-wrapper {
    background-color: #fff;
    border: 1px solid $border-color;
    box-sizing: border-box;
    border-radius: 4px;
    width: 18px;
    height: 18px;
    &:focus-within,
    &:active {
      box-shadow: none;
    }
    & > input.ag-checkbox-input {
      background-color: #fff;
    }
    &::after {
      color: #fff;
    }
    &.ag-checked {
      background-color: var.$c-primary-dark;
      & > input.ag-checkbox-input {
        background-color: #fff;
      }
      &::after {
        top: -2px;
        left: -2px;
        color: #fff;
        font-size: 18px;
        line-height: 18px;
        border: 1px solid $border-color;
        border-radius: 4px;
      }
    }
  }
}
</style>
