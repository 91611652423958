import { Duration } from 'luxon';
import constants from '@/config/constants';

/**
 * returns true if the first shift overlaps the week boundary and the second
 * shift or false otherwise
 */
export default function isWeekBoundaryOverlapInvalid(endTime, startTime) {
  if (endTime.as('hours') > constants.WEEK_HOURS) {
    // reset the end time to the beginning of the week
    const weekBoundaryOverlap = endTime.minus(Duration.fromObject({ hours: constants.WEEK_HOURS }));

    // verify that the end time does not overlap the second shift
    if (weekBoundaryOverlap.as('milliseconds') > startTime.as('milliseconds')) {
      return true;
    }
  }

  return false;
}
