import { Duration } from 'luxon';
import getScheduleCommonDays from '@//util/getScheduleCommonDays';
import Constants from '@/config/constants';
import Schedule from '@/models/Schedule';
import isTimeWithinSchedule from '@/util/isShiftWithinSchedule';
import isValidScheduleDefinition from '@/util/isValidScheduleDefinition';
import isValidShiftDefinition from '@/util/isValidShiftDefinition';

export function shiftMustNotOverlap() {
  // An incomplete shift cannot overlap as it's not defined entirely
  if (!this.schedule.isComplete()) {
    return true;
  }
  const allShifts = this.schedule.getShifts();
  const currentShifts = [];
  const otherShifts = [];
  allShifts.forEach(shift => {
    if (shift.isComplete()) {
      if (shift.name === this.shift.name) {
        currentShifts.push(shift);
      } else {
        otherShifts.push(shift);
      }
    }
  });
  return isValidShiftDefinition(currentShifts, otherShifts, this.schedule);
}

export function shiftNameMustBeUnique(shiftName) {
  if (shiftName === '' || !this.schedule.isComplete()) {
    return true;
  }

  const allShiftNames = this.schedule.getShifts().map(shift => shift.name);
  const shiftNameOccurrences = allShiftNames.filter(c => c === shiftName).length;
  return shiftNameOccurrences <= 1;
}

export function scheduleMustNotOverlap() {
  // An incomplete schedule cannot overlap as it's not defined entirely
  if (!this.schedule.isComplete()) {
    return true;
  }

  const validSchedules = this.schedules.filter(
    (schedule, index) => this.index !== index && schedule.isComplete(),
  );

  return isValidScheduleDefinition(this.schedule, validSchedules);
}
export function scheduleMustNotStartSameDay() {
  // An incomplete schedule cannot be checked as it's not defined entirely
  if (!this.schedule.isComplete()) {
    return true;
  }

  const validSchedules = this.schedules.filter(
    (schedule, index) => this.index !== index && schedule.isComplete(),
  );
  const { days = [] } = getScheduleCommonDays(this.schedule, validSchedules);
  return days.length === 0;
}

export function scheduleNameMustBeUnique(scheduleName) {
  if (scheduleName === '') {
    return true;
  }

  const allNames = this.schedules.map(schedule => schedule.name);
  const nameOccurrences = allNames.filter(c => c === scheduleName).length;
  return nameOccurrences <= 1;
}

export function scheduleMax24Hours() {
  // An incomplete schedule cannot overlap as it's not defined entirely
  if (!this.schedule || !this.schedule.isComplete()) {
    return true;
  }

  const startTime = Duration.fromObject(Schedule.parseTime(this.schedule.startTimeOfDay));
  const endTime = this.schedule.getEndTimeObjBasedOnShifts();
  return endTime.as('hours') - startTime.as('hours') <= Constants.DAY_HOURS;
}

export function shiftStartWithinScheduleTime() {
  // An incomplete schedule cannot be checked
  if (!this.schedule.isComplete()) {
    return true;
  }

  const allShifts = this.schedule.getShifts();
  const currentShift = allShifts ? allShifts[this.shiftIndex] : null;
  if (currentShift && currentShift.isComplete()) {
    return isTimeWithinSchedule(this.schedule, currentShift, currentShift.startTime, null);
  }
  return true;
}
export function shiftEndWithinScheduleTime() {
  // An incomplete schedule cannot be checked
  if (!this.schedule.isComplete()) {
    return true;
  }

  const allShifts = this.schedule.getShifts();
  const currentShift = allShifts ? allShifts[this.shiftIndex] : null;
  if (currentShift && currentShift.isComplete()) {
    return isTimeWithinSchedule(this.schedule, currentShift, null, currentShift.endTime);
  }
  return true;
}
