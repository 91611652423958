import { Duration } from 'luxon';
import constants from '@/config/constants';

/**
 * class for representing and managing a shift
 *
 * shifts are represented as a duration with start and end times relative to a
 * weeks range of 0 to 24 hours
 */
export default class Shift {
  /**
   * creates a Shift object
   *
   * throws an exception if the given end or start times are invalid
   */
  constructor(name, day, startTime, endTime, overlap, timeOutsideSchedule) {
    this.day = day;
    this.endTime = endTime;
    this.name = name;
    this.startTime = startTime;
    this.overlap = overlap || false;
    this.timeOutsideSchedule = timeOutsideSchedule || false;
  }

  // returns the end time formatted as H:SS
  getFormattedEndTime() {
    return Shift.getFormattedTime(this.endTime);
  }

  // returns the start time formatted as H:SS
  getFormattedStartTime() {
    return Shift.getFormattedTime(this.startTime);
  }

  // return Shift End time based on Shift Start time
  static getShiftEndTimeBasedOnStart(start, end) {
    // validate params before proceeding
    if (!start || !end || !Duration.isDuration(start) || !Duration.isDuration(end)) {
      return end;
    }
    const tempStartDuration = Duration.fromObject(start.toObject());
    const tempEndDuration = Duration.fromObject(end.toObject());
    // This is to remove any day offset that was added before comparing to schedule time.
    const startRawTime = tempStartDuration.set({ days: 0 });
    const endRawTime = tempEndDuration.set({ days: 0 });
    let daysToAdd = start.get('days') || 0;
    // if the shift end time is before the start time add a day to account for the day boundary
    if (
      startRawTime &&
      endRawTime &&
      end.get('days') <= daysToAdd &&
      endRawTime.as('milliseconds') <= startRawTime.as('milliseconds')
    ) {
      daysToAdd += 1;
    }
    const result = end.plus(Duration.fromObject({ days: daysToAdd }));
    return result || end;
  }

  // returns the given time formatted as H:SS
  static getFormattedTime(time) {
    // pad minutes with zeroes
    const minutes = time.minutes.toString().padStart(2, '0');

    return `${time.hours}:${minutes}`;
  }

  // returns the end time in hours relative to the beginning of the day
  getEndHours() {
    return (
      this.endTime.hours +
      this.endTime.minutes / constants.HOUR_MINUTES +
      this.endTime.seconds / constants.HOUR_SECONDS
    );
  }

  // returns the start time in hours relative to the beginning of the day
  getStartHours() {
    return (
      this.startTime.hours +
      this.startTime.minutes / constants.HOUR_MINUTES +
      this.startTime.seconds / constants.HOUR_SECONDS
    );
  }

  // returns true if this shift definition has the values required to build shifts or false otherwise
  isComplete() {
    return this.name && this.startTime && this.endTime;
  }

  // returns true if Shift has all entries and no errors
  isValid() {
    return this.isComplete() && !this.timeOutsideSchedule;
  }
}
