import has from 'lodash-es/has';
import Vue from 'vue';
import cerberusAPI from '@/api/cerberusAPI';
import constants from '@/config/constants';
import Site from '@/models/Site';

const actions = {
  // reset the sites display flags
  async resetSitesDisplay({ commit }) {
    commit('setSitesDisplay', {});
  },

  // set the selected site to the given site id
  async setSelectedSite({ commit }, siteId) {
    commit('setSelectedSite', siteId);
  },

  /**
   * set the display property for the given site to the given value and
   * if necessary update the related state display flag
   */
  async setSiteDisplay({ commit, getters }, data) {
    commit('setSiteDisplay', data);

    // get the current state type of the site
    const type = getters.getSiteState(data.siteId);
    // get the related sites and their current display state
    const sites = getters.getSitesByState(type);
    const sitesDisplayed = sites.filter(site => site.display);
    const sitesHidden = sites.filter(site => !site.display);

    // if all sites are displayed or hidden toggle the state display flag
    if (sites.length === sitesDisplayed.length || sites.length === sitesHidden.length) {
      commit('setSitesTypeDisplay', { type, value: data.value });
    }
  },

  // set the sites state to the given value
  async setSiteState({ commit }, data) {
    let setupConfirmed = false;
    const result = await cerberusAPI.setSiteState({
      companyId: data.companyId,
      siteId: data.siteId,
      siteState: data.siteState,
    });

    if (result && result.data) {
      setupConfirmed = result.data.setup_confirmed;
    }

    commit('setSiteState', { setupConfirmed, siteId: data.siteId });
    return setupConfirmed;
  },

  // set the sites to the given value
  async setSites({ commit }, sites) {
    commit('setSites', sites);
  },

  // toggle the sites display flag for the given state type
  async toggleSitesDisplay({ commit, getters }, type) {
    const sites = getters.getSitesByState(type);
    const value = !getters.getSitesDisplay(type);

    for (let i = 0; i < sites.length; i += 1) {
      commit('setSiteDisplay', { siteId: sites[i].id, value });
    }

    commit('setSitesTypeDisplay', { type, value });
  },
};

const getters = {
  // returns the previously selected site id
  getPreviousSelectedSiteId: state => {
    return state.previousSelectedSiteId;
  },

  // returns the selected site object
  getSelectedSite: (state, sitesGetters) => {
    if (!state.selectedSiteId) {
      return null;
    }

    return sitesGetters.getSiteById(state.selectedSiteId);
  },

  // returns the selected site id
  getSelectedSiteId: state => {
    return state.selectedSiteId;
  },

  // returns the site with the given site id
  getSiteById: state => siteId => {
    const site = state.sites.find(searchSite => searchSite.id === siteId);

    return site instanceof Site ? site : null;
  },

  // returns the state of the given site
  getSiteState: (state, sitesGetters) => siteId => {
    if (sitesGetters.isSiteComplete(siteId)) {
      return constants.SITE_STATE.COMPLETE;
    }

    return constants.SITE_STATE.INCOMPLETE;
  },

  // returns an array of all sites for the currently selected company
  getSites: state => {
    return state.sites;
  },

  /**
   * returns an array of sites for the currently selected company that are
   * in the given state type
   */
  getSitesByState: (state, sitesGetters) => type => {
    switch (type) {
      case constants.SITE_STATE.COMPLETE:
        return state.sites.filter(site => sitesGetters.isSiteComplete(site.id));
      case constants.SITE_STATE.INCOMPLETE:
        return state.sites.filter(site => !sitesGetters.isSiteComplete(site.id));
      default:
        throw new Error(`invalid site state: ${type}`);
    }
  },

  /**
   * returns true if the display flag for the given state type is set to true
   * or false otherwise
   */
  getSitesDisplay: state => type => {
    if (!has(state.sitesDisplay, type)) {
      return false;
    }

    return state.sitesDisplay[type];
  },

  /**
   * returns true if the settings for the given site are considered to be
   * complete or false otherwise
   */
  isSiteComplete: (state, sitesGetters, rootState, rootGetters) => siteId => {
    return (
      rootGetters['routeNetworkImports/hasRouteNetworkImportState'](
        siteId,
        constants.SUPERVISOR_BACKUP_EXTRACTION_TYPES.EXTRACT_ROUTE_NETWORK,
        constants.IMPORT_STATUS.COMPLETE,
      ) &&
      rootGetters['schedules/areSchedulesComplete'](siteId) &&
      rootGetters['users/areUsersComplete'](siteId)
    );
  },
};

const mutations = {
  // store the selectedSiteId and update the previousSelectedSiteId
  setSelectedSite(state, selectedSiteId) {
    state.previousSelectedSiteId = state.selectedSiteId;
    state.selectedSiteId = selectedSiteId;
  },

  // set the display property for the given site to the given value
  setSiteDisplay(state, data) {
    const site = state.sites.find(searchSite => searchSite.id === data.siteId);

    if (site instanceof Site) {
      site.display = data.value;
    }
  },

  // store the sites state
  setSiteState(state, data) {
    const { setupConfirmed, siteId } = data;
    try {
      state.sites.find(site => site.id === siteId).setupConfirmed = setupConfirmed;
    } catch (error) {
      throw new Error(`setSiteState received invalid site ID: ${siteId}`);
    }
  },

  // store the sites
  setSites(state, sites) {
    if (Array.isArray(sites)) {
      state.sites = sites;
    } else {
      state.sites = [];
    }
  },

  // store the sitesDisplay flags
  setSitesDisplay(state, value) {
    state.sitesDisplay = value;
  },

  // set the display flag for the given state type to the given value
  setSitesTypeDisplay(state, data) {
    Vue.set(state.sitesDisplay, data.type, data.value);
  },
};

const state = () => ({
  previousSelectedSiteId: null,
  selectedSiteId: null,
  sites: [],
  sitesDisplay: {},
});

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
