<template>
  <div class="admin-page-container">
    <TheHeader
      ref="theHeader"
      :company-change-guard="true"
      @company-change-initiated="handleCompanyChangeInit"
    />
    <BaseGlobalMessage v-if="displayError" :text="errorMessage" :type="'negative'" />

    <section
      class="flex flex-col flex-grow items-center justify-center max-w-6xl mt-16 mx-auto w-10/12"
    >
      <RouteNetworkUpload
        @api-error="onApiError($event)"
        @navigate="handleNavigate"
        @unsaved-changes="setUnsavedChanges"
      />
    </section>
    <TheFooter />
    <ConfirmationModal
      ref="exitModal"
      align="left"
      :confirm-title="$t('routeNetworkView.exitModal.heading')"
      :confirm-message="$t('routeNetworkView.exitModal.subHeading')"
      :cancel-button="$t('routeNetworkView.exitModal.actionOne')"
      :confirm-button="$t('routeNetworkView.exitModal.actionTwo')"
      :danger-confirm="false"
      @on-confirm-action="onConfirmNavigate"
      @on-cancel-action="onCancelNavigate"
    />
  </div>
</template>

<script>
import { BaseGlobalMessage } from '@seegrid/components';
import RouteNetworkUpload from '@/components/RouteNetworkUpload.vue';
import TheFooter from '@/components/TheFooter.vue';
import TheHeader from '@/components/TheHeader.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import constants from '@/config/constants';
import leaveConfirmModalHandlers from '@/mixins/leaveConfirmModalHandlers';
import Site from '@/models/Site';
import SupervisorBackupImport from '@/models/SupervisorBackupImport';

export default {
  name: 'RouteNetworkView',

  components: {
    BaseGlobalMessage,
    ConfirmationModal,
    RouteNetworkUpload,
    TheFooter,
    TheHeader,
  },

  mixins: [leaveConfirmModalHandlers],

  beforeRouteLeave(to, from, next) {
    // Block navigation and show confirm if unsaved changes and pending confirmation
    if (this.unsavedChanges && !this.leaveConfirmed) {
      this.leaveTo = to;
      this.openModal();
      return next(false);
    }
    return next();
  },

  data: () => {
    return {
      displayError: false,
      errorMessage: null,
      leaveConfirmed: false,
      leaveTo: null,
      unsavedChanges: false,
    };
  },

  computed: {
    // returns true if there is an import error or false otherwise
    hasImportError() {
      return (
        (this.siteRouteNetworkImport instanceof SupervisorBackupImport &&
          this.siteRouteNetworkImport.hasExtractMapImageError()) ||
        this.$store.getters['routeNetworkImports/hasImportError']
      );
    },

    // returns the current site
    site() {
      return this.$store.getters['sites/getSelectedSite'];
    },

    // returns the latest route network import for the current site
    siteRouteNetworkImport() {
      if (!(this.site instanceof Site)) {
        return null;
      }
      return this.$store.getters['routeNetworkImports/getSiteRouteNetworkImport'](this.site.id);
    },
  },

  watch: {
    // watch the import error flag and update the error message appropriately
    hasImportError(value) {
      if (value) {
        this.displayErrorMessage(constants.API_ERROR_TYPES.UPLOAD);
      } else {
        this.resetError();
      }
    },
  },

  beforeMount() {
    this.resetError();
  },

  async mounted() {
    // ensure the user has selected a site
    if (!(this.site instanceof Site)) {
      await this.$router.push({ name: 'sites' });
    }

    if (this.hasImportError) {
      this.displayErrorMessage(constants.API_ERROR_TYPES.UPLOAD);
    }
  },

  methods: {
    // display the delete error message of the given type
    displayErrorMessage(type) {
      this.errorMessage = this.$t(`map.error.${type}`);
      this.displayError = true;
    },

    // handle Company change in header
    async handleCompanyChangeInit() {
      if (this.unsavedChanges) {
        this.openModal();
      } else {
        this.leaveConfirmed = true;
        this.$refs.theHeader.confirmCompanyChange();
      }
    },

    // handle Navigation requested from child component
    async handleNavigate(route) {
      if (!route) {
        throw new Error('route parameter is required');
      }

      try {
        await this.$router.push({ name: route });
      } catch (error) {
        // do nothing if intercepted by beforeRouteLeave
      }
    },

    // event handler for displaying the given api error type
    onApiError(event) {
      if (Object.values(constants.API_ERROR_TYPES).includes(event)) {
        this.displayErrorMessage(event);
      }
    },

    openModal() {
      this.$refs.exitModal.open();
    },
    // reset the error message
    resetError() {
      this.displayError = false;
      this.errorMessage = null;
    },

    // Unsaved changes as set by child component used for Leave Confirm modal
    setUnsavedChanges(val) {
      this.unsavedChanges = val;
    },
  },
};
</script>
