import isValidTime from '@/util/isValidTime';

const isShiftComplete = shiftData => {
  // verify that the arguments are valid
  if (!shiftData.name || !isValidTime(shiftData.start_time) || !isValidTime(shiftData.end_time)) {
    return false;
  }
  return true;
};

export default isShiftComplete;
