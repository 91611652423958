<template>
  <div class="admin-page-container">
    <TheHeader />
    <main class="admin-content-container">
      <BaseGlobalMessage
        v-if="messageDisplay"
        :can-delete="true"
        :text="messageText"
        :type="messageType"
        class="mb-6"
        @delete-error="onHideMessage"
      />

      <!-- page heading -->
      <header class="bottom-border flex items-center mb-8 mt-4 pb-4 users-header">
        <BaseButton
          button-type="ghost"
          icon="arrow-left"
          icon-height="24px"
          icon-width="24px"
          viewBox="0 0 16 16"
          just-icon
          :icon-sprite="iconSprite"
          @click="onBackButton()"
        />
        <div class="flex-grow ml-2 mr-auto">
          <h2 class="font-medium text-2.5xl text-primary-dark">
            {{ $t('navigation.breadcrumbs.accountManagement') }} /
            {{ $t('navigation.breadcrumbs.users') }}
          </h2>
        </div>
        <BaseButton
          button-type="primary"
          class="flex ml-auto mr-2"
          size="small"
          @click="onBackButton()"
          >{{ $t('finish') }}</BaseButton
        >
      </header>

      <div v-if="loading" class="container flex justify-center loading-spinner mt-10">
        <Spinner :is-local="true" />
      </div>
      <template v-else>
        <!-- user filter and search -->
        <nav class="users-filters">
          <div class="filters-wrapper">
            <BaseMultiselect
              :group-options-label="$t('sites.allSites')"
              class="filters"
              :label="$t('sites.title')"
              :multiple="true"
              :options="sites"
              :value="selectedSites"
              @input="handleUpdate($event)"
            />
          </div>
          <BaseSearch
            class="search"
            :min-characters-to-search="1"
            :placeholder="$t('userManagement.search')"
            @search-updated="onSearchUpdate"
          />
        </nav>

        <!-- company users table -->
        <section class="bg-gray-2 border-gray-200 mb-8 users-table-container">
          <UsersMgmtTable
            :user-list-type="constants.USER_LIST.COMPANY"
            :company="company"
            :table-label="
              $t('userManagement.userTableLabels.company', { company_name: company.name })
            "
            :users="filteredUsers_Company"
            :no-rows-message="noRowsMessage"
            :can-add="hasFeatureControl(FEATURES.USER, CONTROL_LEVELS.EDITOR)"
            :can-delete="hasFeatureControl(FEATURES.USER, CONTROL_LEVELS.EDITOR)"
            :can-edit="hasFeatureControl(FEATURES.USER, CONTROL_LEVELS.EDITOR)"
            :can-send="hasFeatureControl(FEATURES.USER, CONTROL_LEVELS.EDITOR)"
            @onAddUser="onAddUser"
            @onBulkAdd="onBulkAdd"
            @onDelete="onDelete"
            @onEdit="onEdit"
            @onManage="onManageSites"
            @onSendEmail="onSendEmail"
            @onViewDetails="onViewDetails"
          />
        </section>

        <!-- seegrid users table -->
        <section class="bg-gray-2 border-gray-200 mb-8 users-table-container">
          <UsersMgmtTable
            :user-list-type="constants.USER_LIST.COMPANY_INTERNAL"
            :company="company"
            :table-label="$t('userManagement.userTableLabels.seegrid')"
            :users="filteredUsers_Internal"
            :no-rows-message="noRowsMessage"
            :can-add="hasFeatureControl(FEATURES.USER, CONTROL_LEVELS.EDITOR)"
            :can-delete="hasFeatureControl(FEATURES.USER, CONTROL_LEVELS.EDITOR)"
            :can-edit="false"
            :can-send="false"
            @onAddUser="onAddUser"
            @onBulkAdd="onBulkAdd"
            @onDelete="onDelete"
            @onEdit="onEdit"
            @onManage="onManageCompanies"
            @onSendEmail="onSendEmail"
            @onViewDetails="onViewDetails"
          />
        </section>
      </template>
    </main>

    <TheFooter />

    <ConfirmationModal
      ref="confirmDeleteUsers"
      align="left"
      :confirm-title="$t('userManagement.confirmDeleteUsers.title')"
      :confirm-message="deleteConfirmMessage"
      :cancel-button="$t('userManagement.confirmDeleteUsers.cancel')"
      :confirm-button="$t('userManagement.confirmDeleteUsers.confirm')"
      @on-confirm-action="onDelete_Confirmed"
    />
    <UserDetailsModal ref="userDetailsModal" :user="selectedUser" />
    <UserSendEmailModal
      ref="userSendEmailModal"
      :event-type="sendEmailEventType"
      :users="selectedUsers"
    />
    <AddUserModal
      ref="addCompanyUserModal"
      :company="company"
      :user="selectedUser"
      :new-user="newUser_Company"
      :current-users="users_Company"
      :internal-users="users_Seegrid"
      :internal-user-mode="false"
      @prompt-on-cancel="cancelAddUser"
    />
    <AddUserModal
      ref="addInternalUserModal"
      :company="company"
      :user="selectedUser"
      :new-user="newUser_Internal"
      :current-users="users_Internal"
      :internal-users="users_Seegrid"
      :internal-user-mode="true"
      @prompt-on-cancel="cancelAddUser"
    />
    <BulkAddUsersModal
      ref="addBulkCompanyUsersModal"
      :company="company"
      :current-users="users_Company"
      :internal-users="users_Seegrid"
      :internal-user-mode="false"
      :user-view-sites="selectedSites"
      @prompt-on-cancel="cancelAddUser"
    />
    <BulkAddUsersModal
      ref="addBulkInternalUsersModal"
      :company="company"
      :current-users="users_Internal"
      :internal-users="users_Seegrid"
      :internal-user-mode="true"
      :user-view-sites="selectedSites"
      @prompt-on-cancel="cancelAddUser"
    />
    <EditUserModal
      ref="editCompanyUserModal"
      :company="company"
      :user="selectedUser"
      :current-users="users_Company"
      @prompt-on-cancel="cancelAddUser"
    />
    <BulkManageUsersSitesModal
      ref="bulkManageCompanyUsersModal"
      :company="company"
      :selected-users="selectedUsers"
      :internal-user-mode="false"
    />
    <ConfirmationModal
      ref="confirmCancelAddUser"
      align="left"
      :danger-confirm="false"
      :confirm-title="$t('userManagement.confirmCancelAddUser.title')"
      :confirm-message="$t('userManagement.confirmCancelAddUser.message')"
      :cancel-button="$t('userManagement.confirmCancelAddUser.cancel')"
      :confirm-button="$t('userManagement.confirmCancelAddUser.confirm')"
      @on-cancel-action="cancelAddUser_Cancel"
    />
  </div>
</template>

<script>
import { BaseButton, BaseGlobalMessage, BaseSearch } from '@seegrid/components';
import IconSprite from '@/assets/images/icon-sprite.svg';
import BaseMultiselect from '@/components/BaseMultiselect.vue';
import Spinner from '@/components/Spinner.vue';
import TheFooter from '@/components/TheFooter.vue';
import TheHeader from '@/components/TheHeader.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AddUserModal from '@/components/users/AddUserModal.vue';
import BulkAddUsersModal from '@/components/users/BulkAddUsersModal.vue';
import BulkManageUsersSitesModal from '@/components/users/BulkManageUsersSitesModal.vue';
import EditUserModal from '@/components/users/EditUserModal.vue';
import UserDetailsModal from '@/components/users/UserDetailsModal.vue';
import UserSendEmailModal from '@/components/users/UserSendEmailModal.vue';
import UsersMgmtTable from '@/components/users/UsersMgmtTable.vue';
import constants from '@/config/constants';
import authorization from '@/mixins/authorization';
import message from '@/mixins/message';
import Company from '@/models/Company';
import OrganizationMember from '@/models/OrganizationMember';

// Used to identify events from the above lists
export default {
  name: 'UsersView',

  components: {
    AddUserModal,
    BaseButton,
    BaseGlobalMessage,
    BaseMultiselect,
    BaseSearch,
    BulkAddUsersModal,
    BulkManageUsersSitesModal,
    ConfirmationModal,
    EditUserModal,
    Spinner,
    TheFooter,
    TheHeader,
    UserDetailsModal,
    UserSendEmailModal,
    UsersMgmtTable,
  },

  mixins: [authorization, message],

  data() {
    return {
      activeModalRef: null,
      constants,
      selectedList: '',
      selectedSites: [],
      selectedUser: {},
      selectedUsers: [],
      sendEmailEventType: '',
      userSearch: '',
    };
  },

  computed: {
    // returns the currently selected company
    company() {
      return this.$store.getters['companies/getSelectedCompany'];
    },

    // create the message that appears in the delete confirm box
    deleteConfirmMessage() {
      if (this.selectedUsers.length > 1) {
        const list = this.selectedUsers.map(u => u.name).join(', ');
        return this.$t('userManagement.confirmDeleteUsers.messageMany', { list });
      }
      if (this.selectedUsers.length === 1) {
        const { name } = this.selectedUsers[0];
        return this.$t('userManagement.confirmDeleteUsers.messageOne', {
          company: this.company.name,
          name,
        });
      }
      return '';
    },

    filteredUsers_Company() {
      let users = this.users_Company;
      users = this.filterUsersBySite(users);
      users = this.filterUsersByNameEmail(users);
      return users;
    },

    filteredUsers_Internal() {
      let users = this.users_Internal;
      users = this.filterUsersBySite(users);
      users = this.filterUsersByNameEmail(users);
      return users;
    },

    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },

    // returns true if data is loading or saving, or false otherwise
    loading() {
      return this.$store.getters['users/getLoading'] || this.$store.getters['users/getSaving'];
    },

    // create new users for use by the add users modal
    newUser_Company() {
      const user = new OrganizationMember();
      user.sites = this.selectedSites.map(site => site.id);
      return user;
    },
    newUser_Internal() {
      const user = new OrganizationMember();
      // Internal users added to All Sites
      user.sites = [constants.AUTH0.ALL_SITES];
      return user;
    },

    // what message should be shown when there are no rows
    noRowsMessage() {
      return this.hasFilters
        ? this.$t('userManagement.noRowsMessage.filtered')
        : this.$t('userManagement.noRowsMessage.default');
    },

    // returns the currently selected site
    siteId() {
      return this.$store.getters['sites/getSelectedSiteId'];
    },

    // returns all sites for the current company
    sites() {
      return this.$store.getters['sites/getSites'];
    },

    // returns the users for the currently selected company
    users() {
      if (this.loading) {
        return [];
      }
      return this.$store.getters['users/getOrgMembers'];
    },

    // returns the list of company users
    users_Company() {
      return this.$store.getters['users/filterSeegridMembers'](this.users);
    },

    // return the list of seegird users in this company
    users_Internal() {
      return this.$store.getters['users/findSeegridMembers'](this.users).map(u =>
        u.markAsInternal(),
      );
    },

    // return the list of seegird users
    users_Seegrid() {
      return this.$store.getters['users/getSeegridMembers'];
    },
  },

  watch: {
    // reload the user data when the selected company changes
    async company() {
      if (!this.loading) {
        await this.loadData();
      }
    },
    // If user is saving lock companies selector
    loading(value) {
      this.$store.dispatch('companies/setLocked', value);
    },
  },

  beforeMount() {
    this.$store.dispatch('users/resetState');
  },

  // load the required data
  async mounted() {
    if (!(this.company instanceof Company)) {
      await this.$store.dispatch('companies/loadCompanies');
      await this.$store.dispatch('companies/loadSelectedCompany');
    }

    // ensure the user has access to the selected company
    if (!(this.company instanceof Company)) {
      await this.$router.push({ name: 'home' });
      return;
    }

    await this.loadData();
  },

  methods: {
    // called when the user cancels adding a new user
    cancelAddUser() {
      this.$refs.confirmCancelAddUser.open();
    },
    cancelAddUser_Cancel() {
      this.$nextTick(() => {
        this.activeModalRef.open();
      });
    },

    // display the error message for the given type
    displayErrorMessage(type) {
      this.$store.dispatch('message/display', {
        text: this.$t(`userManagement.error.${type}`),
        type: 'negative',
      });
    },

    // returns an array of users that match the search criteria, if any
    filterUsersByNameEmail(users) {
      return this.userSearch
        ? users.filter(user => {
            return (
              user.name.toLowerCase().includes(this.userSearch.toLowerCase()) ||
              user.email.toLowerCase().includes(this.userSearch.toLowerCase())
            );
          })
        : users;
    },

    // returns an array of users that have access to at least 1 of the selected sites
    filterUsersBySite(users) {
      if (this.selectedSites.length === 0) {
        return users;
      }
      return users.filter(user => {
        for (let i = 0; i < this.selectedSites.length; i += 1) {
          if (user.hasSiteAccess(this.selectedSites[i].id)) {
            return true;
          }
        }
        return false;
      });
    },

    handleBulkAdd() {
      this.$router.push({ name: 'usersBulkAdd' });
    },

    handleUpdate(event) {
      this.selectedSites = event;
    },

    // Indicates whether any filters are at work
    hasFilters() {
      if (this.userSearch) return true;
      if (this.selectedSites.length) return true;
      return false;
    },

    // load the user data
    async loadData() {
      if (
        !this.hasFeatureControl(this.FEATURES.USER, this.CONTROL_LEVELS.VIEWER, this.company.id) ||
        !this.hasFeatureControl(
          this.FEATURES.USER,
          this.CONTROL_LEVELS.VIEWER,
          process.env.VUE_APP_SEEGRID_ORG_ID,
        )
      ) {
        await this.$store.dispatch('users/setLoading', false);
        console.error(
          'This user does not have the correct permissions to view members of Seegrid organization.',
        );
        return;
      }
      await this.$store.dispatch('users/setLoading', true);

      // reset the selected sites
      this.selectedSites = [];

      // if there is a selected site autopopulate it
      if (this.siteId) {
        this.selectedSites.push(this.$store.getters['sites/getSiteById'](this.siteId));
      }

      try {
        await this.$store.dispatch('user/loadUser');
        await this.$store.dispatch('roles/loadRoles', { force: true });
        await this.$store.dispatch('users/loadOrgMembers', {
          parameters: { companyId: this.company.id, includeSites: true },
        });
        await this.$store.dispatch('users/loadSeegridMembers');
      } catch (error) {
        console.error(error);
        this.displayErrorMessage(constants.API_ERROR_TYPES.LOAD);
      }

      await this.$store.dispatch('users/setLoading', false);
    },

    // handle adding users
    onAddUser(list) {
      this.selectedList = list;
      if (list === constants.USER_LIST.COMPANY) {
        this.selectedUser = this.newUser_Company;
        this.activeModalRef = this.$refs.addCompanyUserModal;
        this.$refs.addCompanyUserModal.reset();
        this.$refs.addCompanyUserModal.open();
      } else if (list === constants.USER_LIST.COMPANY_INTERNAL) {
        this.selectedUser = this.newUser_Internal;
        this.activeModalRef = this.$refs.addInternalUserModal;
        this.$refs.addInternalUserModal.reset();
        this.$refs.addInternalUserModal.open();
      }
    },

    // event handler for clicking the back button
    async onBackButton() {
      await this.$router.push({ name: 'sites' });
    },

    // handle adding users in bulk
    onBulkAdd(list) {
      this.selectedList = list;
      if (list === constants.USER_LIST.COMPANY) {
        this.activeModalRef = this.$refs.addBulkCompanyUsersModal;
        this.$refs.addBulkCompanyUsersModal.reset();
        this.$refs.addBulkCompanyUsersModal.open();
      } else if (list === constants.USER_LIST.COMPANY_INTERNAL) {
        this.activeModalRef = this.$refs.addBulkInternalUsersModal;
        this.$refs.addBulkInternalUsersModal.reset();
        this.$refs.addBulkInternalUsersModal.open();
      }
    },

    // Handle removing one or more users
    onDelete(list, users) {
      this.selectedUsers = users;
      this.selectedList = list;
      this.$refs.confirmDeleteUsers.open();
    },
    async onDelete_Confirmed() {
      if (this.selectedUsers.length < 1) return;
      const seegridMembers = this.selectedUsers.filter(user => user.isInternal);
      const nonSeegridMembers = this.selectedUsers.filter(user => !user.isInternal);
      // Remove any Seegrid members from organization
      await this.$store.dispatch('users/removeUsersOrganization', seegridMembers);
      // Delete accounts of any non-Seegrid members
      await this.$store.dispatch('users/deleteUsers', nonSeegridMembers);
      this.selectedUsers = [];
    },

    // Handle editing a user
    onEdit(list, user) {
      this.selectedUser = user;
      this.selectedList = list;
      this.activeModalRef = this.$refs.editCompanyUserModal;
      this.$refs.editCompanyUserModal.reset();
      this.$refs.editCompanyUserModal.open();
    },

    // Handle managing companies
    onManageCompanies(list, users) {
      // TODO: Not currently used - implement for internal users table
      this.selectedUsers = users;
      this.selectedList = list;
      console.log('onManageCompanies', list, users);
    },

    // Handle managing sites
    onManageSites(list, users) {
      this.selectedUsers = users;
      this.selectedList = list;
      this.$refs.bulkManageCompanyUsersModal.open();
    },

    // event handler for the search input
    onSearchUpdate(event) {
      this.userSearch = event.searchString;
    },

    // Handle sending (or resending) invite, verification, and reset pw emails
    onSendEmail(eventType, list, users) {
      this.selectedUsers = users;
      this.selectedList = list;
      if (this.selectedUsers.length < 1) {
        console.warn('all users for this action do not have the correct account status');
        return;
      }
      this.sendEmailEventType = eventType;
      this.$refs.userSendEmailModal.open();
    },

    // Handle viewing details
    onViewDetails(list, user) {
      this.selectedUser = user;
      this.selectedList = list;
      this.$refs.userDetailsModal.open();
    },
  },
};
</script>

<style lang="scss" scoped>
@use "sass:math";
@import '@/assets/css/variables.scss';
@import '@/assets/css/calc.scss';
.admin-content-container {
  > .c-global-message {
    max-width: 72rem;
    width: 100%;
  }
  .users-header {
    button {
      min-height: rem(44px);
    }
  }
  .users-filters {
    display: block;
    margin-bottom: 1rem;
    .search {
      .c-search__input {
        line-height: 1.75;
      }
    }
    .filters-wrapper {
      overflow: visible;
      max-height: calc(24px + 40px); // label+droplist
    }
    .filters {
      margin-bottom: 0.25rem;
      label {
        font-size: 0.875rem;
        line-height: rem(18px); // 18+6=24
        padding-bottom: rem(6px);
      }
    }
  }
  @media (min-width: 1080px) {
    .users-filters {
      display: flex;
      align-items: flex-end;
      .search {
        margin-left: 1rem;
        min-width: rem(293px);
      }
      .filters-wrapper {
        flex-grow: 1;
        margin-bottom: 0;
      }
    }
  }
}
</style>

<style lang="scss">
// override the control styles
.admin-content-container {
  .users-filters {
    .search {
      .c-search__input {
        line-height: 1.75;
      }
    }
  }
}
</style>
