<!--
  Tooltip component
  Use this component to Show tooltip on hover on any element
  How to use.
  Wrap any element with this component to add tooltip example below.
  Props content String (mandatory)
  delay number of ms to hide after, 200ms default,
  direction String on which side to open, defaults to top
  <Tooltip content="TEST THIS TOOLTIP" delay="200" direction="top/right/left/bottom">
    <button ..>
  </Tooltip>
-->
<template>
  <div
    class="Tooltip-Wrapper"
    @mouseenter="showTip"
    @mouseleave="hideTip"
    @focus="showTip"
    @blur="hideTip"
  >
    <slot></slot>
    <div v-show="active" :class="[typeClass]" :style="computedStyles">
      {{ content }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',

  props: {
    content: {
      type: String,
      default: '',
    },
    delay: {
      type: Number,
      default: 200,
    },
    direction: {
      type: String,
      default: 'top',
    },
    height: {
      type: String,
      default: 'auto',
    },
    width: {
      type: String,
      default: 'auto',
    },
  },
  data() {
    return {
      active: false,
      timeout: null,
    };
  },
  computed: {
    computedStyles() {
      return {
        height: this.height,
        width: this.width,
      };
    },
    typeClass() {
      return `Tooltip-Tip ${this.direction}`;
    },
  },
  methods: {
    hideTip() {
      clearInterval(this.timeout);
      this.active = false;
    },
    showTip() {
      this.timeout = setTimeout(() => {
        this.active = true;
      }, this.delay);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
/* Custom properties */
$tooltip-text-color: white;
$tooltip-background-color: $c-primary-dark;
$tooltip-tb-margin: 32px;
$tooltip-lr-margin: 14px;
$tooltip-arrow-size: 8px;

/* Wrapping */
.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem;
  color: $tooltip-text-color;
  background: $tooltip-background-color;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  z-index: 100;
  white-space: normal;
}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: ' ';
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: $tooltip-arrow-size;
  margin-left: calc(#{$tooltip-arrow-size} * -1);
}

/* top positioning */
.Tooltip-Tip.top {
  bottom: calc(#{$tooltip-tb-margin} * 1);
}
/* CSS border triangles */
.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: $tooltip-background-color;
}

/* right positioning */
.Tooltip-Tip.right {
  left: calc(100% + #{$tooltip-lr-margin});
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.right::before {
  left: calc(#{$tooltip-arrow-size} * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: $tooltip-background-color;
}

/* bottom positioning */
.Tooltip-Tip.bottom {
  top: calc(#{$tooltip-tb-margin} * 1);
}
/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: $tooltip-background-color;
}

/* left positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + #{$tooltip-lr-margin});
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.left::before {
  left: auto;
  right: calc(#{$tooltip-arrow-size} * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: $tooltip-background-color;
}

/* bottom-left positioning */
.Tooltip-Tip.bottom-left {
  top: calc(#{$tooltip-tb-margin} * 1); // bottom
  left: auto; // left
  right: calc(100% + #{$tooltip-lr-margin}); // left
  transform: translateX(0) translateY(-50%); // left
}
/* CSS border triangles */
.Tooltip-Tip.bottom-left::before {
  bottom: 60%; // bottom
  left: auto; // left
  right: calc(#{$tooltip-arrow-size} * -2); // left
  transform: translateX(0) translateY(-50%); // left
  border-left-color: $tooltip-background-color; // left
}

/* bottom-right positioning */
.Tooltip-Tip.bottom-right {
  top: calc(#{$tooltip-tb-margin} * 1); // bottom
  left: calc(100% + #{$tooltip-lr-margin}); // right
  right: auto; // right
  transform: translateX(0) translateY(-50%); // right
}
/* CSS border triangles */
.Tooltip-Tip.bottom-right::before {
  bottom: 60%; // bottom
  left: calc(#{$tooltip-arrow-size} * -1); // right
  right: auto; // right
  transform: translateX(0) translateY(-50%); // right
  border-right-color: $tooltip-background-color; // right
}
</style>
