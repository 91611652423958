import has from 'lodash-es/has';
import isString from 'lodash-es/isString';
import { email as isValidEmail } from 'vuelidate/lib/validators';
import cerberusUserAPI from '@/api/cerberusUserAPI';
import constants from '@/config/constants';
import BaseUser from '@/models/BaseUser';

// class for representing and managing a user
export default class User extends BaseUser {
  constructor(
    id = null,
    email = null,
    firstName = '',
    lastName = '',
    name = '',
    companies = {},
    lastLogin = null,
    blocked = false,
    verified = false,
    accountStatus = '',
  ) {
    super();
    this.accountStatus = isString(accountStatus) ? accountStatus : '';
    this.blocked = blocked;
    this.companies = companies;
    this.email = email;
    this.firstName = isString(firstName) ? firstName.trim() : '';
    this.id = id;
    this.lastLogin = lastLogin;
    this.lastName = isString(lastName) ? lastName.trim() : '';
    this.name = isString(name) ? name.trim() : '';
    this.verified = verified;
  }

  // delete the user
  async delete() {
    await cerberusUserAPI.deleteUser(this.id);
  }

  // returns an object with company and site mappings based on the given data
  static fromCompanyJSON(data) {
    if (!Array.isArray(data)) {
      throw new TypeError('data must be an array');
    }

    const companies = {};

    for (let i = 0; i < data.length; i += 1) {
      companies[data[i].company_id] = data[i].site_ids;
    }

    return companies;
  }

  // returns a user object based on the data provided by the api
  static fromUserJSON(user) {
    let companies;
    // companies is not available in Org based Auth
    if (user.companies) {
      companies = User.fromCompanyJSON(user.companies);
    }

    return new User(
      user.user_id,
      user.email,
      user.given_name,
      user.family_name,
      user.name,
      companies,
      user.last_login,
      user.blocked,
      user.email_verified,
      user.account_status,
    );
  }

  // return the user object for the given user id
  static async getUserById(userId) {
    const response = await cerberusUserAPI.getUser(userId);

    if (!has(response, 'data')) {
      return null;
    }

    return User.fromUserJSON(response.data);
  }

  // returns an array of sites the user has access to for the given company
  getUserSites(companyId) {
    if (has(this.companies, companyId) && Array.isArray(this.companies[companyId])) {
      return this.companies[companyId];
    }

    return [];
  }

  // returns true if the user has been assigned to the given company or false otherwise
  hasCompany(companyId) {
    return has(this.companies, companyId);
  }

  // returns true if the user has permissions to access to the given company or false
  isUserCompany(companyId) {
    return has(this.companies, companyId) || has(this.companies, constants.AUTH0.ALL_COMPANIES);
  }

  // returns true if the user has permissions to access to the given site or false
  hasSiteAccess(companyId, siteId) {
    const sites = this.getUserSites(companyId);

    return sites.some(
      searchSiteId => searchSiteId === siteId || searchSiteId === constants.AUTH0.ALL_SITES,
    );
  }

  // returns true if the user is considered to be valid or false otherwise
  isValid() {
    return this.email && this.firstName && this.lastName && isValidEmail(this.email);
  }

  // returns the json expected by the api for creating a user
  toCreateUserJSON(internalGroups = []) {
    // format the companies
    const companies = [];
    Object.entries(this.companies).forEach(([companyId, siteIds]) => {
      companies.push({
        company_id: companyId,
        site_ids: siteIds,
      });
    });

    // if necessary populate the internal group(s)
    let groups = {};
    if (Array.isArray(internalGroups) && internalGroups.length > 0) {
      groups = { internal_groups: internalGroups };
    }

    return {
      companies,
      ...groups,
      user: {
        email: this.email,
        family_name: this.lastName.trim(),
        given_name: this.firstName.trim(),
        name: `${this.firstName} ${this.lastName}`.trim(),
      },
    };
  }

  // returns the json expected by the api for updating a user
  toUpdateUserJSON() {
    return {
      email: this.email,
      family_name: this.lastName.trim(),
      given_name: this.firstName.trim(),
      name: `${this.firstName} ${this.lastName}`.trim(),
    };
  }
}
