const actions = {
  // display the message with the given values
  display({ commit }, data) {
    commit('setText', data.text);
    commit('setType', data.type);
    commit('setCode', data.code);
    commit('setDisplay', true);
  },

  // hide the message and reset the state to initial values
  async hide({ commit }) {
    commit('setDisplay', false);
    commit('setText', '');
    commit('setType', '');
    commit('setCode', '');
  },
};

const getters = {
  // get code value
  getCode: state => {
    return state.code;
  },
  // returns the display flag
  getDisplay: state => {
    return state.display;
  },

  // returns the message text
  getText: state => {
    return state.text;
  },

  // returns the message type
  getType: state => {
    return state.type;
  },
};

const mutations = {
  // set code to the given value
  setCode(state, code) {
    state.code = code;
  },

  // set display to the given value
  setDisplay(state, display) {
    state.display = display;
  },

  // set text to the given value
  setText(state, text) {
    state.text = text;
  },

  // set type to the given value
  setType(state, type) {
    state.type = type;
  },
};

const state = () => ({
  code: '',
  display: false,
  text: '',
  type: '',
});

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
