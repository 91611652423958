import router from '@/router';
import environment from '@/util/environment';

export default Object.freeze({
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  domain: environment.VUE_APP_AUTH0_DOMAIN,
  issuer: process.env.VUE_APP_AUTH0_ISSUER,
  onRedirectCallback: appState => {
    // The *goal* of this statement is to remove auth-related
    // query parameters after the login redirect
    router.replace(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});
