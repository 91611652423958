import store from '@/store';

// returns the array of booleans for setupConfirmed for each site
const getSitesStatus = sites => {
  const sitesStatusArray = [];
  sites.forEach(siteId => {
    const site = store.getters['sites/getSiteById'](siteId);
    sitesStatusArray.push(site.setupConfirmed);
  });
  return sitesStatusArray;
};

// returns array of siteIds for sites
const getSitesToCheck = sites => {
  if (!sites.includes('all_sites')) {
    return sites;
  }
  const allSites = store.getters['sites/getSites'];
  return [...allSites.map(site => site.id)];
};

/**
 * Indicates whether any of the provided sites is confirmed
 * @param {array} sites - Array of site Id's
 * @returns boolean - Any of provided sites is confirmed
 */
const containsConfirmedSite = sites => {
  if (!Array.isArray(sites) || sites.length <= 0) {
    return false;
  }

  const sitesToCheck = getSitesToCheck(sites);
  const sitesStatus = getSitesStatus(sitesToCheck);
  return sitesStatus.some(confirmed => confirmed === true);
};

// Accept array of site id's - Return boolean value if any site is unconfirmed
/**
 * Indicates whether any of the provided sites is confirmed
 * @param {array} sites - Array of site Id's
 * @returns boolean - Any of provided sites is unconfirmed
 */
const containsUnconfirmedSite = sites => {
  if (!Array.isArray(sites) || sites.length <= 0) {
    return false;
  }

  const sitesToCheck = getSitesToCheck(sites);
  const sitesStatus = getSitesStatus(sitesToCheck);
  return sitesStatus.some(confirmed => confirmed === false);
};

export default {
  containsConfirmedSite,
  containsUnconfirmedSite,
};
