import has from 'lodash-es/has';
import sortBy from 'lodash-es/sortBy';
import cerberusUserAPI from '@/api/cerberusUserAPI';
import constants from '@/config/constants';

// class for representing and managing a user role
export default class Role {
  constructor(id, name, description) {
    this.id = id;
    this.description = description;
    this.name = name;
  }

  // as provided by the api
  static fromJSON(role) {
    return new Role(role.id, role.name, role.description);
  }

  // returns true if this is a global role or false otherwise
  isGlobalRole() {
    return constants.USER_ROLES_GLOBAL.includes(this.name);
  }

  // returns true if this is an internal role or false otherwise
  isInternalRole() {
    return constants.USER_ROLES_INTERNAL.includes(this.name);
  }

  // returns an array of roles filtered by the allowed roles
  static async loadRoles() {
    const response = await cerberusUserAPI.getRoles();

    if (!has(response, 'data') || !Array.isArray(response.data)) {
      return [];
    }

    // get the valid role names from the defined list of user roles
    const roleNames = Object.values(constants.USER_ROLES);

    // remove extraneous roles from the response
    const roles = response.data.reduce((accumulator, role) => {
      if (roleNames.includes(role.name)) {
        accumulator.push(Role.fromJSON(role));
      }

      return accumulator;
    }, []);

    return sortBy(roles, 'name');
  }
}
