import Role from '@/models/Role';

const actions = {
  // load the list of roles
  async loadRoles({ commit, getters }, data = {}) {
    const loaded = getters.getLastLoaded;
    if (loaded && data.force !== true) {
      const diff = Date.now() - loaded;
      if (diff < 60000) return;
      // throttle calls to ever 60 seconds
    }
    let roles;
    try {
      roles = await Role.loadRoles();
    } catch (error) {
      commit('setLoading', false);
      throw error;
    }

    commit('setRoles', roles);
  },

  // reset the state to initial values
  resetState({ commit }) {
    commit('setRoles', []);
  },

  // set the loading flag to the given value
  async setLoading({ commit }, value) {
    commit('setLoading', value);
  },
};

const getters = {
  // returns when the data was last loaded
  getLastLoaded: state => {
    return state.loaded;
  },

  // returns true if data is being loaded or false otherwise
  getLoading: state => {
    return state.loading;
  },

  // returns the role object for the given role id, if any
  getRoleById: state => roleId => {
    const role = state.roles.find(searchRole => searchRole.id === roleId);

    if (role instanceof Role) {
      return role;
    }

    return null;
  },

  // returns the role object for the given role name, if any
  getRoleByName: state => roleName => {
    const role = state.roles.find(searchRole => searchRole.name === roleName);

    if (role instanceof Role) {
      return role;
    }

    return null;
  },

  // returns the roles array
  getRoles: state => {
    return state.roles;
  },
};

const mutations = {
  // set the loading flag to the given value
  setLoading(state, value) {
    state.loading = value;
  },

  // set roles to the given value
  setRoles(state, roles) {
    state.roles = roles;
    state.loaded = Date.now();
  },
};

const state = () => ({
  loaded: null,
  loading: true,
  roles: [],
});

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
