import Site from '@/models/Site';
import { validateFeatureControlObject } from '@/util/featureControlUtils';

export default class Company {
  constructor(id, name, sites, orgId, featureControl) {
    if (!Array.isArray(sites) || !sites.every(site => site instanceof Site)) {
      throw new TypeError('sites must be an array of Site objects');
    }
    if (!validateFeatureControlObject(featureControl)) {
      throw new TypeError('featureControl must be a valid object');
    }
    this.id = id;
    this.featureControl = featureControl;
    this.name = name;
    this.orgId = orgId;
    this.sites = sites;
  }

  // as provided by the api
  static fromJSON(companyJSON) {
    return new Company(
      companyJSON.company_id,
      companyJSON.company_name,
      (companyJSON.sites || []).map(Site.fromJSON),
      companyJSON.organization_id,
      companyJSON.feature_control,
    );
  }

  // as expected by the api
  toJSON() {
    return {
      company_id: this.id,
      company_name: this.name,
      organization_id: this.orgId,
      sites: this.sites.map(site => site.toJSON()),
    };
  }
}
