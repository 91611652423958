<template>
  <section v-if="messageVisible">
    <section class="mb-6 unconfirmed-message" :class="messageType">
      <div class="icon-container w-12">
        <BaseIcon
          class="icon w-12"
          :name="messageIcon"
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
        />
      </div>
      <div class="flex flex-col text-container text-primary-dark">
        <div id="messageHeader" class="font-medium mb-4 w-full">{{ messageHeader }}</div>
        <div v-if="!isConfirmed" id="messageList" class="leading-6 mb-4 w-full">
          <ol v-for="listItem in messageList" :key="listItem">
            <li>{{ listItem }}</li>
          </ol>
        </div>
        <div id="message" class="w-full">
          {{ message }}
        </div>
      </div>
      <div class="flex items-center justify-center">
        <BaseButton
          v-if="userIsCAA"
          class="align-middle mx-6"
          button-type="secondary"
          @click="confirmSite"
          >{{ buttonText }}</BaseButton
        >
      </div>
      <div v-if="isConfirmed" class="flex">
        <button class="align-middle p-4" aria-label="close" @click="hideMessage">
          <BaseIcon name="close" width="24px" height="24px" />
        </button>
      </div>
    </section>
  </section>
</template>

<script>
import { BaseButton, BaseIcon } from '@seegrid/components';
import IconSprite from '@/assets/images/icon-sprite.svg';
import authorization from '@/mixins/authorization';

export default {
  name: 'UnconfirmedSitesBanner',

  components: {
    BaseButton,
    BaseIcon,
  },

  mixins: [authorization],

  props: {
    siteId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isConfirmed: false,
      messageVisible: true,
    };
  },

  computed: {
    // returns action button text based on status
    buttonText() {
      return this.isConfirmed ? this.$t('general.undo') : this.$t('general.confirm');
    },

    // returns the currently selected company
    company() {
      return this.$store.getters['companies/getSelectedCompany'];
    },

    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },

    message() {
      if (this.isConfirmed) {
        return this.$t('sites.siteStatus.confirmedMessage.text');
      }
      return this.userIsCAA
        ? this.$t('sites.siteStatus.unconfirmedMessage.textCAA')
        : this.$t('sites.siteStatus.unconfirmedMessage.text');
    },

    messageHeader() {
      if (this.isConfirmed) {
        return this.$t('sites.siteStatus.confirmedMessage.header');
      }
      return this.userIsCAA
        ? this.$t('sites.siteStatus.unconfirmedMessage.headerCAA')
        : this.$t('sites.siteStatus.unconfirmedMessage.header');
    },

    messageIcon() {
      return this.isConfirmed ? 'checkmark' : 'alert';
    },

    messageList() {
      return [
        this.$t('sites.siteStatus.unconfirmedMessage.listItemOne'),
        this.$t('sites.siteStatus.unconfirmedMessage.listItemTwo'),
      ];
    },

    messageType() {
      return this.isConfirmed ? 'positive' : 'warning';
    },

    site() {
      return this.$store.getters[('sites/getSiteById', this.siteId)];
    },

    userIsCAA() {
      return this.hasFeatureControl(this.FEATURES.SITE_REGISTRATION, this.CONTROL_LEVELS.EDITOR);
    },
  },

  methods: {
    async confirmSite() {
      const data = {
        companyId: this.company.id,
        siteId: this.siteId,
        siteState: !this.isConfirmed,
      };

      const response = await this.$store.dispatch('sites/setSiteState', data);

      this.isConfirmed = response;
    },

    hideMessage() {
      this.messageVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$corner-radius: 0.5rem;

.unconfirmed-message {
  border-bottom: 0.0625rem solid rgba(0, 38, 66, 0.12);
  box-shadow: 0 0.625rem 1.25rem rgb(0 38 66 / 4%), 0 0.125rem 0.375rem rgb(0 38 66 / 4%),
    0 0 0.0625rem rgb(0 38 66 / 40%);

  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 45rem) {
    width: calc(100% - (2 * #{5%}) + (#{1rem} * 2));
    max-width: 75rem + (1rem * 2);
    margin-top: 1rem;

    border-radius: $corner-radius;
  }
}

.text-container {
  padding: 0.9rem 0.75rem;
  line-height: 1.4;
  font-weight: 500;
}

.icon-container {
  display: flex;
  align-items: center;
  margin: 0.25rem 0 0.25rem 0.25rem;
  border-radius: $corner-radius / 2;
}

.warning {
  @apply border-status-warning;
  border-width: 0.125rem;

  & .icon-container {
    @apply bg-status-warning;
  }
}

.positive {
  border-color: #3acf6d;
  border-width: 0.125rem;

  & .icon-container {
    background-color: #3acf6d;
  }
}
</style>
