<template>
  <div>
    <div class="flex justify-between pl-1 pr-0.5">
      <div v-for="option in options" :key="option.value" class="px-1">
        <BaseCheckbox
          :id="getId()"
          :hidden="hidden"
          :label="option.text"
          :label-position="'above'"
          :value="getValue(option.value)"
          @input="setValue($event, option.value)"
        />
      </div>
    </div>
    <p v-if="error" class="input-error-text ml-0">
      {{ error }}
    </p>
  </div>
</template>

<script>
import { BaseCheckbox } from '@seegrid/components';
import uniqid from 'uniqid';

export default {
  name: 'InputCheckBox',

  components: {
    BaseCheckbox,
  },

  props: {
    error: {
      type: String,
      default: '',
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      required: true,
    },
  },

  methods: {
    /**
     * generate a unique id for the checkbox component
     * this seems to be required to prevent the same BaseCheckbox component from
     * being reused for all checkbox components
     */
    getId() {
      return uniqid('checkbox-');
    },

    // returns true if the given option is has been selected or false otherwise
    getValue(optionValue) {
      return this.value.includes(optionValue);
    },

    /**
     * if the checkbox for the given value has been checked add it to the values
     * otherwise remove it from the values
     */
    setValue(checked, value) {
      const values = new Set(this.value);

      if (checked) {
        values.add(value);
      } else {
        values.delete(value);
      }

      this.$emit('input', [...values]);
    },
  },
};
</script>
