<template>
  <section
    class="border-opacity-10 c-modal__add-user-modal__form content-start flex pb-4 pt-8 top-border"
  >
    <InputWithSearch
      id="BulkUserEmailInput"
      ref="findUserEmail"
      class="c-modal__add-user-modal__form__control email left-thick-border pl-4"
      :label="$t('userManagement.addUsers.email')"
      :value="userData.email"
      :validation="$v.userData.email"
      :error="validateEmailAddress()"
      :show-add-new="!internalUserMode"
      :format-add-new="formatAddNewUser"
      :search-options="searchUserEmails"
      @input-selected="onEmailSelected"
      @input-updated="updateInput('email', $event, true)"
    />
    <BaseInput
      v-show="showOnNewUser && !internalUserMode"
      id="BulkUserFirstNameInput"
      class="c-modal__add-user-modal__form__control pl-4"
      :label="$t('userManagement.addUsers.firstName')"
      :value="userData.firstName"
      :validation="$v.userData.firstName"
      :error="getError($v.userData.firstName)"
      @input-updated="updateInput('firstName', $event, true)"
    />
    <BaseInput
      v-show="showOnNewUser && !internalUserMode"
      id="BulkUserLastNameInput"
      class="c-modal__add-user-modal__form__control pl-4"
      :label="$t('userManagement.addUsers.lastName')"
      :value="userData.lastName"
      :validation="$v.userData.lastName"
      :error="getError($v.userData.lastName)"
      @input-updated="updateInput('lastName', $event, true)"
    />
    <div
      v-show="showOnExistingUser && !internalUserMode"
      class="bg-gray-1 flex h-10 items-center ml-4 mt-4 p-2 rounded"
    >
      <BaseIcon
        class="mr-1.5 text-primary-dark"
        height="16px"
        name="fg-info-2"
        :icon-sprite="iconSprite"
        width="16px"
        viewBox="0 0 24 24"
      />
      <label>{{ $t('userManagement.addUsers.existingUserMessage') }}</label>
    </div>
    <div
      v-show="showOnNewUser && internalUserMode"
      class="c-modal__add-user-modal__form__control c-modal__add-user-modal__form__internal-msg pl-4"
    >
      <p>{{ $t('userManagement.addUsers.createInternal.message') }}</p>
      <p>{{ $t('userManagement.addUsers.createInternal.instructions') }}</p>
    </div>
    <a class="cursor-pointer ml-auto mt-1 p-2 text-2xl" @click="$emit('delete-row', index)">x</a>
  </section>
</template>

<script>
import { BaseIcon, BaseInput } from '@seegrid/components';
import { email, maxLength, required, requiredIf } from 'vuelidate/lib/validators';
import IconSprite from '@/assets/images/icon-sprite.svg';
import InputWithSearch from '@/components/inputs/InputWithSearch.vue';
import constants from '@/config/constants';
import Company from '@/models/Company';
import isInternalEmail from '@/util/isInternalEmail';
import getErrorMessage from '@/util/validation';

export default {
  name: 'BulkAddUserForm',
  components: { BaseIcon, BaseInput, InputWithSearch },
  props: {
    company: {
      default: null,
      type: Company,
    },
    currentEmailEntries: {
      type: Array,
      required: true,
    },
    currentUsers: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    internalUserMode: {
      type: Boolean,
      required: true,
    },
    internalUsers: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    validate: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      constants,
      emailMismatch: false,
      userData: this.user,
    };
  },
  computed: {
    companies() {
      return this.$store.getters['companies/getCompanies'];
    },

    hasValidEmail() {
      if (!this.userData) return false;
      if (!this.userData.email) return false;
      if (this.emailMismatch) return false;
      return !this.$v.userData.email.$error;
    },

    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },

    isPickingEmail() {
      if (!this.$refs.findUserEmail) return false;
      return this.$refs.findUserEmail.hasFocus && !this.$refs.findUserEmail.showResults;
    },

    searchUserEmails() {
      return this.internalUserMode
        ? (this.internalUsers || []).map(u => u.email).filter(e => e)
        : (this.currentUsers || []).map(u => u.email).filter(e => e);
    },

    // indicates something should be shown if this is an existing user
    showOnExistingUser() {
      if (this.userData.isNewUser) return false;
      if (!this.hasValidEmail) return false;
      if (this.isPickingEmail) return false;
      return true;
    },

    // indicates something should be shown if this is a new user
    showOnNewUser() {
      if (!this.userData.isNewUser) return false;
      if (!this.hasValidEmail) return false;
      if (this.isPickingEmail) return false;
      return true;
    },
  },
  validations: {
    userData: {
      email: {
        email,
        maxLength: maxLength(128),
        required,
      },
      firstName: {
        maxLength: maxLength(128),
        required: requiredIf(function check() {
          return this.userData.isNewUser;
        }),
      },
      lastName: {
        maxLength: maxLength(128),
        required: requiredIf(function check() {
          return this.userData.isNewUser;
        }),
      },
    },
  },
  watch: {
    /**
     * populate the multiselect options when the data is available
     * this ensures that the all companies / sites option is rendered properly
     */
    user() {
      this.userData = this.user;
    },
    validate() {
      if (this.validate) {
        this.validateFormInputs();
      }
    },
  },
  mounted() {},
  methods: {
    // format the new user entry in the search control
    formatAddNewUser(option) {
      return this.$t('userManagement.addUsers.addNewUser', { option });
    },
    getError(validation) {
      return getErrorMessage(validation);
    },
    // called when the user selects an entry in the search control
    onEmailSelected() {
      // for now we dont need to do anything, but future devs
      // can use this feature.
    },
    // called as the user updates for entries
    updateInput(field, input, validateInput = false) {
      const localInput = field === 'email' ? (input || '').toLowerCase() : input;
      const currentEmail = this.user.email;
      this.$emit('update-form', { field, index: this.index, value: localInput });
      if (validateInput) {
        this.$v.userData[field].$touch();
      }
      switch (field) {
        case 'email':
          if (
            localInput &&
            // Resolve from existing email if user has a valid email or always for internal user
            // Internal user is always picked from existing list so lookup has to be done always
            (this.hasValidEmail || this.internalUserMode) &&
            currentEmail !== localInput
          ) {
            // send a signal to the parent modal to find this email address
            this.$emit('resolve-email-address', { email: localInput, index: this.index });
          }
          break;
        default:
          break;
      }
    },
    // special validation for the email address
    validateEmailAddress() {
      // check the basics
      const err = this.getError(this.$v.userData.email);
      if (err) return err;
      // is this an internal user?
      if (this.userData && this.userData.email) {
        const internalEmail = isInternalEmail(this.userData.email);
        const internalUser = this.internalUsers.find(
          u => (u.email || '').toLowerCase() === (this.userData.email || '').toLowerCase(),
        );
        if (this.internalUserMode && !internalEmail) {
          this.emailMismatch = true;
          return this.$t('userManagement.addUsers.error.enterInternalEmail');
        }
        if (!this.internalUserMode && (internalUser || internalEmail)) {
          this.emailMismatch = true;
          return this.$t('userManagement.addUsers.error.enterExternalEmail');
        }
        // check if email is unique compared to other entries
        if (this.$v.userData.email.$dirty) {
          const dup = this.currentEmailEntries.findIndex((e, i) => {
            return e && e.toLowerCase() === this.userData.email.toLowerCase() && i !== this.index;
          });
          if (dup > -1) {
            this.emailMismatch = true;
            return this.$t('userManagement.addUsers.error.emailAlreadyEntered');
          }
        }
      }

      // no errors
      this.emailMismatch = false;
      return '';
    },
    validateFormInputs() {
      this.$v.$touch();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.c-modal__add-user-modal__form {
  .c-modal__add-user-modal__form__control {
    flex-basis: 32%;
    margin-bottom: 1.5rem;
    &.left-thick-border {
      border-left: 4px solid #015585;
    }
    &.email ::v-deep input {
      text-transform: lowercase;
    }
    &.email ::v-deep .c-input__with-search__search li {
      text-transform: lowercase;
    }
  }
  .c-modal__add-user-modal__form__internal-msg {
    margin-top: 0;
    font-size: 0.875rem;
    font-weight: 400;
    p {
      margin-bottom: 1rem;
    }
  }
}
</style>
