<template>
  <div :class="isLocal ? 'spinner-local' : 'spinner-global'">
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Spinner',

  props: {
    isLocal: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss">
@use '@/assets/css/spinner.scss';
</style>
