import { Duration } from 'luxon';
import Schedule from '@/models/Schedule';
import Shift from '@/models/Shift';

/**
 * returns true if the given shift startTime or endTime is within schedule range
 */
const isTimeWithinSchedule = (schedule, shift, shiftStartTime, shiftEndTime) => {
  // verify that the arguments are valid
  if (
    !(schedule instanceof Schedule) ||
    !(shift instanceof Shift) ||
    ((!shiftStartTime || !Duration.isDuration(shiftStartTime)) &&
      (!shiftEndTime || !Duration.isDuration(shiftEndTime)))
  ) {
    return false;
  }
  let shiftTime;
  const scheduleStartTime = Duration.fromObject(Schedule.parseTime(schedule.startTimeOfDay));
  const scheduleEndTime = schedule.getEndTimeObjBasedOnShifts();
  if (shiftStartTime) {
    shiftTime = schedule.getShiftStartTimeBasedOnSchedule(shiftStartTime);
  }

  if (shiftEndTime) {
    const start = schedule.getShiftStartTimeBasedOnSchedule(shift.startTime);
    shiftTime = Shift.getShiftEndTimeBasedOnStart(start, shiftEndTime);
  }
  if (
    shiftTime &&
    (shiftTime.as('milliseconds') < scheduleStartTime.as('milliseconds') ||
      shiftTime.as('milliseconds') > scheduleEndTime.as('milliseconds'))
  ) {
    return false;
  }
  return true;
};

export default isTimeWithinSchedule;
