<template>
  <div
    class="bg-white flex flex-row mb-7 p-1 rounded shift-input-row"
    :class="{ 'border-2 border-status-negative-dark': hasOverlap() }"
  >
    <div class="mr-4 rounded-full w-1" :class="`bg-shift-${colorIndex}`" />

    <div class="flex-grow py-2">
      <div class="flex items-start mb-4">
        <InputText
          class="flex-grow"
          :placeholder="$t('formShift.shiftName')"
          :error="errorShiftName"
          :value="shift.name"
          @input="onInput('name', $event)"
        />

        <a
          v-if="hasFeatureControl(FEATURES.SCHEDULE, CONTROL_LEVELS.EDITOR)"
          class="cursor-pointer ml-8 mt-2 text-gray-6 text-sm"
          @click="onDelete"
        >
          <BaseIcon :name="'trash'" />
        </a>
      </div>

      <div class="c-time-inputs-container flex justify-between">
        <InputTime
          class="text-primary-dark"
          :container-id="schedulesContainerId"
          :label="$t('formShift.from')"
          :value="shift.start_time"
          :error="errorStartTime"
          :placeholder="$t('formShift.startTime')"
          @input="onInput('start_time', $event)"
        />
        <InputTime
          class="c-end-time text-primary-dark"
          :container-id="schedulesContainerId"
          :label="$t('formShift.to')"
          :value="shift.end_time"
          :error="errorEndTime"
          :placeholder="$t('formShift.endTime')"
          @input="onInput('end_time', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BaseIcon } from '@seegrid/components';
import get from 'lodash-es/get';
import { maxLength, required } from 'vuelidate/lib/validators';
import InputText from '@/components/inputs/InputText.vue';
import InputTime from '@/components/inputs/InputTime.vue';
import constants from '@/config/constants';
import authorization from '@/mixins/authorization';
import isValidTime from '@/util/isValidTime';
import {
  shiftEndWithinScheduleTime,
  shiftMustNotOverlap,
  shiftNameMustBeUnique,
  shiftStartWithinScheduleTime,
} from '@/util/vuelidateHelper';

const shiftsCustomValidations = [
  { errorCode: 'shiftNameMustBeUnique', key: 'name.shiftNameMustBeUnique' },
  { errorCode: 'shiftStartOutsideCustomerDay', key: 'start_time.shiftStartWithinScheduleTime' },
  { errorCode: 'shiftEndOutsideCustomerDay', key: 'end_time.shiftEndWithinScheduleTime' },
  { errorCode: 'noOverlappingShifts', key: 'shiftMustNotOverlap' },
];

export default {
  name: 'ShiftInputRow',

  components: {
    BaseIcon,
    InputText,
    InputTime,
  },

  mixins: [authorization],

  props: {
    scheduleIndex: {
      type: Number,
      required: true,
    },
    shift: {
      type: Object,
      required: true,
    },
    shiftIndex: {
      type: Number,
      required: true,
    },
    validate: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      localErrorCode: '',
      schedulesContainerId: constants.SCHEDULE_LIST_ELEMENT_ID,
      submitted: false,
    };
  },

  validations: {
    shift: {
      end_time: {
        isValidTime,
        required,
        shiftEndWithinScheduleTime,
      },
      name: {
        maxLength: maxLength(30),
        required,
        shiftNameMustBeUnique,
      },
      shiftMustNotOverlap,
      start_time: {
        isValidTime,
        required,
        shiftStartWithinScheduleTime,
      },
    },
  },

  computed: {
    // returns true if all schedules are valid or false otherwise
    areSchedulesValid() {
      return this.$store.getters['schedules/areSchedulesValid'];
    },
    colorIndex() {
      const uniqueShiftNames = this.$store.getters['schedules/getUniqueShiftNames'] || [];
      return (uniqueShiftNames.indexOf(this.shift.name) % 8) + 1 || 1;
    },
    // returns currently displayed error code
    currentErrorCode() {
      return this.$store.getters['message/getCode'];
    },
    // returns an error message if the end time field is invalid or null otherwise
    errorEndTime() {
      if (this.$v.shift.end_time.$dirty) {
        if (!this.$v.shift.end_time.required) {
          return this.$t('validation.requiredTime');
        }

        if (!this.$v.shift.end_time.isValidTime) {
          return this.$t('validation.isValidTime');
        }
      }
      this.setShiftTimeOutsideSchedule();
      if (!this.$v.shift.end_time.shiftEndWithinScheduleTime) {
        return this.$t('validation.shiftNotInCustomerDay');
      }
      return null;
    },

    // returns an error message if the shift name field is invalid or null otherwise
    errorShiftName() {
      if (this.$v.shift.name.$dirty && !this.$v.shift.name.required) {
        return this.$t('validation.requiredShiftName');
      }

      if (!this.$v.shift.name.maxLength) {
        return this.$t('validation.shiftNameTooLong');
      }

      if (!this.$v.shift.name.shiftNameMustBeUnique) {
        return this.$t('validation.shiftNameMustBeUnique');
      }
      return null;
    },

    // returns an error message if the start time field is invalid or null otherwise
    errorStartTime() {
      if (this.$v.shift.start_time.$dirty) {
        if (!this.$v.shift.start_time.required) {
          return this.$t('validation.requiredTime');
        }

        if (!this.$v.shift.start_time.isValidTime) {
          return this.$t('validation.isValidTime');
        }
      }
      this.setShiftTimeOutsideSchedule();
      if (!this.$v.shift.start_time.shiftStartWithinScheduleTime) {
        return this.$t('validation.shiftNotInCustomerDay');
      }
      return null;
    },

    // returns true if shift data is loading or false otherwise
    loadingShifts() {
      return this.$store.getters['schedules/getLoading'];
    },

    // returns the current schedules for use by validation
    schedule() {
      return this.$store.getters['schedules/getScheduleByIndex'](this.scheduleIndex);
    },

    // returns the current site id
    siteId() {
      return this.$store.getters['sites/getSelectedSiteId'];
    },
  },

  watch: {
    // run form validation when triggered by parent
    validate(newValue) {
      // ignore flag reset
      if (newValue) {
        this.submitted = true;
        this.$v.$touch();
      }
    },
  },
  updated() {
    this.updateValidationErrors();
  },

  methods: {
    clearErrorMessage(code) {
      if (this.currentErrorCode === `schedules.errors.${code}`) {
        this.$store.dispatch('message/hide');
      }
    },
    // display the error message for the given type
    displayErrorMessage(code) {
      this.$store.dispatch('message/display', {
        code: `schedules.errors.${code}`,
        text: this.$t(`schedules.errors.${code}`),
        type: 'negative',
      });
    },
    /**
     * returns true if the current state of all shift definitions create an
     * overlap or false otherwise
     */
    hasOverlap() {
      if (
        this.loadingShifts ||
        !this.$v.shift.end_time.required ||
        !this.$v.shift.start_time.required ||
        !this.$v.shift.name.required
      ) {
        return false;
      }
      const hasOverlap = !this.$v.shift.shiftMustNotOverlap;
      this.$store.dispatch('schedules/setShiftOverlap', {
        scheduleIndex: this.scheduleIndex,
        shiftIndex: this.shiftIndex,
        siteId: this.siteId,
        value: hasOverlap,
      });
      return hasOverlap;
    },

    // event handler for deleting a shift definition
    async onDelete() {
      this.$emit('delete', this.shiftIndex);

      // validate the current state of all shift definitions
      await this.$store.dispatch('schedules/validate');
    },

    // event handler for updating a field value
    async onInput(field, value) {
      this.$v.shift[field].$touch();
      /**
       * store the new value
       *
       * if the form has been submitted, the validate flag will trigger the
       * validation of the current state of all shift definitions
       */
      await this.$store.dispatch('schedules/setShiftValue', {
        field,
        scheduleIndex: this.scheduleIndex,
        shiftIndex: this.shiftIndex,
        siteId: this.siteId,
        validate: this.submitted,
        value,
      });
    },
    // updates the timeOutsideSchedule flag for the Shift in Store
    setShiftTimeOutsideSchedule() {
      const value =
        !this.$v.shift.start_time.shiftStartWithinScheduleTime ||
        !this.$v.shift.end_time.shiftEndWithinScheduleTime;
      if (this.shift.timeOutsideSchedule !== value) {
        this.$store.dispatch('schedules/setShiftTimeOutsideSchedule', {
          scheduleIndex: this.scheduleIndex,
          shiftIndex: this.shiftIndex,
          siteId: this.siteId,
          value,
        });
      }
    },
    // check validations that triggers global error
    updateValidationErrors() {
      const validationErrorCodes = shiftsCustomValidations.map(
        v => `schedules.errors.${v.errorCode}`,
      );
      let foundErrors = false;
      for (let i = 0; i < shiftsCustomValidations.length; i += 1) {
        const validation = shiftsCustomValidations[i];
        // Get Validation status, true => Valid / default, false => validation failed
        const isValid = get(this.$v.shift, validation.key, true);
        if (!isValid) {
          if (this.currentErrorCode !== validation.errorCode) {
            this.localErrorCode = validation.errorCode;
            this.displayErrorMessage(validation.errorCode);
          }
          foundErrors = true;
          break;
        }
      }
      // Didnt find any shift errors above. Checking error displayed if any from shifts and clear
      if (!foundErrors && this.localErrorCode) {
        if (this.currentErrorCode === `schedules.errors.${this.localErrorCode}`) {
          const clearErrorCode = get(
            shiftsCustomValidations,
            `[${validationErrorCodes.indexOf(this.currentErrorCode)}].errorCode`,
          );
          this.clearErrorMessage(clearErrorCode);
        }
        this.localErrorCode = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div.c-time-inputs-container ::v-deep {
  div.c-end-time > div.flex {
    justify-content: flex-end;
  }
  div.input-time > p.input-error-text {
    margin: 0;
    max-width: 8rem;
    text-align: right;
    padding-left: 1.1rem;
    line-height: 0.875rem;
  }
}
</style>
