<template>
  <div class="spr-panel-contents">
    <!-- no recipients panel -->
    <aside v-if="!hasRecipients" class="flex flex-col spr-no-recipients">
      <div class="mx-auto">
        <span class="block font-normal mb-4 mx-auto text-gray-6 text-sm">{{
          $t('sprConfig.noSprRecipients')
        }}</span>
        <BaseButton
          :size="'small'"
          :button-type="'tertiary'"
          class="c-btn--add mx-auto text-primary-dark text-sm"
          icon="plus"
          @click.stop="onAddRecipients"
        >
          {{ $t('sprConfig.addRecipients') }}
        </BaseButton>
      </div>
    </aside>
    <!-- recipients table and controls -->
    <div v-show="hasRecipients" class="bg-gray-2 border-gray-200 spr-table-wrapper">
      <!-- report table controls -->
      <div
        class="border-gray-200 bottom-border flex items-center spr-table-controls"
        :class="{ 'justify-end': !hasSelections }"
      >
        <span v-show="hasSelections" class="flex flex-grow spr-selected">{{
          usersSelectedLabel
        }}</span>
        <!-- <GhostButton v-show="!hasSelections" :icon-name="'fg-icon-filter'" :icon-sprite="iconSprite" @click.stop="onFilterSPR">{{
          $t('sprConfig.filterReport')
        }}</GhostButton> -->
        <BaseSingleSelect
          v-show="canEdit && hasSelections && !disableStatusChange"
          class="flex ml-2 spr-change-status"
          :disabled="disableStatusChange"
          :placeholder="$t('sprConfig.changeStatus')"
          open-direction="bottom"
          :options="statusOptions"
          @select-updated="onChange_StatusOption($event)"
        />
        <GhostButton
          v-show="canEdit && hasSelections && !disableDelete"
          class="delete-users flex"
          :disabled="disableDelete"
          :icon-name="'fg-icon-trash'"
          :icon-sprite="iconSprite"
          :viewBox="'0 0 24 24'"
          @click.stop="onDelete"
          >{{ $t('sprConfig.deleteRecipients') }}</GhostButton
        >
        <BaseButton
          v-show="canEdit && !hasSelections"
          :size="'small'"
          :button-type="'tertiary'"
          class="c-btn--add ml-2 text-primary-dark text-sm"
          icon="plus"
          @click.stop="onAddRecipients"
        >
          {{ $t('sprConfig.addRecipients') }}
        </BaseButton>
        <HelpTooltip
          v-show="hasSelections && selectionTipShown"
          :content="selectionTipMessage"
          class="flex ml-2 selection-usage-tip"
          direction="bottom-left"
          width="300px"
        />
      </div>
      <BaseContextMenu ref="contextMenu" class="spr-row-context-menu" :offset-x="20">
        <ul role="menu">
          <li v-show="canEdit && !disableDelete" role="none">
            <a role="menuitem" @click.stop="onDelete">{{ $t('sprConfig.deleteRecipients') }}</a>
          </li>
          <li v-show="canEdit && !disableStatusChange" role="none">
            <a role="menuitem" @click.stop="onStatusToggle">{{ $t('sprConfig.changeStatus') }}</a>
          </li>
        </ul>
      </BaseContextMenu>
      <!-- report table -->
      <DataGridV2
        class="spr-recipients-table"
        :context="gridContext"
        :default-sort-model="defaultSortModel"
        :enable-row-multiselect="canEdit"
        :height="height"
        :table-columns="columnDefs"
        :table-rows="formattedRecipients"
        @grid-ready="onGridReady"
        @selection-changed="onChange_RowSelections"
      />
    </div>
    <!-- modals -->
    <SprUsersModal
      ref="usersModal"
      :report-name="report.name"
      :users="users"
      @users-added="onAddRecipients_Save"
      @navigate-to-am="$emit('navigate', { name: 'sites' })"
    />
    <ConfirmationModal
      ref="confirmStatusChange"
      :danger-confirm="false"
      :confirm-title="statusConfirmTitle"
      :confirm-message="statusConfirmMessage"
      :cancel-button="`${$t('sprConfig.confirmStatusSprRecipients.cancel')}`"
      :confirm-button="`${$t('sprConfig.confirmStatusSprRecipients.confirm')}`"
      @on-confirm-action="onStatusChange_Confirmed"
    />
    <ConfirmationModal
      ref="confirmDeleteRecipients"
      :confirm-title="deleteConfirmTitle"
      :confirm-message="deleteConfirmMessage"
      :cancel-button="`${$t('sprConfig.confirmDeleteSprRecipients.cancel')}`"
      :confirm-button="`${$t('sprConfig.confirmDeleteSprRecipients.confirm')}`"
      @on-confirm-action="onDelete_Confirmed"
    />
  </div>
</template>

<script>
import { BaseButton } from '@seegrid/components';
import cloneDeep from 'lodash-es/cloneDeep';
import differenceWith from 'lodash-es/differenceWith';
import { DateTime } from 'luxon';
import SprReportRecipientsTableActions from './SprReportRecipientsTableActions.vue';
import IconSprite from '@/assets/images/icon-sprite.svg';
import BaseContextMenu from '@/components/BaseContextMenu.vue';
import BaseSingleSelect from '@/components/BaseSingleSelect.vue';
import DataGridV2 from '@/components/controls/DataGridV2.vue';
import GhostButton from '@/components/controls/GhostButton.vue';
import HelpTooltip from '@/components/controls/HelpTooltip.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import SprUsersModal from '@/components/sprconfig/SprUsersModal.vue';
import constants from '@/config/constants';
import i18n from '@/i18n';
import SPR from '@/models/SPR';

export default {
  name: 'SprReportRecipientsTable',

  components: {
    BaseButton,
    BaseContextMenu,
    BaseSingleSelect,
    ConfirmationModal,
    DataGridV2,
    GhostButton,
    HelpTooltip,
    SprUsersModal,
  },

  props: {
    canEdit: {
      default: false,
      type: Boolean,
    },
    enableRowAction: {
      default: false,
      type: Boolean,
    },
    height: {
      default: 'initial',
      type: String,
    },
    recipients: {
      required: true,
      type: Array,
    },
    report: {
      required: true,
      type: SPR,
    },
  },

  data() {
    return {
      changeStatus: '',
      columnDefs: null,
      defaultSortModel: [{ colId: 'name', sort: 'asc' }],
      gridApi: null,
      rowActionColumn: {
        cellRendererFramework: SprReportRecipientsTableActions,
        cellStyle: { display: 'flex', justifyContent: 'flex-end' },
        headerName: '',
        sortable: false,
        width: 60,
      },
      selectedRows: [],
    };
  },

  computed: {
    // returns the date format used in this table
    dateFormat() {
      return this.$t('sprConfig.reportsTableFormats.formattedModifiedDate');
    },

    // returns the message displayed in the delete confirmation modal
    deleteConfirmMessage() {
      return this.usersSelectedLabel;
    },
    deleteConfirmTitle() {
      return this.selectedRows.length === 1
        ? this.$t('sprConfig.confirmDeleteSprRecipients.messageOne', {
            name: this.selectedRows[0].formattedName,
          })
        : this.$t('sprConfig.confirmDeleteSprRecipients.messageMany');
    },

    // indicates whether the delete should be disabled
    disableDelete() {
      // need a selection
      if (this.selectedRows.length < 1) return true;
      return false;
    },

    // indicates whether the status change should be disabled
    disableStatusChange() {
      return this.reportIsPaused;
    },

    // adds formatted columns to the rows
    formattedRecipients() {
      if (!this.recipients || !this.recipients.length) {
        return [];
      }
      const formatted = [];
      for (let i = 0; i < this.recipients.length; i += 1) {
        const row = this.recipients[i];
        formatted.push({
          ...row,
          formattedModifiedDate: this.formatDate(row.modified_date_time, this.dateFormat),
          formattedName: this.$t('sprConfig.reportsTableFormats.formattedName', {
            family_name: row.family_name,
            given_name: row.given_name,
          }),
          formattedStatus: this.$t(
            `sprConfig.reportsTableFormats.formattedStatus.${this.effectiveRowStatus(row)}`,
          ),
        });
      }
      return formatted;
    },

    // provides details used by the grid
    gridContext() {
      const self = this;
      return {
        componentParent: self,
        contextMenu: (row, show, x, y) => {
          self.clearSelections();
          row.node.setSelected(true);
          if (show) self.$refs.contextMenu.openMenu(x, y);
          else self.$refs.contextMenu.closeMenu();
        },
      };
    },

    // indicates whether this report has any recipients
    hasRecipients() {
      return this.recipients.length > 0;
    },

    // indicates that there are one or more selections
    hasSelections() {
      return this.selectedRows.length > 0;
    },

    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },

    // indicates whether the report is paused
    reportIsPaused() {
      return this.report.deliveryStatus === constants.SPR_DELIVERY_STATUS.PAUSED;
    },

    // handles showing a tool tip to explain why some controls may be missing
    selectionTipMessage() {
      if (this.disableStatusChange) {
        return this.$t('sprConfig.toolTips.cantChangeStatus');
      }
      return '';
    },
    selectionTipShown() {
      return this.hasSelections && this.disableStatusChange;
    },

    sprSearchString() {
      return this.$store.getters['sprs/getSearchString'];
    },

    // return the message displayed in the change status confirm modal
    statusConfirmMessage() {
      switch (this.changeStatus) {
        case constants.SPR_DELIVERY_STATUS.ACTIVE:
          return this.selectedRows.length === 1
            ? this.$t('sprConfig.confirmStatusSprRecipients.subActiveMessageOne', {
                name: this.selectedRows[0].formattedName,
              })
            : this.$t('sprConfig.confirmStatusSprRecipients.subActiveMessageMany', {
                count: this.selectedRows.length,
              });
        case constants.SPR_DELIVERY_STATUS.PAUSED:
          return this.selectedRows.length === 1
            ? this.$t('sprConfig.confirmStatusSprRecipients.subPauseMessageOne', {
                name: this.selectedRows[0].formattedName,
              })
            : this.$t('sprConfig.confirmStatusSprRecipients.subPauseMessageMany', {
                count: this.selectedRows.length,
              });
        default:
          if (this.changeStatus) {
            console.warn('unknown status', this.changeStatus);
          }
          return '';
      }
    },
    statusConfirmTitle() {
      switch (this.changeStatus) {
        case constants.SPR_DELIVERY_STATUS.ACTIVE:
          return this.$t('sprConfig.confirmStatusSprRecipients.messageActive');
        case constants.SPR_DELIVERY_STATUS.PAUSED:
          return this.$t('sprConfig.confirmStatusSprRecipients.messagePause');
        default:
          if (this.changeStatus) {
            console.warn('unknown status', this.changeStatus);
          }
          return '';
      }
    },

    // the options available to the user for changing status of the selected rows
    statusOptions() {
      // can't change status if the report is paused
      const options = [];
      if (this.reportIsPaused) {
        return options;
      }

      // exclude options if the selected items are in the same state
      if (
        !this.selectedRows.every(r => r.delivery_status === constants.SPR_DELIVERY_STATUS.ACTIVE)
      ) {
        options.push({
          text: this.$t(
            `sprConfig.reportsTableFormats.formattedStatus.${constants.SPR_DELIVERY_STATUS.ACTIVE}`,
          ),
          value: constants.SPR_DELIVERY_STATUS.ACTIVE,
        });
      }
      if (
        !this.selectedRows.every(r => r.delivery_status === constants.SPR_DELIVERY_STATUS.PAUSED)
      ) {
        options.push({
          text: this.$t(
            `sprConfig.reportsTableFormats.formattedStatus.${constants.SPR_DELIVERY_STATUS.PAUSED}`,
          ),
          value: constants.SPR_DELIVERY_STATUS.PAUSED,
        });
      }
      return options;
    },

    // returns the list of users (not already in this report)
    users() {
      let result = [];
      const allUsers = this.$store.getters['users/getOrgMembers'].filter(
        u => u.verified && !u.blocked,
      );
      result = differenceWith(allUsers, this.recipients, (a, b) => a.id === b.id);
      return result;
    },

    // format the user selection labal
    usersSelectedLabel() {
      const count = this.selectedRows.length;
      return count === 1
        ? this.$t('sprConfig.reportUsersSelected.single', { count })
        : this.$t('sprConfig.reportUsersSelected.multiple', { count });
    },
  },

  watch: {
    // called when the list of recipients changes
    recipients() {
      this.$nextTick(() => {
        this.onChange_RowSelections();
      });
    },
    sprSearchString(val) {
      this.updateNameEmailFilters(val);
    },
  },

  beforeMount() {
    this.columnDefs = [
      {
        cellStyle: { textAlign: 'left' },
        checkboxSelection: this.canEdit,
        field: 'formattedName',
        filter: 'agTextColumnFilter',
        filterParams: {
          textCustomComparator: (filter, value, filterText) => {
            const val = value.toLowerCase();
            const recipient = this.formattedRecipients.find(user => {
              return user.formattedName.toLowerCase() === val;
            });
            return (
              recipient.email.indexOf(filterText) >= 0 ||
              recipient.formattedName.toLowerCase().indexOf(filterText) >= 0
            );
          },
        },
        headerCheckboxSelection: this.canEdit,
        headerName: i18n.t('sprConfig.reportsTableColumns.name'),
      },
      {
        cellStyle: { textAlign: 'left' },
        field: 'email',
        headerName: i18n.t('sprConfig.reportsTableColumns.email'),
      },
      {
        cellClass: params => {
          if (this.effectiveRowStatus(params.data) === constants.SPR_DELIVERY_STATUS.ACTIVE)
            return 'active-status';
          if (this.effectiveRowStatus(params.data) === constants.SPR_DELIVERY_STATUS.PAUSED)
            return 'paused-status';
          return 'unknown-status';
        },
        cellRenderer: params => {
          // wraps value in a span by default
          return params.value;
        },
        cellStyle: { textAlign: 'left' },
        field: 'formattedStatus',
        headerName: i18n.t('sprConfig.reportsTableColumns.delivery_status'),
      },
      {
        cellStyle: { textAlign: 'left' },
        comparator: (a, b) => {
          // (a, b, rowA, rowB, inverted)
          const dtA = this.parseDate(a, this.dateFormat);
          const dtB = this.parseDate(b, this.dateFormat);
          return dtA - dtB;
        },
        field: 'formattedModifiedDate',
        headerName: i18n.t('sprConfig.reportsTableColumns.modified_date_time'),
      },
    ];
    if (this.enableRowAction && this.canEdit) {
      this.columnDefs.push(this.rowActionColumn);
    }
  },

  updated() {
    // refresh rows based on any existing filters
    this.updateNameEmailFilters(this.sprSearchString);
  },

  methods: {
    // clear the selections
    clearSelections() {
      if (this.gridApi) {
        this.gridApi.deselectAll();
        this.gridApi.deselectAllFiltered();
      }
    },

    // returns the effective status of a recipient based on the
    // report status and row status
    effectiveRowStatus(row) {
      return this.reportIsPaused ? constants.SPR_DELIVERY_STATUS.PAUSED : row.delivery_status;
    },

    // formats a date
    formatDate(sz, format) {
      if (!sz) {
        return sz;
      }
      try {
        const time = DateTime.fromISO(sz);
        return time.toFormat(format);
      } catch {
        return sz;
      }
    },

    // convert a user object into a recipient
    mapUserToRecipient(user) {
      if (!user) return user;
      return {
        delivery_status: constants.SPR_DELIVERY_STATUS.ACTIVE,
        email: user.email,
        family_name: user.lastName,
        given_name: user.firstName,
        id: user.id,
        modified_date_time: new Date().toISOString(),
      };
    },

    // handle adding users
    onAddRecipients() {
      this.$refs.usersModal.open();
    },
    async onAddRecipients_Save(users) {
      const newUsers = users.map(u => this.mapUserToRecipient(u));
      const spr = cloneDeep(this.report);
      spr.recipients = spr.recipients.concat(newUsers);
      await this.$store.dispatch('sprs/saveSPRAsync', { spr }).catch(error => {
        console.error(error);
      });
      this.onChange_RowSelections();
    },

    // called each time the row check box selections change
    onChange_RowSelections() {
      this.selectedRows = !this.gridApi ? [] : this.gridApi.getSelectedRows() || [];
    },

    // called when the status drop down (in table controls) changes
    onChange_StatusOption(status) {
      this.changeStatus = status;
      this.onStatusChange();
    },

    // handle deleting users
    onDelete() {
      // can't delete the last recipient
      this.$refs.confirmDeleteRecipients.open();
    },
    async onDelete_Confirmed() {
      if (!this.selectedRows.length) return;
      const spr = cloneDeep(this.report);
      spr.recipients = spr.recipients.filter(r => !this.selectedRows.find(u => u.id === r.id));
      await this.$store.dispatch('sprs/saveSPRAsync', { spr }).catch(error => {
        console.error(error);
      });
      this.onChange_RowSelections();
    },

    // handle filtering the rows
    onFilterSPR(e) {
      console.log('onFilterSPR', this, e);
    },

    // called after the table loads
    onGridReady(api) {
      this.gridApi = api;
      // refresh rows based on any existing filters
      this.updateNameEmailFilters(this.sprSearchString);
    },

    // handle user status change
    onStatusChange() {
      if (this.reportIsPaused || !this.changeStatus || this.selectedRows.length < 0) return;
      this.$refs.confirmStatusChange.open();
    },
    async onStatusChange_Confirmed() {
      if (this.reportIsPaused || !this.changeStatus || this.selectedRows.length < 0) return;
      const spr = cloneDeep(this.report);
      this.selectedRows.forEach(u => {
        const change = spr.recipients.find(r => r.id === u.id);
        if (change) {
          change.delivery_status = this.changeStatus;
        }
      });
      await this.$store.dispatch('sprs/saveSPRAsync', { spr }).catch(error => {
        console.error(error);
      });
      this.onChange_RowSelections();
    },
    onStatusToggle() {
      if (this.reportIsPaused) return;
      if (this.selectedRows.length !== 1) return;
      if (this.selectedRows[0].delivery_status === constants.SPR_DELIVERY_STATUS.ACTIVE) {
        this.changeStatus = constants.SPR_DELIVERY_STATUS.PAUSED;
        this.onStatusChange();
      }
      if (this.selectedRows[0].delivery_status === constants.SPR_DELIVERY_STATUS.PAUSED) {
        this.changeStatus = constants.SPR_DELIVERY_STATUS.ACTIVE;
        this.onStatusChange();
      }
    },
    // parse a date by format (used in sorting)
    parseDate(sz, format) {
      if (!sz) {
        return sz;
      }
      try {
        return DateTime.fromFormat(sz, format);
      } catch {
        return sz;
      }
    },

    // callback for row level actions
    performRowAction(e, row, action) {
      if (action === 'deleteRow') {
        this.clearSelections();
        row.node.setSelected(true);
        this.onDelete(e);
      }
    },
    // programmatically filter recipients by name and email
    updateNameEmailFilters(value) {
      if (this.gridApi) {
        const setFilter = {
          filter: value,
          filterType: 'text',
          type: 'contains',
        };
        const recipientFilter = this.gridApi.getFilterInstance('formattedName');
        if (recipientFilter) {
          recipientFilter.setModel(setFilter);
          // refresh rows based on the filter
          this.gridApi.onFilterChanged();
        }
      }
    },
  },
};
</script>

<style lang="scss">
@use "sass:math";
@import '@/assets/css/variables.scss';
@import '@/assets/css/calc.scss';
.spr-panel-contents {
  .spr-no-recipients {
    border-top: 2px solid $c-gray-2;
    min-height: 190px;
    padding-top: 58px;
  }
  .spr-table-controls {
    padding-left: $spr-control-indent;
    padding-right: 2rem;
    background: #f9fafc;
    min-height: rem(56px);
    font-size: 0.875rem;
    button {
      margin-left: 2rem;
      color: $c-ctrl-color-dark;
      line-height: 1.15;
      &.c-btn--disabled {
        color: $c-gray-6;
      }
    }
    .c-btn--add {
      --tw-bg-opacity: 1;
      background-color: rgba(244, 246, 248, var(--tw-bg-opacity));
    }
    .spr-change-status {
      width: rem(146px);
    }
    .selection-usage-tip {
      color: $c-ctrl-color-dark;
    }
  }
  .spr-recipients-table {
    .ag-root-wrapper-body.ag-layout-normal {
      height: initial;
    }
    .ag-row,
    .ag-header-row {
      color: $c-primary-dark;
    }
    .ag-cell {
      .status-pill {
        padding: 0.5rem;
        border-radius: 0.25rem;
      }
      &.active-status {
        span {
          @extend .status-pill;
          background: rgba(58, 207, 109, 0.2);
        }
        color: #307e4b;
      }
      &.paused-status {
        span {
          @extend .status-pill;
          background: rgba(255, 188, 59, 0.3);
        }
        color: #b26306;
      }
    }
  }
}
</style>
