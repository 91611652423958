// Auth routes
const ACCESS_TOKEN = '/auth/access-token/';

const ROLES = '/auth/roles';
const ROLES_ROLE = `${ROLES}/:role_id`;

const USERS = '/auth/users';
const USERS_BULK = '/auth/bulk-users';
const USERS_USER = `${USERS}/:user_id`;
const USERS_USER_GLOBAL_ROLES = `${USERS_USER}/global-roles`;
const USERS_USER_ORGANIZATIONS = `${USERS_USER}/organizations`;
const USERS_USER_ORGANIZATIONS_GROUPS = `${USERS_USER_ORGANIZATIONS}/groups`;
const USERS_USER_ORGANIZATIONS_ROLES = `${USERS_USER_ORGANIZATIONS}/roles`;
const USERS_USER_SEND_RESET_PASSWORD_EMAIL = `${USERS_USER}/actions/send-reset-password-email`;
const USERS_USER_SEND_VERIFICATION_EMAIL = `${USERS_USER}/actions/send-verification-email`;
const USERS_USER_SEND_WELCOME_EMAIL = `${USERS_USER}/actions/send-invitation-email`;

const ORGS = '/auth/organizations';
const ORGS_MEMBERS = `${ORGS}/:company_id/members`;
const ORGS_MEMBERS_MEMBER = `${ORGS_MEMBERS}/:user_id`;
const ORGS_MEMBERS_MEMBER_ROLES = `${ORGS_MEMBERS_MEMBER}/roles`;

const GROUP_SITES = `${ORGS}/:company_id/groups/sites`;
const GROUP_SITE_MEMBERS = `${GROUP_SITES}/:site_id/members`;

// General routes
const COMPANIES = '/general/companies';
const COMPANIES_COMPANY = `${COMPANIES}/:company_id`;

const COMPANIES_SCHEDULE = `${COMPANIES_COMPANY}/schedule`;
const COMPANIES_SCHEDULE_SITE = `${COMPANIES_SCHEDULE}/sites/:site_id`;
const COMPANIES_PENDING_SCHEDULE = `${COMPANIES_COMPANY}/pending-schedule`;
const COMPANIES_PENDING_SCHEDULE_SITE = `${COMPANIES_PENDING_SCHEDULE}/sites/:site_id`;
const COMPANIES_PUBLISH_PENDING_SCHEDULE = `${COMPANIES_COMPANY}/publish-pending-schedule`;

const COMPANIES_SITES = `${COMPANIES_COMPANY}/sites`;
const COMPANIES_SITES_SITE = `${COMPANIES_SITES}/:site_id`;
const COMPANIES_SITES_SITE_STATE = `${COMPANIES_SITES_SITE}/state`;

const COMPANIES_REPORT_SCHEDULES = `${COMPANIES_COMPANY}/report-schedules`;
const COMPANIES_REPORT_SCHEDULES_SITE = `${COMPANIES_REPORT_SCHEDULES}/sites/:site_id`;
const COMPANIES_REPORT_SCHEDULES_SITE_SCHEDULE = `${COMPANIES_REPORT_SCHEDULES_SITE}/:schedule_id`;

const ROUTE_NETWORK = '/general/route-network';
const ROUTE_NETWORK_IMAGES = `${ROUTE_NETWORK}/images`;
const ROUTE_NETWORK_SEGMENTS = `${ROUTE_NETWORK}/segments`;
const ROUTE_NETWORK_STATIONS = `${ROUTE_NETWORK}/stations`;

const SUPERVISOR_BACKUPS = '/general/supervisor/backups';
const SUPERVISOR_BACKUPS_DELETE = `${SUPERVISOR_BACKUPS}/:backup_id`;
const SUPERVISOR_BACKUPS_EXTRACTIONS = `${SUPERVISOR_BACKUPS}/:backup_id/extractions`;
const SUPERVISOR_BACKUPS_UPLOAD_TOKEN = `${SUPERVISOR_BACKUPS}/:backup_id/generate-upload-token`;

const SUPERVISOR_EXTRACTIONS = '/general/supervisor/extractions';

// SSO Routes
const LOGIN_METADATA = '/auth/sso/login-metadata?extra_meta=feature_control';

export default {
  auth: {
    ACCESS_TOKEN,
    GROUP_SITES,
    GROUP_SITE_MEMBERS,
    LOGIN_METADATA,
    ORGS,
    ORGS_MEMBERS,
    ORGS_MEMBERS_MEMBER,
    ORGS_MEMBERS_MEMBER_ROLES,
    ROLES,
    ROLES_ROLE,
    USERS,
    USERS_BULK,
    USERS_USER,
    USERS_USER_GLOBAL_ROLES,
    USERS_USER_ORGANIZATIONS,
    USERS_USER_ORGANIZATIONS_GROUPS,
    USERS_USER_ORGANIZATIONS_ROLES,
    USERS_USER_SEND_RESET_PASSWORD_EMAIL,
    USERS_USER_SEND_VERIFICATION_EMAIL,
    USERS_USER_SEND_WELCOME_EMAIL,
  },
  general: {
    COMPANIES,
    COMPANIES_COMPANY,
    COMPANIES_PENDING_SCHEDULE,
    COMPANIES_PENDING_SCHEDULE_SITE,
    COMPANIES_PUBLISH_PENDING_SCHEDULE,
    COMPANIES_REPORT_SCHEDULES,
    COMPANIES_REPORT_SCHEDULES_SITE,
    COMPANIES_REPORT_SCHEDULES_SITE_SCHEDULE,
    COMPANIES_SCHEDULE,
    COMPANIES_SCHEDULE_SITE,
    COMPANIES_SITES_SITE_STATE,
    ROUTE_NETWORK_IMAGES,
    ROUTE_NETWORK_SEGMENTS,
    ROUTE_NETWORK_STATIONS,
    SUPERVISOR_BACKUPS,
    SUPERVISOR_BACKUPS_DELETE,
    SUPERVISOR_BACKUPS_EXTRACTIONS,
    SUPERVISOR_BACKUPS_UPLOAD_TOKEN,
    SUPERVISOR_EXTRACTIONS,
  },
};
