<template>
  <section
    :id="report.id"
    class="bg-white rounded spr"
    :class="{ closed: isClosed, open: !isClosed }"
  >
    <!-- report header panel -->
    <header
      class="cursor-pointer flex items-center spr-panel"
      tabindex="0"
      @click.stop="onToggleReportGroup()"
    >
      <!-- report header -->
      <div class="flex flex-grow flex-wrap heading-wrapper items-center m-0">
        <div class="flex">
          <h4 class="font-medium heading text-primary-dark text-xl">
            {{ report.name }}
          </h4>
        </div>
        <div class="flex flex-grow">
          <span class="desciption text-sm">
            {{ scheduleDescription }}
          </span>
        </div>
      </div>
      <!-- report controls -->
      <div class="flex inline-flex items-center justify-end spr-controls">
        <div class="flex flex-grow inline-flex items-center justify-end">
          <GhostButton
            v-if="hasSprEditFeatureControl"
            class="edit-report"
            :icon-name="'fg-icon-edit'"
            :icon-sprite="iconSprite"
            @click.stop="onEditSPR"
          />
        </div>
        <button class="flex header-toggle">
          <BaseIcon
            v-if="isClosed"
            :name="'chevron-down'"
            class="inline-block text-primary-dark text-xl"
          />
          <BaseIcon
            v-else
            :icon-sprite="iconSprite"
            :name="'chevron-up'"
            class="inline-block text-primary-dark text-xl"
          />
        </button>
      </div>
    </header>
    <!-- report table view -->
    <SprReportRecipientsTable
      v-if="renderTable"
      :key="`usersList-${report.id}`"
      ref="usersList"
      class="spr-table-container"
      :can-edit="hasSprEditFeatureControl"
      :enable-row-action="true"
      :report="report"
      :recipients="report.recipients"
    />
  </section>
</template>

<script>
import { BaseIcon } from '@seegrid/components';
import { DateTime } from 'luxon';
import IconSprite from '@/assets/images/icon-sprite.svg';
import GhostButton from '@/components/controls/GhostButton.vue';
import SprReportRecipientsTable from '@/components/sprconfig/SprReportRecipientsTable.vue';
import constants from '@/config/constants';
import authorization from '@/mixins/authorization';
import SPR from '@/models/SPR';

export default {
  name: 'SprSite',

  components: {
    BaseIcon,
    GhostButton,
    SprReportRecipientsTable,
  },

  mixins: [authorization],

  props: {
    report: {
      required: true,
      type: SPR,
    },
    site: {
      type: Object,
      required: true,
    },
  },

  data: () => {
    return {
      isClosed: true,
      renderTable: false,
    };
  },

  computed: {
    hasSprEditFeatureControl() {
      return this.hasFeatureControl(this.FEATURES.SPR, this.CONTROL_LEVELS.EDITOR);
    },

    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },

    // returns true if site data is loading or false otherwise
    loadingSiteData() {
      // TODO: add loading logic
      return false;
    },

    // returns the day when the report is send (if weekly)
    reportDay() {
      return this.reportType === constants.SPR_DELIVERIES.WEEKLY ? this.report.daysOfWeek[0] : '';
    },

    // returns the time the report is sent HH:mm:ss
    reportTime() {
      return this.report.localTimeToDeliever;
    },

    // returns the report time formatted HH:mm
    reportTimeFormatted() {
      const time = this.reportTime;
      if (!time || time.length !== 8) {
        // expected HH:mm:ss
        return time;
      }
      return time.substring(0, 5);
    },

    // returns the report type
    reportType() {
      if (!this.report.getReportType) {
        // Assumes the object passed in is SPR class
        console.warn('cannot determine report type', this.report);
        return undefined;
      }
      return this.report.getReportType();
    },

    // returns the formatted schedule description
    scheduleDescription() {
      if (this.reportType === constants.SPR_DELIVERIES.WEEKLY) {
        if (this.report.deliveryStatus === constants.SPR_DELIVERY_STATUS.PAUSED) {
          return this.$t('sprConfig.reportDescriptions.weekly.paused');
        }
        return this.$t('sprConfig.reportDescriptions.weekly.active', {
          day: this.$t(`days.${this.reportDay}`),
          time: this.reportTimeFormatted,
          tz: this.siteTZ,
        });
      }
      if (this.reportType === constants.SPR_DELIVERIES.DAILY) {
        if (this.report.deliveryStatus === constants.SPR_DELIVERY_STATUS.PAUSED) {
          return this.$t('sprConfig.reportDescriptions.daily.paused');
        }
        return this.$t('sprConfig.reportDescriptions.daily.active', {
          time: this.reportTimeFormatted,
          tz: this.siteTZ,
        });
      }
      console.warn('unknown report type', this.reportType);
      return '';
    },

    // gets the timezone for this site
    siteTZ() {
      const timeZone = this.site && this.site.timezone ? this.site.timezone : '';
      const tz = timeZone ? DateTime.fromObject({ zone: timeZone }).toFormat('ZZZZ') : '';
      return tz || timeZone;
    },
  },

  methods: {
    // handle editing the report
    onEditSPR() {
      this.$emit('edit-report', this.report.id);
      if (this.$refs.usersList) {
        this.$refs.usersList.clearSelections();
      }
    },

    // event handler for toggling the group report panel
    onToggleReportGroup() {
      // prevent toggle while data is loading
      if (this.loadingSiteData) {
        return;
      }

      // toggle the panel
      this.showReport(this.$data.isClosed);
    },

    showReport(open = true) {
      // show (or hide) the panel
      this.$set(this.$data, 'isClosed', !open);

      // render the table (assumes originally closed)
      if (open) {
        this.$set(this.$data, 'renderTable', true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "sass:math";
@import '@/assets/css/calc.scss';
@import '@/assets/css/variables.scss';
$report-group-header-font: 20px;
$report-group-height: 62px;
.spr {
  color: $c-primary-dark;
  margin-top: 1.5rem;
  .spr-panel {
    line-height: 1.25rem;
    .heading-wrapper {
      min-width: 0;
      .heading {
        font-size: rem($report-group-header-font);
        margin-left: rem($spr-control-indent);
        padding: 1rem 10rem 0 0;
        min-width: rem(332px + $spr-control-indent);
        max-width: 35ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .desciption {
        line-height: rem(20px);
        padding: 0.5rem 10rem 0.5rem rem($spr-control-indent);
        min-height: rem(20px);
        min-width: 12.5rem;
      }
    }
    .header-toggle {
      min-height: rem($report-group-height);
      padding: 1.5rem 2rem 0rem 2rem;
    }
  }
  .spr-controls {
    button {
      margin-right: 0.25rem;
      line-height: 1.15;
    }
    .edit-report,
    .delete-report {
      display: none;
    }
  }
  &:focus,
  &:hover,
  &.open {
    .spr-controls {
      .edit-report,
      .delete-report {
        display: flex;
      }
    }
  }
  .spr-table-container {
    overflow: hidden;
    transition: 0.2s ease-in;
    border-top-width: 1px;
  }
  &.closed {
    .spr-table-container {
      max-height: 0;
      transition: 0.1s ease-out;
      border-top-width: 0;
      visibility: hidden;
    }
  }
}
@media (min-width: 1080px) {
  .spr {
    .spr-panel {
      line-height: math.div(num($report-group-height), num($report-group-header-font));
      .heading-wrapper {
        min-width: 0;
        .heading {
          padding: 0 0.5rem 0 0;
          min-width: rem(332px - $spr-control-indent);
        }
        .desciption {
          padding: 0 0 0 rem(40px);
          border-left-width: 1px;
        }
      }
    }
  }
}
</style>
