<template>
  <div>
    <SessionTimeoutModal />
    <Spinner v-if="switchingCompanies" />
    <router-view />
  </div>
</template>

<script>
import SessionTimeoutModal from './components/SessionTimeoutModal.vue';
import Spinner from '@/components/Spinner.vue';
import { loadLocaleMessagesAsync } from '@/i18n';
import { setDocumentDirectionPerLocale, setDocumentTitle, setDocumentLang } from '@/util/i18nUtil';

export default {
  name: 'App',
  components: {
    SessionTimeoutModal,
    Spinner,
  },
  computed: {
    switchingCompanies() {
      // While Companies are loaded or switched we need to block the application to prevent interactions
      return (
        this.$store.getters['companies/getLoading'] ||
        this.$store.getters['companies/getSwitchingCompanies']
      );
    },
  },
  methods: {
    loadLocaleMessages(locale) {
      loadLocaleMessagesAsync(locale).then(() => {
        setDocumentLang(locale);
        setDocumentDirectionPerLocale(locale);
        setDocumentTitle(`${this.$t('app.seegrid')} | ${this.$t('app.name')}`);
      });
    },
  },
};
</script>

<style src="./assets/css/tailwind.css" />
