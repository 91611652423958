import isString from 'lodash-es/isString';
import constants from '@/config/constants';

/**
 * Indicates whether the email address is considered internal (seegrid)
 * @param {*} email - the email address to test
 * @returns
 */
const isInternalEmail = email => {
  // basic checks
  if (!email) return false;
  if (!isString(email)) return false;
  if (!email.includes('@')) return false;

  // case insensitive checks
  const checkEmail = email.toLowerCase();
  const seegridDomain = constants.SEEGRID_DOMAIN.toLowerCase();
  if (checkEmail.endsWith(`@${seegridDomain}`)) return true; // @seegrid.com
  if (checkEmail.endsWith(`.${seegridDomain}`)) return true; // @ANYTHING.seegrid.com
  return false;
};

export default isInternalEmail;
