<template>
  <SiteSettings
    :button-show="buttonShow"
    :button-text="buttonText"
    :status-date="statusDate"
    :status-text="statusText"
    :status-text-style-type="statusTextStyle"
    :title="$t(`sites.sections.schedules.title`)"
    @button-click="onClick()"
  />
</template>

<script>
import { DateTime } from 'luxon';
import SiteSettings from '@/components/SiteSettings.vue';
import constants from '@/config/constants';
import authorization from '@/mixins/authorization';

export default {
  name: 'SiteSettingsSchedules',

  components: {
    SiteSettings,
  },

  mixins: [authorization],

  props: {
    siteId: {
      required: true,
      type: String,
    },
  },

  data: () => {
    return {
      buttonShow: false,
      buttonText: '',
      statusDate: '',
      statusText: '',
    };
  },

  computed: {
    // returns true if the site has schedules or false otherwise
    hasLatest() {
      // TODO figure out if this is an actual issue or a false positive
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.$store.getters['schedules/hasLatest'](this.siteId);
    },

    // returns true if the site has pending schedules or false otherwise
    hasPending() {
      // TODO figure out if this is an actual issue or a false positive
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.$store.getters['schedules/hasPending'](this.siteId);
    },

    // returns the appropriate status text style type
    statusTextStyle() {
      let status = '';
      if (this.hasPending) {
        status = 'warning';
      } else if (!this.hasLatest && !this.hasPending) {
        status = 'negative';
      }
      return status;
    },
  },

  mounted() {
    this.setStatusData();
  },

  methods: {
    // event handler for the buttonClick event
    async onClick() {
      // set the site
      await this.$store.dispatch('sites/setSelectedSite', this.siteId);

      // redirect to the shift definitions page
      this.$router.push({ name: 'schedules' });
    },

    // set the status data based on the current state of the schedules
    setStatusData() {
      // reset status data
      this.statusDate = '';

      // determine if there are pending or published schedules
      let batchId;
      if (this.hasPending) {
        batchId = constants.BATCH_PENDING;
      } else if (this.hasLatest) {
        batchId = constants.BATCH_LATEST;
      }

      // update the status data appropriately
      switch (batchId) {
        case constants.BATCH_LATEST:
        case constants.BATCH_PENDING:
          {
            this.buttonText = this.$t('view');

            const schedule = this.$store.getters['schedules/getSchedulesBatch'](
              batchId,
              this.siteId,
            )[0];
            const timestamp = schedule ? schedule.getLastUpdatedTime() : null;
            try {
              this.statusDate = timestamp ? DateTime.fromISO(timestamp).toFormat('D T') : '';
            } catch (err) {
              console.error('error processing last updated of schedule in setStatusData: ', err);
            }

            this.statusText = this.$t(`sites.sections.schedules.${batchId}`);

            this.buttonShow = this.hasFeatureControl(
              this.FEATURES.SCHEDULE,
              this.CONTROL_LEVELS.VIEWER,
            );
          }
          break;
        default:
          this.buttonText = this.$t('add');
          this.statusText = this.$t('sites.sections.schedules.noSchedules');

          this.buttonShow = this.hasFeatureControl(
            this.FEATURES.SCHEDULE,
            this.CONTROL_LEVELS.EDITOR,
          );
          break;
      }
    },
  },
};
</script>
