<template>
  <div>
    <label class="font-medium" :class="labelSize"> {{ label }} </label>
    <VueMultiselect
      :class="{ 'c-multiselect__input--error': error }"
      :value="value"
      :options="multiselectOptions"
      :multiple="multiple"
      :group-values="groupOptions ? 'options' : undefined"
      :group-label="groupOptions ? 'groupOptionsLabel' : undefined"
      :group-select="groupOptions"
      :placeholder="$t('multiselect.placeholder')"
      track-by="name"
      label="name"
      @input="$emit('input', $event)"
    >
      <BaseIcon v-if="error" slot="caret" name="alert" class="c-multiselect__icon--error" />
      <span slot="noResult">{{ $t('multiselect.noResult') }}</span>
    </VueMultiselect>
    <p v-if="error" class="c-multiselect__error--text">
      {{ error }}
    </p>
  </div>
</template>

<script>
import { BaseIcon } from '@seegrid/components';
import VueMultiselect from 'vue-multiselect';

export default {
  name: 'BaseMultiselect',
  components: {
    BaseIcon,
    VueMultiselect,
  },
  props: {
    error: {
      type: String,
      default: '',
    },
    groupOptions: {
      type: Boolean,
      default: true,
    },
    groupOptionsLabel: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelSize: {
      type: String,
      default: 'text-l',
    },
    multiple: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    multiselectOptions() {
      return this.groupOptions
        ? [
            {
              groupOptionsLabel: this.groupOptionsLabel,
              options: this.options,
            },
          ]
        : this.options;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>;

<style lang="scss" scoped>
@use "sass:math";
@import '@/assets/css/variables.scss';
@import '@/assets/css/calc.scss';

::v-deep .multiselect__tag,
::v-deep .multiselect__tag-icon:focus,
::v-deep .multiselect__tag-icon:hover,
::v-deep .multiselect__option--highlight,
::v-deep .multiselect__option--highlight::after {
  background: $c-primary;
}

::v-deep .multiselect {
  border-color: rgba(0, 38, 66, 0.12);
  &:focus,
  &:hover {
    .multiselect__tags {
      border-color: black;
    }
  }
  .multiselect__tags {
    padding: 0 rem(4px);
  }
  .multiselect__input,
  .multiselect__placeholder {
    font-size: 1rem;
    padding-top: rem(6px);
    padding-left: rem(8px);
    margin-bottom: rem(8px);
  }
  .multiselect__tag {
    font-size: 1rem;
    font-weight: 400;
    line-height: rem(30px);
    min-height: rem(30px);
    padding: 0 rem(30px) 0 rem(4px);
    margin: 0 rem(4px) 0 0;
    top: rem(4px);
    border-radius: rem(4px);
    background-color: $c-gray-3;
    color: $c-ctrl-text-color;
    .multiselect__tag-icon {
      border-radius: 0 rem(4px) rem(4px) 0;
      font-size: rem(24px);
      line-height: rem(24px);
      &::after {
        font-size: rem(24px);
        font-weight: 100;
        //border-radius: 0 rem(4px) rem(4px) 0;
        color: $c-ctrl-text-color;
      }
      &:focus,
      &:hover {
        background-color: $c-primary-a11y-dark;
        color: white;
        &::after {
          color: white;
        }
      }
    }
  }
}

::v-deep .multiselect__tags {
  border-radius: 4px;
}

::v-deep .multiselect--active {
  z-index: 400;
}

.c-multiselect__input--error {
  border: 0.0625rem solid $c-status-negative-dark;
  border-radius: 0.375rem;
}

.c-multiselect__icon--error {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  width: 1.25rem;
  height: 1.25rem;
  color: $c-status-negative-dark;
  pointer-events: none;
  transform: translateY(-50%);
}

.c-multiselect__error--text {
  font-size: 0.75rem;
  line-height: 1.5;
  color: $c-status-negative-dark;
  opacity: 1;
  font-weight: 500;
  margin-top: 0.25rem;
}
</style>
