<template>
  <div ref="map" class="map"></div>
</template>

<script>
import { createMap } from '@seegrid/facility-map';
import RouteNetwork from '@/models/RouteNetwork';

export default {
  name: 'RouteNetworkDisplay',

  props: {
    routeNetwork: {
      default: null,
      type: Object,
    },
  },

  data() {
    return {
      routeNetworkMap: null,
    };
  },

  mounted() {
    // if there is a valid route network create the map
    if (this.routeNetwork instanceof RouteNetwork) {
      this.createMap();
    }
  },

  methods: {
    // create the route network map based on the route network data
    createMap() {
      try {
        this.routeNetworkMap = createMap({
          floorPlanImage: {
            dimensions: this.routeNetwork.mapDimensions,
            path: this.routeNetwork.mapPath,
          },
          layers: {
            points: this.routeNetwork.getMapStations(),
            segments: this.routeNetwork.getMapSegments(),
          },
          target: this.$refs.map,
        });
      } catch (error) {
        console.log('There was an error loading the map image', error);
      }
    },
  },
};
</script>
