export default {
  beforeMount() {
    this.onHideMessage();
  },

  computed: {
    // returns currently displayed error code
    messageCode() {
      return this.$store.getters['message/getCode'];
    },
    // returns the message display flag
    messageDisplay() {
      return this.$store.getters['message/getDisplay'];
    },

    // returns the message text
    messageText() {
      return this.$store.getters['message/getText'];
    },

    // returns the message type
    messageType() {
      return this.$store.getters['message/getType'];
    },
  },

  methods: {
    // event handler for hiding the message
    onHideMessage() {
      this.$store.dispatch('message/hide');
    },
  },
};
