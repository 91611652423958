import Schedule from '@/models/Schedule';
import isWeekBoundaryOverlapInvalid from '@/util/isWeekBoundaryOverlapInvalid';

const getScheduleOverlaps = (schedule, allSchedules) => {
  const schedules = new Set();
  // verify that the arguments are valid
  if (!(schedule instanceof Schedule) || !allSchedules || !Array.isArray(allSchedules)) {
    return null;
  }

  // Getting Schedules multiplied by day so individual day schedules can be compared with the rest of schedules
  const currentSchedulesByDay = schedule.getSchedulesByDay();
  const otherScheduleByDay = allSchedules.reduce((arr, s) => arr.concat(s.getSchedulesByDay()), []);
  // verify that the new shifts do not overlap the existing shifts
  for (let i = 0; i < currentSchedulesByDay.length; i += 1) {
    for (let j = 0; j < otherScheduleByDay.length; j += 1) {
      const cs = currentSchedulesByDay[i];
      const os = otherScheduleByDay[j];
      if (
        // verify that the start time does not overlap
        (cs.startTime.as('milliseconds') > os.startTime.as('milliseconds') &&
          cs.startTime.as('milliseconds') < os.endTime.as('milliseconds')) ||
        // verify that the end time does not overlap
        (cs.endTime.as('milliseconds') > os.startTime.as('milliseconds') &&
          cs.endTime.as('milliseconds') < os.endTime.as('milliseconds')) ||
        // verify that the entire shift does not overlap
        (os.startTime.as('milliseconds') >= cs.startTime.as('milliseconds') &&
          os.endTime.as('milliseconds') <= cs.endTime.as('milliseconds'))
      ) {
        schedules.add(os.name);
      }

      // verify that there is not a shift overlap over the week boundary
      if (
        isWeekBoundaryOverlapInvalid(cs.endTime, os.startTime) ||
        isWeekBoundaryOverlapInvalid(os.endTime, cs.startTime)
      ) {
        schedules.add(os.name);
      }
    }
  }
  return { schedules: Array.from(schedules) };
};

export default getScheduleOverlaps;
