/**
 * this module serves as a wrapper for the global $auth object
 */

import has from 'lodash-es/has';
import Vue from 'vue';
import config from '@/config/config';
import constants from '@/config/constants';
import User from '@/models/User';
import environment from '@/util/environment';
import {
  getControlLevelValue,
  validateFeatureName,
  validateControlLevelObject,
} from '@/util/featureControlUtils';

const actions = {
  // if neccessary load the current user data
  async loadUser({ commit, state }) {
    if (state.user instanceof User) {
      return;
    }

    // get the user based on the id set by auth0
    const user = await User.getUserById(Vue.prototype.$auth.user.sub);

    if (!(user instanceof User)) {
      return;
    }

    commit('setUser', user);
  },

  // logout the current user
  async logout() {
    const auth0Url = environment.VUE_APP_AUTH0_DOMAIN;
    const auth0ClientId = process.env.VUE_APP_AUTH0_CLIENT_ID;

    // clear the selected company from the session
    sessionStorage.removeItem(config.SESSION_KEY_COMPANY_ID);
    if (Vue.prototype.$auth && Vue.prototype.$auth.auth0Client) {
      Vue.prototype.$auth.logout();
    } else {
      // Otherwise use Auth0 Logout API URL to logout(This is what Auth0 client internally uses in the above method)
      window.location.assign(
        `https://${auth0Url}/v2/logout?returnTo=${encodeURIComponent(
          window.location.origin + process.env.VUE_APP_BASE_URL_ADMIN,
        )}&client_id=${encodeURIComponent(auth0ClientId)}`,
      );
    }
  },
};

const getters = {
  getUserEmail: () => {
    return Vue.prototype.$auth.user.email;
  },

  getUserGivenName: () => {
    return Vue.prototype.$auth.user.given_name;
  },

  // returns the name of the current user
  getUserName: () => {
    return Vue.prototype.$auth.user.name;
  },

  // returns true if the user has access to the app, false otherwise
  hasAppAccess: () => clientId => {
    const namespace = process.env.VUE_APP_AUTH0_NAMESPACE + constants.AUTH0.NAMESPACE.APP_METADATA;

    // verify that the configured namespace exists in the user object
    if (!has(Vue.prototype.$auth.user, namespace)) {
      throw new Error('invalid authorization namespace');
    }

    const appMetadata = Vue.prototype.$auth.user[namespace];

    if (!(appMetadata instanceof Object)) {
      throw new Error('invalid app_metadata');
    }

    return (
      appMetadata &&
      Object.prototype.hasOwnProperty.call(appMetadata, clientId) &&
      appMetadata[clientId].allow_access === true
    );
  },

  /**
   * returns true if the user has access to the given company
   * or false otherwise
   */
  hasCompany: state => companyId => {
    if (!(state.user instanceof User)) {
      return false;
    }

    return state.user.hasCompany(companyId);
  },

  // returns true if the user has access to the given feature, false otherwise
  hasFeatureControl: (state, sitesGetters, rootState, rootGetters) => (
    featureName,
    controlLevelObject,
    companyId = null,
  ) => {
    // if companyId is not specified, determine access of currently selected company
    const currentCompanyId = companyId || rootGetters['companies/getSelectedCompanyId'];
    const featureControl = rootGetters['companies/getCompanyFeatureControl'](currentCompanyId);
    // validate feature name, control level, and feature control object
    if (
      validateFeatureName(featureName) &&
      validateControlLevelObject(controlLevelObject) &&
      featureControl
    ) {
      // compare the feature control values to determine if the user has access to this UI component
      const userControlLevel = getControlLevelValue(featureControl[featureName]);
      const compareControlLevel = getControlLevelValue(controlLevelObject.name);
      return userControlLevel >= compareControlLevel;
    }
    return false;
  },

  // returns true if the current user is authenticated, false otherwise
  isAuthenticated: () => {
    return Vue.prototype.$auth.isAuthenticated;
  },

  // returns true if the user has the required authorization, or false otherwise
  isAuthorized: (state, userGetters) => route => {
    let hasRoutePermission = true;

    if (has(route, 'meta.featureControl')) {
      // ensure the user has all required feature controls
      hasRoutePermission = route.meta.featureControl.every(featureControl =>
        userGetters.hasFeatureControl(featureControl.feature, featureControl.controlLevel),
      );
    }
    const hasAppAccess = userGetters.hasAppAccess(process.env.VUE_APP_AUTH0_CLIENT_ID);
    return hasAppAccess && hasRoutePermission;
  },

  // returns true if the auth0 plugin is loading, false otherwise
  isLoading: () => {
    return Vue.prototype.$auth.loading;
  },
};

const mutations = {
  // store the user
  setUser(state, value) {
    state.user = value;
  },
};

const state = () => ({
  user: null,
});

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
