<template>
  <BaseButton
    class="c-btn--ghost-button-with-icon text-primary-dark text-sm"
    :button-type="'ghost'"
    :size="size"
    :disabled="disabled"
    v-on="$listeners"
  >
    <div class="flex items-center">
      <span v-if="hasSlot" class="flex"><slot /></span>
      <BaseIcon
        v-if="iconName"
        class="flex ml-2"
        :height="iconHeight"
        :width="iconWidth"
        :viewBox="viewBox"
        :icon-sprite="iconSprite"
        :name="iconName"
      />
    </div>
  </BaseButton>
</template>

<script>
import { BaseButton, BaseIcon } from '@seegrid/components';
import VueTypes from 'vue-types';

export default {
  name: 'GhostButton',

  components: {
    BaseButton,
    BaseIcon,
  },

  props: {
    disabled: VueTypes.bool.def(false),
    iconHeight: VueTypes.string.def('16px'),
    iconName: VueTypes.string,
    iconSprite: VueTypes.oneOfType([String, null]),
    iconWidth: VueTypes.string.def('16px'),
    label: VueTypes.oneOfType([String, null]),
    size: VueTypes.oneOf(['small', 'medium', 'large']).def('small'),
    viewBox: VueTypes.string.def('0 0 16 16'),
  },

  computed: {
    hasSlot() {
      return !!this.$slots.default;
    },
  },
};
</script>

<style lang="scss" scoped>
.c-btn--ghost-button-with-icon {
  margin-right: 0.25rem;
  line-height: 1.15;
}
</style>
