<template>
  <section class="bg-gray-2 mt-5 p-6 rounded-lg">
    <!-- site report heading -->
    <header :id="site.name" class="flex items-center mb-4 mr-auto spr-header text-primary-dark">
      <BaseIcon
        viewBox="0 0 16 16"
        class="header-icon"
        :icon-sprite="iconSprite"
        :name="'fg-icon-map-pin'"
      />
      <h3 class="font-medium heading text-2xl text-primary-dark">
        {{ site.name }}
      </h3>
    </header>

    <!-- report by type heading -->
    <Spinner v-if="loadingSiteData" :is-local="true" />
    <template v-else>
      <SprReport
        v-for="report in reports"
        :key="report.id"
        ref="SprReports"
        :site="site"
        :report="report"
        @edit-report="onEditSpr"
      />
    </template>
  </section>
</template>

<script>
import { BaseIcon } from '@seegrid/components';
import IconSprite from '@/assets/images/icon-sprite.svg';
import Spinner from '@/components/Spinner.vue';
import SprReport from '@/components/sprconfig/SprReport.vue';
import authorization from '@/mixins/authorization';

export default {
  name: 'SprSite',

  components: {
    BaseIcon,
    Spinner,
    SprReport,
  },

  mixins: [authorization],

  props: {
    reports: {
      type: Array,
      required: true,
    },
    site: {
      type: Object,
      required: true,
    },
  },

  computed: {
    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },

    // returns true if site data is loading or false otherwise
    loadingSiteData() {
      // TODO: add loading logic
      return false;
    },
  },

  async mounted() {
    await this.displayPreviousSite();
  },

  methods: {
    // check for a previously selected site and if necessary display it
    async displayPreviousSite() {
      const previousSite = this.$store.getters['sites/getPreviousSelectedSiteId'];

      if (previousSite && previousSite === this.site.id) {
        await this.$store.dispatch('sites/setSiteDisplay', {
          siteId: this.site.id,
          value: true,
        });
      }
    },

    onEditSpr(id) {
      this.$emit('edit-report', id);
    },

    showSPRs(open) {
      this.$refs.SprReports.forEach(component => {
        component.showReport(open);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/calc.scss';
@import '@/assets/css/variables.scss';
$header-icon-xy: 22px;
.spr-header {
  .header-icon {
    width: rem($header-icon-xy);
    height: rem($header-icon-xy);
  }
  .heading {
    margin-left: rem($spr-control-indent - $header-icon-xy);
  }
}
</style>
