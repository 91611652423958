<template>
  <div class="admin-page-container">
    <TheHeader />
    <Spinner v-if="publishingSchedules" />
    <section class="admin-content-container">
      <BaseGlobalMessage
        v-if="displayMessage"
        :can-delete="true"
        :text="messageText"
        :type="messageType"
        class="c-admin-global-message mb-6"
        @delete-error="onHideMessage"
      />
      <div
        class="border-opacity-10 bottom-border flex flex-wrap items-center justify-between mb-10 mt-4 pb-2"
      >
        <div class="mr-auto">
          <h2 class="font-medium text-2.5xl text-primary-dark">
            {{ $t('navigation.breadcrumbs.accountManagement') }}
          </h2>
        </div>
        <div class="flex flex-grow items-center justify-end ml-4" flex-grow="4">
          <BaseSearch
            class="flex-grow max-w-sm"
            :min-characters-to-search="1"
            :placeholder="$t('sites.search')"
            @search-updated="onSearchUpdate"
          />
          <BaseButton
            v-if="hasFeatureControl(FEATURES.SCHEDULE, CONTROL_LEVELS.EDITOR)"
            :disabled="!hasPending"
            :size="'medium'"
            class="ml-2 py-3"
            @click="onPublish"
          >
            {{ $t('sites.publishButton') }}
          </BaseButton>
        </div>
      </div>
      <div v-if="sites.length" class="mx-auto">
        <div class="flex font-medium items-baseline justify-start">
          <h3 class="text-2xl text-primary-dark">
            {{ $t('sites.sitesIncomplete') }}
          </h3>
          <span
            class="cursor-pointer ml-4 mr-auto text-primary-dark text-sm underline"
            @click="onToggleSiteIncompleteDisplay"
            >{{ sitesIncompleteToggleText }}</span
          >
        </div>
        <div v-if="displayNoIncompleteSitesMessage" class="mt-4">
          {{ messageNoIncompleteSites }}
        </div>
        <div v-if="loading" class="flex justify-items-center">
          <Spinner :is-local="true" class="mt-4 mx-auto" />
        </div>
        <template v-else>
          <Site v-for="site in sitesIncomplete" :key="site.id" :site="site" />
        </template>

        <div class="flex font-medium items-baseline justify-start">
          <h3 class="font-medium mt-8 text-2xl text-primary-dark">
            {{ $t('sites.sitesComplete') }}
          </h3>
          <span
            class="cursor-pointer ml-4 mr-auto text-primary-dark text-sm underline"
            @click="onToggleSiteCompleteDisplay"
            >{{ sitesCompleteToggleText }}</span
          >
        </div>
        <div>
          <div v-if="displayNoCompleteSitesMessage" class="mt-4">
            {{ messageNoCompleteSites }}
          </div>
        </div>
        <div v-if="loading" class="flex justify-items-center">
          <Spinner :is-local="true" class="mt-4 mx-auto" />
        </div>
        <template v-else>
          <Site v-for="site in sitesComplete" :key="site.id" :site="site" />
        </template>
      </div>

      <BaseModal
        ref="modal"
        :action-one="$t('sites.modalCancel')"
        :action-two="$t('sites.modalConfirm')"
        :heading="modalTitle"
        :sub-heading="modalText"
        @modal-button-clicked="modalHandler && modalHandler($event)"
      />

      <RouteNetworkOverlay
        v-if="!loading"
        class="2xl:right-12 bottom-24 fixed lg:right-2 md:right-2 sm:right-0 xl:right-6"
      />
    </section>

    <TheFooter />
  </div>
</template>

<script>
import { BaseButton, BaseGlobalMessage, BaseModal, BaseSearch } from '@seegrid/components';
// import differenceBy from 'lodash-es/differenceBy';
import has from 'lodash-es/has';
import RouteNetworkOverlay from '@/components/RouteNetworkOverlay.vue';
import Site from '@/components/Site.vue';
import Spinner from '@/components/Spinner.vue';
import TheFooter from '@/components/TheFooter.vue';
import TheHeader from '@/components/TheHeader.vue';
import config from '@/config/config';
import constants from '@/config/constants';
import authorization from '@/mixins/authorization';
import Company from '@/models/Company';
import SupervisorBackupImport from '@/models/SupervisorBackupImport';

export default {
  name: 'SitesView',

  components: {
    BaseButton,
    BaseGlobalMessage,
    BaseModal,
    BaseSearch,
    RouteNetworkOverlay,
    Site,
    Spinner,
    TheFooter,
    TheHeader,
  },

  mixins: [authorization],

  data: () => {
    return {
      displayMessage: false,
      messageText: null,
      messageType: null,
      modalHandler: null,
      modalText: '',
      modalTitle: '',
      siteSearch: '',
    };
  },

  computed: {
    // returns the currently selected company
    company() {
      return this.$store.getters['companies/getSelectedCompany'];
    },

    /**
     * returns true if the no complete sites message should be displayed
     * or false otherwise
     */
    displayNoCompleteSitesMessage() {
      return !this.loading && !this.isCompleteSites;
    },

    /**
     * returns true if the no incomplete sites message should be displayed
     * or false otherwise
     */
    displayNoIncompleteSitesMessage() {
      return !this.loading && !this.isIncompleteSites;
    },

    // returns the complete sites display flag
    displaySitesComplete() {
      return this.$store.getters['sites/getSitesDisplay'](constants.SITE_STATE.COMPLETE);
    },

    // returns the incomplete sites display flag
    displaySitesIncomplete() {
      return this.$store.getters['sites/getSitesDisplay'](constants.SITE_STATE.INCOMPLETE);
    },

    // returns true if any site has pending schedules or false otherwise
    hasPending() {
      // TODO figure out if this is an actual issue or a false positive
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.$store.getters['schedules/hasPending']();
    },

    // returns true if there are complete sites or false otherwise
    isCompleteSites() {
      return !this.loading && this.sitesComplete.length > 0;
    },

    // returns true if there are incomplete sites or false otherwise
    isIncompleteSites() {
      return !this.loading && this.sitesIncomplete.length > 0;
    },

    // returns true if data is loading or false otherwise
    loading() {
      return (
        this.$store.getters['routeNetworkImports/getLoading'] ||
        this.$store.getters['schedules/getLoading'] ||
        this.$store.getters['users/getLoading']
      );
    },

    /**
     * returns the appropriate message based on if there are no complete sites
     * or if they are filtered by search criteria
     */
    messageNoCompleteSites() {
      return this.siteSearch
        ? this.$t('sites.messageNoSearchResults')
        : this.$t('sites.messageNoCompleteSites');
    },

    /**
     * returns the appropriate message based on if there are no incomplete sites
     * or if they are filtered by search criteria
     */
    messageNoIncompleteSites() {
      return this.siteSearch
        ? this.$t('sites.messageNoSearchResults')
        : this.$t('sites.messageNoIncompleteSites');
    },

    // returns true if schedule data is being published or false otherwise
    publishingSchedules() {
      return this.$store.getters['schedules/getSaving'];
    },

    // returns the schedules for the current company
    schedules() {
      return this.$store.getters['schedules/getSchedules'];
    },

    // returns all sites for the current company
    sites() {
      return this.$store.getters['sites/getSites'];
    },

    /**
     * returns the sites for which:
     * - settings are complete for the current company
     * - optionally match the search criteria
     */
    sitesComplete() {
      const completed = this.$store.getters['sites/getSitesByState'](constants.SITE_STATE.COMPLETE);

      return this.searchSites(completed);
    },

    /**
     * return the appropriate text for the display / hide sites "button" for
     * completed sites
     */
    sitesCompleteToggleText() {
      // if there are no complete sites display nothing
      if (!this.isCompleteSites) {
        return '';
      }

      return !this.displaySitesComplete ? this.$t('sites.displayAll') : this.$t('sites.hideAll');
    },

    /**
     * returns the sites for which:
     * - settings are incomplete for the current company
     * - optionally match the search criteria
     */
    sitesIncomplete() {
      const incomplete = this.$store.getters['sites/getSitesByState'](
        constants.SITE_STATE.INCOMPLETE,
      );

      return this.searchSites(incomplete);
    },

    /**
     * return the appropriate text for the display / hide sites "button" for
     * incomplete sites
     */
    sitesIncompleteToggleText() {
      // if there are no incomplete sites display nothing
      if (!this.isIncompleteSites) {
        return '';
      }

      return !this.displaySitesIncomplete ? this.$t('sites.displayAll') : this.$t('sites.hideAll');
    },

    // returns the timeout between submitting schedules to the api in minutes
    timeoutMinutes() {
      return process.env.VUE_APP_API_SHIFT_DEFINITION_TIMEOUT;
    },
  },

  watch: {
    // reload the site data when the selected sites change
    async sites() {
      if (!this.loading) {
        this.$store.dispatch('companies/setLocked', true);
        await this.loadData();
        this.$store.dispatch('companies/setLocked', false);
      }
    },
  },

  beforeMount() {
    this.$store.dispatch('routeNetworkImports/resetState');
    this.$store.dispatch('schedules/resetState');
    this.$store.dispatch('sprs/resetState');
    this.$store.dispatch('users/resetState');
  },

  // load the required data
  async mounted() {
    if (!(this.company instanceof Company)) {
      await this.$store.dispatch('companies/loadCompanies');
      await this.$store.dispatch('companies/loadSelectedCompany');
    }

    // ensure the user has access to the selected company
    if (!(this.company instanceof Company)) {
      await this.$router.push({ name: 'home' });
    }
    this.$store.dispatch('companies/setLocked', true);
    await this.loadData();
    this.$store.dispatch('companies/setLocked', false);
  },

  methods: {
    // display the loading error message
    displayLoadingErrorMapMessage() {
      this.messageText = this.$t('map.error.load');
      this.messageType = 'negative';
      this.displayMessage = true;
    },

    // display the error loading user message
    displayLoadingErrorUserMessage() {
      this.messageText = this.$t('userManagement.error.load');
      this.messageType = 'negative';
      this.displayMessage = true;
    },

    // display the no sites warning
    displayNoSitesMessage() {
      this.messageText = this.$t('sites.messageNoSites', { company: this.company.name });
      this.messageType = 'warning';
      this.displayMessage = true;
    },

    // display the pending changes warning for users with the appropriate permissions
    displayPendingMessage() {
      if (!this.hasFeatureControl(this.FEATURES.SCHEDULE, this.CONTROL_LEVELS.EDITOR)) {
        return;
      }

      this.messageText = this.$t('sites.messageSubmitPending');
      this.messageType = 'warning';
      this.displayMessage = true;
    },

    /**
     * returns the last time that schedules were published based on
     * session values
     */
    getCompanyPublishTimestamps() {
      const companyPublishTimes = sessionStorage.getItem(config.SESSION_KEY_COMPANY_PUBLISH_TIMES);

      if (companyPublishTimes) {
        return JSON.parse(companyPublishTimes);
      }

      return {};
    },

    /**
     * returns true if the user is attempting to publish before the timeout
     * period has expired or false otherwise
     */
    isPublishTimeout() {
      const companyTimestamps = this.getCompanyPublishTimestamps();
      if (!has(companyTimestamps, this.company.id)) {
        return false;
      }

      const lastPublishedTime = Date.parse(companyTimestamps[this.company.id]);
      const TIMEOUT_MILLISECONDS = this.timeoutMinutes * 60 * 1000;

      if (new Date() - lastPublishedTime < TIMEOUT_MILLISECONDS) {
        this.messageType = 'negative';
        this.displayMessage = true;
        this.messageText = this.$t('sites.messageSubmitTimeout', {
          minutes: this.timeoutMinutes,
        });

        return true;
      }

      return false;
    },

    // load the site data
    async loadData() {
      // reset state
      this.displayMessage = false;
      this.siteSearch = '';
      await this.$store.dispatch('sites/resetSitesDisplay');

      const promiseMap = this.loadMap();
      const promiseSchedules = this.loadSchedules();
      const promiseUsers = this.loadUsers();

      return Promise.all([promiseMap, promiseSchedules, promiseUsers]);
    },

    // load the map data
    async loadMap() {
      /**
       * if the user does not have the required read permissions ensure the
       * loading flag is reset
       */
      if (!this.hasFeatureControl(this.FEATURES.MAP, this.CONTROL_LEVELS.VIEWER)) {
        await this.$store.dispatch('routeNetworkImports/setLoading', false);
        return;
      }

      await this.$store.dispatch('routeNetworkImports/setLoading', true);

      const companyId = this.$store.getters['companies/getSelectedCompanyId'];

      const routeNetworkImport = new SupervisorBackupImport(companyId, null);

      try {
        await this.$store.dispatch(
          'routeNetworkImports/loadRouteNetworkImports',
          routeNetworkImport,
        );

        await this.$store.dispatch('routeNetworkImports/setLoading', false);
      } catch (error) {
        console.error(error);
        this.displayLoadingErrorMapMessage();
      }
    },

    // load the schedules
    async loadSchedules() {
      /**
       * if the user does not have the required read permissions ensure the
       * loading flag is reset
       */
      if (!this.hasFeatureControl(this.FEATURES.SCHEDULE, this.CONTROL_LEVELS.VIEWER)) {
        await this.$store.dispatch('schedules/setLoading', false);
        return;
      }

      await this.$store.dispatch('schedules/setLoading', true);

      await this.$store.dispatch('schedules/loadSchedules');

      // if necessary display the appropriate message
      if (this.sites.length === 0) {
        this.displayNoSitesMessage();
      } else if (this.hasPending) {
        this.displayPendingMessage();
      }

      await this.$store.dispatch('schedules/setLoading', false);
    },

    // load the users
    async loadUsers() {
      /**
       * if the user does not have the required read permissions ensure the
       * loading flag is reset
       */
      if (
        !this.hasFeatureControl(this.FEATURES.USER, this.CONTROL_LEVELS.VIEWER, this.company.id) ||
        !this.hasFeatureControl(
          this.FEATURES.USER,
          this.CONTROL_LEVELS.VIEWER,
          process.env.VUE_APP_SEEGRID_ORG_ID,
        )
      ) {
        await this.$store.dispatch('users/setLoading', false);
        console.error(
          'This user does not have the correct permissions to view members of Seegrid organization.',
        );
        return;
      }

      await this.$store.dispatch('users/setLoading', true);

      try {
        await this.$store.dispatch('roles/loadRoles', { force: true });
        await this.$store.dispatch('users/loadOrgMembers', {
          parameters: { companyId: this.company.id, includeRoles: true, includeSites: true },
        });
        await this.$store.dispatch('users/loadSeegridMembers');
      } catch (error) {
        console.error(error);
        this.displayLoadingErrorUserMessage();
      }

      await this.$store.dispatch('users/setLoading', false);
    },

    // hide the message component
    onHideMessage() {
      this.displayMessage = false;
    },

    // event handler for clicking the publish button
    onPublish() {
      // if user submitted before the timeout period expires show an error
      if (this.isPublishTimeout()) {
        return;
      }

      this.modalHandler = this.onPublishSubmit;
      this.modalTitle = this.$t('sites.modalPublishTitle');
      this.modalText = this.$t('sites.modalPublishText', { minutes: this.timeoutMinutes });
      this.$refs.modal.open();
    },

    // event handler for clicking the publish modal submit button
    async onPublishSubmit(event) {
      if (event.action === constants.MODAL_CONFIRM) {
        try {
          // publish pending schedules
          await this.$store.dispatch('schedules/publishSchedules');

          this.updateLastPublishedTimeStamp();

          // reload the schedule data
          await this.loadSchedules();

          this.messageText = this.$t('sites.messageSubmitSuccess', {
            company: this.company ? this.company.name : '',
          });
          this.messageType = 'positive';
          this.displayMessage = true;
        } catch (error) {
          this.messageText = this.$t('sites.messageSubmitError');
          this.messageType = 'negative';

          // check for timeout error
          if (has(error, 'response.data.errors') && Array.isArray(error.response.data.errors)) {
            for (let i = 0; i < error.response.data.errors.length; i += 1) {
              if (error.response.data.errors[i].error_code === 'shifts_publish_lockout') {
                this.messageText = this.$t('sites.messageSubmitTimeout', {
                  minutes: this.timeoutMinutes,
                });
              }
            }
          }

          this.displayMessage = true;
        }
      }
    },

    // event handler for the search input
    onSearchUpdate(event) {
      this.siteSearch = event.searchString;
    },

    // event handler for clicking the display / hide sites "button"
    async onToggleSiteCompleteDisplay() {
      await this.$store.dispatch('sites/toggleSitesDisplay', constants.SITE_STATE.COMPLETE);
    },

    // event handler for clicking the display / hide sites "button"
    async onToggleSiteIncompleteDisplay() {
      await this.$store.dispatch('sites/toggleSitesDisplay', constants.SITE_STATE.INCOMPLETE);
    },

    // returns an array of sites that match the search criteria, if any
    searchSites(sites) {
      return this.siteSearch
        ? sites.filter(site => site.name.toLowerCase().includes(this.siteSearch.toLowerCase()))
        : sites;
    },

    // update the schedules publish time for the current company
    updateLastPublishedTimeStamp() {
      const companyTimestamps = this.getCompanyPublishTimestamps();
      companyTimestamps[this.company.id] = new Date();

      sessionStorage.setItem(
        config.SESSION_KEY_COMPANY_PUBLISH_TIMES,
        JSON.stringify(companyTimestamps),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-content-container > .c-global-message {
  max-width: 72rem;
  width: 100%;
}
</style>
