/* eslint-disable class-methods-use-this */
// ^ suppressing lintting errors because normally a class method would work on class data which does require this
import difference from 'lodash-es/difference';
import has from 'lodash-es/has';
import isString from 'lodash-es/isString';
import cerberusUserAPI from '@/api/cerberusUserAPI';
import constants from '@/config/constants';

// "abstract" class representing Users and SeegridUsers
// BaseUser class represents the concept of Global User in Auth0
// This class is extended by OrganizationMember class for org based attributes and methods
export default class BaseUser {
  constructor(userData, isInternal) {
    if (userData) {
      // existing user
      this.accountStatus = userData.account_status || '';
      this.blocked = userData.blocked;
      this.email = userData.email || '';
      this.firstName = (isString(userData.given_name) ? userData.given_name.trim() : '') || '';
      this.id = userData.user_id || '';
      this.lastLogin = userData.last_login || '';
      this.lastName = (isString(userData.family_name) ? userData.family_name.trim() : '') || '';
      this.name = (isString(userData.name) ? userData.name.trim() : '') || '';
      this.verified = userData.email_verified;
    } else {
      // new user
      this.accountStatus = '';
      this.blocked = false;
      this.email = '';
      this.firstName = '';
      this.id = '';
      this.lastLogin = '';
      this.lastName = '';
      this.name = '';
      this.verified = false;
    }
    this.globalRoles = null; // global roles are stored and updated only when necessary
    this.isInternal = isInternal; // see markAsInternal
  }

  // delete the user
  async delete() {
    await cerberusUserAPI.deleteUser(this.id);
  }

  // return the global roles for the given user
  static async getUserGlobalRoles(userId) {
    const response = await cerberusUserAPI.getUserGlobalRoles(userId);

    if (!has(response, 'data') || !Array.isArray(response.data)) {
      throw new Error('failed to get global roles');
    }

    const globalRoles = response.data.map(role => role.id);
    this.globalRoles = globalRoles;

    return globalRoles;
  }

  // returns true if the user has the given global role or false otherwise
  async hasGlobalRole(roleId) {
    let globalRoles;
    if (this.globalRoles) {
      globalRoles = this.globalRoles;
    } else {
      globalRoles = await BaseUser.getUserGlobalRoles(this.id);

      if (!globalRoles || !Array.isArray(globalRoles)) {
        console.warn('user global role details were not loaded');
        return false;
      }
    }
    return globalRoles.includes(roleId);
  }

  // Indicates whether this user is new (unsaved) or existing
  get isNewUser() {
    return !this.id;
  }

  // marks this user as internal (seegrid user)
  markAsInternal() {
    this.isInternal = true;
    return this;
  }

  async sendEmail(emailType) {
    if (!emailType) {
      throw new Error('emailType is required to sendEmail');
    }
    let resp;
    switch (emailType) {
      case constants.USER_EMAIL_TYPES.WELCOME:
        resp = await this.sendWelcomeEmail();
        break;
      case constants.USER_EMAIL_TYPES.VERIFICATION:
        resp = await this.sendVerificationEmail();
        break;
      case constants.USER_EMAIL_TYPES.RESET_PASSWORD:
        resp = await this.sendResetPasswordEmail();
        break;
      default:
        resp = null;
    }
    return resp;
  }

  // send a reset password email to the user
  async sendResetPasswordEmail() {
    const resp = await cerberusUserAPI.sendResetPasswordEmail(this.id);
    if (resp && resp.data && resp.data.account_status) {
      this.accountStatus = resp.data.account_status;
    }
    return resp;
  }

  // send a verification email to the user
  async sendVerificationEmail() {
    const resp = await cerberusUserAPI.sendVerificationEmail(this.id);
    if (resp && resp.data && resp.data.account_status) {
      this.accountStatus = resp.data.account_status;
    }
    return resp;
  }

  // send a welcome email to the user
  async sendWelcomeEmail() {
    const resp = await cerberusUserAPI.sendWelcomeEmail(this.id);
    if (resp && resp.data && resp.data.account_status) {
      this.accountStatus = resp.data.account_status;
    }
    return resp;
  }

  /**
   * Update the user's global roles
   * @param {*} selectedRoles - array of role ids
   */
  async updateGlobalRoles(selectedRoles) {
    if (!this.id || !Array.isArray(selectedRoles)) {
      throw new Error('a user id and roles are required to update roles');
    }

    const currentRoles = await BaseUser.getUserGlobalRoles(this.id);

    // determine the new roles and add them to the user
    const newRoles = difference(selectedRoles, currentRoles);
    if (newRoles.length > 0) {
      await cerberusUserAPI.addUserGlobalRoles(this.id, newRoles);
    }

    // determine the old roles and remove them from the user
    const oldRoles = difference(currentRoles, selectedRoles);
    if (oldRoles.length > 0) {
      await cerberusUserAPI.removeUserGlobalRoles(this.id, oldRoles);
    }

    this.globalRoles = selectedRoles;
  }
}
