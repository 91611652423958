<template>
  <BaseButton
    button-type="ghost"
    icon="trash"
    icon-height="16px"
    icon-width="16px"
    viewBox="0 0 24 24"
    just-icon
    @click="invokeParentMethod('delete', $event)"
  />
</template>

<script>
import { BaseButton } from '@seegrid/components';
import Vue from 'vue';

export default Vue.extend({
  name: 'SprUserTableActions',
  components: { BaseButton },
  props: {},
  computed: {},
  methods: {
    invokeParentMethod(action, event) {
      event.stopPropagation();
      this.params.context.componentParent.performUserRowAction(this.params.data, action);
    },
  },
});
</script>
