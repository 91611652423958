import has from 'lodash-es/has';
import Vue from 'vue';
import config from '@/config/config';
import constants from '@/config/constants';
import RouteNetworkImport from '@/models/RouteNetworkImport';

const actions = {
  // delete the route network with the optional given backup id
  async deleteRouteNetworkImport({ commit, dispatch }, data) {
    commit('setLoading', true);
    // reset the state if the reset flag is set
    if (data.reset) {
      dispatch('resetState');
    }
    try {
      if (data.routeNetworkImport instanceof RouteNetworkImport) {
        await data.routeNetworkImport.deleteRouteNetworkImport(data.backupId);
      }
    } catch (error) {
      console.warn('There was an error while trying to delete route network import', error);
    } finally {
      commit('setLoading', false);
    }
  },

  // poll for updates while the status of the given import is processing
  async getSiteRouteNetworkImport({ commit, dispatch }, siteImport) {
    if (
      siteImport.status &&
      siteImport.status !== constants.IMPORT_STATUS.PENDING &&
      siteImport.status !== constants.IMPORT_STATUS.PROCESSING
    ) {
      return;
    }
    const timeOut = setTimeout(async () => {
      try {
        const statusImport = await siteImport.getSiteRouteNetworkImport();

        commit('updateRouteNetworkImport', statusImport);

        dispatch('getSiteRouteNetworkImport', statusImport);
      } catch (error) {
        // retry for the configured number of attempts
        if (siteImport.getErrors().length < config.IMPORT_POLL_RETRY) {
          siteImport.addError(error);

          dispatch('getSiteRouteNetworkImport', siteImport);
        } else {
          commit('setImportError', true);
        }
      }
    }, siteImport.getPollInterval() * 1000);

    commit('setPollingCallTimeoutMap', { siteId: siteImport.siteId, value: timeOut });
  },

  // import the route network for the given import type
  async importRouteNetwork({ commit }, data) {
    await commit('setImportError', false);

    try {
      await data.routeNetworkImport.importRouteNetwork(data.type);
    } catch (error) {
      // reset the loading flag only on failure
      commit('setLoading', false);

      throw error;
    }

    commit('updateRouteNetworkImport', data.routeNetworkImport);
  },

  // load the route network imports for the given import type
  async loadRouteNetworkImports({ commit, dispatch }, routeNetworkImports) {
    dispatch('resetState');

    let data = {};
    try {
      data = await routeNetworkImports.getRouteNetworkImports();
    } catch (error) {
      // reset the loading flag only on failure
      commit('setLoading', false);

      throw error;
    }

    // return if a route network import was not found for the given import type
    if (!(data instanceof Object)) {
      return;
    }

    commit('setRouteNetworkImports', data);

    /**
     * check the processing status for each route network import and continue to
     * poll anything that is processing
     */
    Object.values(data).forEach(value => {
      dispatch('getSiteRouteNetworkImport', value);
    });
  },

  // reset the state to initial values
  resetState({ commit }) {
    commit('setImportError', false);
    commit('setLoading', true);
    commit('setRouteNetworkImports', {});
    commit('setPollingCallTimeoutMap', { reset: true });
  },

  // set the loading flag to the given value
  async setLoading({ commit }, value) {
    commit('setLoading', value);
  },

  // Cancel any polling request for map status
  stopAllNetworkImportPolling({ commit, getters }) {
    const timeOutMap = getters.getPollingCallTimeoutMap();
    if (timeOutMap && Object.keys(timeOutMap).length > 0) {
      Object.keys(timeOutMap).forEach(key => {
        const timeOut = timeOutMap[key];
        try {
          clearTimeout(timeOut);
        } catch (err) {
          console.warn('Err while clearing timeout in stopAllNetworkImportPolling', err);
        }
      });
      commit('setPollingCallTimeoutMap', { reset: true });
    }
  },
};

const getters = {
  // returns true if data is being loaded or false otherwise
  getLoading: state => {
    return state.loading;
  },

  getPollingCallTimeoutMap: state => siteId => {
    if (has(state.pollingCallTimeoutMap, siteId)) {
      return state.pollingCallTimeoutMap[siteId];
    }

    return state.pollingCallTimeoutMap;
  },

  // returns all route network imports
  getRouteNetworkImports: state => {
    return state.routeNetworkImports;
  },

  // returns the route network import for the given site
  getSiteRouteNetworkImport: state => siteId => {
    if (has(state.routeNetworkImports, siteId)) {
      return state.routeNetworkImports[siteId];
    }

    return null;
  },

  // returns true if the importError flag is set or false otherwise
  hasImportError: state => {
    return state.importError;
  },

  /**
   * returns true if the site has a completed route network import
   * or false otherwise
   */
  hasRouteNetworkImportState: (state, routeNetworkImportsGetters) => (siteId, type, status) => {
    const siteRouteNetworkImport = routeNetworkImportsGetters.getSiteRouteNetworkImport(siteId);

    if (siteRouteNetworkImport instanceof RouteNetworkImport) {
      return siteRouteNetworkImport.hasRouteNetworkImportState(type, status);
    }

    return false;
  },
};

const mutations = {
  // set the importError flag to the given value
  setImportError(state, value) {
    state.importError = value;
  },

  // set the loading flag to the given value
  setLoading(state, value) {
    state.loading = value;
  },

  setPollingCallTimeoutMap(state, data) {
    if (has(data, 'siteId')) {
      state.pollingCallTimeoutMap[data.siteId] = data.value;
    } else if (data.reset) {
      state.pollingCallTimeoutMap = {};
    }
  },

  // set routeNetworkImports to the given value
  setRouteNetworkImports(state, value) {
    state.routeNetworkImports = value;
  },

  // update the route network import for the given site with the given data
  updateRouteNetworkImport(state, data) {
    Vue.set(state.routeNetworkImports, data.siteId, data);
  },
};

const state = () => ({
  importError: false,
  loading: true,
  pollingCallTimeoutMap: {},
  routeNetworkImports: {},
});

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
