import Schedule from '@/models/Schedule';
import getScheduleOverlaps from '@/util/getScheduleOverlaps';

/**
 * returns true if the given schedule definition is valid or false if it overlaps
 * the existing schedule definitions
 */
const isValidScheduleDefinition = (schedule, allSchedules) => {
  // verify that the arguments are valid
  if (!(schedule instanceof Schedule) || !allSchedules || !Array.isArray(allSchedules)) {
    return false;
  }

  const { schedules = [] } = getScheduleOverlaps(schedule, allSchedules);

  return schedules.length === 0;
};

export default isValidScheduleDefinition;
