<template>
  <label class="wrapper" :for="id">
    {{ label }}
    <input
      :id="id"
      class="checkbox"
      type="radio"
      :disabled="isDisabled"
      :checked="isChecked"
      :value="value"
      @change="$emit('change', $event.target.value)"
    />
    <span class="checkmark"></span>
    <span v-if="subLabel" class="break" />
    <span v-if="subLabel" class="subtext"> {{ subLabel }}</span>
  </label>
</template>

<script>
import uniqid from 'uniqid';

export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    id: { type: String, default: uniqid('radio-') },
    isDisabled: { type: Boolean, default: false },
    label: { type: String, default: '', required: true },
    modelValue: { type: String, default: '' },
    subLabel: { type: String, default: '' },
    value: { type: String, default: undefined },
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/css/variables' as var;

/* Customize the label (the wrapper) */
.wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default radio button */
  & input:enabled {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  /* On mouse-over, add a grey background color */
  &:hover input:enabled ~ .checkmark {
    border: 1px solid var.$c-primary-dark;
  }
  /* When the radio button is checked, add a blue background */
  & input:checked ~ .checkmark {
    background-color: #fff;
    /* Show the indicator (dot/circle) when checked */
    &:after {
      display: block;
    }
  }
  & .checkmark:after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var.$c-primary-dark;
  }
  & .break {
    flex-basis: 100%;
    height: 5px;
  }
  & .subtext {
    font-weight: 400;
  }
}
/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid var.$c-gray-5;
  /* Create the indicator (the dot/circle - hidden when not checked) */
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
}
</style>
