import forIn from 'lodash-es/forIn';
import has from 'lodash-es/has';
import Schedule from '@/models/Schedule';

const processScheduleByCompanyResp = resp => {
  const result = [];
  if (has(resp, 'data') && has(resp, 'data.sites')) {
    forIn(resp.data.sites, (value, key) => {
      if (has(value, 'schedules') && Array.isArray(value.schedules)) {
        for (let i = 0; i < value.schedules.length; i += 1) {
          const schedule = Schedule.fromJSON(value.schedules[i]);
          schedule.createdDateTime = value.created_date_time;
          schedule.publishedDateTime = value.published_date_time;
          result.push({
            schedule,
            siteId: key,
          });
        }
      }
    });
  }
  return result;
};

const processScheduleBySiteResp = resp => {
  const result = [];
  if (
    has(resp, 'data') &&
    has(resp, 'data.schedules') &&
    has(resp, 'data.site_id') &&
    Array.isArray(resp.data.schedules)
  ) {
    for (let i = 0; i < resp.data.schedules.length; i += 1) {
      result.push({
        schedule: Schedule.fromJSON(resp.data.schedules[i]),
        siteId: resp.data.site_id,
      });
    }
  }
  return result;
};

export default {
  processScheduleByCompanyResp,
  processScheduleBySiteResp,
};
