<template>
  <BaseModalCustom
    ref="sessionTimeoutModal"
    class="timeout-modal"
    :close-on-esc="false"
    :action-one="$t('navigation.logout')"
    :action-two="$t('sessionTimeout.continue')"
    :heading="$t('sessionTimeout.modalHeader')"
    size="small"
    @modal-button-clicked="modalHandler"
  >
    <p class="mb-4 mx-4 text-text-dark">
      {{ $t('sessionTimeout.modalBody') }}
    </p>
    <p class="font-medium text-base text-text-dark">
      {{ timeRemaining }}
    </p>
  </BaseModalCustom>
</template>

<script>
import BaseModalCustom from '@/components/BaseModalCustom.vue';
import constants from '@/config/constants';
import { setDocumentTitle } from '@/util/i18nUtil';

export default {
  name: 'SessionTimeoutModal',
  components: {
    BaseModalCustom,
  },
  data() {
    return {
      countdownDuration: 900, // seconds
      countdownRemaining: 0, // seconds
      countdownTimer: null,
      idle: false,
      modalOpen: false,
    };
  },
  computed: {
    timeRemaining() {
      if (this.idle || this.modalOpen) {
        const minutes = Math.floor(this.countdownRemaining / 60);
        const seconds = this.countdownRemaining - minutes * 60;
        return `${minutes}m ${seconds}s`;
      }
      return null;
    },
  },
  mounted() {
    this.$watch(
      vue => {
        return vue.$store.state.idleVue.isIdle;
      },
      val => {
        this.idle = val;
        if (val && !this.modalOpen) {
          this.startCountdown();
        }
      },
    );
  },
  methods: {
    closeModal() {
      this.modalOpen = false;
      this.$refs.sessionTimeoutModal.close();
    },
    continueSession() {
      this.stopCountdown();
      setDocumentTitle(`${this.$t('app.seegrid')} | ${this.$t('app.name')}`);
    },
    async logout() {
      await this.$store.dispatch('user/logout');
    },
    modalHandler(event) {
      if (event.action === constants.MODAL_CONFIRM) {
        this.continueSession();
      } else if (event.action === constants.MODAL_CANCEL) {
        this.logout();
      }
    },
    openModal() {
      this.modalOpen = true;
      this.$refs.sessionTimeoutModal.open();
    },
    startCountdown() {
      this.openModal();
      this.countdownRemaining = this.countdownDuration;
      this.countdownTimer = setInterval(() => {
        this.countdownRemaining -= 1;
        setDocumentTitle(`${this.$t('app.name')} ${this.timeRemaining}`);
        if (this.countdownRemaining === 0) {
          this.stopCountdown();
          setDocumentTitle(`${this.$t('app.name')} ${this.$t('sessionTimeout.timeout')}`);
          this.logout();
        }
      }, 1000);
    },
    stopCountdown() {
      if (this.countdownTimer !== null) {
        clearInterval(this.countdownTimer);
        this.countdownTimer = null;
        this.closeModal();
      }
    },
  },
};
</script>
