import { cerberusClient } from './cerberusClient';
import Routes from './cerberusRoutes';
import constants from '@/config/constants';

const createPendingSchedule = ({ companyId = '', siteId = '' }, siteSchedule) => {
  const route = Routes.general.COMPANIES_PENDING_SCHEDULE_SITE.replace(
    ':company_id',
    companyId,
  ).replace(':site_id', siteId);

  return cerberusClient().post(route, siteSchedule);
};

const getCompanies = ({ companyId = '' } = {}) => {
  const route = Routes.general.COMPANIES_COMPANY.replace(':company_id', companyId);

  return cerberusClient().get(route);
};

const getLoginMetadata = () => {
  const route = Routes.auth.LOGIN_METADATA;

  return cerberusClient(constants.CERBERUS_CLIENT_TYPES.LOGIN).get(route);
};

const getSchedule = ({ companyId = '' } = {}) => {
  const route = Routes.general.COMPANIES_SCHEDULE.replace(':company_id', companyId);

  return cerberusClient()
    .get(route)
    .catch(err => {
      // Ignore 404 as when no schedule is present API returns 404
      if (err && err.response && err.response.status !== 404) {
        throw err;
      }
    });
};

const getPendingSchedule = ({ companyId = '' } = {}) => {
  const route = Routes.general.COMPANIES_PENDING_SCHEDULE.replace(':company_id', companyId);

  return cerberusClient()
    .get(route)
    .catch(err => {
      // Ignore 404 as when no schedule is present API returns 404
      if (err && err.response && err.response.status !== 404) {
        throw err;
      }
    });
};

const getPendingScheduleBySite = ({ companyId = '', siteId = '' } = {}) => {
  const route = Routes.general.COMPANIES_PENDING_SCHEDULE_SITE.replace(
    ':company_id',
    companyId,
  ).replace(':site_id', siteId);

  return cerberusClient()
    .get(route)
    .catch(err => {
      // Ignore 404 as when no schedule is present API returns 404
      if (err && err.response && err.response.status !== 404) {
        throw err;
      }
    });
};

const getScheduleBySite = ({ companyId = '', siteId = '' } = {}) => {
  const route = Routes.general.COMPANIES_SCHEDULE_SITE.replace(':company_id', companyId).replace(
    ':site_id',
    siteId,
  );

  return cerberusClient()
    .get(route)
    .catch(err => {
      // Ignore 404 as when no schedule is present API returns 404
      if (err && err.response && err.response.status !== 404) {
        throw err;
      }
    });
};
/**
 * publish the pending schedules for the given company
 *
 * the api will publish all saved but unpublished schedules in the
 * database and create a new schedule batch
 */
const publishPendingSchedule = ({ companyId = '' }) => {
  const route = Routes.general.COMPANIES_PUBLISH_PENDING_SCHEDULE.replace(':company_id', companyId);

  return cerberusClient().post(route);
};

const setSiteState = ({ companyId = '', siteId = '', siteState = '' } = {}) => {
  const route = Routes.general.COMPANIES_SITES_SITE_STATE.replace(':company_id', companyId).replace(
    ':site_id',
    siteId,
  );

  return cerberusClient().put(route, { setup_confirmed: siteState });
};

export default {
  createPendingSchedule,
  getCompanies,
  getLoginMetadata,
  getPendingSchedule,
  getPendingScheduleBySite,
  getSchedule,
  getScheduleBySite,
  publishPendingSchedule,
  setSiteState,
};
