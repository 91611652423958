// class for representing and managing a route network station
export default class RouteNetworkStation {
  constructor(id, coordinates, name) {
    this.coordinates = coordinates;
    this.id = id;
    this.name = name;
  }

  // constructs a RouteNetworkStation from the json returned by the api
  static fromJSON(station) {
    return new RouteNetworkStation(station.id, station.coordinates, station.display_name);
  }
}
