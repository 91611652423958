const { BlobServiceClient } = require('@azure/storage-blob');

const AZURE_BLOB_STORAGE = 'https://:account.blob.core.windows.net?:sas_token';

// returns an azure blob service client for the configured account
const azureClient = async sasToken => {
  const account = process.env.VUE_APP_AZURE_ACCOUNT;
  const route = AZURE_BLOB_STORAGE.replace(':account', account).replace(':sas_token', sasToken);

  return new BlobServiceClient(route);
};

export default azureClient;
