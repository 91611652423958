const SUPPORTED_LOCALES = {
  en: 'English',
};

export default Object.freeze({
  IMPORT_POLL_INTERVAL_ROUTE_NETWORK_SECONDS: 15,
  IMPORT_POLL_INTERVAL_SECONDS: 5,
  IMPORT_POLL_RETRY: 1,
  SESSION_KEY_COMPANY_ID: 'selectedCompanyId',
  SESSION_KEY_COMPANY_PUBLISH_TIMES: 'companyPublishTimes',
  SUPPORTED_LOCALES,
  VERSION: process.env.VUE_APP_VERSION,
});
