import isBoolean from 'lodash-es/isBoolean';
import isEmpty from 'lodash-es/isEmpty';
import isNumber from 'lodash-es/isNumber';
import pickBy from 'lodash-es/pickBy';
import { cerberusClient } from './cerberusClient';
import Routes from './cerberusRoutes';
import constants from '@/config/constants';

/**
 * Add one or more roles to a given organization member
 * @param {*} companyId - id of the company
 * @param {*} userId - id of the user
 * @param {*} data - array of role ids
 */
const addOrganizationMemberRoles = (companyId, userId, data) => {
  if (!companyId || !userId || !data.length) throw new Error('missing one or more parameters');
  const route = Routes.auth.ORGS_MEMBERS_MEMBER_ROLES.replace(':company_id', companyId).replace(
    ':user_id',
    userId,
  );

  return cerberusClient().post(route, data);
};

/**
 * Add one or more users to a given organization (company)
 * @param {*} companyId - id of the company
 * @param {*} users - array of one or more user ids
 */
const addOrganizationMembers = (companyId, users) => {
  if (!companyId || !users || !users.length) throw new Error('missing one or more parameters');
  const route = Routes.auth.ORGS_MEMBERS.replace(':company_id', companyId);
  return cerberusClient().post(route, users);
};

/**
 * Get the roles of a given organization member
 * @param {*} companyId - id of the company
 * @param {*} userId - id of the user
 */
const getOrganizationMemberRoles = (companyId, userId) => {
  if (!companyId || !userId) throw new Error('missing one or more parameters');
  const route = Routes.auth.ORGS_MEMBERS_MEMBER_ROLES.replace(':company_id', companyId).replace(
    ':user_id',
    userId,
  );

  return cerberusClient().get(route);
};

const getOrganizationMembers = ({
  companyId = '', // required
  siteId = '',
  userIds = '',
  page = null,
  perPage = null,
  includeDetails = null,
  includeSites = null,
  globalAccess = false,
} = {}) => {
  if (!companyId) throw new Error('missing one or more parameters');
  const route = Routes.auth.ORGS_MEMBERS.replace(':company_id', companyId);
  const clientAccess = globalAccess
    ? constants.CERBERUS_CLIENT_TYPES.GLOBAL
    : constants.CERBERUS_CLIENT_TYPES.ORG_BASED;

  const params = pickBy(
    {
      include_details: includeDetails,
      include_roles: false, // roles should be requested in a separate call for performance reasons
      include_sites: includeSites,
      page,
      per_page: perPage,
      site_id: siteId,
      user_ids: userIds,
    },
    parameter => !isEmpty(parameter) || isNumber(parameter) || isBoolean(parameter),
  );
  return cerberusClient(clientAccess).get(route, { params });
};

/**
 * Removes one or more roles from a given organization member
 * @param {*} companyId - id of the company
 * @param {*} userId - id of the user
 * @param {*} data - array of role ids
 */
const removeOrganizationMemberRoles = (companyId, userId, data) => {
  if (!companyId || !userId || !data.length) throw new Error('missing one or more parameters');
  const route = Routes.auth.ORGS_MEMBERS_MEMBER_ROLES.replace(':company_id', companyId).replace(
    ':user_id',
    userId,
  );

  return cerberusClient().delete(route, { data });
};

/**
 * Removes one or more users from a given organization (company)
 * @param {*} companyId - id of the company
 * @param {*} users - array of one or more user ids
 */
const removeOrganizationMembers = (companyId, users) => {
  if (!companyId || !users || !users.length) throw new Error('missing one or more parameters');
  const route = Routes.auth.ORGS_MEMBERS.replace(':company_id', companyId);

  return cerberusClient().delete(route, { data: users });
};

export default {
  addOrganizationMemberRoles,
  addOrganizationMembers,
  getOrganizationMemberRoles,
  getOrganizationMembers,
  removeOrganizationMemberRoles,
  removeOrganizationMembers,
};
