<template>
  <BaseModalCustom
    ref="userDetailsModal"
    :action-one="$t('okay')"
    class="user-details-container"
    :heading="user.name"
    sub-heading=""
  >
    <section class="pl-6 text-left text-sm">
      <p>
        {{ user.email }}
      </p>
      <p class="mt-2">{{ $t('userManagement.details.status') }}: {{ userStatus }}</p>
    </section>
    <section class="pb-1.5 pt-6 px-6 text-left text-sm">
      <table class="w-full">
        <thead>
          <tr class="border-b border-c-primary-dark">
            <th class="font-medium py-3 w-6/12">
              {{ $t('userManagement.details.role') }}
            </th>
            <th class="font-medium py-3 w-6/12">
              {{ $t('userManagement.details.company') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="!loadingUserDetails">
          <tr
            v-for="userDetail in userDetails"
            :key="userDetail.id"
            class="border-b border-c-primary-dark py-3"
          >
            <td class="py-3">
              {{ userDetail.role }}
            </td>
            <td class="py-3">
              {{ userDetail.company }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="loadingUserDetails" class="mt-10 text-center">
        <Spinner :is-local="true" />
      </div>
    </section>
  </BaseModalCustom>
</template>

<script>
import BaseModalCustom from '@/components/BaseModalCustom.vue';
import Spinner from '@/components/Spinner.vue';
import constants from '@/config/constants';

export default {
  name: 'UserDetailsModal',

  components: {
    BaseModalCustom,
    Spinner,
  },

  props: {
    getAllOrganizations: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loadingUserDetails: false,
      userDetails: [],
      userStatus: '',
    };
  },

  watch: {
    user() {
      // If a Valid User with user id then only update user details
      if (this.user && this.user.id) {
        this.updateUserDetails();
      }
    },
  },

  methods: {
    // format last login date
    formatLastLoginDate(datetime) {
      const date = new Date(datetime);
      if (!(date instanceof Date) || Number.isNaN(date) || datetime === null) return datetime;
      const formatter = new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      });
      const parts = new Map(formatter.formatToParts(date).map(part => [part.type, part.value]));
      // MM/DD/YY
      return `${parts.get('month')}/${parts.get('day')}/${parts.get('year')}`;
    },
    getUserStatus(status) {
      switch (status) {
        case constants.USER_ACCOUNT_STATUS.ACTIVE:
          return !this.user.lastLogin
            ? this.$t('userManagement.state.activeQuiet')
            : this.$t('userManagement.state.active', {
                date: this.formatLastLoginDate(this.user.lastLogin),
              });
        case constants.USER_ACCOUNT_STATUS.INVITED:
          return this.$t('userManagement.state.invited');
        case constants.USER_ACCOUNT_STATUS.UNINVITED:
          return this.$t('userManagement.state.uninvited');
        case constants.USER_ACCOUNT_STATUS.UNVERIFIED:
          return this.$t('userManagement.state.unverified');
        case constants.USER_ACCOUNT_STATUS.VERIFIED:
          return this.$t('userManagement.state.verified');
        default:
          return status;
      }
    },
    open() {
      this.$refs.userDetailsModal.open();
    },
    async updateUserDetails() {
      this.loadingUserDetails = true;
      this.userStatus = this.getUserStatus(this.user.accountStatus);
      this.userDetails = [];
      const companies = await this.user.getOrganizations(this.getAllOrganizations);
      const rolesByOrganization = await this.user.getOrganizationsRoles(this.getAllOrganizations);
      let userDetails = [];
      rolesByOrganization.forEach(userRoles => {
        const companyObject = companies.find(company => {
          return company.company_id === userRoles.company_id;
        });
        userDetails = userDetails.concat(
          userRoles.roles.map(role => {
            return {
              company: companyObject.company_name,
              role: role.name,
            };
          }),
        );
      });
      this.userDetails = userDetails;
      await this.$nextTick();
      this.loadingUserDetails = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-details-container ::v-deep > .c-modal-mask > .c-modal > .c-modal__heading-container {
  text-align: left;
}
</style>
