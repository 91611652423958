import pick from 'lodash-es/pick';
import { Duration } from 'luxon';
import constants from '@/config/constants';
import Shift from '@/models/Shift';
import { getNextWeekday } from '@/util/dayUtils';
import isShiftComplete from '@/util/isShiftComplete';
import isValidTime from '@/util/isValidTime';

// class for representing and managing a shift definition
export default class Schedule {
  /**
   * creates a Schedule object
   *
   * throws an exception if the given daysOfWeek value is invalid
   */
  constructor(name, daysOfWeek, startTimeOfDay, endTimeOfDay, shifts) {
    if (!Array.isArray(daysOfWeek)) {
      throw new TypeError(`invalid daysOfWeek: ${daysOfWeek}`);
    }

    this.daysOfWeek = daysOfWeek;
    this.endTimeOfDay = endTimeOfDay;
    this.name = name;
    this.overlap = false;
    this.shifts = shifts;
    this.startTimeOfDay = startTimeOfDay;
    this.createdDateTime = null;
    this.publishedDateTime = null;
  }

  // constructs a Schedule from the JSON returned by the API
  static fromJSON(schedule) {
    return new Schedule(
      schedule.name,
      schedule.days_of_week,
      schedule.start_time_of_day,
      schedule.end_time_of_day,
      schedule.shifts.map(shift => {
        return {
          ...pick(shift, ['name', 'start_time', 'end_time']),
          overlap: false,
          timeOutsideSchedule: false,
        };
      }),
    );
  }

  /**
   * returns the day number offset based on a monday - sunday week
   *
   * throws an exception if the given day value is invalid
   */
  static getDayOffset(day) {
    let offset;

    switch (day) {
      case constants.DAYS.MONDAY:
        offset = 0;
        break;
      case constants.DAYS.TUESDAY:
        offset = 1;
        break;
      case constants.DAYS.WEDNESDAY:
        offset = 2;
        break;
      case constants.DAYS.THURSDAY:
        offset = 3;
        break;
      case constants.DAYS.FRIDAY:
        offset = 4;
        break;
      case constants.DAYS.SATURDAY:
        offset = 5;
        break;
      case constants.DAYS.SUNDAY:
        offset = 6;
        break;
      default:
        throw new TypeError(`invalid day: ${day}`);
    }

    return offset;
  }

  // return Shift time based on Schedule Start and End time
  getShiftStartTimeBasedOnSchedule(start) {
    // validate params before proceeding
    if (!start || !Duration.isDuration(start)) {
      return start;
    }
    let result;
    const tempDuration = Duration.fromObject(start.toObject());
    // This is to remove any day offset that was added before comparing to schedule time.
    const shiftRawTime = tempDuration.set({ days: 0 });
    const scheduleStart = Duration.fromObject(Schedule.parseTime(this.startTimeOfDay));
    const scheduleEnd = this.getEndTimeObjBasedOnShifts();
    if (
      shiftRawTime.as('milliseconds') < scheduleStart.as('milliseconds') &&
      shiftRawTime.plus(Duration.fromObject({ days: 1 })).as('milliseconds') <
        scheduleEnd.as('milliseconds')
    ) {
      result = start.plus(Duration.fromObject({ days: 1 }));
    }
    return result || start;
  }

  // returns a deep copy of this.shifts array
  getShifts() {
    this.shifts = this.shifts || [];
    return this.shifts.map(shift => {
      // determine the start time relative to the week
      const start = isValidTime(shift.start_time)
        ? Duration.fromObject(Schedule.parseTime(shift.start_time))
        : null;

      // determine the end time relative to the week
      let end = isValidTime(shift.end_time)
        ? Duration.fromObject(Schedule.parseTime(shift.end_time))
        : null;
      // if the shift end time is before the start time add a day to account for the day boundary
      if (start && end && end.as('milliseconds') <= start.as('milliseconds')) {
        end = end.plus(Duration.fromObject({ days: 1 }));
      }

      return new Shift(
        shift.name,
        this.daysOfWeek[0],
        start,
        end,
        shift.overlap,
        shift.timeOutsideSchedule,
      );
    });
  }

  // returns an array of individual shifts per day based on the schedule definition
  getShiftsByDay() {
    const shifts = [];

    for (let i = 0; i < this.daysOfWeek.length; i += 1) {
      const offset = Schedule.getDayOffset(this.daysOfWeek[i]);
      for (let j = 0; j < this.shifts.length; j += 1) {
        // determine the start time relative to the week
        const start = isValidTime(this.shifts[j].start_time)
          ? Duration.fromObject(Schedule.parseTime(this.shifts[j].start_time)).plus({
              days: offset,
            })
          : null;

        // determine the end time relative to the week
        let end = isValidTime(this.shifts[j].end_time)
          ? Duration.fromObject(Schedule.parseTime(this.shifts[j].end_time)).plus({
              days: offset,
            })
          : null;
        // if the shift end time is before the start time add a day to account for the day boundary
        if (start && end && end.as('milliseconds') <= start.as('milliseconds')) {
          end = end.plus(Duration.fromObject({ days: 1 }));
        }
        // Update Start time based on Schedule Start and End, This is if Shift starts into next day of Schedule
        const shiftDayStart = this.getShiftStartTimeBasedOnSchedule(start);

        const nextDay = getNextWeekday(this.daysOfWeek[i]);
        const day =
          shiftDayStart && nextDay && shiftDayStart.as('days') >= offset + 1
            ? nextDay
            : this.daysOfWeek[i];
        const shift = new Shift(
          this.shifts[j].name,
          day,
          start,
          end,
          this.shifts[j].overlap,
          this.shifts[j].timeOutsideSchedule,
        );

        shifts.push(shift);
      }
    }

    return shifts;
  }

  // returns last updated time based on createdDateTime and publishedDateTime
  getLastUpdatedTime() {
    return this.publishedDateTime || this.createdDateTime;
  }

  // returns true if this shift definition has the values required to build shifts or false otherwise
  isComplete() {
    return (
      this.daysOfWeek.length > 0 &&
      isValidTime(this.endTimeOfDay) &&
      isValidTime(this.startTimeOfDay)
    );
  }

  // returns true if this shift definition is valid or false otherwise
  isValid() {
    return this.name.length > 0 && this.isComplete() && this.areShiftsValid();
  }

  /**
   * returns an object based on the parsed given time string
   *
   * throws an exception if the given time value is invalid
   */
  static parseTime(time) {
    if (!isValidTime(time)) {
      throw new Error(`invalid time: ${time}`);
    }

    const split = time.split(':');

    return {
      hours: parseInt(split[0], 10),
      minutes: parseInt(split[1], 10),
      seconds: parseInt(split[2], 10),
    };
  }

  // returns a JSON representation of this Schedule as expected by the API
  toJSON() {
    return {
      days_of_week: this.daysOfWeek,
      end_time_of_day: this.endTimeOfDay,
      name: this.name,
      shifts: this.shifts,
      start_time_of_day: this.startTimeOfDay,
    };
  }

  // return end time based on shifts, as end time can be next day based on shifts
  getEndTimeObjBasedOnShifts() {
    const scheduleStart = Duration.fromObject(Schedule.parseTime(this.startTimeOfDay));
    let scheduleEnd = Duration.fromObject(Schedule.parseTime(this.endTimeOfDay));
    const { totalTimeInShifts, crossedDayBoundary } = this.getTotalTimeInShiftsAndDayBoundary();
    // Below if is used to determine if Schedule end time is next day or not based on shifts
    // If Schedule End time is is less than start then it is possibly in next day.
    // Or Total time of Shifts is greater than the Schedule time and atleast one Shift crosses day boundary
    if (
      scheduleEnd.toMillis() <= scheduleStart.toMillis() ||
      (crossedDayBoundary && scheduleEnd.toMillis() - scheduleStart.toMillis() < totalTimeInShifts)
    ) {
      scheduleEnd = scheduleEnd.plus({
        days: 1,
      });
    }
    return scheduleEnd;
  }

  // return sum of time in shifts
  getTotalTimeInShiftsAndDayBoundary() {
    // calculate total number of milliseconds by adding time of shifts
    let totalTimeInShifts = 0;
    let crossedDayBoundary = false;
    this.shifts.forEach(shift => {
      if (isShiftComplete(shift)) {
        const shiftStart = Duration.fromObject(Schedule.parseTime(shift.start_time));
        let shiftEnd = Duration.fromObject(Schedule.parseTime(shift.end_time));
        if (shiftEnd < shiftStart) {
          shiftEnd = shiftEnd.plus(Duration.fromObject({ days: 1 }));
          crossedDayBoundary = true;
        }
        const shiftLengthMs = shiftEnd.toMillis() - shiftStart.toMillis();
        totalTimeInShifts += shiftLengthMs;
      }
    });
    return { crossedDayBoundary, totalTimeInShifts };
  }

  getSchedulesByDay() {
    if (!this.isComplete()) {
      return null;
    }
    // Compare each day of current schedule, with each day of remaining schedules
    return this.daysOfWeek.map(day => {
      const offset = Schedule.getDayOffset(day);
      const startTime = Duration.fromObject(Schedule.parseTime(this.startTimeOfDay)).plus(
        Duration.fromObject({ days: offset }),
      );
      let endTime = this.getEndTimeObjBasedOnShifts();
      endTime = endTime.plus(Duration.fromObject({ days: offset }));
      return {
        day,
        endTime,
        name: this.name,
        startTime,
      };
    });
  }

  areShiftsValid() {
    const invalidShifts = this.shifts.filter(
      shift => !isShiftComplete(shift) || shift.overlap || shift.timeOutsideSchedule,
    );
    return invalidShifts.length === 0;
  }
}
