import has from 'lodash-es/has';
import azureAPI from '@/api/azureAPI';
import cerberusRouteNetworkAPI from '@/api/cerberusRouteNetworkAPI';
import config from '@/config/config';
import constants from '@/config/constants';
import RouteNetworkImport from '@/models/RouteNetworkImport';

/**
 * store the data for supervisor backup import states based on process type
 * and status
 */
const MAP_STATE_DATA = {
  [constants.SUPERVISOR_BACKUP_EXTRACTION_TYPES.EXTRACT_MAP_IMAGE]: {
    [constants.IMPORT_STATUS.COMPLETE]: {
      button: {
        disabled: false,
        text: 'view',
      },
      message: {
        style: 'negative',
        text: 'sites.sections.map.statusMapComplete',
      },
      title: {
        style: '',
      },
    },
    [constants.IMPORT_STATUS.ERROR]: {
      button: {
        disabled: false,
        text: 'view',
      },
      message: {
        style: 'negative',
        text: 'sites.sections.map.errorUploading',
      },
      title: {
        style: 'negative',
      },
    },
    [constants.IMPORT_STATUS.PENDING]: {
      button: {
        disabled: true,
        text: 'view',
      },
      message: {
        style: 'warning',
        text: 'sites.sections.map.statusProcessing',
      },
      title: {
        style: '',
      },
    },
    [constants.IMPORT_STATUS.PROCESSING]: {
      button: {
        disabled: true,
        text: 'view',
      },
      message: {
        style: 'warning',
        text: 'sites.sections.map.statusProcessing',
      },
      title: {
        style: '',
      },
    },
  },
  [constants.SUPERVISOR_BACKUP_EXTRACTION_TYPES.EXTRACT_ROUTE_NETWORK]: {
    [constants.IMPORT_STATUS.COMPLETE]: {
      button: {
        disabled: false,
        text: 'view',
      },
      message: {
        style: '',
        text: 'sites.sections.map.statusRouteNetworkComplete',
      },
      title: {
        style: '',
      },
    },
    [constants.IMPORT_STATUS.ERROR]: {
      button: {
        disabled: false,
        text: 'view',
      },
      message: {
        style: 'negative',
        text: 'sites.sections.map.errorProcessing',
      },
      title: {
        style: 'negative',
      },
    },
    [constants.IMPORT_STATUS.PENDING]: {
      button: {
        disabled: true,
        text: 'view',
      },
      message: {
        style: 'warning',
        text: 'sites.sections.map.statusProcessing',
      },
      title: {
        style: '',
      },
    },
    [constants.IMPORT_STATUS.PROCESSING]: {
      button: {
        disabled: true,
        text: 'view',
      },
      message: {
        style: 'warning',
        text: 'sites.sections.map.statusProcessing',
      },
      title: {
        style: '',
      },
    },
  },
};

/**
 * class for representing and managing a route network import based on a
 * supervisor backup
 */
export default class SupervisorBackupImport extends RouteNetworkImport {
  constructor(companyId, siteId, file) {
    super(companyId, siteId);

    this.extractionType = null;
    this.file = file;
    this.filePath = null;
  }

  // initialize a supervisor backup
  async createSupervisorBackup() {
    if (!this.companyId || !this.siteId) {
      throw new Error('companyId and siteId are required to create a supervisor backup');
    }

    const response = await cerberusRouteNetworkAPI.createSupervisorBackup(
      this.companyId,
      this.siteId,
    );

    if (!has(response, 'data')) {
      throw new Error(`invalid createSupervisorBackup response: ${response}`);
    }

    this.id = response.data.id;
    this.filePath = response.data.file_path;
  }

  // delete the current or given supervisor backup
  async deleteRouteNetworkImport(backupId) {
    if (!backupId && !this.id) {
      throw new Error('a backup id is required to delete a supervisor backup import');
    }

    await cerberusRouteNetworkAPI.deleteSupervisorBackup(backupId || this.id);
  }

  // constructs a SupervisorBackupImport from the json returned by the api
  static fromJSON(backup) {
    // create the base object, the file is only used by the upload process
    const supervisorBackupImport = new SupervisorBackupImport(
      backup.company_id,
      backup.site_id,
      null,
    );

    // set the processing status data
    supervisorBackupImport.extractionType = backup.action;
    supervisorBackupImport.id = backup.supervisor_backup_id;
    if (Array.isArray(backup.states) && backup.states.length > 0) {
      // the most recent status is stored in the last element
      supervisorBackupImport.status = backup.states[backup.states.length - 1].state;
      supervisorBackupImport.timestamp = backup.states[backup.states.length - 1].timestamp;
    }

    return supervisorBackupImport;
  }

  // returns the status poll interval in seconds based on the extraction type
  getPollInterval() {
    if (
      this.extractionType === constants.SUPERVISOR_BACKUP_EXTRACTION_TYPES.EXTRACT_ROUTE_NETWORK
    ) {
      return config.IMPORT_POLL_INTERVAL_ROUTE_NETWORK_SECONDS;
    }

    return super.getPollInterval();
  }

  // get the status data for the current extraction type and status
  getStatusData() {
    if (
      has(MAP_STATE_DATA, this.extractionType) &&
      has(MAP_STATE_DATA[this.extractionType], this.status)
    ) {
      return MAP_STATE_DATA[this.extractionType][this.status];
    }

    return {};
  }

  /**
   * get a sas token for the purposes of uploading a supervisor backup file to
   * azure blob storage
   */
  async getSupervisorBackupUploadToken() {
    if (!this.id) {
      throw new Error('a backup id is required to get an upload token');
    }

    const response = await cerberusRouteNetworkAPI.getSupervisorBackupUploadToken(this.id);

    if (!has(response, 'data')) {
      throw new Error(`invalid getSupervisorBackupUploadToken response: ${response}`);
    }

    return response.data.upload_token;
  }

  // get all supervisor backup imports for the current company
  async getRouteNetworkImports() {
    if (!this.companyId) {
      throw new Error('companyId is required to get supervisor backup imports');
    }

    const parameters = {
      companyId: this.companyId,
      siteId: this.siteId,
    };

    const response = await cerberusRouteNetworkAPI.getSupervisorBackupImports(parameters);

    if (!has(response, 'data') || !Array.isArray(response.data)) {
      throw new Error(`invalid getSupervisorBackupImports response: ${response}`);
    }

    // sort the response data
    SupervisorBackupImport.sortBackupData(response.data);

    // find the most recent backup for each site
    const indices = {};
    for (let i = 0; i < response.data.length; i += 1) {
      indices[response.data[i].site_id] = i;
    }

    // create the route network imports
    const routeNetworkImports = {};
    Object.entries(indices).forEach(([key, value]) => {
      routeNetworkImports[key] = SupervisorBackupImport.fromJSON(response.data[value]);
    });

    return routeNetworkImports;
  }

  /**
   * get the supervisor backup import for the current or given site with the
   * optional states and extraction types
   */
  async getSiteRouteNetworkImport(backupId, states, types) {
    if (!backupId && !this.id) {
      throw new Error('a backup id is required to get a site supervisor import');
    }

    const response = await cerberusRouteNetworkAPI.getSupervisorBackupImportStatus({
      backupId: backupId || this.id,
      states: Array.isArray(states) ? states.join(',') : '',
      types: Array.isArray(types) ? types.join(',') : '',
    });

    if (!has(response, 'data') || response.data.length === 0) {
      throw new Error(`invalid getSupervisorBackupImportStatus response: ${response}`);
    }

    // sort the response data
    SupervisorBackupImport.sortBackupData(response.data);

    // the last element is the most recent status
    const routeNetworkImport = response.data.pop();

    return SupervisorBackupImport.fromJSON(routeNetworkImport);
  }

  /**
   * returns true if the route network import state has the given type and status
   * or false otherwise
   */
  hasRouteNetworkImportState(type, status) {
    if (this.status === status && this.extractionType === type) {
      return true;
    }

    return false;
  }

  // returns true if extracting the route network image failed or false otherwise
  hasExtractMapImageError() {
    return this.hasRouteNetworkImportState(
      constants.SUPERVISOR_BACKUP_EXTRACTION_TYPES.EXTRACT_MAP_IMAGE,
      constants.IMPORT_STATUS.ERROR,
    );
  }

  // returns true if extracting the route network data failed or false otherwise
  hasExtractRouteNetworkError() {
    return this.hasRouteNetworkImportState(
      constants.SUPERVISOR_BACKUP_EXTRACTION_TYPES.EXTRACT_ROUTE_NETWORK,
      constants.IMPORT_STATUS.ERROR,
    );
  }

  // process the given extraction type of a route network based on a supervisor backup
  async importRouteNetwork(type) {
    switch (type) {
      case constants.SUPERVISOR_BACKUP_EXTRACTION_TYPES.EXTRACT_MAP_IMAGE:
        await this.processRouteNetworkImage();
        break;
      case constants.SUPERVISOR_BACKUP_EXTRACTION_TYPES.EXTRACT_ROUTE_NETWORK:
        await this.processSupervisorBackup(type);
        break;
      default:
        throw new Error(`invalid route network extraction type: ${type}`);
    }
  }

  /**
   * returns true if extracting the route network image is complete
   * or false otherwise
   */
  isExtractMapImageComplete() {
    return this.hasRouteNetworkImportState(
      constants.SUPERVISOR_BACKUP_EXTRACTION_TYPES.EXTRACT_MAP_IMAGE,
      constants.IMPORT_STATUS.COMPLETE,
    );
  }

  /**
   * returns true if extracting the route network data is complete
   * or false otherwise
   */
  isExtractRouteNetworkComplete() {
    return this.hasRouteNetworkImportState(
      constants.SUPERVISOR_BACKUP_EXTRACTION_TYPES.EXTRACT_ROUTE_NETWORK,
      constants.IMPORT_STATUS.COMPLETE,
    );
  }

  // initialize, upload, and process a route network based on a supervisor backup
  async processRouteNetworkImage() {
    // create the backup
    await this.createSupervisorBackup();

    // get an upload token
    const token = await this.getSupervisorBackupUploadToken();
    // upload the backup
    await this.uploadSupervisorBackup(token);

    // extract the map image for verification
    await this.processSupervisorBackup(
      constants.SUPERVISOR_BACKUP_EXTRACTION_TYPES.EXTRACT_MAP_IMAGE,
    );
  }

  // process a supervisor backup using the given extraction type
  processSupervisorBackup(type) {
    if (!this.id || !Object.values(constants.SUPERVISOR_BACKUP_EXTRACTION_TYPES).includes(type)) {
      throw new Error(
        'a backup id and a valid extraction type are required to process a supervisor backup',
      );
    }

    return cerberusRouteNetworkAPI.processSupervisorBackup(this.id, type);
  }

  // sort the given response data by state timestamp ascending
  static sortBackupData(data) {
    data.sort((backup1, backup2) => {
      if (
        !Array.isArray(backup1.states) ||
        backup1.states.length === 0 ||
        !Array.isArray(backup2.states) ||
        backup2.states.length === 0
      ) {
        return 0;
      }

      // sort by the most recent status
      return (
        backup1.states[backup1.states.length - 1].timestamp -
        backup2.states[backup2.states.length - 1].timestamp
      );
    });
  }

  // upload the supervisor backup using the given token
  uploadSupervisorBackup(token) {
    if (!(this.file instanceof File) || !this.filePath || !token) {
      throw new Error(
        'token, filePath, and a valid file are required to upload a supervisor backup',
      );
    }

    return azureAPI.uploadFile(this.file, this.filePath, token);
  }
}
