import azureClient from '@/api/azureClient';

const container = process.env.VUE_APP_AZURE_CONTAINER;

/**
 * upload the given file to the given path in azure blob storage using the
 * given sas token
 *
 * based on:
 * https://docs.microsoft.com/en-us/azure/developer/javascript/tutorial/browser-file-upload-azure-storage-blob#upload-file-to-azure-storage-blob-with-azure-sdk-client-library
 */
const uploadFile = async (file, filePath, sasToken) => {
  const blobServiceClient = await azureClient(sasToken);

  const containerClient = blobServiceClient.getContainerClient(container);
  const blockBlobClient = containerClient.getBlockBlobClient(filePath);

  const options = {
    blobHTTPHeaders: { blobContentType: file.type },
    metadata: { filename: file.name },
  };

  return blockBlobClient.uploadData(file, options);
};

export default {
  uploadFile,
};
