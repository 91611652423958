<template>
  <nav class="flex flex-wrap h-12 items-center justify-between">
    <div class="border-opacity-25 border-r border-white flex h-full">
      <button
        id="MenuButton"
        class="items-center menu-item-link px-3 py-2 rounded text-white"
        @click="onToggleMenu()"
      >
        <BaseIcon :height="'24'" :icon-sprite="iconSprite" :name="menuIcon" :width="'24'" />
      </button>
    </div>

    <ul
      id="Menu"
      class="-ml-64 absolute bg-primary-dark border-opacity-25 border-t border-white duration-400 flex flex-col max-h-screen mt-12 opacity-0 text-white top-0 transition-all w-64 z-500"
      :class="{ 'menu-open': showMenu }"
    >
      <router-link
        class="cursor-pointer flex items-center menu-item-link pl-4 py-6 relative"
        :exact="true"
        :tag="'li'"
        :to="{ name: 'dashboard' }"
      >
        <div class="w-10">
          <BaseIcon
            :height="'20'"
            :icon-sprite="iconSprite"
            :name="'fg-icon-pie-chart'"
            :view-box="'0 0 16 16'"
            :width="'20'"
          />
        </div>
        {{ $t('navigation.goTo') }} {{ $t('navigation.dashboard') }}
      </router-link>

      <li
        class="border-opacity-25
          border-t
          border-white
          flex
          items-center
          pb-4
          pl-4
          pt-6"
      >
        <div class="w-10">
          <BaseIcon
            :height="'20'"
            :icon-sprite="iconSprite"
            :name="'fg-icon-settings'"
            :width="'20'"
          />
        </div>
        <span>{{ $t('navigation.settings') }}</span>
      </li>

      <!-- <li
        class="cursor-pointer menu-item-link pl-14 py-4 relative text-gray-5"
        @click="onNavigate({ name: 'sites' })"
      >
        {{ $t('navigation.accountMgmnt') }}
      </li> -->

      <li
        v-for="navigation in navigationMain"
        :key="navigation.route"
        class="cursor-pointer menu-item-link pl-14 py-4 relative text-gray-5"
        @click="onNavigate({ name: navigation.route })"
      >
        {{ $t(`navigation.${navigation.name}`) }}
      </li>

      <li
        class="border-opacity-25 border-t border-white cursor-pointer flex items-center mb-auto menu-item-link pl-4 py-6 relative"
        @click="onLogout()"
      >
        <div class="w-10">
          <BaseIcon :height="'20'" :icon-sprite="iconSprite" :name="'fg-icon-exit'" :width="'20'" />
        </div>
        {{ $t('navigation.logout') }}
      </li>

      <li class="border-opacity-25 border-t border-white mt-8 p-4">
        <Version />
      </li>
    </ul>

    <ul id="NavigationMain" class="flex h-full leading-4 mr-auto text-sm text-white">
      <router-link
        v-for="(navigation, index) in navigationMain"
        :key="navigation.route"
        :class="navigationMainStyle(index)"
        :exact="true"
        :tag="'li'"
        :to="{ name: navigation.route }"
        class="border-opacity-25 border-r border-white cursor-pointer hidden items-center px-4 py-2 relative"
      >
        {{ $t(`navigation.${navigation.name}`) }}
      </router-link>
    </ul>

    <div class="flex items-center ml-auto px-4">
      <div class="max-w-3xs overflow-ellipsis overflow-hidden text-white whitespace-nowrap">
        <span class="text-white text-xs">{{ userName }}</span>
      </div>

      <div v-if="displayCompanySelect" id="NavigationCompany" class="ml-8 text-white">
        <span v-if="companies.length === 1" class="text-xs">{{ companies[0].name }}</span>
        <template v-else>
          <BaseSelect
            v-if="!disableCompanySelect"
            class="max-w-3xs"
            :hidden="true"
            :label="$t('navigation.customers')"
            :options="companyOptions"
            @select-updated="onCompanyChange"
          />
          <Spinner2 v-else :label="$t('companies.loading')" />
        </template>
      </div>
    </div>
  </nav>
</template>

<script>
import { BaseIcon, BaseSelect } from '@seegrid/components';
import IconSprite from '@/assets/images/icon-sprite.svg';
import Version from '@/components/Version.vue';
import Spinner2 from '@/components/controls/Spinner2.vue';
import authorization from '@/mixins/authorization';

export default {
  name: 'TheNavigation',

  components: {
    BaseIcon,
    BaseSelect,
    Spinner2,
    Version,
  },

  mixins: [authorization],

  props: {
    companyChangeGuard: {
      required: true,
      type: Boolean,
    },
    companyRedirectRoute: {
      required: true,
      type: String,
    },
    hideCompanySelect: {
      required: true,
      type: Boolean,
    },
  },

  data: () => {
    return {
      showMenu: false,
    };
  },

  computed: {
    // returns the companies the user has access to
    companies() {
      return this.$store.getters['companies/getCompanies'];
    },

    // returns the companies as select options
    companyOptions() {
      // map to option objects
      return this.companies.map(company => {
        const option = {
          selected: this.selectedCompany === company.id,
          text: company.name,
          value: company.id,
        };

        return option;
      });
    },

    // returns true if company change is prohibited momentarily
    disableCompanySelect() {
      return this.$store.getters['companies/getLocked'];
    },

    // returns true if the company select should be displayed or false otherwise
    displayCompanySelect() {
      /**
       * the order in which companies and selectedCompany are populated is not
       * guaranteed so ensure both are set before displaying
       */
      return !this.hideCompanySelect && this.companies.length > 0 && this.selectedCompany;
    },

    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },

    // returns the menu button icon based on the current menu state
    menuIcon() {
      return this.showMenu ? 'fg-icon-close' : 'fg-icon-menu';
    },

    // returns the route data for the main navigation links
    navigationMain() {
      return [
        {
          name: 'accountMgmnt',
          route: 'sites',
        },
        {
          name: 'sprConfig',
          route: 'sprConfigList',
        },
      ];
    },

    // returns the selected company id
    selectedCompany() {
      return this.$store.getters['companies/getCompanySelectId'];
    },

    // returns the currently logged in user name
    userName() {
      return (
        this.$store.getters['user/getUserGivenName'] || this.$store.getters['user/getUserEmail']
      );
    },
  },

  beforeDestroy() {
    // remove event listener for hiding the menu
    document.removeEventListener('mousedown', this.onHideMenu);
  },

  mounted() {
    // event listener for hiding the menu
    document.addEventListener('mousedown', this.onHideMenu);
  },

  methods: {
    async cancelChangeCompany() {
      const selectedCompany = this.$store.getters['companies/getSelectedCompanyId'];
      await this.$store.dispatch('companies/setCompanySelect', selectedCompany);
    },
    async changeCompany() {
      let navigationError = false;
      try {
        await this.$store.dispatch('companies/setSelectedCompany', this.selectedCompany);
        await this.$nextTick();
        await this.$router.push({ name: this.companyRedirectRoute });
      } catch (error) {
        // ignore error thrown when navigating to the same page
        if (error.name !== 'NavigationDuplicated') {
          navigationError = true;
        }
      } finally {
        /**
         * if the navigation fails try refresh
         * to handle the failure
         */
        if (navigationError) {
          await this.$router.go();
        }
      }
    },
    /**
     * returns a css class object with the appropriate main navigation display
     * classes based on the navigation section
     */
    navigationMainStyle(index) {
      return {
        // display the rest of the links only for medium resolutions
        'md:flex': index > 0,
        // display the account management link for all resolutions except small
        'sm:flex': index === 0,
      };
    },

    // event handler for setting the selected company
    async onCompanyChange(companyId) {
      await this.$store.dispatch('companies/setCompanySelect', companyId);
      if (this.companyChangeGuard) {
        this.$emit('company-change-initiated');
      } else {
        await this.$nextTick();
        await this.changeCompany();
      }
    },

    // event handler for hiding the dropdown menu when it loses focus
    onHideMenu(event) {
      /**
       * if the clicked button is not the primary button
       * or the click target is the menu button or a menu link
       * do nothing and / or defer to the onNavigate / toggle event handler
       */
      if (event.button !== 0 || event.target.closest('.menu-item-link')) {
        return;
      }

      this.showMenu = false;
    },

    // event handler for logging the user out
    async onLogout() {
      await this.$store.dispatch('user/logout');
    },

    // event handler for handling the menu navigation
    async onNavigate(routeObject) {
      try {
        await this.$router.push(routeObject);
      } catch (error) {
        // if navigating to the same page just close the menu
        if (error.name === 'NavigationDuplicated') {
          this.showMenu = false;
        }
      }
    },

    // event handler for toggling the menu
    onToggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/assets/css/navigation.scss';
</style>
