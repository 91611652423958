import Vue from 'vue';
import Vuex from 'vuex';
import companies from '@/store/modules/companies';
import message from '@/store/modules/message';
import roles from '@/store/modules/roles';
import routeNetworkImports from '@/store/modules/routeNetworkImports';
import routeNetworks from '@/store/modules/routeNetworks';
import schedules from '@/store/modules/schedules';
import sites from '@/store/modules/sites';
import sprs from '@/store/modules/sprs';
import user from '@/store/modules/user';
import users from '@/store/modules/users';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    companies,
    message,
    roles,
    routeNetworkImports,
    routeNetworks,
    schedules,
    sites,
    sprs,
    user,
    users,
  },
});
