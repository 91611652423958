<template>
  <SiteSettings
    :button-show="buttonShow"
    :button-text="buttonText"
    :status-text="statusTextCompanyUser"
    :status-text-style-type="statusTextStyle"
    :status-text-two="statusTextSeegridUser"
    :title="$t('sites.sections.users.title')"
    @button-click="onClick"
  />
</template>

<script>
import SiteSettings from '@/components/SiteSettings.vue';
import authorization from '@/mixins/authorization';

export default {
  name: 'SiteSettingsUsers',

  components: {
    SiteSettings,
  },

  mixins: [authorization],

  props: {
    siteId: {
      required: true,
      type: String,
    },
  },

  computed: {
    // returns true if the button should be shown or false otherwise
    buttonShow() {
      return this.hasFeatureControl(this.FEATURES.USER, this.CONTROL_LEVELS.VIEWER);
    },

    // set the button text
    buttonText() {
      return this.$t('view');
    },

    // returns the company users for the site
    companyUsers() {
      return this.$store.getters['users/filterSeegridMembers'](this.siteUsers);
    },

    // returns the seegrid users for the site
    seegridUsers() {
      return this.$store.getters['users/findSeegridMembers'](this.siteUsers);
    },

    // returns the users for the site
    siteUsers() {
      return this.$store.getters['users/getOrgMembers'].filter(u => u.hasSiteAccess(this.siteId));
    },

    // set the status text based on the number of users
    statusTextCompanyUser() {
      const company = this.$store.getters['companies/getSelectedCompany'];
      return this.$t('sites.sections.users.users', {
        company: !company ? '' : company.name,
        number: this.companyUsers.length,
      });
    },

    // set the status text based on the number of users
    statusTextSeegridUser() {
      return this.$t('sites.sections.users.seegridUsers', { number: this.seegridUsers.length });
    },

    // returns the appropriate title text style type
    statusTextStyle() {
      if (this.companyUsers.length === 0) {
        return 'negative';
      }

      return '';
    },
  },

  methods: {
    // event handler for the buttonClick event
    async onClick() {
      // set the site
      await this.$store.dispatch('sites/setSelectedSite', this.siteId);

      // redirect to the users page
      await this.$router.push({ name: 'users' });
    },
  },
};
</script>
