<template>
  <div class="bg-gray-1 flex p-2">
    <div
      v-if="messageType === 'warning'"
      class="bg-status-warning flex float-left h-10 items-center rounded"
    >
      <BaseIcon class="icon w-12" name="alert" width="20px" height="20px" viewBox="0 0 24 24" />
    </div>
    <BaseIcon
      v-else-if="messageType === 'info'"
      class="float-left icon min-w-min"
      name="fg-info-2"
      height="20px"
      :icon-sprite="iconSprite"
      width="20px"
      viewBox="0 0 24 24"
    />
    <slot />
  </div>
</template>

<script>
import { BaseIcon } from '@seegrid/components';
import IconSprite from '@/assets/images/icon-sprite.svg';

export default {
  name: 'BaseLocalMessage',

  components: {
    BaseIcon,
  },

  props: {
    messageType: {
      default: 'info',
      type: String,
      validator: value => {
        // The value must match one of these strings
        return ['info', 'warning'].indexOf(value) !== -1;
      },
    },
  },

  computed: {
    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },
  },

  methods: {},
};
</script>
