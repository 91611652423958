import isEmpty from 'lodash-es/isEmpty';
import pickBy from 'lodash-es/pickBy';
import { cerberusClient } from './cerberusClient';
import Routes from './cerberusRoutes';

// initialize a supervisor backup for the given company and site
const createSupervisorBackup = (companyId, siteId) => {
  const route = Routes.general.SUPERVISOR_BACKUPS;

  const params = {
    company_id: companyId,
    site_id: siteId,
  };

  return cerberusClient().post(route, null, { params });
};

// delete the given supervisor backup and the associated route networks
const deleteSupervisorBackup = backupId => {
  const route = Routes.general.SUPERVISOR_BACKUPS_DELETE.replace(':backup_id', backupId);

  return cerberusClient().delete(route);
};

// get the route network images for the given backup id or company and optional site
const getRouteNetworkImages = ({ backupId = '', companyId = '', siteId = '' } = {}) => {
  const route = Routes.general.ROUTE_NETWORK_IMAGES;

  const params = pickBy(
    { backup_id: backupId, company_id: companyId, site_id: siteId },
    parameter => !isEmpty(parameter),
  );

  return cerberusClient().get(route, { params });
};

// get the route network segments for the given backup id or company and optional site
const getRouteNetworkSegments = ({ backupId = '', companyId = '', siteId = '' } = {}) => {
  const route = Routes.general.ROUTE_NETWORK_SEGMENTS;

  const params = pickBy(
    { backup_id: backupId, company_id: companyId, site_id: siteId },
    parameter => !isEmpty(parameter),
  );

  return cerberusClient().get(route, { params });
};

// get the route network stations for the given backup id or company and optional site
const getRouteNetworkStations = ({ backupId = '', companyId = '', siteId = '' } = {}) => {
  const route = Routes.general.ROUTE_NETWORK_STATIONS;

  const params = pickBy(
    { backup_id: backupId, company_id: companyId, site_id: siteId },
    parameter => !isEmpty(parameter),
  );

  return cerberusClient().get(route, { params });
};

/**
 * get the supervisor backup status for the given backup filtered by the
 * optional comma separated extraction actions and states
 */
const getSupervisorBackupImportStatus = ({ backupId = '', actions = '', states = '' } = {}) => {
  const route = Routes.general.SUPERVISOR_BACKUPS_EXTRACTIONS.replace(':backup_id', backupId);

  const params = pickBy({ actions, states }, parameter => !isEmpty(parameter));

  return cerberusClient().get(route, { params });
};

// get the supervisor backup imports for the given company and optional site
const getSupervisorBackupImports = ({ companyId = '', siteId = '' } = {}) => {
  const route = Routes.general.SUPERVISOR_EXTRACTIONS;

  const params = pickBy(
    { company_id: companyId, site_id: siteId },
    parameter => !isEmpty(parameter),
  );

  return cerberusClient().get(route, { params });
};

// get a sas token for the purposes of uploading a supervisor backup to blob storage
const getSupervisorBackupUploadToken = backupId => {
  const route = Routes.general.SUPERVISOR_BACKUPS_UPLOAD_TOKEN.replace(':backup_id', backupId);

  const params = { backup_id: backupId };

  return cerberusClient().get(route, { params });
};

// process the given supervisor backup import with the given extraction action
const processSupervisorBackup = (backupId, action) => {
  const route = Routes.general.SUPERVISOR_BACKUPS_EXTRACTIONS.replace(':backup_id', backupId);

  const params = {
    action,
  };

  return cerberusClient().post(route, null, { params });
};

export default {
  createSupervisorBackup,
  deleteSupervisorBackup,
  getRouteNetworkImages,
  getRouteNetworkSegments,
  getRouteNetworkStations,
  getSupervisorBackupImportStatus,
  getSupervisorBackupImports,
  getSupervisorBackupUploadToken,
  processSupervisorBackup,
};
