// A class representing a site belonging to a Company
export default class Site {
  constructor(id, name, setupConfirmed, timezone) {
    this.display = false;
    this.id = id;
    this.name = name;
    this.setupConfirmed = setupConfirmed;
    this.timezone = timezone;
  }

  // as provided from the api
  static fromJSON(siteJSON) {
    return new Site(
      siteJSON.site_id,
      siteJSON.site_name,
      siteJSON.state.setup_confirmed,
      siteJSON.local_timezone,
    );
  }

  // as expected by the api
  toJSON() {
    return {
      local_timezone: this.timezone,
      site_id: this.id,
      site_name: this.name,
    };
  }
}
