<template>
  <div
    v-if="showStatusOverlay"
    class="bg-white flex flex-col items-center mb-4 rounded-md shadow-lg status-button-modal w-72"
  >
    <div class="bg-primary-dark h-10 rounded-t-md w-full">
      <p class="ml-3 mt-2 text-l text-white">{{ $t('routeNetworkStatusOverlay.overlayTitle') }}</p>
      <div class="absolute cursor-pointer right-0 top-0" @click="closeModalClicked">
        <BaseIcon
          name="fg-icon-close-white"
          :height="'26px'"
          class="mr-0.5 mt-2"
          :icon-sprite="iconSprite"
          :width="'26px'"
        ></BaseIcon>
      </div>
    </div>
    <div class="flex flex-col m-5 px-8 w-full">
      <div
        v-for="(site, index) in routeNetworkImports"
        :key="index"
        :class="{
          'bottom-border': index !== routeNetworkImports.length - 1,
          'mb-2': index !== routeNetworkImports.length - 1,
        }"
        class="flex justify-between"
      >
        <p class="text-l">{{ getSiteNameFromId(site) }}</p>
        <p class="mt-1 text-gray-6 text-xs">
          {{ getRouteNetworkStatusForSite(site).message }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseIcon } from '@seegrid/components';
import IconSprite from '@/assets/images/icon-sprite.svg';
import constants from '@/config/constants';

class RouteNetworkStatus {
  constructor(status, message) {
    this.status = status;
    this.message = message;
  }
}

export default {
  name: 'RouteNetworkOverlay',
  components: {
    BaseIcon,
  },
  data() {
    return {
      showStatusOverlay: false,
    };
  },
  computed: {
    iconSprite() {
      return IconSprite;
    },
    routeNetworkImports() {
      const allImports = this.$store.getters['routeNetworkImports/getRouteNetworkImports'];
      return Object.values(allImports).filter(
        site => this.getRouteNetworkStatusForSite(site).status !== constants.IMPORT_STATUS.COMPLETE,
      );
    },
  },
  watch: {
    routeNetworkImports() {
      this.checkShouldDisplay();
    },
  },
  mounted() {
    this.checkShouldDisplay();
  },
  methods: {
    checkShouldDisplay() {
      this.showStatusOverlay = this.routeNetworkImports.length > 0;
    },
    closeModalClicked() {
      this.showStatusOverlay = false;
    },
    getRouteNetworkStatusForSite(site) {
      const routeNetworkStatusText = this.$t(site.getStatusData().message.text);

      // Check if everything is done
      if (
        this.$store.getters['routeNetworkImports/hasRouteNetworkImportState'](
          site.siteId,
          constants.SUPERVISOR_BACKUP_EXTRACTION_TYPES.EXTRACT_ROUTE_NETWORK,
          constants.IMPORT_STATUS.COMPLETE,
        )
      ) {
        return new RouteNetworkStatus(site.status, routeNetworkStatusText);
      }

      // Check if uploaded map needs to be processed, if so: mark as still processing so the button is orange
      if (
        this.$store.getters['routeNetworkImports/hasRouteNetworkImportState'](
          site.siteId,
          constants.SUPERVISOR_BACKUP_EXTRACTION_TYPES.EXTRACT_MAP_IMAGE,
          constants.IMPORT_STATUS.COMPLETE,
        )
      ) {
        return new RouteNetworkStatus(constants.IMPORT_STATUS.PROCESSING, routeNetworkStatusText);
      }

      // Default to processing if we're not in an error state
      if (site.status !== constants.IMPORT_STATUS.ERROR) {
        return new RouteNetworkStatus(constants.IMPORT_STATUS.PROCESSING, routeNetworkStatusText);
      }

      return new RouteNetworkStatus(constants.IMPORT_STATUS.ERROR, routeNetworkStatusText);
    },
    getSiteNameFromId(site) {
      const companySites = this.$store.getters['companies/getCompanySites'](site.companyId);
      const foundSite = companySites.find(searchSite => searchSite.id === site.siteId);
      if (foundSite) {
        return foundSite.name;
      }
      return site.siteId;
    },
  },
};
</script>
