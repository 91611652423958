<template>
  <BaseModalCustom
    ref="bulkManageUsers"
    class="c-modal__add-user-modal mx-6"
    :size="modalSize"
    :close-on-esc="false"
    :heading="modalHeading"
  >
    <section v-if="displayUserList" class="flex-grow my-10 px-6">
      <Spinner v-if="loadingUsers" :is-local="true" />
      <div v-else>
        <p class="font-medium mb-6">{{ $t('sites.sections.users.title') }}</p>
        <table class="table-fixed users-bulk-sites-table">
          <colgroup>
            <col span="1" style="width: 5%;" />
            <col span="1" style="width: 30%;" />
            <col span="1" style="width: 20%;" />
            <col span="1" style="width: 45%;" />
          </colgroup>
          <tbody>
            <tr class="text-xs thead">
              <th>Name</th>
              <th></th>
              <th>Roles</th>
              <th>Sites</th>
            </tr>
            <tr v-for="user in formattedUsers" :key="user.id">
              <td class="align-middle">
                <BaseCheckbox
                  :id="user.id"
                  label=""
                  :value="userSelected(user.id)"
                  @input="updateUserSelected($event, user.id)"
                />
              </td>
              <td class="pr-3 text-sm">{{ user.name }}</td>
              <td class="text-sm">{{ user.roles }}</td>
              <td>
                <span v-for="site in user.sites" :key="site" class="inline-block rounded site-pill">
                  <p class="font-medium p-1 text-center text-xs">{{ getSiteName(site) }}</p>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <section v-if="displaySiteSelector" class="flex-grow my-10 px-6">
      <BaseMultiselect
        class="w-1/2"
        :error="getError($v.selectedSites)"
        :group-options-label="$t('multiselect.selectAll')"
        :label="siteSelectLabel"
        :multiple="true"
        :options="sitesOptions"
        :value="selectedSites"
        :validation="$v.selectedSites"
        @input="updateInputSites($event)"
      />
    </section>
    <section v-if="displayConfirm" class="flex-grow my-10 px-6">
      {{ confirmText }}
    </section>

    <section class="flex flex-row-reverse px-6">
      <BaseButton
        v-if="displayButton('removeFromSitesButton')"
        id="removeFromSitesButton"
        :disabled="userSelectedUsers.length < 1"
        button-type="primary"
        class="ml-3"
        @click="removeFromSites"
      >
        {{ $t('userManagement.manageSites.removeFromSites') }}
      </BaseButton>
      <BaseButton
        v-if="displayButton('addToSitesButton')"
        id="addToSitesButton"
        :disabled="userSelectedUsers.length < 1"
        button-type="primary"
        class="ml-3"
        @click="addToSites"
      >
        {{ $t('userManagement.manageSites.addToSites') }}
      </BaseButton>
      <BaseButton
        v-if="displayButton('confirmButton')"
        id="confirmButton"
        button-type="primary"
        class="ml-3"
        @click="confirm"
      >
        {{ $t('general.confirm') }}
      </BaseButton>
      <BaseButton button-type="tertiary" @click="close">
        {{ $t('general.cancel') }}
      </BaseButton>
    </section>
  </BaseModalCustom>
</template>

<script>
import { BaseButton, BaseCheckbox } from '@seegrid/components';
import { required } from 'vuelidate/lib/validators';
import BaseModalCustom from '@/components/BaseModalCustom.vue';
import BaseMultiselect from '@/components/BaseMultiselect.vue';
import Spinner from '@/components/Spinner.vue';
import constants from '@/config/constants';
import Company from '@/models/Company';
import OrganizationMember from '@/models/OrganizationMember';
import getErrorMessage from '@/util/validation';

export default {
  name: 'BulkManageUsersSitesModal',

  components: {
    BaseButton,
    BaseCheckbox,
    BaseModalCustom,
    BaseMultiselect,
    Spinner,
  },
  props: {
    company: {
      default: null,
      type: Company,
    },
    internalUserMode: {
      type: Boolean,
      required: true,
    },
    selectedUsers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      action: null,
      constants,
      displayConfirm: false,
      displaySiteSelector: false,
      displayUserList: true,
      formattedUsers: [],
      loadingUsers: false,
      userSelectedSites: [],
      userSelectedUsers: [],
    };
  },
  computed: {
    // header text shown during confirmation
    confirmHeader() {
      return this.$t('userManagement.manageSites.title.confirm', { action: this.action });
    },

    // confirmation text whenever adding / removing sites from users
    confirmText() {
      const usersString = this.currentSelectedUsers
        .map(user => `${user.firstName} ${user.lastName}`)
        .join(', ');
      const siteString = this.userSelectedSites.map(site => site.name).join(', ');

      if (this.action === constants.USER_SITES_ACTIONS.ADD_TO_SITES) {
        return this.$t('userManagement.manageSites.willBeAdded', {
          site: siteString,
          users: usersString,
        });
      }

      return this.$t('userManagement.manageSites.willBeRemoved', {
        site: siteString,
        users: usersString,
      });
    },

    // users selected on current component
    currentSelectedUsers() {
      return this.selectedUsers.filter(user => this.userSelectedUsers.includes(user.id));
    },

    modalHeading() {
      if (this.displayConfirm) {
        return this.confirmHeader;
      }

      switch (this.action) {
        case constants.USER_SITES_ACTIONS.ADD_TO_SITES:
          return this.$t('userManagement.manageSites.title.addToSites');
        case constants.USER_SITES_ACTIONS.REMOVE_FROM_SITES:
          return this.$t('userManagement.manageSites.title.removeFromSites');
        default:
          return this.$t('userManagement.manageSites.title.bulk');
      }
    },

    modalSize() {
      return this.displayConfirm ? 'small' : 'xlarge';
    },

    selectedSites() {
      return this.userSelectedSites.map(site =>
        this.sitesOptions.find(o => o.value === site.value),
      );
    },

    siteSelectLabel() {
      switch (this.action) {
        case constants.USER_SITES_ACTIONS.ADD_TO_SITES:
          return this.$t('userManagement.manageSites.label.sitesAddTo');
        case constants.USER_SITES_ACTIONS.REMOVE_FROM_SITES:
          return this.$t('userManagement.manageSites.label.sitesRemoveFrom');
        default:
          return '';
      }
    },

    sites() {
      return this.$store.getters['sites/getSites'];
    },

    // the list of available site options
    sitesOptions() {
      const options = this.sites.map(site => {
        return {
          name: site.name,
          value: site.id,
        };
      });
      return options;
    },
  },

  validations: {
    selectedSites: {
      required,
    },
  },

  watch: {
    async selectedUsers() {
      this.formattedUsers = await this.getFormattedUsers();
    },
  },

  methods: {
    addToSites() {
      if (this.action === constants.USER_SITES_ACTIONS.ADD_TO_SITES && this.displaySiteSelector) {
        this.showConfirm();
      } else {
        this.showAddToSites();
      }
    },

    async bulkAddToSites() {
      try {
        await this.$store.dispatch('users/saveUserSitesBulk', {
          company: this.company,
          isInternal: this.internalUserMode,
          sites: this.selectedSites.map(site => site.value),
          users: this.currentSelectedUsers,
        });
      } catch (error) {
        if (error.message === constants.API_ERROR_TYPES.BULK_USER_PARTIAL_FAILED) {
          this.displayErrorMessage(constants.API_ERROR_TYPES.BULK_USER_PARTIAL_FAILED);
        } else {
          console.error(JSON.stringify(error));
          this.displayErrorMessage(constants.API_ERROR_TYPES.SAVE);
        }
      }
    },

    async bulkRemoveFromSites() {
      try {
        await this.$store.dispatch('users/removeUserSitesBulk', {
          company: this.company,
          isInternal: this.internalUserMode,
          sites: this.selectedSites.map(site => site.value),
          users: this.currentSelectedUsers,
        });
      } catch (error) {
        if (error.message === constants.API_ERROR_TYPES.BULK_USER_PARTIAL_FAILED) {
          this.displayErrorMessage(constants.API_ERROR_TYPES.BULK_USER_PARTIAL_FAILED);
        } else {
          console.error(JSON.stringify(error));
          this.displayErrorMessage(constants.API_ERROR_TYPES.SAVE);
        }
      }
    },

    close() {
      this.$refs.bulkManageUsers.close();
      this.reset();
    },

    async confirm() {
      await this.$store.dispatch('users/setLoading', true);
      this.$refs.bulkManageUsers.close();
      if (this.action === constants.USER_SITES_ACTIONS.ADD_TO_SITES) {
        await this.bulkAddToSites();
      } else {
        await this.bulkRemoveFromSites();
      }
      this.close();

      await this.$store.dispatch('users/setLoading', false);
    },

    // adjust action buttons displayed based on selected actions
    displayButton(button) {
      if (this.displayUserList && !this.displayConfirm) {
        return ['addToSitesButton', 'removeFromSitesButton'].includes(button);
      }
      if (this.displaySiteSelector) {
        if (this.action === constants.USER_SITES_ACTIONS.REMOVE_FROM_SITES) {
          return button === 'removeFromSitesButton';
        }
        return button === 'addToSitesButton';
      }

      if (this.displayConfirm) {
        return button === 'confirmButton';
      }
      return false;
    },

    // display the error message for the given type
    displayErrorMessage(type) {
      this.$store.dispatch('message/display', {
        text: this.$t(`userManagement.error.${type}`),
        type: 'negative',
      });
    },

    getError(validation) {
      return getErrorMessage(validation);
    },

    async getFormattedUsers() {
      this.loadingUsers = true;
      const formattedUsers = [];
      if (!this.selectedUsers || !this.selectedUsers.length) {
        return formattedUsers;
      }

      const promises = this.selectedUsers.map(async user => {
        let formattedRoles = '';
        const roles = await OrganizationMember.getUserOrganizationRolesNames(
          this.company.id,
          user.id,
        );
        if (roles) {
          formattedRoles = roles
            .map(role => constants.USER_ROLES_ABBREVIATIONS[role] || '')
            .join(', ');
        }

        formattedUsers.push({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
          roles: formattedRoles,
          sites: user.sites,
        });
      });
      await Promise.all(promises);
      this.loadingUsers = false;

      return formattedUsers;
    },

    getSiteName(siteId) {
      const matchedSite = (this.sitesOptions || []).find((site = {}) => siteId === site.value);
      return matchedSite && matchedSite.name ? matchedSite.name : siteId;
    },

    // show the form
    open() {
      this.$refs.bulkManageUsers.open();
      this.$nextTick(() => {
        this.userSelectedUsers = this.selectedUsers.map(user => user.id);
      });
    },

    removeFromSites() {
      if (
        this.action === constants.USER_SITES_ACTIONS.REMOVE_FROM_SITES &&
        this.displaySiteSelector
      ) {
        this.showConfirm();
      } else {
        this.showRemoveFromSites();
      }
    },

    reset() {
      this.action = null;
      this.displayConfirm = false;
      this.displaySiteSelector = false;
      this.displayUserList = true;
      this.userSelectedUsers = [];
      this.userSelectedSites = [];
      this.$v.$reset();
    },

    // updated properties to show add to sites view
    showAddToSites() {
      this.action = constants.USER_SITES_ACTIONS.ADD_TO_SITES;
      this.displaySiteSelector = true;
      this.displayUserList = false;
    },

    // updated properties to show confirmation view
    showConfirm() {
      this.displayConfirm = true;
      this.displaySiteSelector = false;
      this.displayUserList = false;
    },

    // updated properties to show remove from sites view
    showRemoveFromSites() {
      this.action = constants.USER_SITES_ACTIONS.REMOVE_FROM_SITES;
      this.displaySiteSelector = true;
      this.displayUserList = false;
    },

    // called as the user updates for entries
    updateInputSites(inputData) {
      this.userSelectedSites = inputData;
      this.$v.selectedSites.$touch();
    },

    // allow users to change user selection on current component
    updateUserSelected(event, userId) {
      const index = this.userSelectedUsers.findIndex(user => user === userId);
      if (event && index === -1) {
        this.userSelectedUsers.push(userId);
      } else if (!event && index > -1) {
        this.userSelectedUsers.splice(index, 1);
      }
    },

    userSelected(userId) {
      return this.userSelectedUsers.includes(userId);
    },
  },
};
</script>

<style lang="scss" scoped>
.site-pill {
  background-color: rgba(151, 215, 251, 0.3);
}

td .site-pill:not(:first-child) {
  margin-left: 10px;
}

.users-bulk-sites-table tr.thead {
  border-bottom-width: 1rem;
  border-color: transparent;
}

.users-bulk-sites-table tr {
  border-bottom-width: 0.5rem;
  border-color: transparent;
}

.users-bulk-sites-table .c-checkbox {
  margin-top: -0.8rem;
}

.c-modal__add-user-modal {
  ::v-deep .c-modal__heading,
  ::v-deep .c-modal__subheading,
  ::v-deep .c-modal__body-container {
    text-align: left;
  }
  ::v-deep .c-modal__body-container {
    padding-bottom: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  ::v-deep .c-modal-mask {
    display: block;
    .c-modal {
      margin-top: 1rem;
      min-height: 50vh;
      max-height: 80vh;
      display: flex;
      flex-direction: column;
    }
  }
  @media (min-height: 600px) {
    ::v-deep .c-modal-mask {
      display: block;
      .c-modal {
        margin-top: calc(15vh);
      }
    }
  }
  @media (min-height: 900px) {
    ::v-deep .c-modal-mask {
      display: block;
      .c-modal {
        margin-top: calc(10vh);
      }
    }
  }
}
</style>
