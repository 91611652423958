<template>
  <section
    class="c-modal__edit-user-modal__form content-start max-w-6xl mx-auto p-6 rounded-lg text-left"
  >
    <BaseInput
      id="UserFirstNameInput"
      class="c-modal__edit-user-modal__form__control"
      :label="$t('userManagement.addUsers.firstName')"
      :value="userData.firstName"
      :validation="$v.userData.firstName"
      :error="getError($v.userData.firstName)"
      @input-updated="updateInput('firstName', $event, true)"
    />
    <BaseInput
      id="UserLastNameInput"
      class="c-modal__edit-user-modal__form__control"
      :label="$t('userManagement.addUsers.lastName')"
      :value="userData.lastName"
      :validation="$v.userData.lastName"
      :error="getError($v.userData.lastName)"
      @input-updated="updateInput('lastName', $event, true)"
    />
    <BaseInput
      id="UserEmailInput"
      class="c-modal__edit-user-modal__form__control email"
      :label="$t('userManagement.addUsers.email')"
      :value="userData.email"
      :validation="$v.userData.email"
      :error="validateEmailAddress()"
      @input-updated="updateInput('email', $event, true)"
    />
    <div v-show="emailUpdated" class="bg-gray-1 flex h-10 items-center mb-4 p-2 rounded">
      <BaseIcon
        class="mr-1.5 text-primary-dark"
        height="16px"
        name="fg-info-2"
        :icon-sprite="iconSprite"
        width="16px"
        viewBox="0 0 24 24"
      />
      <label>{{ welcomeEmailMessage }}</label>
    </div>
    <BaseMultiselect
      class="c-modal__edit-user-modal__form__control"
      :error="getError($v.selectedSites)"
      :group-options="false"
      :label="$t('userManagement.addUsers.sites')"
      :multiple="true"
      :options="sitesOptions"
      :value="selectedSites"
      :validation="$v.selectedSites"
      @input="updateInput('selectedSites', $event, true)"
    />
  </section>
</template>

<script>
import { BaseIcon, BaseInput } from '@seegrid/components';
import { email, maxLength, required } from 'vuelidate/lib/validators';
import IconSprite from '@/assets/images/icon-sprite.svg';
import BaseMultiselect from '@/components/BaseMultiselect.vue';
import constants from '@/config/constants';
import Company from '@/models/Company';
import OrganizationMember from '@/models/OrganizationMember';
import allowWelcomeEmail from '@/util/allowWelcomeEmail';
import isInternalEmail from '@/util/isInternalEmail';
import getErrorMessage from '@/util/validation';

export default {
  name: 'EditUserForm',
  components: { BaseIcon, BaseInput, BaseMultiselect },
  props: {
    company: {
      default: null,
      type: Company,
    },
    currentUsers: {
      type: Array,
      required: true,
    },
    emailUpdated: {
      default: false,
      type: Boolean,
    },
    user: {
      type: Object,
      required: true,
    },
    validate: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      constants,
      emailMismatch: false,
      userData: this.user,
    };
  },
  computed: {
    allSitesOption() {
      return {
        name: this.$t('sites.allSites'),
        value: constants.AUTH0.ALL_SITES,
      };
    },

    companies() {
      return this.$store.getters['companies/getCompanies'];
    },

    hasValidEmail() {
      if (!this.userData) return false;
      if (!this.userData.email) return false;
      if (this.emailMismatch) return false;
      return !this.$v.userData.email.$error;
    },

    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },

    selectedSites() {
      if (!(this.userData instanceof OrganizationMember)) {
        return [];
      }
      if (!this.userData.sites || this.userData.sites.length < 1) {
        return [];
      }
      return this.userData.sites.map(id => this.sitesOptions.find(o => o.value === id));
    },

    sites() {
      return this.$store.getters['sites/getSites'];
    },

    // the list of available site options
    sitesOptions() {
      const options = this.sites.map(site => {
        return {
          name: site.name,
          value: site.id,
        };
      });
      options.unshift(this.allSitesOption);
      return options;
    },

    welcomeEmailEligible() {
      return allowWelcomeEmail.containsConfirmedSite(this.user.sites);
    },

    welcomeEmailMessage() {
      if (!this.emailUpdated) {
        return '';
      }

      return this.welcomeEmailEligible
        ? this.$t('userManagement.editUser.verifyEmailToBeSent')
        : this.$t('userManagement.editUser.verifyEmailNotSent');
    },
  },
  validations: {
    selectedSites: {
      required,
    },
    userData: {
      email: {
        email,
        maxLength: maxLength(128),
        required,
      },
      firstName: {
        maxLength: maxLength(128),
        required,
      },
      lastName: {
        maxLength: maxLength(128),
        required,
      },
    },
  },
  watch: {
    /**
     * populate the multiselect options when the data is available
     * this ensures that the all companies / sites option is rendered properly
     */
    user() {
      this.userData = this.user;
    },
    validate() {
      if (this.validate) {
        this.$emit('validate-form-result', this.validateFormInputs());
      }
    },
  },
  mounted() {},
  methods: {
    // format the new user entry in the search control
    formatAddNewUser(option) {
      return this.$t('userManagement.addUsers.addNewUser', { option });
    },
    getError(validation) {
      return getErrorMessage(validation);
    },
    // called when the user selects an entry in the search control
    onEmailSelected() {
      // for now we dont need to do anything, but future devs
      // can use this feature.
    },
    // called as the user updates for entries
    updateInput(field, input, validateInput = false) {
      switch (field) {
        case 'selectedSites':
          {
            let selectedOptions = input;

            // did the user JUST select the all sites option?
            const allSitedJustAdded =
              selectedOptions.includes(this.allSitesOption) &&
              !this.selectedSites.includes(this.allSitesOption);
            if (allSitedJustAdded) {
              // make it the only selection
              selectedOptions = [this.allSitesOption.value];
            } else {
              // filter all sites from the list
              selectedOptions = selectedOptions
                .filter(o => o !== this.allSitesOption)
                .map(o => o.value);
            }
            this.$emit('update-form', { field, value: selectedOptions });
          }
          break;
        case 'email':
          this.$emit('update-form', { field, value: (input || '').toLowerCase() });
          break;
        default:
          this.$emit('update-form', { field, value: input });
          break;
      }
      if (validateInput) {
        if (field === 'selectedSites') {
          this.$v.selectedSites.$touch();
        } else {
          this.$v.userData[field].$touch();
        }
      }
    },
    // special validation for the email address
    validateEmailAddress() {
      // check the basics
      const err = this.getError(this.$v.userData.email);
      if (err) return err;
      // check to make sure the email doesn't match another existing user
      if (this.userData && this.userData.email && this.currentUsers) {
        const dup = this.currentUsers.find(u => {
          return (
            u.email &&
            u.email.trim().toLowerCase() === this.userData.email.trim().toLowerCase() &&
            u.id !== this.user.id
          );
        });
        if (dup) {
          this.emailMismatch = true;
          return this.$t('userManagement.editUser.error.emailExists');
        }
      }
      // is this an internal email?
      if (this.userData && this.userData.email) {
        const internalEmail = isInternalEmail(this.userData.email);
        if (internalEmail) {
          this.emailMismatch = true;
          return this.$t('userManagement.addUsers.error.enterExternalEmail');
        }
      }

      // no errors
      this.emailMismatch = false;
      return '';
    },
    validateFormInputs() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.c-modal__edit-user-modal__form {
  .c-modal__edit-user-modal__form__control {
    margin-bottom: 1rem;
    &.email ::v-deep input {
      text-transform: lowercase;
    }
  }
}
</style>
