<template>
  <SiteSettings
    :button-disabled="buttonDisabled"
    :button-show="buttonShow"
    :button-text="buttonText"
    :status-date="statusDate"
    :status-text="statusText"
    :status-text-style-type="statusTextStyle"
    :title="$t('sites.sections.map.title')"
    :title-style-type="titleStyle"
    @button-click="onClick"
  />
</template>

<script>
import has from 'lodash-es/has';
import { DateTime } from 'luxon';
import SiteSettings from '@/components/SiteSettings.vue';
import authorization from '@/mixins/authorization';
import RouteNetworkImport from '@/models/RouteNetworkImport';

export default {
  name: 'SiteSettingsMap',

  components: {
    SiteSettings,
  },

  mixins: [authorization],

  props: {
    siteId: {
      required: true,
      type: String,
    },
  },

  computed: {
    // set the button state based on the current state of the import
    buttonDisabled() {
      if (!(this.siteRouteNetworkImport instanceof RouteNetworkImport)) {
        return false;
      }

      const data = this.siteRouteNetworkImport.getStatusData();

      if (has(data, 'button.disabled')) {
        return data.button.disabled;
      }

      return false;
    },

    // returns true if the button should be shown or false otherwise
    buttonShow() {
      // if there is not an import require create permissions
      if (!(this.siteRouteNetworkImport instanceof RouteNetworkImport)) {
        return this.hasFeatureControl(this.FEATURES.MAP, this.CONTROL_LEVELS.EDITOR);
      }

      return this.hasFeatureControl(this.FEATURES.MAP, this.CONTROL_LEVELS.VIEWER);
    },

    // set the button text based on the current state of the import
    buttonText() {
      if (!(this.siteRouteNetworkImport instanceof RouteNetworkImport)) {
        return this.$t('sites.sections.map.buttonUpload');
      }

      const data = this.siteRouteNetworkImport.getStatusData();

      if (has(data, 'button.text')) {
        return this.$t(data.button.text);
      }

      return this.$t('view');
    },

    // returns the route network import for the site
    siteRouteNetworkImport() {
      return this.$store.getters['routeNetworkImports/getSiteRouteNetworkImport'](this.siteId);
    },

    // set the status date based on the current state of the import
    statusDate() {
      if (!(this.siteRouteNetworkImport instanceof RouteNetworkImport)) {
        return '';
      }

      return DateTime.fromSeconds(this.siteRouteNetworkImport.timestamp).toFormat('D T');
    },

    // set the status text based on the current state of the import
    statusText() {
      if (!(this.siteRouteNetworkImport instanceof RouteNetworkImport)) {
        return this.$t('sites.sections.map.statusNoMap');
      }

      const data = this.siteRouteNetworkImport.getStatusData();

      if (has(data, 'message.text')) {
        return this.$t(data.message.text);
      }

      return this.$t('sites.sections.map.statusUnknown');
    },

    // returns the appropriate status text style type
    statusTextStyle() {
      if (!(this.siteRouteNetworkImport instanceof RouteNetworkImport)) {
        return 'negative';
      }

      const data = this.siteRouteNetworkImport.getStatusData();

      if (has(data, 'message.style')) {
        return data.message.style;
      }

      return '';
    },

    // returns the appropriate title text style type
    titleStyle() {
      if (!(this.siteRouteNetworkImport instanceof RouteNetworkImport)) {
        return 'negative';
      }

      const data = this.siteRouteNetworkImport.getStatusData();

      if (has(data, 'title.style')) {
        return data.title.style;
      }

      return '';
    },
  },

  methods: {
    // event handler for the buttonClick event
    async onClick() {
      // set the site
      await this.$store.dispatch('sites/setSelectedSite', this.siteId);

      // if there is no upload or there is an error redirect to the upload page
      if (
        !(this.siteRouteNetworkImport instanceof RouteNetworkImport) ||
        this.siteRouteNetworkImport.hasExtractMapImageError()
      ) {
        await this.$router.push({ name: 'routeNetworkUpload' });
        return;
      }

      // redirect to the route network page
      await this.$router.push({ name: 'routeNetwork' });
    },
  },
};
</script>
