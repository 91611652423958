<template>
  <div class="flex flex-col h-full items-center w-full">
    <div class="border-opacity-10 bottom-border flex items-center pb-2 w-full">
      <BaseButton
        button-type="ghost"
        icon="arrow-left"
        icon-height="24px"
        icon-width="24px"
        viewBox="0 0 16 16"
        just-icon
        :icon-sprite="iconSprite"
        @click="handleClose"
      />
      <div v-if="site">
        <h2 class="font-medium mx-2 text-2.5xl text-primary-dark">{{ site.name }}</h2>
      </div>
      <h2 class="font-medium text-2.5xl text-primary-dark">{{ $t('map.uploadMapTitle') }}</h2>
    </div>
    <div class="flex justify-center m-16 w-full">
      <label
        v-if="displayUpload"
        class="cursor-pointer flex flex-col h-52 items-center justify-center w-full"
      >
        <BaseButton
          v-if="!mapArchive"
          button-type="secondary"
          icon="upload"
          icon-height="24px"
          icon-width="24px"
          viewBox="0 0 16 16"
          :icon-sprite="iconSprite"
          @click="upldBtnClick"
        >
          {{ $t('map.noArchiveUploaded') }}
        </BaseButton>
        <span v-if="mapArchive" class="leading-normal mt-2 text-primary-dark text-xl">
          {{ mapArchive.name }}
        </span>
        <span v-if="!mapArchive" class="leading-normal mt-2 text-gray-6 text-sm">{{
          $t('map.acceptableFormats', { files: acceptedFormats })
        }}</span>
        <input
          ref="file"
          :accept="acceptedFormats"
          type="file"
          class="hidden"
          @change="handleFileAttached"
        />
      </label>
      <div v-else class="flex flex-col h-44 items-center justify-center w-2/3">
        <p class="mb-6 text-2xl">{{ $t('map.fileUploading') }}</p>
        <ProgressBar indeterminate class="h-6 mb-2 mx-2"></ProgressBar>
      </div>
    </div>
    <div class="flex justify-center w-full">
      <div v-if="displayUpload" class="flex justify-center mb-6 w-full">
        <template v-if="hasError">
          <BaseButton
            v-if="hasFeatureControl(FEATURES.MAP, CONTROL_LEVELS.EDITOR)"
            class="mx-2"
            :button-type="'tertiary'"
            @click="handleDelete"
          >
            {{ $t('map.delete') }}
          </BaseButton>
        </template>
        <BaseButton
          v-if="mapArchive && hasFeatureControl(FEATURES.MAP, CONTROL_LEVELS.EDITOR)"
          class="mx-2"
          @click="handleFileUpload"
        >
          {{ $t('map.uploadButton') }}
        </BaseButton>
      </div>
    </div>
    <BaseModal
      ref="modal"
      :action-one="$t('sites.modalCancel')"
      :heading="modalTitle"
      :sub-heading="modalText"
    />
  </div>
</template>

<script>
import { BaseButton, BaseModal } from '@seegrid/components';
import IconSprite from '@/assets/images/icon-sprite.svg';
import ProgressBar from '@/components/ProgressBar.vue';
import constants from '@/config/constants';
import authorization from '@/mixins/authorization';
import Site from '@/models/Site';
import SupervisorBackupImport from '@/models/SupervisorBackupImport';

export default {
  name: 'RouteNetworkUpload',

  components: {
    BaseButton,
    BaseModal,
    ProgressBar,
  },

  mixins: [authorization],

  data() {
    return {
      isUploading: false,
      mapArchive: null,
      modalText: '',
      modalTitle: '',
    };
  },

  computed: {
    // Returns Accepted File Formats for Input accept tag
    acceptedFormats() {
      return constants.ARCHIVE_FILE_FORMATS.join(', ');
    },

    // returns the currently selected company
    companyId() {
      return this.$store.getters['companies/getSelectedCompanyId'];
    },

    // returns true if the upload component should be displayed or false otherwise
    displayUpload() {
      return !this.isUploading || this.hasError;
    },

    // returns true if the route network import upload failed or false otherwise
    hasError() {
      if (
        (this.siteRouteNetworkImport instanceof SupervisorBackupImport &&
          this.siteRouteNetworkImport.hasExtractMapImageError()) ||
        this.$store.getters['routeNetworkImports/hasImportError']
      ) {
        return true;
      }

      return false;
    },

    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },

    // returns the current site
    site() {
      return this.$store.getters['sites/getSelectedSite'];
    },

    // returns the route network import for the site
    siteRouteNetworkImport() {
      if (!(this.site instanceof Site)) {
        return null;
      }
      return this.$store.getters['routeNetworkImports/getSiteRouteNetworkImport'](this.site.id);
    },
  },

  watch: {
    isUploading(value) {
      this.$store.dispatch('companies/setLocked', value);
    },

    // once processing is complete proceed to the next step
    async siteRouteNetworkImport(routeNetworkImport) {
      await this.onStatusChange(routeNetworkImport);
    },
  },

  methods: {
    // event handler for closing the page
    handleClose() {
      this.$emit('navigate', 'sites');
    },

    // event handler for deleting the backup
    async handleDelete() {
      try {
        // delete the in progress import
        await this.$store.dispatch('routeNetworkImports/deleteRouteNetworkImport', {
          routeNetworkImport: this.siteRouteNetworkImport,
        });
      } catch (error) {
        this.$emit('api-error', constants.API_ERROR_TYPES.DELETE);
        return;
      }
      // unsaved-change is used to prompt user while navigating away from page
      this.$emit('unsaved-changes', false);
      this.$emit('navigate', 'sites');
    },

    // Once User Added File, Make sure it's valid and prompt with modal if not
    handleFileAttached(event) {
      // User Cancelled
      if (!event.target.files.length) {
        return;
      }

      // eslint-disable-next-line prefer-destructuring
      this.mapArchive = event.target.files[0];

      if (!this.isValidFileFormat()) {
        this.mapArchive = null;
        this.modalTitle = this.$t('map.modalBadFormatTitle');
        this.modalText = this.$t('map.modalBadFormatText', { files: this.acceptedFormats });
        this.$refs.modal.open();
      } else {
        // unsaved-change is used to prompt user while navigating away from page
        this.$emit('unsaved-changes', true);
      }
    },

    // event handler for importing the selected supervisor backup file
    async handleFileUpload() {
      this.isUploading = true;
      this.$emit('unsaved-changes', true);

      // if the previous upload failed delete it
      if (this.hasError) {
        await this.$store.dispatch('routeNetworkImports/deleteRouteNetworkImport', {
          reset: true,
          routeNetworkImport: this.siteRouteNetworkImport,
        });
      }

      const routeNetworkImport = new SupervisorBackupImport(
        this.companyId,
        this.site.id,
        this.mapArchive,
      );

      try {
        // create the supervisor backup
        await this.$store.dispatch('routeNetworkImports/importRouteNetwork', {
          routeNetworkImport,
          type: constants.SUPERVISOR_BACKUP_EXTRACTION_TYPES.EXTRACT_MAP_IMAGE,
        });
      } catch (error) {
        this.$emit('api-error', constants.API_ERROR_TYPES.UPLOAD);
        this.isUploading = false;
        return;
      }

      try {
        // poll for the processing status
        await this.$store.dispatch(
          'routeNetworkImports/getSiteRouteNetworkImport',
          routeNetworkImport,
        );
      } catch (error) {
        this.$emit('api-error', constants.API_ERROR_TYPES.PROCESS);
        this.isUploading = false;
      }
    },

    // This should be caught by `accept` property but just in case, it's here again
    isValidFileFormat() {
      return constants.ARCHIVE_FILE_FORMATS.some(str => {
        return this.mapArchive.name.endsWith(str);
      });
    },

    // event handler for evaluating the processing status
    async onStatusChange(routeNetworkImport) {
      if (!(routeNetworkImport instanceof SupervisorBackupImport)) {
        return;
      }

      // if processing failed display an error
      if (routeNetworkImport.hasExtractMapImageError()) {
        this.isUploading = false;
      }

      // if processing was successful redirect to the route network page
      if (routeNetworkImport.isExtractMapImageComplete()) {
        this.$emit('unsaved-changes', false);
        this.$emit('navigate', 'routeNetwork');
      }
    },

    // Upload button click handler
    upldBtnClick() {
      if (this.$refs.file) {
        this.$refs.file.click();
      }
    },
  },
};
</script>
