<template>
  <div class="input-time">
    <div class="flex items-center">
      <label v-if="label" class="font-medium label mr-2 text-xs">
        {{ label }}
      </label>
      <div class="flex items-center" :class="{ 'has-error': error }">
        <vue-timepicker
          ref="timepicker"
          v-model="inputTime"
          auto-scroll
          close-on-complete
          drop-direction="auto"
          :container-id="containerId"
          :format="timePickerFormat"
          :input-width="width"
          manual-input
          :fixed-dropdown-button="!value"
          :placeholder="placeholder"
          @open="onOpen"
        >
          <template #dropdownButton>
            <BaseIcon :name="'chevron-down'" class="text-primary-dark" />
          </template>
        </vue-timepicker>
      </div>
    </div>
    <p v-if="error" class="input-error-text">
      {{ error }}
    </p>
  </div>
</template>

<script>
import { BaseIcon } from '@seegrid/components';
import debounce from 'lodash-es/debounce';
import VueTypes from 'vue-types';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';

const DEBOUNCE_TIMEOUT_TIMEPICKER = 250;

export default {
  name: 'InputTime',

  components: {
    BaseIcon,
    VueTimepicker,
  },

  props: {
    containerId: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    hidden: VueTypes.bool.def(false),
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    timePickerFormat: {
      type: String,
      default: 'HH:mm:ss',
    },
    value: VueTypes.oneOfType([String, null]),
    width: {
      type: String,
      default: '7rem',
    },
  },

  computed: {
    inputTime: {
      get() {
        return this.value;
      },
      /**
       * debounce input so that the calendar is not immedidately updated while
       * the user is still typing
       */
      // function keyword is required to allow access to this
      // eslint-disable-next-line func-names
      set: debounce(function(value) {
        this.$emit('input', value);

        // VueTimepicker handles empty strings oddly so the value has to be cleared manually
        if (this.inputTime.length === 0) {
          this.$refs.timepicker.clearTime();
        }
      }, DEBOUNCE_TIMEOUT_TIMEPICKER),
    },
  },

  methods: {
    async onOpen() {
      await this.$nextTick();
      try {
        const picker = this.$refs.timepicker.$el.querySelector('div.dropdown');
        if (picker && picker.scrollIntoView) {
          picker.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }
      } catch (err) {
        console.log('Error while auto scrolling timepicker', err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/css/variables' as var;

::v-deep .vue__time-picker .dropdown {
  z-index: 400;
}

::v-deep .vue__time-picker .dropdown ul li:not([disabled]).active,
::v-deep .vue__time-picker .dropdown ul li:not([disabled]).active:hover,
::v-deep .vue__time-picker .dropdown ul li:not([disabled]).active:focus {
  background: var.$c-primary-a11y-light;
}

::v-deep .vue__time-picker input {
  border-radius: 4px;
}
div.has-error ::v-deep .vue__time-picker input {
  border: 1px solid var.$c-status-negative-dark;
}
</style>
