<template>
  <footer class="border-opacity-25 border-primary-dark border-t mt-8">
    <div class="flex items-center justify-between max-w-6xl mx-auto pb-6 pt-4 w-11/12">
      <router-link :to="{ name: 'home' }">
        <img src="../assets/images/logo.png" class="mr-2 w-20" />
      </router-link>

      <Version class="w-48" />
    </div>
  </footer>
</template>

<script>
import Version from '@/components/Version.vue';

export default {
  name: 'TheFooter',

  components: {
    Version,
  },
};
</script>
