<template>
  <Tooltip
    class="help-tool-tip"
    :content="content"
    :delay="delay"
    :direction="direction"
    focusable="true"
    tabindex="0"
    :height="height"
    :width="width"
  >
    <BaseIcon
      class="icon"
      name="fg-icon-help"
      :height="iconHeight"
      :width="iconWidth"
      :icon-sprite="iconSprite"
      viewBox="0 0 16 16"
      focusable="true"
    />
  </Tooltip>
</template>

<script>
import { BaseIcon } from '@seegrid/components';
import IconSprite from '@/assets/images/icon-sprite.svg';
import Tooltip from '@/components/Tooltip.vue';

export default {
  name: 'HelpTooltip',

  components: { BaseIcon, Tooltip },

  props: {
    content: {
      type: String,
      default: '',
    },
    delay: {
      type: Number,
      default: 200,
    },
    direction: {
      type: String,
      default: 'top',
    },
    height: {
      type: String,
      default: 'auto',
    },
    iconHeight: {
      type: String,
      default: '20px',
    },
    iconWidth: {
      type: String,
      default: '20px',
    },
    width: {
      type: String,
      default: 'auto',
    },
  },

  computed: {
    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },
  },
};
</script>
