import { Auth0TokenProvider, CerberusApiClient, PERMISSIONS } from '@seegrid/cerberus-api';
import constants from '@/config/constants';
import { getInstance } from '@/plugins/Auth0Plugin';
import environment from '@/util/environment';

let auth0TokenProvider;
let lastOrg;
let lastClientType;
let clientWrapper;

// SA: TODO: In future when Cerberus API by default has global_access false we can refactor
// the below variable to only send it when true other wise dont send.
let CERBERUS_GLOBAL_ACCESS = false;

const CERBERUS_LOGIN_SCOPE = [PERMISSIONS.READ_SSO];

const CERBERUS_REQUESTED_SCOPES = [
  PERMISSIONS.CREATE_COMPANY_SCHEDULE,
  PERMISSIONS.CREATE_GLOBAL_ROLE_USER,
  PERMISSIONS.CREATE_ORGANIZATION_MEMBER,
  PERMISSIONS.CREATE_ORG_GROUP_USER,
  PERMISSIONS.CREATE_ORG_ROLE_USER,
  PERMISSIONS.CREATE_SPR,
  PERMISSIONS.CREATE_SUPERVISOR_BACKUP,
  PERMISSIONS.CREATE_USER,
  PERMISSIONS.CREATE_USER_ACTION,
  PERMISSIONS.CREATE_USER_INTERNAL,
  PERMISSIONS.DELETE_COMPANY_SCHEDULE,
  PERMISSIONS.DELETE_GLOBAL_ROLE_USER,
  PERMISSIONS.DELETE_ORGANIZATION_MEMBER,
  PERMISSIONS.DELETE_ORG_GROUP_USER,
  PERMISSIONS.DELETE_ORG_ROLE_USER,
  PERMISSIONS.DELETE_SPR,
  PERMISSIONS.DELETE_SUPERVISOR_BACKUP,
  PERMISSIONS.DELETE_USER,
  PERMISSIONS.DELETE_USER_INTERNAL,
  PERMISSIONS.READ_COMPANY_SCHEDULE,
  PERMISSIONS.READ_GLOBAL_ROLE_USER,
  PERMISSIONS.READ_ORGANIZATION_MEMBER,
  PERMISSIONS.READ_ORG_GROUP_USER,
  PERMISSIONS.READ_ORG_ROLE_USER,
  PERMISSIONS.READ_ROLE,
  PERMISSIONS.READ_ROUTE_NETWORK,
  PERMISSIONS.READ_SITE,
  PERMISSIONS.READ_SPR,
  PERMISSIONS.READ_SSO,
  PERMISSIONS.READ_SUPERVISOR_BACKUP,
  PERMISSIONS.READ_USER,
  PERMISSIONS.READ_USER_INTERNAL,
  PERMISSIONS.UPDATE_COMPANY_SCHEDULE,
  PERMISSIONS.UPDATE_SITE_STATE,
  PERMISSIONS.UPDATE_SPR,
  PERMISSIONS.UPDATE_SUPERVISOR_BACKUP,
  PERMISSIONS.UPDATE_USER,
  PERMISSIONS.UPDATE_USER_INTERNAL,
];

const baseURL = environment.VUE_APP_CERBERUS_URL;

const auth0Config = {
  baseURL: process.env.VUE_APP_AUTH0_API_AUDIENCE || baseURL,
  environment: process.env.NODE_ENV,
  usePopup: process.env.VUE_APP_A0_USE_POPUP === 'true',
};

const invalidateA0OrgHint = () => {
  /*
    When requesting a global access token, without an org_id property, we must remove the organization_hint cookies
    The auth0-spa-js library will default to the cookie value if one exists whenever a org_id is not requested
  */
  const ORG_HINT_COOKIE = `${process.env.VUE_APP_AUTH0_CLIENT_ID}.organization_hint`;
  document.cookie = `auth0.${ORG_HINT_COOKIE}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  document.cookie = `_legacy_auth0.${ORG_HINT_COOKIE}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const cerberusClient = (type = constants.CERBERUS_CLIENT_TYPES.ORG_BASED) => {
  const authClient = getInstance();
  const currentOrg = authClient && authClient.currentOrgId ? authClient.currentOrgId : null;
  // If Client exists with the current Org token just return existing client
  if (lastOrg === currentOrg && lastClientType === type && clientWrapper) {
    return clientWrapper.getClient();
  }
  lastOrg = currentOrg;
  lastClientType = type;
  auth0Config.ignoreCache = true;
  switch (type) {
    case constants.CERBERUS_CLIENT_TYPES.ORG_BASED:
      if (currentOrg) {
        CERBERUS_GLOBAL_ACCESS = false;
        auth0Config.scopes = CERBERUS_REQUESTED_SCOPES;
        auth0Config.organization = currentOrg;
      }
      break;
    case constants.CERBERUS_CLIENT_TYPES.LOGIN:
      CERBERUS_GLOBAL_ACCESS = true;
      auth0Config.scopes = CERBERUS_LOGIN_SCOPE;
      invalidateA0OrgHint();
      delete auth0Config.organization;
      break;
    case constants.CERBERUS_CLIENT_TYPES.GLOBAL:
      CERBERUS_GLOBAL_ACCESS = true;
      auth0Config.scopes = CERBERUS_REQUESTED_SCOPES;
      invalidateA0OrgHint();
      delete auth0Config.organization;
      break;
    default:
      throw new Error('No matching cerberusClient found in cerberusClient.js');
  }
  auth0TokenProvider = new Auth0TokenProvider(getInstance, auth0Config);
  clientWrapper = new CerberusApiClient(baseURL, auth0TokenProvider, CERBERUS_GLOBAL_ACCESS);
  return clientWrapper.getClient();
};

export const refreshCerberusToken = async type => {
  cerberusClient(type);
  await clientWrapper.refreshToken();
};
