export default {
  methods: {
    // event handler for handling the confirm modal
    onCancelNavigate() {
      this.leaveConfirmed = false;
      // check for company change
      const companySelect = this.$store.getters['companies/getCompanySelectId'];
      const selectedCompany = this.$store.getters['companies/getSelectedCompanyId'];
      if (companySelect !== selectedCompany) {
        // if the navigation was cancelled reset the selected company
        this.$refs.theHeader.cancelCompanyChange();
      }
    },

    // event handler for handling the confirm modal
    async onConfirmNavigate() {
      // check for company change
      const companySelect = this.$store.getters['companies/getCompanySelectId'];
      const selectedCompany = this.$store.getters['companies/getSelectedCompanyId'];
      this.leaveConfirmed = true;
      await this.$refs.exitModal.close();
      // wait a tick to allow the modal to execute it's close routine
      await this.$nextTick();
      // if necessary, set the new company
      if (companySelect !== selectedCompany) {
        await this.$refs.theHeader.confirmCompanyChange();
      } else if (this.leaveTo) {
        // complete the navigation
        await this.$router.push(this.leaveTo);
      }
    },
  },
};
