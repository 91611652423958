<template>
  <section class="bg-gray-2 border-gray-200 users-table-container">
    <!-- report table controls -->
    <div
      class="border-gray-200 bottom-border flex items-center users-table-controls"
      :class="{ 'justify-end': !hasSelections }"
    >
      <span v-show="hasSelections" class="flex flex-grow selected">{{ usersSelectedLabel }}</span>
      <span v-show="!hasSelections" class="flex flex-grow table-label">{{ tableLabel }}</span>
      <GhostButton
        v-show="canSend && hasSelections && isWelcomeEmailEligible"
        class="needs-selection"
        :disabled="!(showSendWelcome || showResendWelcome)"
        @click.stop="onSendEmailAction('sendWelcomeEmail')"
      >
        {{ $t('userManagement.buttons.sendWelcomeEmail') }}
      </GhostButton>
      <GhostButton
        v-show="canSend && hasSelections && showSendVerification"
        class="needs-selection"
        @click.stop="onSendEmailAction('sendVerificationEmail')"
      >
        {{ $t('userManagement.buttons.sendVerificationEmail') }}
      </GhostButton>
      <GhostButton
        v-show="canSend && hasSelections && showResetPassword"
        class="needs-selection"
        @click.stop="onSendEmailAction('resetPassword')"
      >
        {{ $t('userManagement.buttons.resetPassword') }}
      </GhostButton>
      <GhostButton
        v-show="canEdit && hasSelections && showManageSites"
        class="manage-access needs-selection"
        :icon-name="'fg-icon-edit'"
        :icon-sprite="iconSprite"
        @click.stop="onManage"
      >
        {{
          userListType === constants.USER_LIST.ALL_INTERNAL
            ? $t('userManagement.buttons.manageCompanies')
            : $t('userManagement.buttons.manageSites')
        }}
      </GhostButton>
      <GhostButton
        v-show="canEdit && hasOneSelection"
        class="edit-user needs-selection"
        :icon-name="'fg-icon-edit'"
        :icon-sprite="iconSprite"
        @click.stop="onEdit"
      >
        {{ $t('userManagement.buttons.edit') }}
      </GhostButton>
      <GhostButton
        v-show="canDelete && hasOneSelection"
        class="delete-users needs-selection"
        :icon-name="'fg-icon-trash'"
        :icon-sprite="iconSprite"
        :viewBox="'0 0 24 24'"
        @click.stop="onDelete"
      >
        {{ $t('userManagement.buttons.delete') }}
      </GhostButton>
      <template v-if="hasUserEditFeatureControl">
        <BaseButton
          v-show="canAdd && !hasSelections"
          :size="'small'"
          :button-type="'secondary'"
          class="ml-6 text-primary-dark text-sm"
          icon="plus"
          @click.stop="onBulkAdd"
        >
          {{ $t('userManagement.buttons.bulkAdd') }}
        </BaseButton>
        <BaseButton
          v-show="canAdd && !hasSelections"
          :size="'small'"
          :button-type="'secondary'"
          class="ml-6 text-primary-dark text-sm"
          icon="plus"
          @click.stop="onAddUser"
        >
          {{ $t('userManagement.buttons.addUser') }}
        </BaseButton>
      </template>
    </div>
    <BaseContextMenu ref="contextMenu" class="row-context-menu" :offset-x="20">
      <ul role="menu">
        <li v-show="canSend && showResetPassword" role="none">
          <a role="menuitem" @click.stop="onSendEmailAction('resetPassword')">{{
            $t('userManagement.actions.resetPassword')
          }}</a>
        </li>
        <li v-show="canSend && showSendVerification" role="none">
          <a role="menuitem" @click.stop="onSendEmailAction('sendVerificationEmail')">{{
            $t('userManagement.actions.sendVerificationEmail')
          }}</a>
        </li>
        <li v-show="canSend && showResendWelcome" role="none">
          <a role="menuitem" @click.stop="onSendEmailAction('resendWelcomeEmail')">{{
            $t('userManagement.actions.resendWelcomeEmail')
          }}</a>
        </li>
        <li v-show="canSend && showSendWelcome" role="none">
          <a role="menuitem" @click.stop="onSendEmailAction('sendWelcomeEmail')">{{
            $t('userManagement.actions.sendWelcomeEmail')
          }}</a>
        </li>
        <li role="none">
          <a role="menuitem" @click.stop="onViewDetails">{{
            $t('userManagement.actions.viewDetails')
          }}</a>
        </li>
        <li v-show="canEdit" role="none">
          <a role="menuitem" @click.stop="onEdit">{{ $t('userManagement.actions.edit') }}</a>
        </li>
        <li v-show="canDelete" role="none">
          <a role="menuitem" @click.stop="onDelete">{{ $t('userManagement.actions.delete') }}</a>
        </li>
      </ul>
    </BaseContextMenu>
    <!-- report table -->
    <DataGridV2
      class="users-table"
      :context="gridContext"
      :default-sort-model="defaultSortModel"
      :enable-row-multiselect="hasUserEditFeatureControl"
      :height="height"
      :table-columns="columnDefs"
      :table-rows="formattedUsers"
      :no-rows-message="noRowsMessage"
      @grid-ready="onGridReady"
      @selection-changed="onChange_RowSelections"
    />
  </section>
</template>

<script>
import { BaseButton } from '@seegrid/components';
import UsersMgmtTableActions from './UsersMgmtTableActions.vue';
import IconSprite from '@/assets/images/icon-sprite.svg';
import BaseContextMenu from '@/components/BaseContextMenu.vue';
import DataGridV2 from '@/components/controls/DataGridV2.vue';
import GhostButton from '@/components/controls/GhostButton.vue';
import constants from '@/config/constants';
import i18n from '@/i18n';
import authorization from '@/mixins/authorization';
import Company from '@/models/Company';
import allowWelcomeEmail from '@/util/allowWelcomeEmail';

export default {
  name: 'UsersMgmtTable',

  components: {
    BaseButton,
    BaseContextMenu,
    DataGridV2,
    GhostButton,
  },

  mixins: [authorization],

  props: {
    canAdd: {
      default: true,
      type: Boolean,
    },
    canDelete: {
      default: true,
      type: Boolean,
    },
    canEdit: {
      default: true,
      type: Boolean,
    },
    canSend: {
      default: true,
      type: Boolean,
    },
    company: {
      default: null,
      type: Company,
    },
    enableRowAction: {
      default: true,
      type: Boolean,
    },
    height: {
      default: 'initial',
      type: String,
    },
    noRowsMessage: {
      default: null,
      type: String,
    },
    tableLabel: {
      required: true,
      type: String,
    },
    userListType: {
      required: true,
      type: String,
    },
    users: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      columnDefs: null,
      constants,
      defaultSortModel: [{ colId: 'name', sort: 'asc' }],
      gridApi: null,
      rowActionColumn: {
        cellRendererFramework: UsersMgmtTableActions,
        cellStyle: { display: 'flex', justifyContent: 'flex-end' },
        headerName: '',
        sortable: false,
        width: 60,
      },
      selectedRows: [],
      sendEmailActions: {
        resendWelcomeEmail: {
          eventType: 'onSendWelcome',
          status: [constants.USER_ACCOUNT_STATUS.INVITED],
        },
        resetPassword: {
          eventType: 'onResetPassword',
          status: [constants.USER_ACCOUNT_STATUS.ACTIVE, constants.USER_ACCOUNT_STATUS.VERIFIED],
        },
        sendVerificationEmail: {
          eventType: 'onSendVerification',
          status: [constants.USER_ACCOUNT_STATUS.UNVERIFIED],
        },
        sendWelcomeEmail: {
          eventType: 'onSendWelcome',
          status: [constants.USER_ACCOUNT_STATUS.UNINVITED],
        },
      },
    };
  },

  computed: {
    // adds formatted columns to the rows
    formattedUsers() {
      if (!this.users || !this.users.length) {
        return [];
      }
      const sites = {};
      const roles = {};
      const resolveSiteNames = list =>
        list
          .map(id => {
            if (id === constants.AUTH0.ALL_SITES) {
              return this.$t('sites.allSites');
            }
            if (!sites[id]) {
              sites[id] = this.$store.getters['sites/getSiteById'](id).name;
            }
            return sites[id];
          })
          .join(', ');
      const resolveStatus = row => {
        switch (row.accountStatus) {
          case constants.USER_ACCOUNT_STATUS.ACTIVE:
            return !row.lastLogin
              ? this.$t('userManagement.state.activeQuiet')
              : this.$t('userManagement.state.active', {
                  date: this.formatLastLoginDate(row.lastLogin),
                });
          case constants.USER_ACCOUNT_STATUS.INVITED:
            return this.$t('userManagement.state.invited');
          case constants.USER_ACCOUNT_STATUS.UNINVITED:
            return this.$t('userManagement.state.uninvited');
          case constants.USER_ACCOUNT_STATUS.UNVERIFIED:
            return this.$t('userManagement.state.unverified');
          case constants.USER_ACCOUNT_STATUS.VERIFIED:
            return this.$t('userManagement.state.verified');
          default:
            return row.accountStatus;
        }
      };
      const resolveRole = list =>
        list
          .map(id => {
            if (!roles[id]) {
              roles[id] = this.$store.getters['roles/getRoleById'](id).name;
            }
            return roles[id];
          })
          .join(', ');
      const formatted = [];
      for (let i = 0; i < this.users.length; i += 1) {
        const row = this.users[i];
        formatted.push({
          ...row,
          formattedName: this.$t('userManagement.userTableFormats.formattedName', {
            family_name: row.lastName,
            given_name: row.firstName,
          }),
          formattedRole: resolveRole(row.roles || []),
          formattedSites: resolveSiteNames(row.sites || []),
          formattedStatus: resolveStatus(row),
          originalUser: row,
        });
      }
      return formatted;
    },

    // provides details used by the grid
    gridContext() {
      const self = this;
      return {
        componentParent: self,
        contextMenu: (row, show, x, y) => {
          self.clearSelections();
          row.node.setSelected(true);
          if (show) self.$refs.contextMenu.openMenu(x, y);
          else self.$refs.contextMenu.closeMenu();
        },
      };
    },

    // indicates that there is exactly one selection
    hasOneSelection() {
      return this.selectedRows.length === 1;
    },

    // indicates that there are one or more selections
    hasSelections() {
      return this.selectedRows.length > 0;
    },

    hasUserEditFeatureControl() {
      return this.hasFeatureControl(this.FEATURES.USER, this.CONTROL_LEVELS.EDITOR);
    },

    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },

    // determine if user status is eligible to send welcome email
    isWelcomeEmailEligible() {
      const UNCONFIRMED_USER_STATUS = [
        ...this.sendEmailActions.sendWelcomeEmail.status,
        ...this.sendEmailActions.resendWelcomeEmail.status,
      ];

      return this.selectedUsers
        .map(user => user.accountStatus)
        .some(status => UNCONFIRMED_USER_STATUS.includes(status));
    },

    // returns the original user objects in the selected rows
    selectedUsers() {
      return this.selectedRows.map(r => r.originalUser);
    },

    // should we show the manage sites
    showManageSites() {
      // TODO: Only display on external users currently
      return this.selectedRows.length > 1 && this.userListType === 'company';
    },

    // should we show the resend welcome email
    showResendWelcome() {
      return this.showSendEmailAction('resendWelcomeEmail');
    },

    // should we show the reset password
    showResetPassword() {
      return this.showSendEmailAction('resetPassword');
    },

    // should we show the send verification email
    showSendVerification() {
      return this.showSendEmailAction('sendVerificationEmail');
    },

    // should we show the send welcome email
    showSendWelcome() {
      return this.showSendEmailAction('sendWelcomeEmail');
    },

    // format the user selection label
    usersSelectedLabel() {
      const count = this.selectedRows.length;
      return count === 1
        ? this.$t('sprConfig.reportUsersSelected.single', { count })
        : this.$t('sprConfig.reportUsersSelected.multiple', { count });
    },
  },

  watch: {
    // called when the list of users changes
    users() {
      this.$nextTick(() => {
        this.onChange_RowSelections();
      });
    },
  },

  beforeDestroy() {
    // hide any messages added from clicking on user
    this.$store.dispatch('message/hide');
  },

  beforeMount() {
    this.columnDefs = [
      {
        cellStyle: { textAlign: 'left' },
        checkboxSelection: this.hasUserEditFeatureControl,
        field: 'formattedName',
        headerCheckboxSelection: this.hasUserEditFeatureControl,
        headerName: i18n.t('userManagement.userTableColumns.name'),
      },
      {
        cellStyle: { textAlign: 'left' },
        field: 'email',
        headerName: i18n.t('userManagement.userTableColumns.email'),
      },
      this.userListType === constants.USER_LIST.ALL_INTERNAL
        ? {
            cellStyle: { textAlign: 'left' },
            field: 'formattedRole',
            headerName: i18n.t('userManagement.userTableColumns.role'),
          }
        : {
            cellStyle: { textAlign: 'left' },
            field: 'formattedSites',
            headerName: i18n.t('userManagement.userTableColumns.sites'),
          },
      {
        cellStyle: { textAlign: 'left' },
        field: 'formattedStatus',
        headerName: i18n.t('userManagement.userTableColumns.status'),
        width: 225,
      },
    ];
    if (this.enableRowAction) {
      this.columnDefs.push(this.rowActionColumn);
    }
  },

  methods: {
    // clear the selections
    clearSelections() {
      if (this.gridApi) {
        this.gridApi.deselectAll();
        this.gridApi.deselectAllFiltered();
      }
    },

    // format last login date
    formatLastLoginDate(datetime) {
      const date = new Date(datetime);
      if (!(date instanceof Date) || Number.isNaN(date) || datetime === null) return datetime;
      const formatter = new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      });
      const parts = new Map(formatter.formatToParts(date).map(part => [part.type, part.value]));
      // MM/DD/YY
      return `${parts.get('month')}/${parts.get('day')}/${parts.get('year')}`;
    },

    // join user account statuses by event type for a given action
    joinAccountStatusesByEvent(action) {
      let accountStatuses = [];
      Object.keys(this.sendEmailActions).forEach(key => {
        if (this.sendEmailActions[key].eventType === this.sendEmailActions[action].eventType) {
          accountStatuses = accountStatuses.concat(this.sendEmailActions[key].status);
        }
      });
      // return account statuses without any duplicates
      return Array.from(new Set(accountStatuses));
    },

    // handle adding a user
    onAddUser() {
      this.$emit('onAddUser', this.userListType);
    },

    // handle adding users in bulk
    onBulkAdd() {
      this.$emit('onBulkAdd', this.userListType);
    },

    // called each time the row check box selections change
    onChange_RowSelections() {
      this.selectedRows = this.gridApi.getSelectedRows();
      if (this.hasUserEditFeatureControl) {
        if (
          this.selectedUsers.length > 0 &&
          !(this.showSendWelcome || this.showResendWelcome) &&
          this.isWelcomeEmailEligible
        ) {
          this.$store.dispatch('message/display', {
            text: this.$t('userManagement.addUsers.bulk.welcomeUnavailable'),
            type: 'warning',
          });
        } else {
          this.$store.dispatch('message/hide');
        }
      }
    },

    // handles deleting the user
    onDelete() {
      if (!this.hasSelections) return;
      this.$emit('onDelete', this.userListType, this.selectedUsers);
    },

    // handles editing the user
    onEdit() {
      if (!this.hasSelections) return;
      this.$emit('onEdit', this.userListType, this.selectedUsers[0]);
    },

    // called after the table loads
    onGridReady(api) {
      this.gridApi = api;
    },

    // handles manage sites
    onManage() {
      if (!this.hasSelections) return;
      this.$emit('onManage', this.userListType, this.selectedUsers);
    },

    // handle actions based on user's status - i.e. send welcome email, etc.
    onSendEmailAction(action) {
      if (!this.hasSelections) return;
      if (this.sendEmailActions[action]) {
        const accountStatuses = this.joinAccountStatusesByEvent(action);
        // filter users with given account status for event type
        const selectedUsers = this.selectedUsers.filter(u => {
          return accountStatuses.includes(u.accountStatus);
        });
        this.$emit(
          'onSendEmail',
          this.sendEmailActions[action].eventType,
          this.userListType,
          selectedUsers,
        );
      }
    },

    // handles viewing the details
    onViewDetails() {
      if (!this.hasSelections) return;
      this.$emit('onViewDetails', this.userListType, this.selectedUsers[0]);
    },

    // callback for row level actions
    performRowAction(/* e, row, action */) {},

    // determine whether to display an action based on user account status and site status
    showSendEmailAction(action) {
      const HIDE_ACTIONS = ['resendWelcomeEmail', 'sendWelcomeEmail'];

      return !!this.selectedUsers.find(u => {
        let allConfirmed = true;
        if (HIDE_ACTIONS.includes(action)) {
          const allSitesGroup = u.sites.some(site => site === 'all_sites');
          const userSites = allSitesGroup
            ? this.$store.getters['sites/getSites'].map(site => site.id)
            : u.sites;

          allConfirmed = allowWelcomeEmail.containsConfirmedSite(userSites);
        }

        return (
          allConfirmed &&
          this.sendEmailActions[action] &&
          this.sendEmailActions[action].status.indexOf(u.accountStatus) >= 0
        );
      });
    },
  },
};
</script>

<style lang="scss">
@use "sass:math";
@import '@/assets/css/variables.scss';
@import '@/assets/css/calc.scss';
.users-table-container {
  .users-table-controls {
    padding-left: 3rem;
    padding-right: 2rem;
    background: #f9fafc;
    min-height: rem(56px);
    font-size: 0.875rem;
    button {
      margin-left: rem(24px);
      color: $c-primary-a11y-dark;
      line-height: 1.15;
      &.c-btn--disabled {
        color: $c-gray-6;
      }
      &.needs-selection {
        color: $c-ctrl-color-dark;
      }
    }
    .table-label {
      font-size: 1rem;
      font-weight: 500;
    }
  }
  .users-table {
    .ag-root-wrapper-body.ag-layout-normal {
      height: initial;
    }
    .ag-row,
    .ag-header-row {
      color: $c-primary-dark;
    }
  }
}
</style>
