<template>
  <div class="flex flex-col text-center whitespace-nowrap">
    <p class="text-primary-dark text-xl">{{ title }}</p>
    <p class="mt-6" :class="statusTextStyleClasses">
      {{ statusText }}
    </p>
    <p v-if="statusTextTwo" class="mt-2.5" :class="statusTextTwoStyleClasses">
      {{ statusTextTwo }}
    </p>
    <p v-if="statusDate" class="mb-auto mt-2.5 text-gray-6 text-xs">
      {{ statusDate }}
    </p>
    <BaseButton
      v-if="buttonShow"
      :disabled="buttonDisabled"
      :size="'large'"
      class="mt-6 mx-auto"
      @click="onClick()"
      >{{ buttonText }}</BaseButton
    >
  </div>
</template>

<script>
import { BaseButton } from '@seegrid/components';

export default {
  name: 'SiteSettings',

  components: {
    BaseButton,
  },

  props: {
    buttonDisabled: {
      default: false,
      type: Boolean,
    },
    buttonShow: {
      default: false,
      type: Boolean,
    },
    buttonText: {
      required: true,
      type: String,
    },
    statusDate: {
      default: '',
      type: String,
    },
    statusText: {
      required: true,
      type: String,
    },
    statusTextStyleType: {
      default: '',
      type: String,
    },
    statusTextTwo: {
      default: '',
      type: String,
    },
    statusTextTwoStyleType: {
      default: '',
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    titleStyleType: {
      default: '',
      type: String,
    },
  },
  computed: {
    statusTextStyleClasses() {
      return `${this.statusTextTwo && this.statusDate ? '' : 'mb-auto'} ${this.textStyle(
        this.statusTextStyleType,
      )}`;
    },
    statusTextTwoStyleClasses() {
      return `${this.statusDate ? '' : 'mb-auto'} ${this.textStyle(this.statusTextTwoStyleType)}`;
    },
  },
  methods: {
    // event handler for clicking the button
    async onClick() {
      this.$emit('button-click');
    },

    // returns the appropriate text style based on the given type
    textStyle(type) {
      switch (type) {
        case 'negative':
          return 'text-status-negative-dark';
        case 'positive':
          return 'text-primary-dark';
        case 'warning':
          return 'text-status-warning-dark';
        default:
          return 'text-primary-dark';
      }
    },
  },
};
</script>
