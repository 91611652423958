const defaultDomain = 'seegridfleetcentral.com';
const requiredDomains = ['seegrid.com', defaultDomain];
const knownDomains = ['localhost'].concat(requiredDomains);

// This file is copied between AMP and dashboard code base, keep them in sync until library created

const RawEnvironment = {
  getEnvironmentObjectSafely(environmentValue) {
    try {
      const maybeObject = JSON.parse(environmentValue);
      const type = typeof maybeObject;
      if (['string', 'boolean'].some(t => t === type)) {
        return undefined;
      }
      return maybeObject;
    } catch (e) {
      return undefined;
    }
  },

  getEnvironmentValueConsideringLocation(environmentKey) {
    // Step 1. Is there even a value to consider?
    const environmentValue = process.env[environmentKey];

    if (!environmentValue) {
      return environmentValue;
    }

    // Step 2. Is the value a JSON object?
    const environmentObject = this.getEnvironmentObjectSafely(environmentValue);
    if (!environmentObject) {
      return environmentValue;
    }

    // Step 3. Do the keys match the set of required domains for being considered a location-based
    // configuration value?
    if (!requiredDomains.every(domain => domain in environmentObject)) {
      return environmentValue;
    }

    return this.getValueForLocationEnvironmentObject(environmentObject);
  },

  getValueForLocationEnvironmentObject(environmentObject) {
    const { hostname } = window.location;

    const environmentMatch = knownDomains.find(domain => hostname.endsWith(domain));
    if (!environmentMatch) {
      console.error(`Location [${hostname}] is unsupported by environment, using default`);
      return environmentObject[defaultDomain];
    }

    return environmentObject[environmentMatch] || environmentObject[defaultDomain];
  },
};

export default new Proxy(RawEnvironment, {
  get(target, prop) {
    let result;
    if (prop in target) {
      result = target[prop];
    } else {
      result = target.getEnvironmentValueConsideringLocation(prop);
      // Cache the result for efficiency and avoiding lots of log spam for the above
      if (result) {
        // eslint-disable-next-line no-param-reassign
        target[prop] = result;
      }

      if (process.env.NODE_ENV === 'development' && result) {
        if (result) {
          console.log(`${prop}: ${result}`);
        } else {
          console.warn(`Expected value for environment ${prop}`);
        }
      }
    }

    return result;
  },
  set(target, prop, value) {
    if (prop in target) {
      // eslint-disable-next-line no-param-reassign
      target[prop] = value;
    } else {
      process.env[prop] = value;
    }

    return true;
  },
});
