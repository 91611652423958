<template>
  <BaseModalCustom ref="usersmodal" size="xlarge">
    <section class="custom-modal-header flex items-center mx-7">
      <BaseButton
        button-type="ghost"
        icon="close"
        icon-height="24px"
        icon-width="24px"
        viewBox="0 0 24 24"
        just-icon
        @click="close"
      />
      <h2 class="flex-grow font-medium mx-4 text-2xl text-left text-primary-dark">
        {{ modalTitle }}
      </h2>
      <div class="flex">
        <BaseButton button-type="secondary" @click="close">
          {{ $t('reportConfigForm.cancel') }}
        </BaseButton>
        <BaseButton button-type="primary" class="ml-3" :disabled="!usersSelected" @click="addUsers">
          {{ $t('reportConfigForm.add') }}
        </BaseButton>
      </div>
    </section>
    <BaseLocalMessage class="mx-7 my-8">
      <span class="ml-3.5 text-primary-dark2 text-sm">
        {{ $t('reportConfigForm.addUserHelpText') }}
      </span>
      <a
        class="cursor-pointer ml-1 text-primary-lightBlue text-sm underline"
        @click="navigateToAccntMgmnt"
      >
        {{ $t('reportConfigForm.accountMgmntLink') }} &#8594;
      </a>
    </BaseLocalMessage>
    <section
      class="bg-gray-1 border-opacity-10 bottom-border flex items-center justify-end mb-0 mt-8 mx-7 px-2 py-2.5"
    >
      <BaseSearch
        class="w-72"
        :min-characters-to-search="1"
        :placeholder="$t('reportConfigForm.searchUser')"
        @search-updated="onSearchUpdate"
      />
    </section>
    <section class="mx-7 pb-8 user-table-container">
      <SprUsersTable
        v-if="modalOpen"
        key="usersList"
        ref="usersList"
        :enable-row-action="false"
        :height="'60vh'"
        :user-search="userSearch"
        :show-internal-users="true"
        :users="filteredUsers"
        @selection-changed="onSelectionChanged"
      />
    </section>
  </BaseModalCustom>
</template>

<script>
import { BaseButton, BaseSearch } from '@seegrid/components';
import IconSprite from '@/assets/images/icon-sprite.svg';
import BaseLocalMessage from '@/components/BaseLocalMessage.vue';
import BaseModalCustom from '@/components/BaseModalCustom.vue';
import SprUsersTable from '@/components/sprconfig/SprUsersTable.vue';

export default {
  name: 'SprUsersModal',
  components: {
    BaseButton,
    BaseLocalMessage,
    BaseModalCustom,
    BaseSearch,
    SprUsersTable,
  },
  props: {
    reportName: {
      default: '',
      type: String,
    },
    users: {
      default() {
        return [];
      },
      type: Array,
    },
  },
  data() {
    return {
      modalOpen: false,
      selectedUsers: [],
      userSearch: '',
    };
  },
  computed: {
    filteredUsers() {
      let { users } = this;
      users = this.filterUsersByNameEmail(users);
      return users;
    },
    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },
    modalTitle() {
      return this.$t('reportConfigForm.addUserModalTitle', {
        reportName: this.reportName ? this.reportName : this.$t('reportConfigForm.report'),
      });
    },
    usersSelected() {
      return this.selectedUsers && this.selectedUsers.length > 0;
    },
  },
  methods: {
    addUsers() {
      this.$emit('users-added', this.selectedUsers);
      this.$nextTick(() => {
        this.close();
      });
    },
    close() {
      this.$refs.usersmodal.close();
      this.modalOpen = this.$refs.usersmodal.modalOpen;
    },
    // returns an array of users that match the search criteria, if any
    filterUsersByNameEmail(users) {
      return this.userSearch
        ? users.filter(user => {
            return (
              user.name.toLowerCase().includes(this.userSearch.toLowerCase()) ||
              user.email.toLowerCase().includes(this.userSearch.toLowerCase())
            );
          })
        : users;
    },
    async navigateToAccntMgmnt() {
      this.close();
      await this.$nextTick();
      this.$emit('navigate-to-am');
    },
    // event handler for the search input
    onSearchUpdate(event) {
      this.selectedUsers = [];
      this.userSearch = event.searchString;
    },
    onSelectionChanged(rows) {
      this.selectedUsers = rows || [];
    },
    open() {
      this.resetState();
      this.$refs.usersmodal.open();
      this.modalOpen = this.$refs.usersmodal.modalOpen;
    },
    resetState() {
      this.selectedUsers = [];
      this.userSearch = '';
      if (this.$refs.usersList) {
        this.$refs.usersList.clearSelections();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-table-container {
  min-height: 50vh;
  max-height: calc(95vh - 17rem);
  overflow: auto;
}
::v-deep .c-modal__body-container {
  padding: 0;
}
</style>
