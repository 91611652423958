// class for representing and managing a route network segment
export default class RouteNetworkSegment {
  constructor(id, coordinates) {
    this.coordinates = coordinates;
    this.id = id;
  }

  // constructs a RouteNetworkSegment from the json returned by the api
  static fromJSON(segment) {
    return new RouteNetworkSegment(segment.id, segment.coordinates);
  }
}
