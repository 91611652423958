import has from 'lodash-es/has';
import cerberusRouteNetworkAPI from '@/api/cerberusRouteNetworkAPI';
import RouteNetworkSegment from '@/models/RouteNetworkSegment';
import RouteNetworkStation from '@/models/RouteNetworkStation';

// class for representing and managing a route network
export default class RouteNetwork {
  constructor() {
    this.id = null;
    this.mapDimensions = null;
    this.mapPath = null;
    this.segments = [];
    this.stations = [];
  }

  // returns the route network segments in the format expected by the map component
  getMapSegments() {
    return this.segments;
  }

  // returns the route network stations in the format expected by the map component
  getMapStations() {
    return [
      {
        id: 'stations',
        points: this.stations,
      },
    ];
  }

  /**
   * load the route network data with the given parameters
   * return a route network object on success or null otherwise
   */
  static async loadRouteNetwork(parameters) {
    const routeNetwork = new RouteNetwork();

    const imagesPromise = routeNetwork.loadRouteNetworkImages(parameters);
    const segmentsPromise = routeNetwork.loadRouteNetworkSegments(parameters);
    const stationsPromise = routeNetwork.loadRouteNetworkStations(parameters);

    await Promise.all([imagesPromise, segmentsPromise, stationsPromise]);

    // if the route network object is invalid return null
    if (!routeNetwork.mapPath) {
      return null;
    }

    return routeNetwork;
  }

  // load the route network image with the given parameters
  async loadRouteNetworkImages(parameters) {
    this.mapDimensions = null;
    this.mapPath = null;

    const response = await cerberusRouteNetworkAPI.getRouteNetworkImages(parameters);

    // if the result is valid set the route network map
    if (has(response, 'data') && Array.isArray(response.data) && response.data.length > 0) {
      this.mapDimensions = response.data[0].dimensions;
      this.mapPath = response.data[0].path;
    }
  }

  // load the latest route network segments with the given parameters
  async loadRouteNetworkSegments(parameters) {
    this.segments = [];

    const response = await cerberusRouteNetworkAPI.getRouteNetworkSegments(parameters);

    // if the result is valid add each route network segment
    if (has(response, 'data') && Array.isArray(response.data)) {
      for (let i = 0; i < response.data.length; i += 1) {
        this.segments.push(RouteNetworkSegment.fromJSON(response.data[i]));
      }
    }
  }

  // load the latest route network stations with the given parameters
  async loadRouteNetworkStations(parameters) {
    this.stations = [];

    const response = await cerberusRouteNetworkAPI.getRouteNetworkStations(parameters);

    // if the result is valid add each route network station
    if (has(response, 'data') && Array.isArray(response.data)) {
      for (let i = 0; i < response.data.length; i += 1) {
        this.stations.push(RouteNetworkStation.fromJSON(response.data[i]));
      }
    }
  }
}
