<template>
  <div class="max-w-sm mt-8">
    <section>
      <ShiftInputRow
        v-for="(shift, index) in shifts"
        :key="`shift-${index}`"
        :shift-index="index"
        :schedule-index="scheduleIndex"
        :shift="shift"
        :validate="validate"
        @delete="deleteShift($event)"
      />
    </section>

    <div class="bottom-0 btn-bg">
      <BaseButton
        v-if="hasFeatureControl(FEATURES.SCHEDULE, CONTROL_LEVELS.EDITOR)"
        size="small"
        button-type="tertiary"
        class=""
        @click="addShift"
        >{{ $t('formShift.addNewShift') }}</BaseButton
      >
    </div>
  </div>
</template>

<script>
import { BaseButton } from '@seegrid/components';
import ShiftInputRow from '@/components/ShiftInputRow.vue';
import authorization from '@/mixins/authorization';

export default {
  name: 'FormShift',

  components: {
    BaseButton,
    ShiftInputRow,
  },
  mixins: [authorization],
  props: {
    scheduleIndex: {
      type: Number,
      required: true,
    },
    shifts: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      validate: false,
    };
  },

  methods: {
    // event handler for clicking the add shift link
    addShift() {
      this.$store.dispatch('schedules/addNewShift', { scheduleIndex: this.scheduleIndex });
    },

    // event handler for clicking the remove shift link
    async deleteShift(index) {
      // hack to trigger vuelidate form validation on the child components
      this.$emit('trigger-validate');
      await this.$nextTick();

      this.$store.dispatch('schedules/deleteShift', {
        scheduleIndex: this.scheduleIndex,
        shiftIndex: index,
      });
    },
  },
};
</script>
