<template>
  <BaseInput
    :label="label"
    :placeholder="placeholder"
    :error="error"
    :hidden="hidden"
    :value="value"
    @input-updated="$emit('input', $event)"
  />
</template>

<script>
import { BaseInput } from '@seegrid/components';

export default {
  name: 'InputText',
  components: { BaseInput },
  props: {
    error: {
      type: String,
      default: '',
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      required: true,
    },
  },
};
</script>
