<template>
  <div
    class="bg-white border border-gray-5 border-solid company-selector flex flex-col h-full pt-4 rounded text-primary-dark w-full"
  >
    <div class="px-8">
      <img src="../assets/images/logo.png" class="mb-8 mx-auto w-26" />
      <label class="font-medium text-xl">{{ $t('companies.selectACompany') }}</label>
      <BaseSearch
        class="mb-4 mt-4"
        :autocomplete="'off'"
        :min-characters-to-search="1"
        :placeholder="$t('search')"
        @search-updated="onSearchUpdated"
      />

      <Spinner v-if="loadingCompanies" :is-local="true" />
    </div>

    <div class="flex-grow overflow-auto">
      <div v-if="companySearchResultError" class="company-search-result">
        {{ companySearchResultError }}
      </div>

      <transition-group v-if="!loadingCompanies" name="company-transition">
        <div
          v-for="company in foundCompanies"
          :key="company.id"
          :class="{ 'border-primary-dark': companyId === company.id }"
          class="company-search-result hover:bg-gray-1"
          @click="onCompanySelected(company.id)"
        >
          {{ company.name }}
        </div>
      </transition-group>
    </div>

    <a
      role="button"
      class="bg-primary-a11y-dark duration-200 enter-btn opacity-25 p-5 pointer-events-none text-center text-white transition-opacity"
      :class="companyId ? ['opacity-100', 'cursor-pointer', 'pointer-events-auto'] : []"
      @click="onEnter"
    >
      {{ $t('companies.enter') }}
    </a>
  </div>
</template>

<script>
import { BaseSearch } from '@seegrid/components';
import Spinner from '@/components/Spinner.vue';
import router from '@/router';

export default {
  name: 'CompanySelector',

  components: {
    BaseSearch,
    Spinner,
  },

  data() {
    return {
      companyId: null,
      searchString: '',
    };
  },

  computed: {
    companies() {
      return this.$store.getters['companies/getCompanies'];
    },

    companySearchResultError() {
      if (this.foundCompanies.length === 0) {
        if (this.searchString) {
          return this.$t('companies.noMatches', { searchString: this.searchString });
        }

        if (!this.loadingCompanies) {
          return this.$t('companies.noCompanies');
        }
      }

      return '';
    },

    foundCompanies() {
      return !this.searchString
        ? this.companies
        : this.companies.filter(
            company => company.name.toLowerCase().search(this.searchString.toLowerCase()) !== -1,
          );
    },

    // returns true if company data is loading or false otherwise
    loadingCompanies() {
      return this.$store.getters['companies/getLoading'];
    },
  },

  methods: {
    onCompanySelected(companyId) {
      this.companyId = companyId;
    },
    async onEnter() {
      await this.$store.dispatch('companies/setSelectedCompany', this.companyId);
      await this.$nextTick();
      router.push({ name: 'sites' });
    },
    onSearchUpdated({ searchString }) {
      this.searchString = searchString;
      // if the user searches for something that doesn't exist, clear their selected company
      const filteredCompanyIds = this.foundCompanies.map(company => company.id);
      if (this.companyId && !filteredCompanyIds.includes(this.companyId)) {
        this.companyId = null;
      }
    },
  },
};
</script>

<style scoped>
.company-selector {
  max-height: 40rem;
  max-width: 32rem;
}

.company-transition-enter-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.company-transition-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.company-transition-enter,
.company-transition-leave-to {
  opacity: 0;
  transform: translateX(-16px);
}
</style>
