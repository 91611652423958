const API_ERROR_TYPES = {
  BULK_USER_PARTIAL_FAILED: 'bulkUsersPartiallyFailed',
  DELETE: 'delete',
  LOAD: 'load',
  PROCESS: 'process',
  SAVE: 'save',
  UPLOAD: 'upload',
};

const AUTH0 = {
  ALL_COMPANIES: 'all_companies',
  ALL_SITES: 'all_sites',
  INTERNAL: 'seegrid_internal',
  NAMESPACE: {
    APP_METADATA: 'app_metadata',
    AUTHORIZATION: 'user_authorization',
  },
  PAGE_LIMIT: 50,
};

const DAYS = {
  FRIDAY: 'FRIDAY',
  MONDAY: 'MONDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
  THURSDAY: 'THURSDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
};

// feature control levels used to determine user access to UI components
const CONTROL_LEVELS = {
  DISALLOWED: { name: 'DISALLOWED', value: 0 },
  EDITOR: { name: 'EDITOR', value: 2 },
  OWNER: { name: 'OWNER', value: 3 },
  VIEWER: { name: 'VIEWER', value: 1 },
};

const FEATURES = {
  ADMIN_COMPANY_SELECTION: 'admin_company_selection',
  MAP: 'map',
  SCHEDULE: 'schedule',
  SITE_REGISTRATION: 'site_registration',
  SPR: 'spr',
  USER: 'user',
};

const IMPORT_STATUS = {
  COMPLETE: 'complete',
  ERROR: 'error',
  PENDING: 'pending',
  PROCESSING: 'processing',
};

const MULTISELECT = {
  SELECT_ALL: 'select_all',
};

const SITE_STATE = {
  COMPLETE: 'complete',
  INCOMPLETE: 'incomplete',
};

const SUPERVISOR_BACKUP_EXTRACTION_TYPES = {
  EXTRACT_MAP_IMAGE: 'extract_map_image',
  EXTRACT_ROUTE_NETWORK: 'extract_route_network_data',
};

const TIME_ZONES = [
  { text: 'America/New_York (EST)', value: 'America/New_York' },
  { text: 'America/Chicago (CST)', value: 'America/Chicago' },
  { text: 'America/Denver (MST)', value: 'America/Denver' },
  { text: 'America/Los_Angeles (PST)', value: 'America/Los_Angeles' },
  { text: 'America/Indiana/Knox', value: 'America/Indiana/Knox' },
  { text: 'America/Phoenix', value: 'America/Phoenix' },
];

const SPR_DELIVERIES = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
};

const SPR_DELIVERY_STATUS = {
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
};

const USER_LIST = {
  ALL_INTERNAL: 'all-internal',
  COMPANY: 'company',
  COMPANY_INTERNAL: 'company-internal',
};

const USER_ROLES = {
  ADMIN: 'Super Admin',
  CUSTOMER_ACCOUNT_ADMIN: 'Customer Account Admin',
  CUSTOMER_SUCCESS_MANAGER: 'Customer Success Manager',
  CUSTOMER_SUCCESS_SPECIALIST: 'Customer Success Specialist',
  DASHBOARD: 'Dashboard User',
  GLOBAL_DEFAULT_USER: 'Global Default User',
  GLOBAL_PLATFORM_ADMIN: 'Global Platform Admin',
  GLOBAL_USER_ADMIN: 'Global User Admin',
  TECH_SUPPORT: 'Tech Support',
};

const USER_ROLES_ABBREVIATIONS = {
  'Customer Account Admin': 'CAA',
  'Customer Success Manager': 'CSM',
  'Customer Success Specialist': 'CSS',
  'Dashboard User': 'DU',
  'Global Default User': 'GDU',
  'Global Platform Admin': 'GPA',
  'Global User Admin': 'GUA',
  'Super Admin': 'SA',
  'Tech Support': 'TS',
};

const USER_ROLES_GLOBAL = [
  USER_ROLES.GLOBAL_DEFAULT_USER,
  USER_ROLES.GLOBAL_PLATFORM_ADMIN,
  USER_ROLES.GLOBAL_USER_ADMIN,
];

const USER_ROLES_INTERNAL = [
  USER_ROLES.ADMIN,
  USER_ROLES.CUSTOMER_ACCOUNT_ADMIN,
  USER_ROLES.CUSTOMER_SUCCESS_MANAGER,
  USER_ROLES.CUSTOMER_SUCCESS_SPECIALIST,
  USER_ROLES.TECH_SUPPORT,
];

const USER_SITES_ACTIONS = {
  ADD_TO_SITES: 'add',
  REMOVE_FROM_SITES: 'remove',
};

const USER_ACCOUNT_STATUS = {
  ACTIVE: 'ACTIVE',
  INVITED: 'INVITED',
  UNINVITED: 'UNINVITED',
  UNVERIFIED: 'UNVERIFIED',
  VERIFIED: 'VERIFIED',
};

const CERBERUS_CLIENT_TYPES = {
  GLOBAL: 'global',
  LOGIN: 'login',
  ORG_BASED: 'orgBased',
};

const USER_EMAIL_TYPES = {
  RESET_PASSWORD: 'onResetPassword',
  VERIFICATION: 'onSendVerification',
  WELCOME: 'onSendWelcome',
};

const HTTP_STATUSES = {
  CREATED: 201,
  MULTI_STATUS: 207,
};

export default Object.freeze({
  API_ERROR_TYPES,
  ARCHIVE_FILE_FORMATS: ['.tar', '.gz'],
  AUTH0,
  BATCH_LATEST: 'latest',
  BATCH_PENDING: 'pending',
  CERBERUS_CLIENT_TYPES,
  CONTROL_LEVELS,
  DAYS,
  DAY_HOURS: 24,
  FEATURES,
  HOUR_MINUTES: 60,
  HOUR_SECONDS: 3600,
  HTTP_STATUSES,
  IMPORT_STATUS,
  MODAL_CANCEL: 'actionOne',
  MODAL_CONFIRM: 'actionTwo',
  MULTISELECT,
  SCHEDULE_LIST_ELEMENT_ID: 'schedule-list-container',
  SEEGRID_DEF_ORG_ID: 'seegrid',
  SEEGRID_DOMAIN: 'seegrid.com',
  SITE_STATE,
  SPR_DEFAULT_SEND_TIME: '00:00',
  SPR_DELIVERIES,
  SPR_DELIVERY_STATUS,
  SUPERVISOR_BACKUP_EXTRACTION_TYPES,
  TIME_ZONES,
  USER_ACCOUNT_STATUS,
  USER_EMAIL_TYPES,
  USER_LIST,
  USER_MODAL_ADD: 'add',
  USER_MODAL_EDIT: 'edit',
  USER_ROLES,
  USER_ROLES_ABBREVIATIONS,
  USER_ROLES_GLOBAL,
  USER_ROLES_INTERNAL,
  USER_SITES_ACTIONS,
  WEEK_HOURS: 168,
});
