import { cerberusClient } from './cerberusClient';
import Routes from './cerberusRoutes';

// DEL /companies/{company_id}/sites/{site_id}/report-schedules/{schedule_id}
const deleteReportSchedules = (companyId, siteId, scheduleId) => {
  if (!companyId || !siteId || !scheduleId) throw new Error('missing params');
  const route = Routes.general.COMPANIES_REPORT_SCHEDULES_SITE_SCHEDULE.replace(
    ':company_id',
    companyId,
  )
    .replace(':site_id', siteId)
    .replace(':schedule_id', scheduleId);
  return cerberusClient().delete(route);
};

// GET /companies/{company_id}/report-schedules
const getReportSchedules = companyId => {
  const route = Routes.general.COMPANIES_REPORT_SCHEDULES.replace(':company_id', companyId);

  return cerberusClient()
    .get(route)
    .catch(err => {
      // Ignore 404 as when no schedule is present API returns 404
      if (err && err.response && err.response.status !== 404) {
        throw err;
      }
    });
};

// POST /companies/{company_id}/sites/{site_id}/report-schedules
const postReportSchedules = (companyId, siteId, data) => {
  if (!companyId || !siteId || !data) throw new Error('missing params');
  const route = Routes.general.COMPANIES_REPORT_SCHEDULES_SITE.replace(
    ':company_id',
    companyId,
  ).replace(':site_id', siteId);
  return cerberusClient()
    .post(route, data)
    .catch(err => {
      throw err;
    });
};

// PUT /companies/{company_id}/sites/{site_id}/report-schedules/{schedule_id}
const putReportSchedules = (companyId, siteId, scheduleId, data) => {
  if (!companyId || !siteId || !scheduleId || !data) throw new Error('missing params');
  const route = Routes.general.COMPANIES_REPORT_SCHEDULES_SITE_SCHEDULE.replace(
    ':company_id',
    companyId,
  )
    .replace(':site_id', siteId)
    .replace(':schedule_id', scheduleId);
  return cerberusClient()
    .put(route, data)
    .catch(err => {
      throw err;
    });
};

export default {
  deleteReportSchedules,
  getReportSchedules,
  postReportSchedules,
  putReportSchedules,
};
