<template>
  <BaseModalCustom
    ref="userModal"
    class="c-modal__edit-user-modal"
    :action-one="$t('userManagement.editUser.cancel')"
    :action-two="$t('userManagement.editUser.save')"
    :close-on-esc="false"
    :heading="$t('userManagement.editUser.title')"
    @modal-button-clicked="onModalButton"
  >
    <EditUserForm
      ref="userForm"
      :user="userData"
      :validate="validate"
      :company="company"
      :current-users="currentUsers"
      :email-updated="emailUpdated"
      @update-form="updateUserData"
      @validate-form-result="validateUserDataResult"
    />
  </BaseModalCustom>
</template>
<script>
import cloneDeep from 'lodash-es/cloneDeep';
import BaseModalCustom from '@/components/BaseModalCustom.vue';
import EditUserForm from '@/components/users/EditUserForm.vue';
import constants from '@/config/constants';
import Company from '@/models/Company';
import allowWelcomeEmail from '@/util/allowWelcomeEmail';

export default {
  name: 'EditUserModal',

  components: {
    BaseModalCustom,
    EditUserForm,
  },
  props: {
    company: {
      default: null,
      type: Company,
    },
    currentUsers: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userData: cloneDeep(this.user),
      validForm: true,
      validate: false,
    };
  },
  computed: {
    emailUpdated() {
      return (
        this.userData &&
        this.user &&
        this.userData.email &&
        this.user.email &&
        this.userData.email.trim().toLowerCase() !== this.user.email.trim().toLowerCase()
      );
    },
  },
  watch: {
    user() {
      this.userData = cloneDeep(this.user);
    },
  },
  methods: {
    close() {
      this.$refs.userModal.close();
    },
    // display the error message for the given type
    displayErrorMessage(type) {
      this.$store.dispatch('message/display', {
        text: this.$t(`userManagement.error.${type}`),
        type: 'negative',
      });
    },
    // called when the user clicks the modal button
    onModalButton(event) {
      if (event.action === constants.MODAL_CONFIRM) {
        this.validate = true;
      } else if (event.action === constants.MODAL_CANCEL) {
        this.promptOnCancel();
      }
    },
    // show the form
    open() {
      this.$refs.userModal.open();
    },
    // handles when the user clicks cancel when editing user
    promptOnCancel() {
      if (!this.$refs.userForm.$v.$anyDirty) return;
      this.$emit('prompt-on-cancel');
    },
    // reset the form between opens
    reset() {
      this.userData = cloneDeep(this.user);
      this.$refs.userForm.$v.$reset();
    },
    // called to save the current user
    async saveUser() {
      if (!(this.company instanceof Company)) {
        throw new TypeError('company is required to save user');
      }

      await this.$store.dispatch('users/setLoading', true);

      try {
        await this.$store.dispatch('users/saveUser', {
          company: this.company,
          invite: false,
          user: this.userData,
        });
        const welcomeEmailEligible = allowWelcomeEmail.containsConfirmedSite(this.userData.sites);
        if (this.emailUpdated && welcomeEmailEligible) {
          await this.$store.dispatch('users/sendEmail', {
            emailType: constants.USER_EMAIL_TYPES.VERIFICATION,
            userIdList: [this.userData.id],
          });
        }
      } catch (error) {
        console.error(JSON.stringify(error));
        this.displayErrorMessage(constants.API_ERROR_TYPES.SAVE);
      }

      await this.$store.dispatch('users/setLoading', false);
    },
    // called as the user entered new form data
    updateUserData(event) {
      switch (event.field) {
        case 'firstName': {
          this.userData.firstName = event.value;
          this.userData.name = `${this.userData.firstName} ${this.userData.lastName}`;
          break;
        }
        case 'lastName': {
          this.userData.lastName = event.value;
          this.userData.name = `${this.userData.firstName} ${this.userData.lastName}`;
          break;
        }
        case 'selectedSites': {
          this.userData.sites = event.value;
          break;
        }
        default: {
          this.userData[event.field] = event.value;
          break;
        }
      }
    },
    // called after the user data has been validated
    async validateUserDataResult(result) {
      this.validate = false;
      this.validForm = result;
      if (this.validForm) {
        await this.saveUser();
      } else {
        // Form is invalid, reopen form after save button click
        this.$refs.userModal.open();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.c-modal__edit-user-modal {
  ::v-deep .c-modal__heading,
  ::v-deep .c-modal__subheading,
  ::v-deep .c-modal__body-container {
    text-align: left;
  }
  ::v-deep .c-modal__body-container {
    padding-bottom: 1rem;
  }
  ::v-deep .c-modal-mask {
    display: block;
    .c-modal {
      margin-top: 1rem;
    }
  }
  @media (min-height: 600px) {
    ::v-deep .c-modal-mask {
      display: block;
      .c-modal {
        margin-top: calc(15vh);
      }
    }
  }
  @media (min-height: 900px) {
    ::v-deep .c-modal-mask {
      display: block;
      .c-modal {
        margin-top: calc(33vh);
      }
    }
  }
}
</style>
