<template>
  <div class="flex-grow">
    <div class="flex justify-between pb-4">
      <InputText
        class="w-64"
        :label="$t('reportConfigForm.reportName')"
        :value="reportName"
        :error="errorReportName"
        @input="updateName"
      />
      <div v-if="isSprConfigComplete && !isSaving && !editMode" class="flex">
        <BaseButton
          button-type="secondary"
          size="small"
          @click="$emit('navigate', { name: 'sprConfigList' })"
        >
          {{ $t('reportConfigForm.cancel') }}
        </BaseButton>
        <BaseButton button-type="primary" class="ml-3" size="small" @click="saveReport">
          {{ $t('reportConfigForm.schedule') }}
        </BaseButton>
      </div>
    </div>
    <InputSelect
      :disabled="editMode"
      :label="$t('reportConfigForm.site')"
      class="pb-4 w-64"
      :options="siteOptions"
      :value="selectedSite"
      @input="updateSite"
    />
    <InputSelect
      :label="$t('reportConfigForm.type')"
      class="pb-4 w-64"
      :options="reportTypes"
      :value="selectedReportType"
      @input="updateReportType"
    />
    <InputSelect
      v-if="showDaySelect"
      :label="$t('reportConfigForm.dayToSend')"
      class="pb-4 w-64"
      :options="weekdayOptions"
      :value="selectedDay"
      @input="updateReportDay"
    />
    <label class="font-medium mb-2 pr-6 text-primary-dark text-xs">
      {{ $t('reportConfigForm.time') }}
    </label>
    <div class="flex pb-8">
      <InputTime
        width="16rem"
        time-picker-format="HH:mm"
        class="text-primary-dark w-64"
        :error="errorReportTime"
        :value="reportTime"
        label=""
        placeholder=" "
        @input="updateReportTime"
      />
      <span class="mt-2 mx-2 text-gray-6 text-xs">{{ siteTZ }}</span>
      <BaseLocalMessage v-if="isTimeToSendBeforeAutoSuggested" message-type="warning">
        <span class="ml-3.5 text-primary-dark2 text-sm">
          {{
            `${$t('reportConfigForm.timeToSendHelp')} ${$t('reportConfigForm.timeToSendWarning')}`
          }}
        </span>
      </BaseLocalMessage>
      <BaseLocalMessage v-else>
        <span class="ml-3.5 text-primary-dark2 text-sm">
          {{ $t('reportConfigForm.timeToSendHelp') }}
        </span>
      </BaseLocalMessage>
    </div>
    <p v-if="!editMode" class="font-medium mb-5 text-primary-dark text-xl">
      {{ $t('reportConfigForm.users') }}
    </p>
    <div v-if="!hasRecipients && !editMode" class="flex items-center">
      <label class="font-light pr-6 text-gray-6 text-sm">
        {{ $t('reportConfigForm.noUsers') }}
      </label>
      <BaseButton button-type="primary" icon="plus" @click="openUsersModal">
        {{ $t('reportConfigForm.addUser') }}
      </BaseButton>
    </div>
    <div v-if="hasRecipients && !editMode">
      <section
        class="bg-gray-1 border-opacity-10 bottom-border flex items-center justify-between px-9 py-2.5 top-border"
      >
        <span class="font-light mb-2 pr-6 text-primary-dark text-sm">
          {{ $t('reportConfigForm.userCount', { count: recipients.length }) }}
        </span>
        <div class="buttons-container flex">
          <BaseButton
            v-if="selectedUsers.length"
            button-type="ghost"
            class="mr-6"
            icon="trash"
            size="small"
            @click="onDeleteRecepientList"
          >
            {{ $t('reportConfigForm.delete') }}
          </BaseButton>
          <BaseButton button-type="tertiary" icon="plus" size="small" @click="openUsersModal">
            {{ $t('reportConfigForm.addUser') }}
          </BaseButton>
        </div>
      </section>
      <section class="pb-8 user-table-container">
        <SprUsersTable
          :current-company="company"
          :enable-row-action="true"
          :height="'15rem'"
          :show-internal-users="true"
          :users="recipients"
          @delete="onDeleteRecepient"
          @selection-changed="onSelectionChanged"
        />
      </section>
    </div>
    <div v-if="editMode">
      <BaseRadio
        v-for="radioOption in reportStatusOptions"
        :id="radioOption.value"
        :key="radioOption.value"
        v-model="reportStatus"
        class="font-medium text-xs"
        :label="radioOption.label"
        :sub-label="radioOption.subLabel"
        :value="radioOption.value"
        @change="onReportStatusChange"
      />
    </div>
    <SprUsersModal
      v-if="!editMode"
      ref="usersmodal"
      :users="users"
      @users-added="handleAddUsers"
      @navigate-to-am="$emit('navigate', { name: 'sites' })"
    />
    <SaveSprStatusModal
      v-if="!editMode"
      ref="savingStatusModal"
      :modal-text="'reportConfigForm.statusModal'"
      :report-site="reportSiteName"
      :report-type="$t(`reportConfigForm.${selectedReportType}`)"
      :report-time="reportTime"
      :report-day="reportDay"
      @navigate-back="savedGoBackToList"
      @retry-save="saveReport"
    />
    <ConfirmationModal
      ref="confirmNoSprRecipients"
      :confirm-title="$t('sprConfig.confirmNoSprRecipients.message')"
      :confirm-message="$t('sprConfig.confirmNoSprRecipients.subMessage')"
      :cancel-button="$t('sprConfig.confirmNoSprRecipients.cancel')"
      :confirm-button="$t('sprConfig.confirmNoSprRecipients.confirm')"
      @on-confirm-action="saveReport_Confirmed"
    />
  </div>
</template>

<script>
import { BaseButton } from '@seegrid/components';
import differenceWith from 'lodash-es/differenceWith';
import { DateTime, Duration } from 'luxon';
import { required } from 'vuelidate/lib/validators';
import BaseLocalMessage from '@/components/BaseLocalMessage.vue';
import BaseRadio from '@/components/inputs/BaseRadio.vue';
import InputSelect from '@/components/inputs/InputSelect.vue';
import InputText from '@/components/inputs/InputText.vue';
import InputTime from '@/components/inputs/InputTime.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import SaveSprStatusModal from '@/components/sprconfig/SaveSprStatusModal.vue';
import SprUsersModal from '@/components/sprconfig/SprUsersModal.vue';
import SprUsersTable from '@/components/sprconfig/SprUsersTable.vue';
import constants from '@/config/constants';
import i18n from '@/i18n';
import SPR from '@/models/SPR';
import getSprTimeBySchedule from '@/util/getSprTimeBySchedule';
import isValidTime from '@/util/isValidTime';

const weekdays = [
  constants.DAYS.MONDAY,
  constants.DAYS.TUESDAY,
  constants.DAYS.WEDNESDAY,
  constants.DAYS.THURSDAY,
  constants.DAYS.FRIDAY,
  constants.DAYS.SATURDAY,
  constants.DAYS.SUNDAY,
];

export default {
  name: 'ScheduleReportForm',
  components: {
    BaseButton,
    BaseLocalMessage,
    BaseRadio,
    ConfirmationModal,
    InputSelect,
    InputText,
    InputTime,
    SaveSprStatusModal,
    SprUsersModal,
    SprUsersTable,
  },
  props: {
    // All Customer Schedules
    customerSchedules: {
      type: Object,
      required: true,
    },
    // component is loaded in New SPR add page or Edit SPR Modal
    // formViewMode will be used to toggle view options based on whether
    formViewMode: {
      default: 'new',
      type: String,
    },
    // Report Schedule object which will be used in case of Edit
    reportSchedule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      autoSuggestedTime: null,
      daysOfWeek: this.reportSchedule.daysOfWeek,
      noRecipientsOk: false,
      recipients: this.reportSchedule.recipients,
      reportId: this.reportSchedule.id,
      reportName: this.reportSchedule.name,
      reportStatus: this.reportSchedule.deliveryStatus,
      reportStatusOptions: Object.keys(constants.SPR_DELIVERY_STATUS).map(k => {
        const val = constants.SPR_DELIVERY_STATUS[k];
        return {
          label: i18n.t(`reportConfigForm.reportStatus.${val}`),
          modelValue: this.reportSchedule.deliveryStatus,
          subLabel: i18n.t(`reportConfigForm.reportStatus.${val}_subLabel`),
          value: val,
        };
      }),
      reportTypes: Object.keys(constants.SPR_DELIVERIES).map(k => {
        const val = constants.SPR_DELIVERIES[k];
        return {
          selected: val === this.reportSchedule.getReportType(),
          text: i18n.t(`reportConfigForm.${val}`),
          value: val,
        };
      }),
      selectedDay: this.reportSchedule.daysOfWeek[0],
      selectedReportType: this.reportSchedule.getReportType(),
      selectedSite: this.reportSchedule.siteId,
      selectedTime: null,
      selectedUsers: [],
    };
  },
  validations: {
    reportName: {
      required,
    },
    reportTime: {
      isValidTime,
      required,
    },
  },
  computed: {
    // returns the currently selected company
    company() {
      return this.$store.getters['companies/getSelectedCompany'];
    },
    editMode() {
      return this.formViewMode === 'edit';
    },
    errorReportName() {
      if (this.$v.reportName.$dirty && !this.$v.reportName.required) {
        return this.$t('validation.requiredReportName');
      }
      return null;
    },
    errorReportTime() {
      if (this.$v.reportTime.$dirty && !this.$v.reportTime.required) {
        return this.$t('validation.requiredTime');
      }
      if (this.$v.reportTime.$dirty && !this.$v.reportTime.isValidTime) {
        return this.$t('validation.isValidTime');
      }
      return null;
    },
    hasRecipients() {
      return this.recipients && this.recipients.length > 0;
    },
    isSaving() {
      return this.$store.getters['sprs/getSaving'];
    },
    // returns true/false whether the SPR schedule config has all the fields and no form errors
    isSprConfigComplete() {
      return (
        this.reportId &&
        this.reportName &&
        this.reportTime &&
        this.selectedReportType &&
        (this.selectedReportType !== constants.SPR_DELIVERIES.WEEKLY || this.selectedDay) &&
        this.selectedSite &&
        this.reportStatus &&
        !this.errorReportTime
      );
    },
    // Returns true if User selected Time is less than Auto suggested time
    isTimeToSendBeforeAutoSuggested() {
      if (!this.reportTime || !this.autoSuggestedTime) return false;

      const reportTimeObj = this.parseTime(this.reportTime);
      const autoTimeObj = this.parseTime(this.autoSuggestedTime);
      return reportTimeObj && autoTimeObj && reportTimeObj.toMillis() < autoTimeObj.toMillis();
    },
    reportDay() {
      return this.selectedReportType === constants.SPR_DELIVERIES.WEEKLY ? this.selectedDay : '';
    },
    reportSiteName() {
      let name;
      if (this.siteOptions && this.selectedSite) {
        name = this.siteOptions.find(s => s.value === this.selectedSite).text;
      }
      return name;
    },
    // Calculate best time based on report delivery day and schedules
    reportTime() {
      let time;
      if (this.selectedTime !== null) {
        time = this.selectedTime;
      } else {
        time = this.reportSchedule.localTimeToDeliever || this.autoSuggestedTime;
      }
      return time;
    },
    showDaySelect() {
      return this.selectedReportType === constants.SPR_DELIVERIES.WEEKLY;
    },
    siteOptions() {
      return this.sites.map(v => {
        return {
          selected: v.id === this.selectedSite,
          text: v.name,
          value: v.id,
        };
      });
    },
    siteTZ() {
      const site = this.sites.filter(s => s.id === this.selectedSite);
      const timeZone = site && site.length > 0 ? site[0].timezone : '';
      const tz = timeZone ? DateTime.fromObject({ zone: timeZone }).toFormat('ZZZZ') : '';
      return tz || timeZone;
    },
    sites() {
      return this.$store.getters['sites/getSites'];
    },
    // returns all active users
    users() {
      let result = [];
      const allUsers = this.$store.getters['users/getOrgMembers'].filter(
        u => u.verified && !u.blocked,
      );
      result = differenceWith(allUsers, this.recipients, (a, b) => a.id === b.id);
      return result;
    },
    weekdayOptions() {
      return weekdays.map(v => {
        return {
          selected: v === this.selectedDay,
          text: i18n.t(`days.${v}`),
          value: v,
        };
      });
    },
  },
  watch: {
    customerSchedules() {
      this.calcAutoSuggestedTime();
    },
    isSprConfigComplete(val) {
      this.$emit('form-status-change', val);
    },
    reportSchedule() {
      this.reset();
    },
    sites(val) {
      if (val && val.length > 0) {
        this.selectedSite = this.sites[0].id;
        this.calcAutoSuggestedTime();
      }
    },
  },
  mounted() {
    // If no site is selected then set to the default first option
    if (!this.selectedSite && this.sites && this.sites.length > 0) {
      this.selectedSite = this.sites[0].id;
      this.calcAutoSuggestedTime();
    }
  },
  methods: {
    calcAutoSuggestedTime() {
      this.selectedTime = null;
      this.autoSuggestedTime = getSprTimeBySchedule({
        reportDay: this.selectedDay,
        reportType: this.selectedReportType,
        schedules: this.customerSchedules,
        siteId: this.selectedSite,
      });
    },
    handleAddUsers(users) {
      this.recipients = this.recipients.concat(users);
      this.triggerFormUpdated();
    },
    onDeleteRecepient(rowData) {
      if (rowData) {
        const rowIndex = this.recipients.findIndex(v => v.id === rowData.id);
        const selectedIndex = this.selectedUsers.findIndex(v => v.id === rowData.id);
        if (rowIndex > -1) {
          this.recipients.splice(rowIndex, 1);
        }
        if (selectedIndex > -1) {
          this.selectedUsers.splice(selectedIndex, 1);
        }
      }
    },
    onDeleteRecepientList() {
      const userIdsToBeDeleted = this.selectedUsers.map(u => {
        return { id: u.id };
      });
      userIdsToBeDeleted.forEach(u => this.onDeleteRecepient(u));
      this.selectedUsers = [];
    },
    onReportStatusChange(value) {
      this.reportStatus = value;
      this.triggerFormUpdated();
    },
    onSelectionChanged(rows) {
      this.selectedUsers = rows || [];
    },
    openUsersModal() {
      this.$refs.usersmodal.open();
    },
    // return Duration object from HH:mm string
    parseTime(timeString) {
      let durationObj;
      // ensure the time matches 24 hour time format (HH:mm)
      if (isValidTime(timeString)) {
        const timeArr = timeString.split(':');
        const timeObj = {
          hours: parseInt(timeArr[0], 10),
          minutes: parseInt(timeArr[1], 10),
        };
        // has seconds attribute
        if (timeArr.length === 3) timeObj.seconds = parseInt(timeArr[2], 10);
        durationObj = Duration.fromObject(timeObj);
      }
      return durationObj;
    },
    reset() {
      this.daysOfWeek = this.reportSchedule.daysOfWeek;
      this.noRecipientsOk = false;
      this.recipients = this.reportSchedule.recipients;
      this.reportId = this.reportSchedule.id;
      this.reportName = this.reportSchedule.name;
      this.reportStatus = this.reportSchedule.deliveryStatus;
      [this.selectedDay] = this.reportSchedule.daysOfWeek;
      this.selectedReportType = this.reportSchedule.getReportType();
      this.selectedSite = this.reportSchedule.siteId;
      this.calcAutoSuggestedTime();
      this.selectedTime = this.reportSchedule.localTimeToDeliever;
    },
    async saveReport() {
      if (!this.editMode && !this.hasRecipients && !this.noRecipientsOk) {
        this.$refs.confirmNoSprRecipients.open();
      } else {
        await this.saveReport_Confirmed();
      }
    },
    async saveReport_Confirmed() {
      if (!this.hasRecipients) {
        // prompt only once
        this.noRecipientsOk = true;
      }
      const daysOfWeek =
        this.selectedReportType === constants.SPR_DELIVERIES.DAILY
          ? [
              constants.DAYS.MONDAY,
              constants.DAYS.TUESDAY,
              constants.DAYS.WEDNESDAY,
              constants.DAYS.THURSDAY,
              constants.DAYS.FRIDAY,
              constants.DAYS.SATURDAY,
              constants.DAYS.SUNDAY,
            ]
          : [this.selectedDay];
      const recipients = this.recipients.map(u => {
        return {
          delivery_status: u.delivery_status || constants.SPR_DELIVERY_STATUS.ACTIVE,
          id: u.id,
        };
      });
      const spr = new SPR({
        companyId: this.company.id,
        daysOfWeek,
        deliveryStatus: this.reportStatus,
        id: this.reportId,
        localTimeToDeliever: this.reportTime,
        name: this.reportName,
        nextDeliveryDateTime: null,
        recipients,
        siteId: this.selectedSite,
      });
      await this.$store
        .dispatch('sprs/saveSPRAsync', { spr })
        .then(() => {
          this.$emit('report-saved');
        })
        .catch(error => {
          console.error(error);
        });
    },
    async savedGoBackToList() {
      this.$v.$reset();
      this.$emit('form-updated', false);
      await this.$nextTick();
      this.$emit('navigate', { name: 'sprConfigList' });
    },
    triggerFormUpdated() {
      this.$emit('form-updated', true);
    },
    updateName(val) {
      this.$v.reportName.$touch();
      this.reportName = val;
      this.triggerFormUpdated();
    },
    updateReportDay(val) {
      this.selectedDay = val;
      this.calcAutoSuggestedTime();
      this.triggerFormUpdated();
    },
    updateReportTime(val) {
      // This check is needed as setting reportTime fires this event back from timepicker and we ignore it
      if (val !== this.reportTime) {
        this.$v.reportTime.$touch();
        this.selectedTime = val;
        this.triggerFormUpdated();
      }
    },
    updateReportType(val) {
      this.selectedReportType = val;
      if (val === 'weekly' && !this.selectedDay) {
        this.selectedDay = 'MONDAY';
      }
      this.calcAutoSuggestedTime();
      this.triggerFormUpdated();
    },
    updateSite(val) {
      this.selectedSite = val;
      this.calcAutoSuggestedTime();
      this.triggerFormUpdated();
    },
  },
};
</script>
