<template>
  <!--
    This is a custom component built to match the PrimeVue Dropdown Loading state.
    When we upgrade to Primvue based component library we should stop using this and
    possibly replace and delete it altogether.
  -->
  <div class="bg-opacity-10 bg-white h-9 inline-flex items-center px-2 relative rounded">
    <span v-if="label" class="mr-4 text-white text-xs">{{ label }}</span>
    <div class="rounded-r">
      <div class="spinner text-white">
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'Spinner2',

  props: {
    label: VueTypes.oneOfType([String, null]),
  },
};
</script>

<style lang="scss" scoped>
@keyframes ball-clip-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner {
  display: block;
  font-size: 0;
  width: 16px;
  height: 16px;
  & > div {
    width: 16px;
    height: 16px;
    background: transparent;
    border-color: white;
    border-width: 2px;
    border-bottom-color: transparent;
    border-radius: 100%;
    -webkit-animation: ball-clip-rotate 2s linear infinite;
    -moz-animation: ball-clip-rotate 2s linear infinite;
    -o-animation: ball-clip-rotate 2s linear infinite;
    animation: ball-clip-rotate 2s linear infinite;
  }
}
</style>
