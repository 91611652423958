import has from 'lodash-es/has';
import Vue from 'vue';
import RouteNetwork from '@/models/RouteNetwork';

const actions = {
  // load the route network data using the given parameters
  async loadRouteNetwork({ commit, dispatch, rootGetters }, parameters) {
    dispatch('resetState');

    const siteId = rootGetters['sites/getSelectedSiteId'];

    let routeNetwork;
    try {
      routeNetwork = await RouteNetwork.loadRouteNetwork(parameters);
    } catch (error) {
      // reset the loading flag only on failure
      commit('setLoading', false);

      throw error;
    }

    // return if a route network was not found for the given parameters
    if (!(routeNetwork instanceof RouteNetwork)) {
      return;
    }

    const data = {
      routeNetwork,
      siteId,
    };

    commit('addRouteNetwork', data);
  },

  // reset the state to initial values
  resetState({ commit }) {
    commit('setLoading', true);
    commit('setRouteNetworks', {});
  },

  // set the loading flag to the given value
  async setLoading({ commit }, value) {
    commit('setLoading', value);
  },
};

const getters = {
  // returns true if data is being loaded or false otherwise
  getLoading: state => {
    return state.loading;
  },

  // returns all route networks
  getRouteNetworks: state => {
    return state.routeNetworks;
  },

  // returns the route network for the given site
  getSiteRouteNetwork: state => siteId => {
    if (has(state.routeNetworks, siteId)) {
      return state.routeNetworks[siteId];
    }

    return null;
  },
};

const mutations = {
  // add the route networks for the given site to the store
  addRouteNetwork(state, data) {
    Vue.set(state.routeNetworks, data.siteId, data.routeNetwork);
  },

  // set the loading flag to the given value
  setLoading(state, value) {
    state.loading = value;
  },

  // set routeNetworks to the given value
  setRouteNetworks(state, value) {
    state.routeNetworks = value;
  },
};

const state = () => ({
  loading: true,
  routeNetworks: {},
});

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
