<template>
  <BaseModalCustom
    ref="editmodal"
    class="edit-spr-modal-container"
    size="xlarge"
    @closed="onModalClose"
  >
    <section class="mx-7 pb-8 spr-form-container text-left">
      <ScheduleReportForm
        ref="sprForm"
        :customer-schedules="customerSchedules"
        :form-view-mode="'edit'"
        :report-schedule="sprSchedule"
        @report-saved="saveSpr_Saved"
        @form-status-change="onFormStatusChange"
        @form-updated="onFormUpdated"
      />
    </section>
    <section class="flex footer-container justify-between mx-7 pb-8">
      <GhostButton
        class="delete-report"
        :icon-name="'trash'"
        :viewBox="'0 0 24 24'"
        @click="onDeleteSPR"
      >
        {{ $t('sprConfig.deleteReport') }}
      </GhostButton>
      <div class="flex">
        <BaseButton button-type="secondary" @click="close">
          {{ $t('reportConfigForm.cancel') }}
        </BaseButton>
        <BaseButton
          button-type="primary"
          class="ml-3"
          :disabled="updateBtnDisabled"
          @click="saveSpr"
        >
          {{ $t('reportConfigForm.update') }}
        </BaseButton>
      </div>
    </section>
    <ConfirmationModal
      ref="confirmDeleteSpr"
      :confirm-title="deleteModalMessage"
      :cancel-button="`${$t('sprConfig.confirmDeleteSpr.cancel')}`"
      :confirm-button="`${$t('sprConfig.confirmDeleteSpr.confirm')}`"
      @on-confirm-action="onDeleteSPR_Confirmed"
    />
  </BaseModalCustom>
</template>

<script>
import { BaseButton } from '@seegrid/components';
import IconSprite from '@/assets/images/icon-sprite.svg';
import BaseModalCustom from '@/components/BaseModalCustom.vue';
import GhostButton from '@/components/controls/GhostButton.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import ScheduleReportForm from '@/components/sprconfig/ScheduleReportForm.vue';

export default {
  name: 'EditSprModal',
  components: {
    BaseButton,
    BaseModalCustom,
    ConfirmationModal,
    GhostButton,
    ScheduleReportForm,
  },
  props: {
    // All Customer Schedules
    customerSchedules: {
      type: Object,
      required: true,
    },
    sprId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isFormComplete: true,
      isInitialState: true,
    };
  },
  computed: {
    // returns the path of the icon svg for use by the icon component
    deleteModalMessage() {
      return `${this.$t('sprConfig.confirmDeleteSpr.message', {
        name: this.sprSchedule ? this.sprSchedule.name : '',
      })}`;
    },
    iconSprite() {
      return IconSprite;
    },
    modalTitle() {
      return this.$t('reportConfigForm.addUserModalTitle', {
        reportName: this.reportName ? this.reportName : this.$t('reportConfigForm.report'),
      });
    },
    sprSchedule() {
      return this.$store.getters['sprs/getSPRsById'](this.sprId);
    },
    updateBtnDisabled() {
      return !this.isFormComplete || this.isInitialState;
    },
  },
  methods: {
    close() {
      this.$refs.editmodal.close();
    },
    // handle delete the report
    onDeleteSPR(e) {
      this.$refs.confirmDeleteSpr.open();
      e.cancelBubble = true;
      return false;
    },
    onDeleteSPR_Confirmed() {
      this.$refs.confirmDeleteSpr.close();
      this.close();
      this.$store.dispatch('sprs/deleteSPRAsync', { spr: this.sprSchedule }).catch(error => {
        console.error(error);
      });
    },
    onFormStatusChange(status) {
      this.isFormComplete = status;
    },
    onFormUpdated() {
      this.isInitialState = false;
    },
    async onModalClose() {
      await this.$nextTick();
      this.$emit('closed');
    },
    open() {
      this.$refs.editmodal.open();
    },
    async saveSpr() {
      await this.$refs.sprForm.saveReport();
    },
    async saveSpr_Saved() {
      this.close();
      await this.$nextTick();
      this.$emit('report-updated');
    },
  },
};
</script>

<style lang="scss" scoped>
.user-table-container {
  min-height: 50vh;
  max-height: calc(95vh - 17rem);
  overflow: auto;
}
.edit-spr-modal-container ::v-deep > .c-modal-mask > .c-modal > .c-modal__body-container {
  padding: 0;
}
::v-deep .vue__time-picker {
  font-size: 1rem;
  line-height: 1.5;
  input.display-time {
    font-size: 1rem;
    line-height: 1.5;
  }
}
</style>
