import has from 'lodash-es/has';
import cerberusSprAPI from '@/api/cerberusSprAPI';
import constants from '@/config/constants';

export default class SPR {
  constructor(params) {
    this.name = params.name;
    this.daysOfWeek = params.daysOfWeek;
    this.deliveryStatus = params.deliveryStatus;
    this.localTimeToDeliever = params.localTimeToDeliever;
    this.nextDeliveryDateTime = params.nextDeliveryDateTime;
    this.id = params.id;
    this.companyId = params.companyId;
    this.siteId = params.siteId;
    this.recipients = params.recipients;
  }

  static async getSPRs(companyId) {
    const response = await cerberusSprAPI.getReportSchedules(companyId);
    if (!has(response, 'data.sites') || Object.keys(response.data.sites).length === 0) {
      return {};
    }
    const sprsBySiteObject = {};
    Object.keys(response.data.sites).forEach(key => {
      if (response.data.sites[key] && Array.isArray(response.data.sites[key])) {
        sprsBySiteObject[key] = response.data.sites[key].map(item => {
          let newSpr;
          try {
            newSpr = SPR.fromJSON(item);
          } catch (e) {
            console.error('Error processing spr data in getSPRs', e);
          }
          return newSpr;
        });
      }
    });
    return sprsBySiteObject;
  }

  /**
   * Constants
   */
  static NEW_SPR_ID = 'new';

  /**
   * Indicates whether this SPR is new (unsaved)
   */
  get isNewSPR() {
    return this.id === SPR.NEW_SPR_ID;
  }

  /**
   * Add a new report via the API
   */
  async post() {
    const response = await cerberusSprAPI.postReportSchedules(
      this.companyId,
      this.siteId,
      this.toCreateJSON(),
    );
    if (response && response.data) {
      return SPR.fromJSON(response.data);
    }
    console.warn('SPR.post returned an unexpected response', response);
    return null;
  }

  async delete() {
    await cerberusSprAPI.deleteReportSchedules(this.companyId, this.siteId, this.id);
  }

  /**
   * Updates an existing report via the API
   */
  async put() {
    if (this.isNewSPR) throw new Error('cannot update new SPR, use post instead');
    const response = await cerberusSprAPI.putReportSchedules(
      this.companyId,
      this.siteId,
      this.id,
      this.toUpdateJSON(),
    );
    if (response && response.data) {
      return SPR.fromJSON(response.data);
    }
    console.warn('SPR.put returned an unexpected response', response);
    return null;
  }

  // Returns report type based on daysOfWeek
  getReportType() {
    let reportType;
    if (this.daysOfWeek.length === 1) {
      reportType = constants.SPR_DELIVERIES.WEEKLY;
    } else if (this.daysOfWeek.length === 7) {
      reportType = constants.SPR_DELIVERIES.DAILY;
    }
    return reportType || constants.SPR_DELIVERIES.DAILY;
  }

  // Returns a New SPR with Default Values
  static getNewEmptySPRSchedule() {
    return new SPR({
      companyId: '',
      daysOfWeek: [
        constants.DAYS.MONDAY,
        constants.DAYS.TUESDAY,
        constants.DAYS.WEDNESDAY,
        constants.DAYS.THURSDAY,
        constants.DAYS.FRIDAY,
        constants.DAYS.SATURDAY,
        constants.DAYS.SUNDAY,
      ],
      deliveryStatus: constants.SPR_DELIVERY_STATUS.ACTIVE,
      id: SPR.NEW_SPR_ID,
      localTimeToDeliever: null,
      name: '',
      nextDeliveryDateTime: null,
      recipients: [],
      siteId: '',
    });
  }

  // as provided from the api
  static fromJSON(sprJSON) {
    const recipients = sprJSON.recipients.map(i => {
      return {
        ...i.user,
        delivery_status: i.delivery_status,
        modified_date_time: i.modified_date_time,
      };
    });
    return new SPR({
      companyId: sprJSON.company_id,
      daysOfWeek: sprJSON.days_of_week,
      deliveryStatus: sprJSON.delivery_status,
      id: sprJSON.id,
      localTimeToDeliever: sprJSON.local_time_to_deliver,
      name: sprJSON.name,
      nextDeliveryDateTime: sprJSON.next_delivery_date_time,
      recipients,
      siteId: sprJSON.site_id,
    });
  }

  // as expected by the api
  toCreateJSON() {
    return {
      days_of_week: this.daysOfWeek,
      delivery_status: this.deliveryStatus,
      local_time_to_deliver: this.localTimeToDeliever,
      name: this.name,
      recipient_user_ids: this.recipients.map(u => u.id),
    };
  }

  toUpdateJSON() {
    return {
      days_of_week: this.daysOfWeek,
      delivery_status: this.deliveryStatus,
      local_time_to_deliver: this.localTimeToDeliever,
      name: this.name,
      recipients: this.recipients.map(u => {
        return { delivery_status: u.delivery_status, id: u.id };
      }),
    };
  }
}
