<template>
  <div class="flex font-medium justify-between text-gray-6 text-xs">
    <span v-if="version">{{ $t('version') }} {{ version }}</span>
    <span>&copy;{{ currentYear }} {{ $t('seegrid') }}</span>
  </div>
</template>

<script>
import config from '@/config/config';

export default {
  name: 'Version',

  computed: {
    // returns the current year
    currentYear() {
      const now = new Date();

      return now.getFullYear();
    },

    // returns the configured version
    version() {
      return config.VERSION;
    },
  },
};
</script>
