<template>
  <section
    class="c-modal__add-user-modal__form content-start max-w-6xl mx-auto p-6 rounded-lg text-left"
    :class="{
      'c-modal__add-user-modal__form--with-new-user': showOnNewUser && !internalUserMode,
    }"
  >
    <InputWithSearch
      id="UserEmailInput"
      ref="findUserEmail"
      class="c-modal__add-user-modal__form__control email"
      :label="$t('userManagement.addUsers.email')"
      :value="userData.email"
      :validation="$v.userData.email"
      :error="validateEmailAddress()"
      :show-add-new="!internalUserMode"
      :format-add-new="formatAddNewUser"
      :search-options="searchUserEmails"
      @input-selected="onEmailSelected"
      @input-updated="updateInput('email', $event, true)"
    />
    <BaseInput
      v-show="showOnNewUser && !internalUserMode"
      id="UserFirstNameInput"
      class="c-modal__add-user-modal__form__control"
      :label="$t('userManagement.addUsers.firstName')"
      :value="userData.firstName"
      :validation="$v.userData.firstName"
      :error="getError($v.userData.firstName)"
      @input-updated="updateInput('firstName', $event, true)"
    />
    <BaseInput
      v-show="showOnNewUser && !internalUserMode"
      id="UserLastNameInput"
      class="c-modal__add-user-modal__form__control"
      :label="$t('userManagement.addUsers.lastName')"
      :value="userData.lastName"
      :validation="$v.userData.lastName"
      :error="getError($v.userData.lastName)"
      @input-updated="updateInput('lastName', $event, true)"
    />
    <BaseMultiselect
      v-show="!internalUserMode"
      class="c-modal__add-user-modal__form__control"
      :error="getError($v.selectedSites)"
      :group-options="false"
      :label="$t('userManagement.addUsers.sites')"
      :multiple="true"
      :options="sitesOptions"
      :value="selectedSites"
      :validation="$v.selectedSites"
      @input="updateInput('selectedSites', $event, true)"
    />
    <BaseCheckbox
      v-show="showOnNewUser && !internalUserMode"
      class="c-modal__add-user-modal__form__control mt-4"
      :value="sendUserInvite && containsConfirmedSite"
      :disabled="!containsConfirmedSite"
      :label="$t('userManagement.addUsers.sendUserInvite')"
      label-position="right"
      @input="updateInput('sendUserInvite', $event)"
    />
    <section v-if="containsUnconfirmedSite" class="flex mb-8 mt-4 px-6">
      <BaseLocalMessage
        v-show="showOnNewUser && !internalUserMode"
        id="unconfirmedSitesMessage"
        class="bg-white border border-primary-lightBlue w-full"
      >
        <div class="ml-3.5 text-primary-lightBlue text-xs">
          {{ welcomeEmailMessage }}
        </div>
      </BaseLocalMessage>
    </section>
    <div
      v-show="showOnNewUser && internalUserMode"
      class="c-modal__add-user-modal__form__control c-modal__add-user-modal__form__internal-msg"
    >
      <p>{{ $t('userManagement.addUsers.createInternal.message') }}</p>
      <p>{{ $t('userManagement.addUsers.createInternal.instructions') }}</p>
    </div>
  </section>
</template>

<script>
import { BaseCheckbox, BaseInput } from '@seegrid/components';
import { email, maxLength, required, requiredIf } from 'vuelidate/lib/validators';
import BaseLocalMessage from '@/components/BaseLocalMessage.vue';
import BaseMultiselect from '@/components/BaseMultiselect.vue';
import InputWithSearch from '@/components/inputs/InputWithSearch.vue';
import constants from '@/config/constants';
import Company from '@/models/Company';
import OrganizationMember from '@/models/OrganizationMember';
import allowWelcomeEmail from '@/util/allowWelcomeEmail';
import isInternalEmail from '@/util/isInternalEmail';
import getErrorMessage from '@/util/validation';

export default {
  name: 'AddUserForm',
  components: { BaseCheckbox, BaseInput, BaseLocalMessage, BaseMultiselect, InputWithSearch },
  props: {
    company: {
      default: null,
      type: Company,
    },
    currentUsers: {
      type: Array,
      required: true,
    },
    internalUserMode: {
      type: Boolean,
      required: true,
    },
    internalUsers: {
      type: Array,
      required: true,
    },
    sendUserInvite: {
      default: true,
      type: Boolean,
    },
    user: {
      type: Object,
      required: true,
    },
    validate: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      constants,
      emailMismatch: false,
      userData: this.user,
    };
  },
  computed: {
    allSitesOption() {
      return {
        name: this.$t('sites.allSites'),
        value: constants.AUTH0.ALL_SITES,
      };
    },

    companies() {
      return this.$store.getters['companies/getCompanies'];
    },

    containsConfirmedSite() {
      const selectedSites = this.selectedSites.map(site => site.value);
      return allowWelcomeEmail.containsConfirmedSite(selectedSites);
    },

    containsUnconfirmedSite() {
      const selectedSites = this.selectedSites.map(site => site.value);
      return allowWelcomeEmail.containsUnconfirmedSite(selectedSites);
    },

    hasValidEmail() {
      if (!this.userData) return false;
      if (!this.userData.email) return false;
      if (this.emailMismatch) return false;
      return !this.$v.userData.email.$error;
    },

    isPickingEmail() {
      if (!this.$refs.findUserEmail) return false;
      return this.$refs.findUserEmail.hasFocus && this.$refs.findUserEmail.showResults;
    },

    searchUserEmails() {
      return this.internalUserMode
        ? (this.internalUsers || []).map(u => u.email).filter(e => e)
        : (this.currentUsers || []).map(u => u.email).filter(e => e);
    },

    selectedSites() {
      // Internal users added to All Sites
      if (this.internalUserMode) {
        return [this.sitesOptions.find(o => o.value === constants.AUTH0.ALL_SITES)];
      }
      if (!(this.userData instanceof OrganizationMember)) {
        return [];
      }
      if (!this.userData.sites || this.userData.sites.length < 1) {
        return [];
      }
      return this.userData.sites.map(id => this.sitesOptions.find(o => o.value === id));
    },

    // indicates something should be shown if this is an existing user
    showOnExistingUser() {
      if (this.userData.isNewUser) return false;
      if (!this.hasValidEmail) return false;
      if (this.isPickingEmail) return false;
      return true;
    },

    // indicates something should be shown if this is a new user
    showOnNewUser() {
      if (!this.userData.isNewUser) return false;
      if (!this.hasValidEmail) return false;
      if (this.isPickingEmail) return false;
      return true;
    },

    sites() {
      return this.$store.getters['sites/getSites'];
    },

    // the list of available site options
    sitesOptions() {
      const options = this.sites.map(site => {
        return {
          name: site.name,
          value: site.id,
        };
      });
      options.unshift(this.allSitesOption);
      return options;
    },

    welcomeEmailMessage() {
      if (!this.containsUnconfirmedSite) {
        return '';
      }
      return this.containsConfirmedSite
        ? this.$t('userManagement.addUsers.mixedWelcomeEmail')
        : this.$t('userManagement.addUsers.noWelcomeEmail');
    },
  },
  validations: {
    selectedSites: {
      required,
    },
    userData: {
      email: {
        email,
        maxLength: maxLength(128),
        required,
      },
      firstName: {
        maxLength: maxLength(128),
        required: requiredIf(function check() {
          return this.userData.isNewUser;
        }),
      },
      lastName: {
        maxLength: maxLength(128),
        required: requiredIf(function check() {
          return this.userData.isNewUser;
        }),
      },
    },
  },
  watch: {
    /**
     * populate the multiselect options when the data is available
     * this ensures that the all companies / sites option is rendered properly
     */
    user() {
      this.userData = this.user;
    },
    validate() {
      if (this.validate) {
        this.$emit('validate-form-result', this.validateFormInputs());
      }
    },
  },

  methods: {
    // format the new user entry in the search control
    formatAddNewUser(option) {
      return this.$t('userManagement.addUsers.addNewUser', { option });
    },
    getError(validation) {
      return getErrorMessage(validation);
    },
    // called when the user selects an entry in the search control
    onEmailSelected() {
      // for now we dont need to do anything, but future devs
      // can use this feature.
    },
    // called as the user updates for entries
    updateInput(field, input, validateInput = false) {
      const localInput = field === 'email' ? (input || '').toLowerCase() : input;
      let currentEmail;
      switch (field) {
        case 'selectedSites':
          {
            let selectedOptions = localInput;

            // did the user JUST select the all sites option?
            const allSitedJustAdded =
              selectedOptions.includes(this.allSitesOption) &&
              !this.selectedSites.includes(this.allSitesOption);
            if (allSitedJustAdded) {
              // make it the only selection
              selectedOptions = [this.allSitesOption.value];
            } else {
              // filter all sites from the list
              selectedOptions = selectedOptions
                .filter(o => o !== this.allSitesOption)
                .map(o => o.value);
            }
            this.$emit('update-form', { field, value: selectedOptions });
          }
          break;
        case 'email':
          currentEmail = this.user.email;
          this.$emit('update-form', { field, value: localInput });
          break;
        default:
          this.$emit('update-form', { field, value: localInput });
          break;
      }
      if (validateInput) {
        if (field === 'selectedSites') {
          this.$v.selectedSites.$touch();
        } else {
          this.$v.userData[field].$touch();
        }
      }
      switch (field) {
        case 'email':
          if (
            localInput &&
            // Resolve from existing email if user has a valid email or always for internal user
            // Internal user is always picked from existing list so lookup has to be done always
            (this.hasValidEmail || this.internalUserMode) &&
            currentEmail !== localInput
          ) {
            // send a signal to the parent modal to find this email address
            this.$emit('resolve-email-address', localInput);
          }
          break;
        default:
          break;
      }
    },
    // special validation for the email address
    validateEmailAddress() {
      // check the basics
      const err = this.getError(this.$v.userData.email);
      if (err) return err;

      // is this an internal user?
      if (this.userData && this.userData.email) {
        const internalEmail = isInternalEmail(this.userData.email);
        const internalUser = this.internalUsers.find(
          u => (u.email || '').toLowerCase() === (this.userData.email || '').toLowerCase(),
        );
        if (this.internalUserMode && !internalEmail) {
          this.emailMismatch = true;
          return this.$t('userManagement.addUsers.error.enterInternalEmail');
        }
        if (!this.internalUserMode && (internalUser || internalEmail)) {
          this.emailMismatch = true;
          return this.$t('userManagement.addUsers.error.enterExternalEmail');
        }
      }

      // no errors
      this.emailMismatch = false;
      return '';
    },
    validateFormInputs() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.c-modal__add-user-modal__form {
  .c-modal__add-user-modal__form__control {
    margin-bottom: 1.5rem;
    &.email ::v-deep input {
      text-transform: lowercase;
    }
    &.email ::v-deep .c-input__with-search__search li {
      text-transform: lowercase;
    }
  }
  &--with-new-user {
    .c-modal__add-user-modal__form__control {
      margin-bottom: 0.5rem;
    }
  }
  .c-modal__add-user-modal__form__internal-msg {
    margin-top: 0;
    font-size: 0.875rem;
    font-weight: 400;
    p {
      margin-bottom: 1rem;
    }
  }
}
</style>
