import difference from 'lodash-es/difference';
import has from 'lodash-es/has';
import cerberusGroupsAPI from '@/api/cerberusGroupsAPI';
import cerberusOrganizationAPI from '@/api/cerberusOrganizationAPI';
import cerberusUserAPI from '@/api/cerberusUserAPI';
import constants from '@/config/constants';
import BaseUser from '@/models/BaseUser';
import Company from '@/models/Company';

/* eslint-disable no-underscore-dangle */

// class for representing and managing a user
export default class OrganizationMember extends BaseUser {
  constructor(companyId, userData, sites, isInternal = false, organizationRoles = null) {
    super(userData, isInternal);
    this.companyId = companyId;
    if (userData && !this.companyId) throw new Error('OrganizationMember.companyId expected');

    this.sites = sites;
    this.organizationRoles = organizationRoles;
    // TODO: Yoda-1455: Temporary solution
    this.previouslySelectedSites = sites;
  }

  // returns a user object based on the data provided by the api
  static fromOrganizationMemberJSON(companyId, user) {
    return new OrganizationMember(companyId, user.user_data, user.sites);
  }

  /**
   * Returns the list of user's organization group mappings
   * globalAccess=true: return group mappings in all companies that the user has access to
   * globalAccess=false: return only the group mappings of the current organization
   */
  async getOrganizationsGroups(globalAccess = false) {
    const response = await cerberusUserAPI.getUserOrganizationsGroups(this.id, globalAccess);

    if (!has(response, 'data') || !Array.isArray(response.data)) {
      throw new Error('failed to get user organization groups');
    }

    return response.data;
  }

  /**
   * Returns the list of user's organization role mappings
   * globalAccess=true: return role mappings in all companies that the user has access to
   * globalAccess=false: return only the role mappings of the current organization
   */
  async getOrganizationsRoles(globalAccess = false) {
    const response = await cerberusUserAPI.getUserOrganizationsRoles(this.id, globalAccess);

    if (!has(response, 'data') || !Array.isArray(response.data)) {
      throw new Error('failed to get user organization roles');
    }

    return response.data;
  }

  /**
   * Returns the list of user's organizations
   * globalAccess=true: return all companies that the user has access to
   * globalAccess=false: return only the the current organization info
   */
  async getOrganizations(globalAccess = false) {
    const response = await cerberusUserAPI.getUserOrganizations(this.id, globalAccess);

    if (!has(response, 'data') || !Array.isArray(response.data)) {
      throw new Error('failed to get user organizations');
    }

    return response.data;
  }

  /**
   * Returns the list of OrganizationMember for the given organization / company
   *
   * Required Parameters:
   *  parameters.companyId
   *
   * Optional Parameters:
   *  parameters.siteId
   *  parameters.userIds
   *  parameters.page
   *  parameters.perPage
   *  parameters.includeDetails
   *  parameters.includeSites
   *  parameters.includeRoles - SLOW
   */
  static async getOrganizationMembers(parameters) {
    let response;
    let users = [];
    if (!parameters || !parameters.companyId) throw new Error('missing parameters.companyId param');
    do {
      // for proper paging each request must be performed synchronously
      /* eslint-disable-next-line no-await-in-loop */
      response = await cerberusOrganizationAPI.getOrganizationMembers(parameters);
      // terminate the loop if the response is invalid
      if (!has(response, 'data')) {
        break;
      }
      const usersJson = response.data.users || [];
      users = users.concat(
        usersJson.map(u => OrganizationMember.fromOrganizationMemberJSON(parameters.companyId, u)),
      );
    } while (response.data.length === constants.AUTH0.PAGE_LIMIT);
    return users;
  }

  // remove the user from organization
  async removeOrganization() {
    await cerberusOrganizationAPI.removeOrganizationMembers(this.companyId, [this.id]);
  }

  // return the organization roles for the given user
  static async getUserOrganizationRoles(companyId, userId) {
    const response = await cerberusOrganizationAPI.getOrganizationMemberRoles(companyId, userId);

    if (!has(response, 'data') || !Array.isArray(response.data)) {
      throw new Error('failed to get organization roles');
    }

    const organizationRoles = response.data.map(role => role.id);
    this.organizationRoles = organizationRoles;

    return organizationRoles;
  }

  // return the organization roles names for the given user
  static async getUserOrganizationRolesNames(companyId, userId) {
    const response = await cerberusOrganizationAPI.getOrganizationMemberRoles(companyId, userId);

    if (!has(response, 'data') || !Array.isArray(response.data)) {
      throw new Error('failed to get organization roles');
    }

    const organizationRolesNames = response.data.map(role => role.name);

    return organizationRolesNames;
  }

  // returns true if the user has permissions to access to the given site or false
  hasSiteAccess(siteId) {
    if (!this.sites) {
      console.warn('user site details were not loaded');
      return false;
    }
    return this.sites.some(id => id === siteId || id === constants.AUTH0.ALL_SITES);
  }

  // returns true if the user has the given organization role or false otherwise
  async hasOrganizationRole(roleId) {
    const organizationRoles = await OrganizationMember.getUserOrganizationRoles(
      this.companyId,
      this.id,
    );

    if (!organizationRoles || !Array.isArray(organizationRoles)) {
      console.warn('user organization role details were not loaded');
      return false;
    }
    return organizationRoles.includes(roleId);
  }

  /**
   * save - create or update the user
   *
   * company - the company to which this user should be added or updated
   * invite (optional) - send an invite email
   * organizationRoles (optional) - add / update the user's organization role(s)
   */
  async save(company, invite, organizationRoles = null) {
    // if the user id exists update the user otherwise create the user
    if (this.isNewUser) {
      await this._saveNew(company, invite, organizationRoles);
    } else {
      await this._saveUpdate(organizationRoles);
    }
  }

  // save as a new user
  async _saveNew(company, invite, organizationRoles) {
    if (!(company instanceof Company)) {
      throw new TypeError('company is required to save user');
    }
    // cant create internal users
    if (this.isInternal) throw new Error('cannot create internal users');

    // create a new user
    const response = await cerberusUserAPI.createUser(this.saveNewUserJSON(company));
    if (!has(response, 'data')) {
      throw new Error('failed to create user');
    }

    // Update the user details
    const userData = response.data;
    this._handleUserCreatedResp(userData, company);

    // Add any default org roles that have been assigned to user
    let defaultOrgRoles = [];
    const userOrgRoles = userData.roles.find(role => role.company_id === company.id);
    if (userOrgRoles) {
      defaultOrgRoles = userOrgRoles.roles.map(role => role.id);
    }

    if (Array.isArray(this.organizationRoles)) {
      this.organizationRoles = this.organizationRoles.concat(defaultOrgRoles);
    } else {
      this.organizationRoles = defaultOrgRoles;
    }

    if (organizationRoles) {
      // Update the user roles
      await this.updateOrganizationRoles(organizationRoles);
    }

    // Send an invite?
    if (invite) {
      await this.sendWelcomeEmail();
    }
  }

  _handleUserCreatedResp(userData, company) {
    this.companyId = company.id;
    this.id = userData.user_id || this.id;
    this.accountStatus = userData.account_status || this.accountStatus;
    this.blocked = userData.blocked;
    this.lastLogin = userData.last_login || this.lastLogin;
    this.verified = userData.email_verified;
  }

  _handleUserUpdatedResp(userData) {
    this.accountStatus = userData.account_status || this.accountStatus;
    this.blocked = userData.blocked;
    this.lastLogin = userData.last_login || this.lastLogin;
    this.verified = userData.email_verified;
  }

  // update the existing user
  async _saveUpdate(organizationRoles = null) {
    // Only update the profile of external users
    if (!this.isInternal) {
      const response = await cerberusUserAPI.updateUser(this.id, this.saveUserProfileJSON());
      if (!has(response, 'data')) {
        throw new Error('failed to update user');
      }
      // Update the user details
      const userData = response.data;
      this._handleUserUpdatedResp(userData);
    }

    // Update the user sites
    await this.updateSites();

    if (organizationRoles) {
      // Update the user roles
      await this.updateOrganizationRoles(organizationRoles);
    }
  }

  /**
   * Returns the formatted json used to create or update profile details
   */
  saveUserProfileJSON() {
    return {
      email: this.email,
      family_name: (this.lastName || '').trim(),
      given_name: (this.firstName || '').trim(),
      name: `${this.firstName} ${this.lastName}`.trim(),
    };
  }

  /**
   * Returns the formatted json used to create a new user
   */
  saveNewUserJSON(company) {
    return {
      companies: [
        {
          company_id: company.id,
          site_ids: this.sites || [],
        },
      ],
      user: this.saveUserProfileJSON(),
    };
  }

  /**
   * Update the user's organization roles
   * @param {*} selectedRoles - array of role ids
   */
  async updateOrganizationRoles(selectedRoles) {
    if (!this.id || !Array.isArray(selectedRoles)) {
      throw new Error('a user id and roles are required to update organization roles');
    }

    const currentRoles = await OrganizationMember.getUserOrganizationRoles(this.companyId, this.id);

    // determine the new roles and add them to the user
    const newRoles = difference(selectedRoles, currentRoles);
    if (newRoles.length > 0) {
      await cerberusOrganizationAPI.addOrganizationMemberRoles(this.companyId, this.id, newRoles);
    }

    // determine the old roles and remove them from the user
    const oldRoles = difference(currentRoles, selectedRoles);
    if (oldRoles.length > 0) {
      await cerberusOrganizationAPI.removeOrganizationMemberRoles(
        this.companyId,
        this.id,
        newRoles,
      );
    }

    this.organizationRoles = selectedRoles;
  }

  // TODO: Yoda-1455: Temporary solution
  // should be updated with a new backend endpoint that simply updates a list of sites for a given user
  // update the sites to which this user is a member
  async updateSites() {
    // is the sites set or loaded?
    if (!this.sites) {
      throw new Error('org member sites not set or loaded');
    }
    if (!this.id) {
      throw new Error('new org member must first be saved');
    }

    // make sure the user belongs to the org (required for internal users)
    if (this.isInternal) {
      await cerberusOrganizationAPI.addOrganizationMembers(this.companyId, [this.id]);
    }

    // compare currently and previously selected sites to see which sites need to be added
    const addSites = difference(this.sites, this.previouslySelectedSites);
    const promiseAdd = addSites.map(siteId =>
      cerberusGroupsAPI.addUsersToCompanySite(this.companyId, siteId, [this.id]),
    );
    if (promiseAdd.length) {
      try {
        await Promise.all(promiseAdd);
      } catch (err) {
        console.error(err.stack);
        throw err;
      }
    }

    // compare previously and currently selected sites to see which sites need to be removed
    const removeSites = difference(this.previouslySelectedSites, this.sites);
    const promiseRemove = removeSites.map(siteId =>
      cerberusGroupsAPI.removeUsersFromCompanySite(this.companyId, siteId, [this.id]),
    );
    if (promiseRemove.length) {
      try {
        await Promise.all(promiseRemove);
      } catch (err) {
        console.error(err.stack);
        throw err;
      }
    }
    // update previously selected sites with currently selected sites for next iteration of selections
    this.previouslySelectedSites = [...this.sites];
  }

  /**
   * Returns the list of OrganizationMember that were successfully created
   *
   * Required Parameters:
   *  company - the company to which this user should be added or updated
   *  users - all users added to Bulk list
   *  invite - send invites
   */
  static async saveBulk(company, users, invite = false) {
    const response = await cerberusUserAPI.createBulkUsers(
      OrganizationMember.saveBulkUsersJSON(company, users),
    );
    if (!has(response, 'data')) {
      throw new Error('failed to create user');
    }
    // Update the user details
    let successUserResponses = [];
    const failedUserResponses = [];
    const usersCreatedSuccessList = [];
    try {
      // All Users Created
      if (response && response.status === constants.HTTP_STATUSES.CREATED) {
        successUserResponses = response.data;
      } else if (response && response.status === constants.HTTP_STATUSES.MULTI_STATUS) {
        // Some Users Created some failed
        const usersResponseArray = response.data || [];
        for (
          let i = 0;
          usersResponseArray && usersResponseArray.length && i < usersResponseArray.length;
          i += 1
        ) {
          const responseElement = usersResponseArray[i];
          if (responseElement.status === constants.HTTP_STATUSES.CREATED) {
            successUserResponses.push(responseElement.response);
          } else {
            failedUserResponses.push(responseElement);
          }
        }
      }
      for (
        let i = 0;
        successUserResponses && successUserResponses.length && i < successUserResponses.length;
        i += 1
      ) {
        const userResp = successUserResponses[i];
        const user = users.find(u => u.email === userResp.email);
        if (user && userResp) {
          user._handleUserCreatedResp(userResp, company);
          usersCreatedSuccessList.push(user);
        }
      }
    } catch (err) {
      console.error(err.stack);
      throw err;
    }

    // Send an invite?
    if (invite && usersCreatedSuccessList.length > 0) {
      try {
        const emailPromises = usersCreatedSuccessList.map(async u => {
          await u.sendWelcomeEmail();
        });
        await Promise.all(emailPromises);
      } catch (err) {
        console.error(err.stack);
        throw err;
      }
    }

    return { addedUsers: usersCreatedSuccessList, failedUsers: failedUserResponses };
  }

  /**
   * Returns the formatted json used to create a new user
   */
  static saveBulkUsersJSON(company, users) {
    return {
      companies: [
        {
          company_id: company.id,
          site_ids: users[0].sites || [],
        },
      ],
      users: users.map(u => u.saveUserProfileJSON()),
    };
  }
}
