<template>
  <BaseModalCustom
    ref="savingStatusModal"
    :action-one="modalButton1"
    :action-two="modalButton2"
    :close-on-esc="false"
    :heading="modalHeading"
    :size="'small'"
    :sub-heading="modalSubHeading"
    @modal-button-clicked="onModalButtonClicked"
  />
</template>

<script>
import BaseModalCustom from '@/components/BaseModalCustom.vue';
import constants from '@/config/constants';

export default {
  name: 'SaveSprStatusModal',
  components: { BaseModalCustom },
  props: {
    allowRetry: {
      default: true,
      type: Boolean,
    },
    autoClose: {
      default: false,
      type: Boolean,
    },
    modalText: {
      type: String,
      required: true,
    },
    reportDay: {
      default: '',
      type: String,
    },
    reportSite: {
      default: '',
      type: String,
    },
    reportTime: {
      default: '',
      type: String,
    },
    reportType: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      savingState: false,
    };
  },
  computed: {
    isSaving() {
      return this.$store.getters['sprs/getSaving'];
    },
    modalButton1() {
      if (!this.savingState) {
        return this.saveError && this.allowRetry
          ? this.$t(`${this.modalText}.cancel`)
          : this.$t(`${this.modalText}.okay`);
      }
      return null;
    },
    modalButton2() {
      if (!this.savingState && this.saveError && this.allowRetry) {
        return this.$t(`${this.modalText}.retry`);
      }
      return null;
    },
    modalHeading() {
      if (this.savingState) {
        return this.$t(`${this.modalText}.savingMessage`);
      }
      return this.saveError
        ? this.$t(`${this.modalText}.unsuccessfulSave`)
        : this.$t(`${this.modalText}.success`);
    },
    modalSubHeading() {
      if (!this.savingState) {
        if (this.saveError) {
          return this.allowRetry ? '' : this.$t(`${this.modalText}.unsuccessfulSaveReason`);
        }
        const reportDayText = this.reportDay
          ? ` ${this.$t(`days.${this.reportDay}`)}`
          : this.$t('reportConfigForm.day');
        return this.$t(`${this.modalText}.successfulSaveMsg`, {
          day: reportDayText,
          site: this.reportSite,
          time: this.reportTime,
          type: this.reportType,
        });
      }
      return '';
    },
    saveError() {
      return !!this.$store.getters['sprs/getLastError'];
    },
  },
  watch: {
    async isSaving(val) {
      if (val) {
        this.open();
        this.savingState = true;
        return;
      }
      if (this.autoClose && !this.saveError) {
        await this.close();
      } else {
        this.savingState = false;
      }
    },
  },
  methods: {
    async close() {
      await this.$nextTick();
      await this.$refs.savingStatusModal.close();
    },
    async onModalButtonClicked(event) {
      if (event.action === constants.MODAL_CANCEL) {
        if (!this.saveError) {
          this.$emit('navigate-back');
        }
        await this.close();
      }
      if (event.action === constants.MODAL_CONFIRM && this.saveError) {
        this.$emit('retry-save');
      }
    },
    open() {
      this.resetState();
      this.$refs.savingStatusModal.open();
    },
    resetState() {
      this.savingState = this.isSaving;
    },
  },
};
</script>
