<template>
  <nav
    v-show="menuVisible"
    ref="contextMenu"
    type="context"
    tabindex="0"
    class="context-menu"
    @blur="onBlur"
    @mouseleave="onBlur"
  >
    <slot />
  </nav>
</template>

<script>
export default {
  name: 'BaseContextMenu',
  components: {},
  props: {
    offsetX: {
      default: 0,
      type: Number,
    },
    offsetY: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      menuVisible: false,
    };
  },
  computed: {},
  methods: {
    closeMenu() {
      this.menuVisible = false;
    },
    onBlur() {
      this.menuVisible = false;
    },
    openMenu(x, y) {
      const useX = x;
      const useY = y;
      // TODO: The abs positioning of the row in the grid makes
      // this unreliable so for now open next to the mouse click
      // const parent = document.elementFromPoint(x, y);
      // if (parent) {
      //   const parentAt = parent.getBoundingClientRect();
      //   useX = parentAt.left;
      //   useY = parentAt.top;
      // }
      this.menuVisible = true;
      this.$nextTick(() => {
        const menuAt = this.$refs.contextMenu.getBoundingClientRect();
        const topAt = useY - menuAt.height / 2 - this.offsetY;
        const leftAt = useX - menuAt.width - this.offsetX;
        this.$refs.contextMenu.style.top = `${topAt}px`;
        this.$refs.contextMenu.style.left = `${leftAt}px`;
        this.$refs.contextMenu.focus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use "sass:math";
@import '@/assets/css/calc.scss';
@import '@/assets/css/variables.scss';
.context-menu {
  position: absolute;
  z-index: 9999;
  padding: 0;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: -4px 4px 20px #2222;
  overflow: hidden;
  ul li {
    padding: 0.5rem 0.75rem;
    text-align: left;
    line-height: 1.5;
    font-size: 1rem;
    display: block;
    border-bottom: 1px solid rgba(0, 38, 66, 0.06);
    min-width: rem(150px);
    cursor: pointer;
    &:hover,
    &:focus {
      background-color: $c-primary-light-blue;
      color: white;
      border-bottom: 0;
    }
    a,
    button {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
