import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getBrowserLocale, supportedLocalesInclude } from '@/util/i18nUtil';

Vue.use(VueI18n);

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true });

  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale;
  }
  return process.env.VUE_APP_I18N_LOCALE || 'en';
}

const startingLocale = getStartingLocale();

const i18n = new VueI18n({
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  locale: startingLocale,
  messages: {},
});

const loadedLanguages = [];

export function loadLocaleMessagesAsync(locale) {
  if (loadedLanguages.length > 0 && i18n.locale === locale) {
    return Promise.resolve(locale);
  }

  // If the language was already loaded
  if (loadedLanguages.includes(locale)) {
    i18n.locale = locale;
    return Promise.resolve(locale);
  }

  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "locale-[request]" */ `@/locales/${locale}.json`).then(
    messages => {
      i18n.setLocaleMessage(locale, messages.default);
      i18n.setLocaleMessage(locale, messages.default);
      loadedLanguages.push(locale);
      loadedLanguages.push(locale);
      i18n.locale = locale;
      return Promise.resolve(locale);
    },
  );
}

loadLocaleMessagesAsync(startingLocale);

export default i18n;
