<template>
  <!-- NOTE This is a workaround component of BaseModal from the component library.
  This should be replaced with the updated BaseModal component once the component
  allows for slots in the package.

  No changes have been made within <template /> or <script /> other than adding
  the <slot /> inside the body of the modal.
  -->
  <section>
    <slot name="modal-trigger" />
    <transition name="modal">
      <div
        v-show="modalOpen"
        class="c-modal-mask"
        @keydown="focusTrapOnKeydown"
        @click="onModalMaskClick"
      >
        <div
          ref="modalContainer"
          :aria-labelledby="idDialogLabel"
          role="dialog"
          class="c-modal"
          :class="modalSize"
          tabindex="0"
          @click.stop
        >
          <div :id="idDialogLabel" class="c-modal__heading-container">
            <h2 v-if="heading" class="c-modal__heading">
              {{ heading }}
            </h2>
            <p v-if="subHeading" class="c-modal__subheading">
              {{ subHeading }}
            </p>
          </div>
          <div class="c-modal__body-container">
            <slot />
          </div>
          <div v-if="actionOne" class="c-modal__action-container">
            <button
              ref="actionOne"
              class="c-modal__action c-modal__action-one"
              @click="onButtonClick('actionOne')"
            >
              {{ actionOne }}
            </button>
            <button
              v-if="actionTwo"
              ref="actionTwo"
              class="c-modal__action c-modal__action-two"
              :class="actionTwoClass"
              @click="onButtonClick('actionTwo')"
            >
              {{ actionTwo }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import uniqid from 'uniqid';
import VueTypes from 'vue-types';

export default {
  name: 'BaseModalCustom',
  props: {
    actionOne: VueTypes.oneOfType([String, null]),
    actionTwo: VueTypes.oneOfType([String, null]),
    actionTwoNegative: VueTypes.bool.def(false),
    closeOnEsc: VueTypes.bool.def(true),
    heading: VueTypes.oneOfType([String, null]),
    size: VueTypes.oneOf(['small', 'medium', 'large', 'xlarge']).def('medium'),
    subHeading: VueTypes.oneOfType([String, null]),
  },
  data() {
    return {
      idDialogLabel: uniqid('c-modal-dialog-'),
      idSubHeadingLabel: uniqid('c-modal-subheading-'),
      modalOpen: false,
    };
  },
  computed: {
    actionTwoClass() {
      const actionTwoNegative = this.actionTwoNegative ? 'negative' : 'positive';
      return `c-modal__action--${actionTwoNegative}`;
    },
    modalSize() {
      return `modal-${this.size}`;
    },
  },
  watch: {
    modalOpen() {
      document.body.classList.toggle('modal-open');

      this.$nextTick(() => {
        if (this.modalOpen) this.$refs.modalContainer.focus();
      });
    },
  },
  methods: {
    close() {
      this.modalOpen = false;
      this.$emit('closed');
    },
    focusTrapOnKeydown(event) {
      const { keyCode, shiftKey, target } = event;
      const focusable = this.$refs.modalContainer.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      );
      const firstFocusable = focusable[0];
      const lastFocusable = focusable[focusable.length - 1];

      const tab = event.key || keyCode;
      const esc = event.key || keyCode;

      if (tab === 'Tab' || tab === 9) {
        const focusForward = tab && !shiftKey && target === lastFocusable;
        const focusBackward = tab && shiftKey && target === firstFocusable;
        const focusLooping = focusForward || focusBackward;

        if (!focusLooping) {
          return;
        }

        event.preventDefault();

        (focusForward ? firstFocusable : lastFocusable).focus();
      }

      if (this.closeOnEsc && (esc === 'Escape' || esc === 'Esc' || esc === 27)) {
        this.close();
      }
    },
    onButtonClick(event) {
      this.$emit('modal-button-clicked', { action: event });
      this.close();
    },
    onModalMaskClick() {
      if (this.closeOnEsc) {
        this.close();
      }
    },
    open() {
      this.modalOpen = true;
      this.$emit('opened');
    },
  },
};
</script>

<style lang="scss">
body.modal-open {
  // Prevent scrolling the page when the modal is open
  overflow-y: hidden;
}
</style>

<style lang="scss" scoped>
// All dynamic CSS referencing other files in the BaseModal component in the
// component library have been hard-coded in for this workaround.
// The overflow-y for c-modal has been set for smaller screens.
$border: 0.0625rem solid #e1e5e9;

.c-modal-mask {
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // Center the modal
  display: flex;
  align-items: center;

  background: rgba(#19242c, 0.65);
}

.c-modal {
  max-width: 72rem;
  max-height: 95vh;
  margin: 0 auto;
  border-radius: 0.25rem;
  overflow-y: auto;
  z-index: 1200;

  background: #ffffff;

  &.modal-small {
    width: 25rem;
    @media (max-width: 30rem) {
      margin: 0 1rem;
    }
  }

  &.modal-medium {
    width: 35rem;
  }

  &.modal-large {
    width: 45rem;
  }

  &.modal-xlarge {
    width: 70rem;
  }
}

.c-modal__heading-container {
  padding: 1rem * 2 1rem 0 1rem;

  text-align: center;

  @media (min-width: 45rem) {
    padding: 1rem * 2 1rem * 2 0 1rem * 2;
  }
}

.c-modal__heading {
  font-weight: 500;
  color: #002642;
  font-size: 1.5rem;
  line-height: 1.3;

  @media (min-width: 45rem) {
    font-size: 1.75rem;
  }
}

.c-modal__subheading {
  font-size: 0.875rem;
  line-height: 1.5;
  color: #002642;
  opacity: 1;
  font-weight: 400;

  padding-top: 1rem;
  @media (min-width: 45rem) {
    padding: 1rem 1rem 1rem * 2 1rem;
  }
}

.c-modal__body-container {
  font-size: 0.85rem;
  line-height: 1.5;
  color: #002642;
  opacity: 1;
  font-weight: 400;
  padding: 1rem * 0.5 1rem * 0.5 1rem * 2 1rem * 0.5;
  text-align: center;
}

.c-modal__action-container {
  border-top: $border;
  display: flex;
  flex-direction: row;
}

.c-modal__action {
  background-color: transparent;
  color: inherit;
  border: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  border-radius: 0;
  appearance: none; // Just in case we missed anything.

  font-size: 0.875rem;
  line-height: 1.1;
  color: #002642;
  opacity: 1;
  font-weight: 500;
  flex: 1;
  padding: 1.25rem 1rem;

  text-align: center;
  cursor: pointer;

  transition: all 200ms cubic-bezier(0.35, 0.35, 0.05, 1);

  &:hover {
    text-decoration: none;
    background: #f4f6f8;
  }

  &:active {
    background: #e1e5e9;
  }

  &:not(:last-child) {
    border-right: $border;
  }
}

.c-modal__action--positive {
  color: #0070b0;
}

.c-modal__action--negative {
  // Darken the color slightly to boost color contrast
  color: #af4740;
}

// Modal transition timing values
.modal-enter-active {
  transition: all 350ms cubic-bezier(0.14, 0.39, 0, 1); // Modal mask fading in

  & .c-modal {
    transition: transform 325ms cubic-bezier(0.14, 0.39, 0, 1),
      opacity 200ms 25ms cubic-bezier(0.14, 0.39, 0, 1); // Modal fading in
  }
}

.modal-leave-active {
  transition: all 200ms cubic-bezier(0.8, 0, 0.8, 0.55); // Modal mask fading out

  & .c-modal {
    transition: all 175ms cubic-bezier(0.8, 0, 0.8, 0.55); // Modal fading out
  }
}

// Modal from/to values
.modal-enter,
.modal-leave-to {
  opacity: 0;

  & .c-modal {
    opacity: 0;
    transform: translateY(-1rem);
  }
}

.modal-enter-to,
.modal-leave {
  opacity: 1;

  & .c-modal {
    opacity: 1;
    transform: none;
  }
}
</style>
