<template>
  <BaseModalCustom
    ref="confirmationModal"
    class="c-modal__confirmation-modal"
    :class="`c-modal__confirmation-modal-${align}`"
    :action-one="cancelButton"
    :action-two="confirmButton"
    :action-two-negative="dangerConfirm"
    :close-on-esc="false"
    :heading="confirmTitle"
    :size="size"
    @modal-button-clicked="onModalButtonClicked"
  >
    <slot v-if="confirmMessage" name="modal-body">
      <p
        v-for="(line, index) in confirmMessageLines"
        :key="`line-${index}`"
        class="c-modal__subheading c-modal__subheading-line"
      >
        {{ line }}
      </p>
    </slot>
  </BaseModalCustom>
</template>

<script>
import BaseModalCustom from '@/components/BaseModalCustom.vue';
import constants from '@/config/constants';

export default {
  name: 'ConfirmationModal',
  components: { BaseModalCustom },
  props: {
    align: {
      default: 'center',
      type: String,
    },
    cancelButton: {
      type: String,
      required: true,
    },
    confirmButton: {
      type: String,
      required: true,
    },
    confirmMessage: {
      default: undefined,
      type: String,
    },
    confirmTitle: {
      type: String,
      required: true,
    },
    dangerConfirm: {
      default: true,
      type: Boolean,
    },
    size: {
      default: 'medium',
      type: String,
      validator: value => {
        // The value must match one of these strings
        return ['small', 'medium', 'large', 'xlarge'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    // Split the message into lines
    confirmMessageLines() {
      return this.confirmMessage ? this.confirmMessage.split('\n') : undefined;
    },
  },
  methods: {
    async close() {
      this.$refs.confirmationModal.close();
    },
    async onModalButtonClicked(event) {
      if (event.action === constants.MODAL_CONFIRM) {
        this.$emit('on-confirm-action');
      } else {
        this.$emit('on-cancel-action');
      }
      await this.close();
    },
    open() {
      this.$refs.confirmationModal.open();
    },
  },
};
</script>

<style lang="scss">
.c-modal__confirmation-modal {
  &.c-modal__confirmation-modal-left {
    .c-modal__heading,
    .c-modal__subheading,
    .c-modal__body-container {
      text-align: left;
    }
    .c-modal__body-container {
      padding-left: 0;
    }
  }
  &.c-modal__confirmation-modal-right {
    .c-modal__heading,
    .c-modal__subheading,
    .c-modal__body-container {
      text-align: right;
    }
    .c-modal__body-container {
      padding-right: 0;
    }
  }
  &.c-modal__confirmation-modal-center {
    .c-modal__heading,
    .c-modal__subheading,
    .c-modal__body-container {
      text-align: center;
    }
  }
  .c-modal__body-container {
    margin: 0 2rem;
    padding: 1rem 0 2rem 0;
  }
  .c-modal__subheading-line {
    margin-bottom: 1rem;
  }
}
</style>
