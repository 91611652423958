<template>
  <div id="Calendar" style="{ width: `${width}px` }"></div>
</template>

<script>
import * as d3 from 'd3';
import constants from '@/config/constants';
import i18n from '@/i18n';

const EVENT_HEIGHT_MINIMUM = 10;
const EVENT_OPACITY = 0.9;
const EVENT_PADDING = 5;
const EVENT_ROUNDING = 4;
const HIGHLIGHT_OFFSET = 4;
const HIGHLIGHT_WIDTH = 4;

export default {
  name: 'Calendar',

  props: {
    days: {
      type: Array,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    margins: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      calendarHeight: null,
      calendarWidth: null,
      eventWidth: null,
      svg: null,
    };
  },

  computed: {
    events() {
      return this.$store.getters['schedules/getAllShiftsByDay'];
    },
    uniqueShiftNames() {
      return this.$store.getters['schedules/getUniqueShiftNames'] || [];
    },
  },

  watch: {
    // when the events change rebuild the calendar
    events() {
      this.buildCalendar();
    },
    height() {
      this.buildCalendar();
    },
    width() {
      this.buildCalendar();
    },
  },

  mounted() {
    this.buildCalendar();
  },

  methods: {
    // build the calendar based on the given settings and events
    buildCalendar() {
      // clear any previously created events
      this.clearCalendar();

      // calculate the calendar height
      this.calendarHeight = this.height - this.margins.top - this.margins.bottom;

      // calculate the calendar width
      this.calendarWidth = this.width - this.margins.left - this.margins.right;

      // create the calendar
      this.createSVG();
      this.drawBackground();

      const dayScale = this.drawDayAxis();
      const hourScale = this.drawHourAxis();

      // calculate the event width based on the day scale minus padding
      this.eventWidth = dayScale() / this.days.length - EVENT_PADDING * 2;

      // add the events to the calendar
      for (let i = 0; i < this.events.length; i += 1) {
        if (this.events[i].isComplete()) {
          this.drawEvent(this.events[i], dayScale, hourScale);
        }
      }
    },

    // remove all elements from the svg
    clearCalendar() {
      d3.select('#Calendar > *').remove();
    },

    // create the given event based on the give data
    createEvent(event, scaledDay, scaledEnd, scaledStart) {
      // create a container for the event elements
      const container = this.svg.append('g');

      const colorIndex = (this.uniqueShiftNames.indexOf(event.name) % 8) + 1 || 1;

      let blockHeight = scaledEnd - scaledStart;
      /**
       * if the block height is less than the minimum height update it to the
       * minimum height
       *
       * this ensures that the block is visible but may result in overlaps
       */
      if (blockHeight < EVENT_HEIGHT_MINIMUM) {
        blockHeight = EVENT_HEIGHT_MINIMUM;
      }

      // create the event block
      container
        .append('rect')
        // set the background color
        .attr('class', `fill-current text-shift-lighter-${colorIndex}`)
        // set the end time relative to start time
        .attr('height', blockHeight)
        // set the event width
        .attr('width', this.eventWidth)
        .attr('opacity', EVENT_OPACITY)
        // set the rounding
        .attr('rx', EVENT_ROUNDING)
        .attr('ry', EVENT_ROUNDING)
        // move the event to the appropriate day including padding
        .attr('x', scaledDay + EVENT_PADDING)
        // set the start time relative to the beginning of the day
        .attr('y', scaledStart);

      // if there is space add the event highlight
      const highlightHeight = blockHeight - HIGHLIGHT_OFFSET * 2;
      if (highlightHeight > 0) {
        container
          .append('rect')
          // set the background color
          .attr('class', `fill-current text-shift-${colorIndex}`)
          // set the end time relative to start time minus offset
          .attr('height', highlightHeight)
          // set the event width
          .attr('width', HIGHLIGHT_WIDTH)
          // set the rounding
          .attr('rx', EVENT_ROUNDING)
          .attr('ry', EVENT_ROUNDING)
          // move the event to the appropriate day including padding
          .attr('x', scaledDay + EVENT_PADDING + HIGHLIGHT_OFFSET)
          // set the start time relative to the beginning of the day
          .attr('y', scaledStart + HIGHLIGHT_OFFSET);
      }

      // create a container for the event text
      const textContainer = container
        .append('text')
        // set the font color
        .attr('class', `fill-current text-shift-darker-${colorIndex}`)
        // add extra padding for text
        .attr('transform', `translate(${EVENT_PADDING}, ${EVENT_PADDING})`)
        // set the text start relative to the beginning of the day
        .attr('y', scaledStart);

      // declare eventWidth in local scope for use by truncate()
      const { eventWidth } = this;

      // set the event name text
      textContainer
        .append('tspan')
        .attr('class', 'text-xs')
        // move the text down relative to the top of the container
        .attr('dy', '1em')
        // move the text to the appropriate day including padding
        .attr('x', scaledDay + EVENT_PADDING + HIGHLIGHT_OFFSET * 2)
        // set the text to the event name
        .text(event.name)
        // ensure the text fits in the event block
        .each(function truncate() {
          // get the current text element
          const textElement = d3.select(this);
          // get the initial text width
          let textWidth = this.getComputedTextLength();
          // get the initial text
          let text = textElement.text();

          // while the text is too wide remove one character until it fits
          while (textWidth > eventWidth - EVENT_PADDING - HIGHLIGHT_OFFSET * 2) {
            text = text.slice(0, -1);
            textElement.text(`${text}...`);
            textWidth = this.getComputedTextLength();
          }

          // get the dimensions of the generated text element
          const textDimensions = this.getBoundingClientRect();

          // if the text element is taller than the available space, hide it
          if (textDimensions.height > scaledEnd - scaledStart) {
            textElement.classed('hidden', true);
          }
        });
    },

    // create the svg container
    createSVG() {
      this.svg = d3
        .select('#Calendar')
        .append('svg')
        // set the default color
        .attr('class', 'fill-current text-gray-5')
        // set the total height including margins
        .attr('height', this.height)
        // set the total width including margins
        .attr('width', this.width)
        .append('g')
        // allow space for the margins / axes
        .attr('transform', `translate(${this.margins.left}, ${this.margins.top})`);
    },

    // draw the calendar background
    drawBackground() {
      // create the svg background
      this.svg
        .append('rect')
        // set the background color
        .attr('class', 'fill-current text-gray-2')
        // set the calendar height not including margins
        .attr('height', this.calendarHeight)
        // set the calendar width not including margins
        .attr('width', this.calendarWidth);
    },

    // draw the day axis and grid lines
    drawDayAxis() {
      const dayRange = [];
      // create the day range based on the days, the range should have days + 1 elements
      for (let i = 0; i < this.days.length + 1; i += 1) {
        dayRange.push((i / this.days.length) * this.calendarWidth);
      }

      // create the day axis scale based on the days and calculated widths
      const dayScale = d3
        .scaleOrdinal()
        .domain(this.days)
        .range(dayRange);

      // create a day axis based on the day axis scale
      const dayAxis = d3.axisTop(dayScale);

      // append the day axis to the svg
      this.svg
        .append('g')
        .call(dayAxis)
        .selectAll('text')
        // set the day label color
        .attr('class', 'font-medium font-sans text-primary-dark text-sm')
        // give some space between calendar and the axis
        .attr('y', this.margins.top * -0.5)
        // move the day labels to the middle of the column
        .attr('x', (this.calendarWidth / this.days.length) * 0.5);

      // create a day axis grid with an inverted extended axis
      const dayGrid = dayAxis.tickFormat('').tickSize(-this.calendarHeight);

      // append the day axis grid to the svg
      this.svg.append('g').call(dayGrid);

      return dayScale;
    },

    // draw the given event using the given scales
    drawEvent(event, dayScale, hourScale) {
      const scaledDay = dayScale(i18n.t(`days.${event.day}`));
      const scaledEnd = hourScale(event.getEndHours());
      const scaledStart = hourScale(event.getStartHours());

      /**
       * if the event ends at midnight draw it to the bottom of the calendar
       * without overlapping
       */
      if (scaledEnd === 0) {
        this.createEvent(event, scaledDay, this.calendarHeight, scaledStart);
      }
      // else if the event overlaps the day boundary, split it and draw the pieces
      else if (scaledStart >= scaledEnd) {
        // create the end of day part of the event
        this.createEvent(event, scaledDay, this.calendarHeight, scaledStart);

        // add a day for the overlap
        let scaledDayOverlap = scaledDay + dayScale() / this.days.length;
        // unless the event overlaps the week boundary, then reset to the beginning of the week
        if (event.endTime.as('hours') > constants.WEEK_HOURS) {
          scaledDayOverlap = 0;
        }

        // create the overlapped start of day part of the event
        this.createEvent(event, scaledDayOverlap, scaledEnd, 0);
      }
      // else draw the event on the appropriate day
      else {
        this.createEvent(event, scaledDay, scaledEnd, scaledStart);
      }
    },

    drawHourAxis() {
      // create the hour axis scale based on the height
      const hourScale = d3
        .scaleLinear()
        .domain([0, constants.DAY_HOURS])
        .range([0, this.calendarHeight]);

      // create an hour axis based on the hour axis scale
      const hourAxis = d3
        .axisLeft(hourScale)
        .tickFormat(hour => {
          if (hour === 24) {
            return '';
          }

          return `${hour.toString().padStart(2, '0')}:00`;
        })
        .ticks(24);

      // append the hour axis to the svg
      this.svg
        .append('g')
        .call(hourAxis)
        // set the hour label color
        .selectAll('text')
        .attr('class', 'font-medium font-sans text-primary-dark text-sm');

      // create an hour axis grid with an inverted extended axis
      const hourGrid = hourAxis.tickFormat('').tickSize(-this.calendarWidth);

      // append the hour axis grid to the svg
      this.svg.append('g').call(hourGrid);

      return hourScale;
    },
  },
};
</script>
