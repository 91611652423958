import { PERMISSIONS } from '@seegrid/cerberus-api';
import constants from '@/config/constants';

export default {
  created() {
    this.CONTROL_LEVELS = constants.CONTROL_LEVELS;
    this.FEATURES = constants.FEATURES;
    this.PERMISSIONS = PERMISSIONS;
  },

  methods: {
    // returns true if the user has the given feature control or false otherwise
    hasFeatureControl(featureName, controlLevelObject, companyId = null) {
      return this.$store.getters['user/hasFeatureControl'](
        featureName,
        controlLevelObject,
        companyId,
      );
    },
  },
};
