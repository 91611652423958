import Schedule from '@/models/Schedule';
import Shift from '@/models/Shift';

/**
 * returns true if the given shift definition is valid or false if it overlaps
 * the existing shift definitions within the schedule
 */
const isValidShiftDefinition = (shifts, existingShifts, schedule) => {
  // verify that the arguments are valid
  if (
    !shifts ||
    !Array.isArray(shifts) ||
    !existingShifts ||
    !Array.isArray(existingShifts) ||
    !(schedule instanceof Schedule)
  ) {
    return false;
  }
  for (let i = 0; i < shifts.length; i += 1) {
    const currShiftStartTime = schedule.getShiftStartTimeBasedOnSchedule(shifts[i].startTime);
    const currShiftEndTime = Shift.getShiftEndTimeBasedOnStart(
      currShiftStartTime,
      shifts[i].endTime,
    );
    for (let j = 0; j < existingShifts.length; j += 1) {
      const otherShiftStartTime = schedule.getShiftStartTimeBasedOnSchedule(
        existingShifts[j].startTime,
      );
      const otherShiftEndTime = Shift.getShiftEndTimeBasedOnStart(
        otherShiftStartTime,
        existingShifts[j].endTime,
      );
      if (
        // verify that the start time does not overlap
        (currShiftStartTime.as('milliseconds') > otherShiftStartTime.as('milliseconds') &&
          currShiftStartTime.as('milliseconds') < otherShiftEndTime.as('milliseconds')) ||
        // verify that the end time does not overlap
        (currShiftEndTime.as('milliseconds') > otherShiftStartTime.as('milliseconds') &&
          currShiftEndTime.as('milliseconds') < otherShiftEndTime.as('milliseconds')) ||
        // verify that the entire shift does not overlap
        (otherShiftStartTime.as('milliseconds') >= currShiftStartTime.as('milliseconds') &&
          otherShiftEndTime.as('milliseconds') <= currShiftEndTime.as('milliseconds'))
      ) {
        return false;
      }
    }
  }
  return true;
};

export default isValidShiftDefinition;
