import constants from '@/config/constants';
import guard from '@/router/auth';
import environment from '@/util/environment';
import AuthErrorView from '@/views/AuthErrorView.vue';
import CompanySelectorView from '@/views/CompanySelectorView.vue';
import RouteNetworkUploadView from '@/views/RouteNetworkUploadView.vue';
import RouteNetworkView from '@/views/RouteNetworkView.vue';
import SPRConfigAddView from '@/views/SPRConfigAddView.vue';
import SPRConfigListView from '@/views/SPRConfigListView.vue';
import SchedulesView from '@/views/SchedulesView.vue';
import SitesView from '@/views/SitesView.vue';
import UsersView from '@/views/UsersView.vue';

/**
 * admin base url relative to the configured VUE_APP_BASE_URL_ADMIN environment
 * setting used by vue router for the publicPath
 *
 * navigated to with vue router
 */
const BASE_URL_ADMIN = '/';

/**
 * dashboard base url
 *
 * navigated to with window.location
 */
const BASE_URL_DASHBOARD = environment.VUE_APP_BASE_URL_DASHBOARD;

const PATHS = {
  AUTH_ERROR: `${BASE_URL_DASHBOARD}#/autherror`,
  DASHBOARD: BASE_URL_DASHBOARD,
  HOME: BASE_URL_ADMIN,
  ROUTE_NETWORK: `${BASE_URL_ADMIN}route-network`,
  ROUTE_NETWORK_UPLOAD: `${BASE_URL_ADMIN}route-network/upload`,
  SCHEDULES: `${BASE_URL_ADMIN}schedules`,
  SITES: `${BASE_URL_ADMIN}sites`,
  SPR_CONFIGURATION: `${BASE_URL_ADMIN}spr-configuration`,
  SPR_CONFIGURATION_ADD: `${BASE_URL_ADMIN}spr-configuration/add`,
  USERS: `${BASE_URL_ADMIN}users`,
  USERS_BULK_ADD: `${BASE_URL_ADMIN}users/add`,
  USERS_INTERNAL: `${BASE_URL_ADMIN}users/internal`,
};

const routes = [
  {
    component: AuthErrorView,
    name: 'AuthErrorView',
    path: PATHS.AUTH_ERROR,
    props: true,
  },
  {
    beforeEnter: guard,
    component: CompanySelectorView,
    name: 'home',
    path: PATHS.HOME,
  },
  {
    // redirect to the dashboard overview page
    beforeEnter() {
      window.location.assign(PATHS.DASHBOARD);
    },
    name: 'dashboard',
    path: PATHS.DASHBOARD,
  },
  {
    component: RouteNetworkView,
    meta: {
      featureControl: [
        {
          controlLevel: constants.CONTROL_LEVELS.VIEWER,
          feature: constants.FEATURES.MAP,
        },
      ],
      redirectURL: PATHS.SITES,
    },
    name: 'routeNetwork',
    path: PATHS.ROUTE_NETWORK,
  },
  {
    beforeEnter: guard,
    component: RouteNetworkUploadView,
    meta: {
      featureControl: [
        {
          controlLevel: constants.CONTROL_LEVELS.VIEWER,
          feature: constants.FEATURES.MAP,
        },
      ],
      redirectURL: PATHS.SITES,
    },
    name: 'routeNetworkUpload',
    path: PATHS.ROUTE_NETWORK_UPLOAD,
  },
  {
    beforeEnter: guard,
    component: SchedulesView,
    meta: {
      featureControl: [
        {
          controlLevel: constants.CONTROL_LEVELS.VIEWER,
          feature: constants.FEATURES.SCHEDULE,
        },
      ],
      redirectURL: PATHS.SITES,
    },
    name: 'schedules',
    path: PATHS.SCHEDULES,
  },
  {
    beforeEnter: guard,
    component: SitesView,
    name: 'sites',
    path: PATHS.SITES,
  },
  {
    beforeEnter: guard,
    component: SPRConfigListView,
    meta: {
      featureControl: [
        {
          controlLevel: constants.CONTROL_LEVELS.VIEWER,
          feature: constants.FEATURES.SPR,
        },
      ],
      redirectURL: PATHS.SITES,
    },
    name: 'sprConfigList',
    path: PATHS.SPR_CONFIGURATION,
  },
  {
    beforeEnter: guard,
    component: SPRConfigAddView,
    meta: {
      featureControl: [
        {
          controlLevel: constants.CONTROL_LEVELS.EDITOR,
          feature: constants.FEATURES.SPR,
        },
      ],
      redirectURL: PATHS.SPR_CONFIGURATION,
    },
    name: 'sprConfigAdd',
    path: PATHS.SPR_CONFIGURATION_ADD,
  },
  {
    beforeEnter: guard,
    component: UsersView,
    meta: {
      featureControl: [
        {
          controlLevel: constants.CONTROL_LEVELS.VIEWER,
          feature: constants.FEATURES.USER,
        },
      ],
      redirectURL: PATHS.SITES,
    },
    name: 'users',
    path: PATHS.USERS,
  },
];

export default routes;
