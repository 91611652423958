<template>
  <div class="flex flex-col h-full items-center w-full">
    <div class="border-opacity-10 bottom-border flex items-center pb-2 w-full">
      <BaseButton
        button-type="ghost"
        icon="arrow-left"
        icon-height="24px"
        icon-width="24px"
        viewBox="0 0 16 16"
        just-icon
        :icon-sprite="iconSprite"
        @click="handleClose"
      />
      <div v-if="site">
        <h2 class="font-medium mx-2 text-2.5xl text-primary-dark">{{ site.name }}</h2>
      </div>
      <h2 class="font-medium text-2.5xl text-primary-dark">{{ $t('map.uploadMapTitle') }}</h2>
    </div>
    <div class="bg-gray-4 flex-grow m-16 rounded-lg w-full">
      <RouteNetworkDisplay :route-network="siteRouteNetwork" class="h-full" />
    </div>
    <div class="flex justify-center w-full">
      <template v-if="!isProcessed">
        <BaseButton
          v-if="hasFeatureControl(FEATURES.MAP, CONTROL_LEVELS.EDITOR)"
          class="mx-2"
          :button-type="'tertiary'"
          @click="handleDelete"
          >{{ $t('map.delete') }}</BaseButton
        >
        <BaseButton
          v-if="hasFeatureControl(FEATURES.MAP, CONTROL_LEVELS.EDITOR)"
          class="mx-2"
          :disabled="processButtonDisabled"
          @click="handleProcess"
          >{{ processButtonText }}</BaseButton
        >
      </template>
      <template v-else>
        <BaseButton
          v-if="hasFeatureControl(FEATURES.MAP, CONTROL_LEVELS.EDITOR)"
          class="mx-2"
          @click="handleReplace"
          >{{ $t('map.replace') }}</BaseButton
        >
      </template>
    </div>
  </div>
</template>

<script>
import { BaseButton } from '@seegrid/components';
import IconSprite from '@/assets/images/icon-sprite.svg';
import RouteNetworkDisplay from '@/components/RouteNetworkDisplay.vue';
import constants from '@/config/constants';
import authorization from '@/mixins/authorization';
import SupervisorBackupImport from '@/models/SupervisorBackupImport';

export default {
  name: 'RouteNetwork',

  components: {
    BaseButton,
    RouteNetworkDisplay,
  },

  mixins: [authorization],

  computed: {
    // returns the path of the icon svg for use by the icon component
    iconSprite() {
      return IconSprite;
    },

    // returns true if the route network is completely processed or false otherwise
    isProcessed() {
      if (!(this.siteRouteNetworkImport instanceof SupervisorBackupImport)) {
        return false;
      }

      return this.siteRouteNetworkImport.isExtractRouteNetworkComplete();
    },

    // set the process button state based on the current state of the import
    // unsaved-change is set to true in parent when Confirm / Retry button is visible
    // unsaved-change is used to prompt user while navigating away from page
    processButtonDisabled() {
      if (!(this.siteRouteNetworkImport instanceof SupervisorBackupImport)) {
        this.$emit('unsaved-changes', false);
        return true;
      }

      /**
       * the button should be enabled if the map was successfully extracted or
       * if there was an error during route network processing
       */
      const disabled = !(
        this.siteRouteNetworkImport.isExtractMapImageComplete() ||
        this.siteRouteNetworkImport.hasExtractRouteNetworkError()
      );
      if (!disabled && !this.isProcessed) {
        this.$emit('unsaved-changes', true);
      } else {
        this.$emit('unsaved-changes', false);
      }
      return disabled;
    },

    // returns the process button text based on the current state of the import
    processButtonText() {
      if (
        this.siteRouteNetworkImport instanceof SupervisorBackupImport &&
        this.siteRouteNetworkImport.hasExtractRouteNetworkError()
      ) {
        return this.$t('map.retry');
      }

      return this.$t('map.finish');
    },

    // returns the current site
    site() {
      return this.$store.getters['sites/getSelectedSite'];
    },

    // returns the route network for the current site
    siteRouteNetwork() {
      return this.$store.getters['routeNetworks/getSiteRouteNetwork'](this.site.id);
    },

    // returns the route network import for the site
    siteRouteNetworkImport() {
      return this.$store.getters['routeNetworkImports/getSiteRouteNetworkImport'](this.site.id);
    },
  },

  methods: {
    // event handler for closing the page
    handleClose() {
      this.$emit('navigate', 'sites');
    },

    // event handler for deleting the backup
    async handleDelete() {
      this.$emit('unsaved-changes', false);
      await this.$store.dispatch('routeNetworks/setLoading', true);
      // delete the in progress import
      try {
        await this.$store.dispatch('routeNetworkImports/deleteRouteNetworkImport', {
          routeNetworkImport: this.siteRouteNetworkImport,
        });
      } catch (error) {
        this.$emit('api-error', constants.API_ERROR_TYPES.DELETE);
        return;
      }

      await this.$router.push({ name: 'routeNetworkUpload' });
    },

    // event handler for processing the route network
    async handleProcess() {
      this.$emit('unsaved-changes', false);
      await this.$store.dispatch('routeNetworks/setLoading', true);

      try {
        await this.$store.dispatch('routeNetworkImports/importRouteNetwork', {
          routeNetworkImport: this.siteRouteNetworkImport,
          type: constants.SUPERVISOR_BACKUP_EXTRACTION_TYPES.EXTRACT_ROUTE_NETWORK,
        });
      } catch (error) {
        /**
         * TODO https://youtrack.dev.seegrid.com/issue/RA-243
         * this event is not properly handled by RouteNetworkView
         *
         * the current theory is that the event handler is not triggered due to
         * the combination of v-else and @api-error on the RouteNetwork component
         */
        this.$emit('api-error', constants.API_ERROR_TYPES.PROCESS);

        // TODO this should return early when the error message is properly displayed
        // return;
      }

      await this.$router.push({ name: 'sites' });
    },

    // event handler for replacing the route network
    handleReplace() {
      this.$emit('navigate', 'routeNetworkUpload');
    },
  },
};
</script>
