<template>
  <div class="border-b border-opacity-10 border-primary-dark p-4">
    <div class="c-scheduleform-container" :class="{ 'has-overlap': hasOverlap() }">
      <InputText
        class="c-scheduleform-input"
        :error="errorScheduleName"
        :placeholder="$t('schedules.scheduleName')"
        :value="scheduleName"
        @input="onInput('name', $event)"
      />
      <BaseButton
        button-type="ghost"
        class="c-scheduleform-trash ml-2"
        icon="trash"
        icon-height="16px"
        icon-width="16px"
        viewBox="0 0 24 24"
        just-icon
        @click="deleteSchedule"
      />
      <div class="push" />
      <BaseButton
        button-type="ghost"
        class="c-scheduleform-toggle"
        :class="accordionClasses"
        icon="chevron-down"
        icon-height="24px"
        icon-width="24px"
        viewBox="0 0 24 24"
        just-icon
        @click="toggleShifts"
      />
    </div>
    <div v-if="showShiftForm" class="max-w-sm mt-4">
      <InputCheckBox
        class="mb-4"
        :error="errorDaysOfWeek"
        :options="weekdayOptions"
        :value="schedule.daysOfWeek"
        @input="onInput('daysOfWeek', $event)"
      />
      <p class="mb-2 text-primary-dark text-sm">{{ $t('schedules.customerDayHelpText') }}</p>
      <div class="c-time-inputs-container flex justify-between">
        <InputTime
          class="text-primary-dark"
          :container-id="schedulesContainerId"
          :error="errorStartTime"
          :label="$t('formShift.from')"
          :value="schedule.startTimeOfDay"
          :placeholder="$t('formShift.startTime')"
          @input="onInput('startTimeOfDay', $event)"
        />
        <InputTime
          class="ml-4 text-primary-dark"
          :container-id="schedulesContainerId"
          :error="errorEndTime"
          :label="$t('formShift.to')"
          :value="schedule.endTimeOfDay"
          :placeholder="$t('formShift.endTime')"
          @input="onInput('endTimeOfDay', $event)"
        />
      </div>
      <p v-if="errorScheduleOver24Hours || errorScheduleOverlap" class="input-error-text ml-0">
        {{ errorScheduleOver24Hours || errorScheduleOverlap }}
      </p>
      <FormShift
        ref="formShift"
        :schedule-index="index"
        :shifts="schedule.shifts"
        @trigger-validate="onValidate"
      />
    </div>
  </div>
</template>

<script>
import { BaseButton } from '@seegrid/components';
import get from 'lodash-es/get';
import { required } from 'vuelidate/lib/validators';
import FormShift from '@/components/FormShift.vue';
import InputCheckBox from '@/components/inputs/InputCheckBox.vue';
import InputText from '@/components/inputs/InputText.vue';
import InputTime from '@/components/inputs/InputTime.vue';
import constants from '@/config/constants';
import i18n from '@/i18n';
import getScheduleCommonDays from '@/util/getScheduleCommonDays';
import getScheduleOverlaps from '@/util/getScheduleOverlaps';
import isValidTime from '@/util/isValidTime';
import {
  scheduleMax24Hours,
  scheduleMustNotOverlap,
  scheduleMustNotStartSameDay,
  scheduleNameMustBeUnique,
} from '@/util/vuelidateHelper';

const scheduleCustomValidations = [
  { errorCode: 'scheduleNameMustBeUnique', key: 'name.scheduleNameMustBeUnique' },
  { errorCode: 'scheduleMax24Hours', key: 'endTimeOfDay.scheduleMax24Hours' },
  { errorCode: 'noOverlappingSchedules', key: 'scheduleMustNotOverlap' },
  { errorCode: 'noOverlappingSchedules', key: 'scheduleMustNotStartSameDay' },
];

export default {
  name: 'ScheduleForm',
  components: {
    BaseButton,
    FormShift,
    InputCheckBox,
    InputText,
    InputTime,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    schedule: {
      type: Object,
      required: true,
    },
    validate: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localErrorCode: '',
      schedulesContainerId: constants.SCHEDULE_LIST_ELEMENT_ID,
      showShiftForm: false,
      submitted: false,
      weekdayOptions: [
        { text: i18n.t('daysAbbreviations.Monday'), value: constants.DAYS.MONDAY },
        { text: i18n.t('daysAbbreviations.Tuesday'), value: constants.DAYS.TUESDAY },
        { text: i18n.t('daysAbbreviations.Wednesday'), value: constants.DAYS.WEDNESDAY },
        { text: i18n.t('daysAbbreviations.Thursday'), value: constants.DAYS.THURSDAY },
        { text: i18n.t('daysAbbreviations.Friday'), value: constants.DAYS.FRIDAY },
        { text: i18n.t('daysAbbreviations.Saturday'), value: constants.DAYS.SATURDAY },
        { text: i18n.t('daysAbbreviations.Sunday'), value: constants.DAYS.SUNDAY },
      ],
    };
  },

  validations: {
    schedule: {
      daysOfWeek: {
        required,
        scheduleMustNotStartSameDay,
      },
      endTimeOfDay: {
        isValidTime,
        required,
        scheduleMax24Hours,
      },
      name: {
        required,
        scheduleNameMustBeUnique,
      },
      scheduleMustNotOverlap,
      startTimeOfDay: {
        isValidTime,
        required,
        scheduleMax24Hours,
      },
    },
  },
  computed: {
    accordionClasses() {
      return this.showShiftForm && 'c-scheduleform--shift-expanded';
    },
    // returns currently displayed error code
    currentErrorCode() {
      return this.$store.getters['message/getCode'];
    },
    // returns an error message if the days of week field is invalid or null otherwise
    errorDaysOfWeek() {
      if (this.$v.schedule.daysOfWeek.$dirty && !this.$v.schedule.daysOfWeek.required) {
        return this.$t('validation.requiredDaysOfWeek');
      }
      if (
        this.$v.schedule.daysOfWeek.$dirty &&
        !this.$v.schedule.daysOfWeek.scheduleMustNotStartSameDay
      ) {
        const validSchedules = this.schedules.filter(
          (schedule, index) => this.index !== index && schedule.isComplete(),
        );
        const { days = [], schedules = [] } = getScheduleCommonDays(this.schedule, validSchedules);
        const labelDays = days.map(d => this.$t(`days.${d}`));
        const msg =
          labelDays.length > 0 && schedules.length > 0
            ? this.$t('validation.scheduleMustNotStartOnSameDay', {
                days: labelDays.join(', '),
                schedules: schedules.join(', '),
              })
            : this.$t('validation.scheduleMustNotOverlapDefault');
        return msg;
      }

      return null;
    },
    // returns an error message if the end time field is invalid or null otherwise
    errorEndTime() {
      if (this.$v.schedule.endTimeOfDay.$dirty) {
        if (!this.$v.schedule.endTimeOfDay.required) {
          return this.$t('validation.requiredTime');
        }
        if (!this.$v.schedule.endTimeOfDay.isValidTime) {
          return this.$t('validation.isValidTime');
        }
        // returning blank as we just want to highlight control in this case without an error message
        if (!this.$v.schedule.endTimeOfDay.scheduleMax24Hours) {
          return ' ';
        }
      }
      return null;
    },
    // returns an error message if the schedule name field is invalid or null otherwise
    errorScheduleName() {
      if (this.$v.schedule.name.$dirty && !this.$v.schedule.name.required) {
        return this.$t('validation.requiredShiftName');
      }

      if (!this.$v.schedule.name.scheduleNameMustBeUnique) {
        return this.$t('validation.scheduleNameMustBeUnique');
      }
      return null;
    },
    errorScheduleOver24Hours() {
      if (
        (this.$v.schedule.endTimeOfDay.$dirty || this.$v.schedule.startTimeOfDay.$dirty) &&
        !this.$v.schedule.endTimeOfDay.scheduleMax24Hours
      ) {
        return this.$t('validation.scheduleOver24Hours');
      }
      return null;
    },
    // returns an error message if Schedules overlap
    errorScheduleOverlap() {
      if (
        (this.$v.schedule.daysOfWeek.$dirty ||
          this.$v.schedule.endTimeOfDay.$dirty ||
          this.$v.schedule.startTimeOfDay.$dirty) &&
        !this.$v.schedule.scheduleMustNotOverlap
      ) {
        const validSchedules = this.schedules.filter(
          (schedule, index) => this.index !== index && schedule.isComplete(),
        );
        const { schedules = [] } = getScheduleOverlaps(this.schedule, validSchedules);
        const msg =
          schedules.length > 0
            ? this.$t('validation.scheduleMustNotOverlap', {
                schedules: schedules.join(', '),
              })
            : this.$t('validation.scheduleMustNotOverlapDefault');
        return msg;
      }

      return null;
    },
    // returns an error message if the start time field is invalid or null otherwise
    errorStartTime() {
      if (this.$v.schedule.startTimeOfDay.$dirty) {
        if (!this.$v.schedule.startTimeOfDay.required) {
          return this.$t('validation.requiredTime');
        }
        if (!this.$v.schedule.startTimeOfDay.isValidTime) {
          return this.$t('validation.isValidTime');
        }
        // returning blank as we just want to highlight control in this case without an error message
        if (!this.$v.schedule.startTimeOfDay.scheduleMax24Hours) {
          return ' ';
        }
      }
      return null;
    },
    scheduleName() {
      return this.schedule.name || '';
    },
    // returns the current schedules for use by validation
    schedules() {
      return this.$store.getters['schedules/getCurrentSchedules'];
    },
    // returns the current site id
    siteId() {
      return this.$store.getters['sites/getSelectedSiteId'];
    },
  },
  watch: {
    // run form validation when triggered by parent
    validate(newValue) {
      // ignore flag reset
      if (newValue) {
        this.submitted = true;
        this.$v.$touch();
      }
    },
  },
  updated() {
    this.updateValidationErrors();
  },
  methods: {
    clearErrorMessage(code) {
      if (this.currentErrorCode === `schedules.errors.${code}`) {
        this.$store.dispatch('message/hide');
      }
    },
    deleteSchedule() {
      this.$store.dispatch('schedules/deleteSchedule', { scheduleIndex: this.index });
      this.onValidate();
    },
    // display the error message for the given type
    displayErrorMessage(code) {
      this.$store.dispatch('message/display', {
        code: `schedules.errors.${code}`,
        text: this.$t(`schedules.errors.${code}`),
        type: 'negative',
      });
    },
    hasOverlap() {
      const result = !this.$v.schedule.scheduleMustNotOverlap;

      this.$store.dispatch('schedules/setScheduleOverlap', {
        scheduleIndex: this.index,
        siteId: this.siteId,
        value: result,
      });

      return result;
    },
    // event handler for updating a field value
    async onInput(field, value) {
      this.$v.schedule[field].$touch();
      await this.$store.dispatch('schedules/setScheduleValue', {
        field,
        scheduleIndex: this.index,
        siteId: this.siteId,
        validate: this.submitted,
        value,
      });

      // validate the current overlap state of all shift definitions
      // await this.$store.dispatch('schedules/validateOverlap');
    },
    onValidate() {
      this.$emit('trigger-validate');
    },
    toggleShifts() {
      this.showShiftForm = !this.showShiftForm;
    },
    // check validations that triggers global error
    updateValidationErrors() {
      const validationErrorCodes = scheduleCustomValidations.map(
        v => `schedules.errors.${v.errorCode}`,
      );
      let foundErrors = false;
      for (let i = 0; i < scheduleCustomValidations.length; i += 1) {
        const validation = scheduleCustomValidations[i];
        // Get Validation status, true => Valid / default, false => validation failed
        const isValid = get(this.$v.schedule, validation.key, true);
        if (!isValid) {
          if (this.currentErrorCode !== validation.errorCode) {
            this.localErrorCode = validation.errorCode;
            this.displayErrorMessage(validation.errorCode);
          }
          foundErrors = true;
          break;
        }
      }
      // Didnt find any shift errors above. Checking error displayed if any from shifts and clear
      if (!foundErrors && this.localErrorCode) {
        if (this.currentErrorCode === `schedules.errors.${this.localErrorCode}`) {
          const clearErrorCode = get(
            scheduleCustomValidations,
            `[${validationErrorCodes.indexOf(this.currentErrorCode)}].errorCode`,
          );
          this.clearErrorMessage(clearErrorCode);
        }
        this.localErrorCode = '';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@seegrid/components/assets/scss/variables' as var;
@use '@seegrid/components/assets/scss/tools' as t;

.c-scheduleform-container {
  display: flex;
}
.c-scheduleform-input {
  flex-grow: 1;
  max-width: 18rem;
  order: 0;
}
.c-scheduleform-trash {
  order: 1;
}
.c-scheduleform-toggle {
  order: 3;
}
.push {
  margin-left: auto;
  order: 2;
}
.c-scheduleform--shift-expanded {
  transform: rotate(180deg);
  transition: transform var.$duration var.$timing-function-ease-in;
}
div.c-time-inputs-container ::v-deep {
  div.c-end-time > div.flex {
    justify-content: flex-end;
  }
  div.input-time > p.input-error-text {
    margin: 0;
    max-width: 8rem;
    text-align: right;
    padding-left: 1.1rem;
    line-height: 0.875rem;
  }
}
</style>
