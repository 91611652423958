import i18n from '@/i18n';

export default function getErrorMessage(v) {
  if (!v.$dirty) {
    return '';
  }

  const validationsToCheck = Object.keys(v.$params);

  if (!v.required && validationsToCheck.includes('required')) {
    return i18n.t('validation.required');
  }
  if (!v.maxLength && validationsToCheck.includes('maxLength')) {
    return i18n.t('validation.maxLength', { maxChars: v.$params.maxLength.max });
  }
  if (!v.email && validationsToCheck.includes('email')) {
    return i18n.t('validation.email');
  }

  // Found an error not found above, probably want to add a specific message for the specific validation criteria
  if (v.$error) {
    return i18n.t('validation.genericError');
  }

  return '';
}
