import intersection from 'lodash-es/intersection';
import Schedule from '@/models/Schedule';

const getScheduleCommonDays = (schedule, allSchedules) => {
  const days = new Set();
  const schedules = new Set();
  // verify that the arguments are valid
  if (!(schedule instanceof Schedule) || !allSchedules || !Array.isArray(allSchedules)) {
    return null;
  }

  // Get days in other Schedules
  // Check if the days in schedule are not present in all other schedules. intersection will return common days
  allSchedules.forEach(s => {
    const commonDays = intersection(schedule.daysOfWeek, s.daysOfWeek);
    if (commonDays && commonDays.length > 0) {
      schedules.add(s.name);
      commonDays.forEach(day => days.add(day));
    }
  });
  return { days: Array.from(days), schedules: Array.from(schedules) };
};

export default getScheduleCommonDays;
